import React, { useEffect, useState, useContext } from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { Aes256Encrypt, Decrypt_Id_Name, base64ToString, filterPassedDateTime, filterPassedTime, getShowingDateText, getShowingMonthDateYear, getShowingWithOutTime, stringToBase64, tableCustomStyles } from '../../../../Common/Utility';
import { AddDeleteUpadate, AddDelete_Img, fetchPostData } from '../../../../hooks/Api';
import { toastifyError, toastifySuccess } from '../../../../Common/AlertMsg';
import { AgencyContext } from '../../../../../Context/Agency/Index';
import { RequiredFieldIncident } from '../../../Utility/Personnel/Validation';
import ConfirmModal from '../../ConfirmModal';
import defualtImage from '../../../../../img/uploadImage.png'
import { Carousel } from 'react-responsive-carousel';
import DeletePopUpModal from '../../../../Common/DeleteModal';
import ChangesModal from '../../../../Common/ChangesModal';
import IdentifyFieldColor from '../../../../Common/IdentifyFieldColor';
import DataTable from 'react-data-table-component';
import MasterNameModel from '../../../MasterNameModel/MasterNameModel';
import { useDispatch, useSelector } from 'react-redux';
import { get_Inc_ReportedDate, get_LocalStoreData } from '../../../../../redux/actions/Agency';
import ImageModel from '../../../ImageModel/ImageModel';
import { get_AgencyOfficer_Data, get_ArrestJuvenileDis_DrpData, get_ArrestType_Drp, get_ArresteeName_Data, get_Masters_Name_Drp_Data } from '../../../../../redux/actions/DropDownsData';
import { Comman_changeArrayFormat } from '../../../../Common/ChangeArrayFormat';
import { get_ScreenPermissions_Data } from '../../../../../redux/actions/IncidentAction';
import ListModal from '../../../Utility/ListManagementModel/ListModal';
import { PhoneField } from '../../../Agency/AgencyValidation/validators';

const Home = ({ setShowJuvinile, setShowPoliceForce, DecArrestId, setStatus }) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const localStoreData = useSelector((state) => state.Agency.localStoreData);
    const uniqueId = sessionStorage.getItem('UniqueUserID') ? Decrypt_Id_Name(sessionStorage.getItem('UniqueUserID'), 'UForUniqueUserID') : '';

    const arresteeNameData = useSelector((state) => state.DropDown.arresteeNameData);
    const mastersNameDrpData = useSelector((state) => state.DropDown.mastersNameDrpData);
    const agencyOfficerDrpData = useSelector((state) => state.DropDown.agencyOfficerDrpData);
    const arrestTypeDrpData = useSelector((state) => state.DropDown.arrestTypeDrpData);
    const arrestJuvenileDisDrpData = useSelector((state) => state.DropDown.arrestJuvenileDisDrpData);
    const effectiveScreenPermission = useSelector((state) => state.Incident.effectiveScreenPermission);
    const incReportedDate = useSelector((state) => state.Agency.incReportedDate);

    const useQuery = () => {
        const params = new URLSearchParams(useLocation().search);
        return {
            get: (param) => params.get(param)
        };
    };

    let DecEIncID = 0

    const query = useQuery();
    var IncID = query?.get("IncId");
    var ArrestId = query?.get("ArrestId");
    var IncNo = query?.get("IncNo");
    var IncSta = query?.get("IncSta");
    var ArrNo = query?.get("ArrNo");
    var Name = query?.get("Name");
    let MstPage = query?.get('page');
    let ChargeSta = query?.get('ChargeSta');
    var ArrestSta = query?.get('ArrestSta');

    if (!IncID) { DecEIncID = 0; }
    else { DecEIncID = parseInt(base64ToString(IncID)); }

    const { get_Arrest_Count, setArrestName, ArresteName, arrestFilterData, get_Data_Arrest, policeForceDrpData, get_Police_Force, changesStatusCount, setChangesStatus, get_Incident_Count, setActiveArrest } = useContext(AgencyContext);

    const [arrestDate, setArrestDate] = useState();
    const [rightGivenCode, setRightGivenCode] = useState('N');
    const [ArrestID, setArrestID] = useState('');
    const [Editval, setEditval] = useState();
    const [showModal, setShowModal] = useState(false);
    const [arresteeChange, setArresteeChange] = useState();
    const [clickedRow, setClickedRow] = useState(null);
    const [modalStatus, setModalStatus] = useState(false);
    const [imageid, setImageId] = useState('');
    const [multiImage, setMultiImage] = useState([]);
    const [MainIncidentID, setMainIncidentID] = useState('');
    const [loginAgencyID, setloginAgencyID] = useState('');
    const [loginPinID, setloginPinID,] = useState('');

    const [nameModalStatus, setNameModalStatus] = useState(false);
    const [possessionID, setPossessionID] = useState('');
    const [possenSinglData, setPossenSinglData] = useState([]);
    const [uploadImgFiles, setuploadImgFiles] = useState([]);
    const [imageModalStatus, setImageModalStatus] = useState(false);
    const [mstNameID, setMstNameID] = useState(0);
    const [arrestingAgencyDrpData, setAgencyNameDrpData] = useState([]);
    const [isDatePickerRequiredColor, setDatePickerRequiredColor] = useState(false);
    const [openPage, setOpenPage] = useState('');
    const [statesChangeStatus, setStatesChangeStatus] = useState(false);

    const type = "ArrestMod"

    const [value, setValue] = useState({
        'ArrestID': '', 'AgencyID': '', 'ArrestNumber': '', 'IncidentID': '', 'CreatedByUserFK': '', 'IsJuvenileArrest': '', 'ArrestDtTm': '', 'ArrestingAgency': '',
        'ArrestTypeID': '', 'SupervisorID': '', 'PoliceForceID': '', 'RightsGivenID': '', 'JuvenileDispositionID': '', 'PhoneNo': '', 'PrimaryOfficerID': '',
        'GivenByID': '', 'ArresteeID': '', 'ArresteeLable': 0, 'ModifiedByUserFK': '',
    });

    const [errors, setErrors] = useState({
        'ArresteeIDError': '', 'PrimaryOfficerIDError': '', 'ArrestDtTmError': '',
    })

    const [imgData, setImgData] = useState({
        "PictureTypeID": '', "ImageViewID": '', "ImgDtTm": '', "OfficerID": '', "Comments": '', "DocumentID": ''
    })

    useEffect(() => {
        if (DecEIncID) {
            setMainIncidentID(DecEIncID); dispatch(get_ArresteeName_Data('', '', DecEIncID));
            if (!incReportedDate) { dispatch(get_Inc_ReportedDate(DecEIncID)) }
        }
        if (MstPage === "MST-Arrest-Dash" && possessionID) {
            dispatch(get_Masters_Name_Drp_Data(possessionID, 0, 0));
        }
    }, [DecEIncID, nameModalStatus, possessionID]);


    useEffect(() => {
        if (possessionID) {
            checkSelectedName(parseInt(possessionID))
        }
    }, [possessionID, arresteeNameData]);

    useEffect(() => {
        if (!localStoreData?.AgencyID || !localStoreData?.PINID) {
            if (uniqueId) dispatch(get_LocalStoreData(uniqueId));
        }
    }, []);

    useEffect(() => {
        if (localStoreData) {
            setloginAgencyID(localStoreData?.AgencyID); setloginPinID(localStoreData?.PINID); get_Arrest_Count(DecArrestId)
            dispatch(get_ScreenPermissions_Data("A067", localStoreData?.AgencyID, localStoreData?.PINID));
        }
    }, [localStoreData]);

    useEffect(() => {
        if (loginAgencyID) {
            setValue({ ...value, 'IncidentID': DecEIncID, 'ArrestID': DecArrestId, 'CreatedByUserFK': loginPinID, 'AgencyID': loginAgencyID });
            get_Data_Arrest(DecEIncID, MstPage === "MST-Arrest-Dash" ? true : false); get_Arresting_DropDown(loginAgencyID, loginPinID);
            if (agencyOfficerDrpData?.length === 0) dispatch(get_AgencyOfficer_Data(localStoreData?.AgencyID, DecEIncID))
            if (arrestTypeDrpData?.length === 0) dispatch(get_ArrestType_Drp(loginAgencyID));
            if (arrestJuvenileDisDrpData?.length === 0) dispatch(get_ArrestJuvenileDis_DrpData(loginAgencyID));
            if (policeForceDrpData?.length === 0) { get_Police_Force(); }
        }
    }, [loginAgencyID]);

    useEffect(() => {
        if (DecArrestId) {
            setArrestID(DecArrestId); get_Arrest_Count(DecArrestId);
            // GetSingleData(DecArrestId, DecEIncID);
        } else {
            setMultiImage(''); setStatus(false); setArrestID('');
            reset_Value()
        }
    }, [DecArrestId]);

    useEffect(() => {
        if (ArrestID) {
            get_Arrest_Count(ArrestID)
        }
    }, [ArrestID]);

    useEffect(() => {
        if (DecArrestId || DecEIncID) {
            GetSingleData(DecArrestId, DecEIncID);
        } else { reset_Value() }
    }, [DecArrestId, DecEIncID]);

    const GetSingleData = (ArrestID, MainIncidentID) => {
        const val = { 'ArrestID': ArrestID, 'PINID': '0', 'IncidentID': MainIncidentID }
        fetchPostData('Arrest/GetSingleData_Arrest', val)
            .then((res) => {
                if (res.length > 0) { setStatus(true); setEditval(res); }
                else { setEditval([]) }
            })
    }

    useEffect(() => {
        document.addEventListener('load', function () {
            document.getElementById('#myModal').modal('showModal');
        });
    }, [])

    const check_Validation_Error = (e) => {
        const arresteeStatus = checkSelectedName(value?.ArresteeID)
        if (arresteeStatus) {
            const ArresteeIDError = RequiredFieldIncident(value.ArresteeID);
            const PrimaryOfficerIDError = RequiredFieldIncident(value.PrimaryOfficerID);
            const ArrestDtTmError = RequiredFieldIncident(value.ArrestDtTm);
            const CellPhoneError = value.PhoneNo ? PhoneField(value.PhoneNo) : 'true'

            setErrors(pre => {
                return {
                    ...pre,
                    ['ArresteeIDError']: ArresteeIDError || pre['ArresteeIDError'],
                    ['PrimaryOfficerIDError']: PrimaryOfficerIDError || pre['PrimaryOfficerIDError'],
                    ['ArrestDtTmError']: ArrestDtTmError || pre['ArrestDtTmError'],
                    ['CellPhoneError']: CellPhoneError || pre['CellPhoneError'],

                }
            });
        } else {
            setShowModal(true);
            setErrors(pre => { return { ...pre, ['ArresteeIDError']: '' } })
        }
        // if (MstPage === "MST-Arrest-Dash") {
        //     const ArresteeIDError = RequiredFieldIncident(value.ArresteeID);
        //     const PrimaryOfficerIDError = RequiredFieldIncident(value.PrimaryOfficerID);
        //     const ArrestDtTmError = RequiredFieldIncident(value.ArrestDtTm);
        //     setErrors(pre => {
        //         return {
        //             ...pre,
        //             ['ArresteeIDError']: ArresteeIDError || pre['ArresteeIDError'],
        //             ['PrimaryOfficerIDError']: PrimaryOfficerIDError || pre['PrimaryOfficerIDError'],
        //             ['ArrestDtTmError']: ArrestDtTmError || pre['ArrestDtTmError'],
        //         }
        //     });

        // } else {
        //     if (arresteeStatus) {
        //         const ArresteeIDError = RequiredFieldIncident(value.ArresteeID);
        //         const PrimaryOfficerIDError = RequiredFieldIncident(value.PrimaryOfficerID);
        //         const ArrestDtTmError = RequiredFieldIncident(value.ArrestDtTm);
        //         setErrors(pre => {
        //             return {
        //                 ...pre,
        //                 ['ArresteeIDError']: ArresteeIDError || pre['ArresteeIDError'],
        //                 ['PrimaryOfficerIDError']: PrimaryOfficerIDError || pre['PrimaryOfficerIDError'],
        //                 ['ArrestDtTmError']: ArrestDtTmError || pre['ArrestDtTmError'],
        //             }
        //         });
        //     } else {
        //         setShowModal(true);
        //         setErrors(pre => { return { ...pre, ['ArresteeIDError']: '' } })
        //     }
        // }

    }

    // Check All Field Format is True Then Submit 
    const { ArresteeIDError, PrimaryOfficerIDError, ArrestDtTmError, CellPhoneError } = errors

    useEffect(() => {
        if (PrimaryOfficerIDError === 'true' && ArrestDtTmError === 'true' && ArresteeIDError === 'true' && CellPhoneError === 'true') {
            if (MstPage === "MST-Arrest-Dash") {
                if (ArrestID && (ArrestSta === true || ArrestSta === 'true')) { update_Arrest(); }
                else { insert_Arrest_Data(); }
            } else {
                if (ArrestID && (ArrestSta === true || ArrestSta === 'true')) { update_Arrest(); }
                else { insert_Arrest_Data(); }
            }
        }
    }, [PrimaryOfficerIDError, ArrestDtTmError, ArresteeIDError, CellPhoneError])

    useEffect(() => {
        if (Editval?.length > 0) {
            get_Arrest_MultiImage(ArrestID); dispatch(get_ArresteeName_Data('', '', DecEIncID));
            setValue({
                ...value,
                'ArrestID': Editval[0]?.ArrestID, 'ArrestNumber': Editval[0]?.ArrestNumber, 'IsJuvenileArrest': Editval[0]?.IsJuvenileArrest,
                'ArrestDtTm': Editval[0]?.ArrestDtTm ? getShowingDateText(Editval[0]?.ArrestDtTm) : "",
                'ArrestingAgency': Editval[0]?.ArrestingAgency ? Editval[0]?.ArrestingAgency : '', 'PhoneNo': Editval[0]?.PhoneNo ? Editval[0]?.PhoneNo : '',
                'ArrestTypeID': Editval[0]?.ArrestTypeID, 'SupervisorID': Editval[0]?.SupervisorID, 'PoliceForceID': Editval[0]?.PoliceForceID ? Editval[0]?.PoliceForceID : '',
                'ArresteeID': Editval[0]?.ArresteeID, 'RightsGivenID': Editval[0]?.RightsGivenID, 'JuvenileDispositionID': Editval[0]?.JuvenileDispositionID,
                'GivenByID': Editval[0]?.GivenByID, 'PrimaryOfficerID': Editval[0]?.PrimaryOfficerID,
                'ModifiedByUserFK': loginPinID,
            });
            setPossessionID(Editval[0]?.ArresteeID);
            setArrestName(Editval[0]?.Arrestee_Name ? Editval[0]?.Arrestee_Name : '');
            setArrestDate(Editval[0]?.ArrestDtTm ? new Date(Editval[0]?.ArrestDtTm) : ''); setRightGivenCode(Get_Given_Code(Editval, policeForceDrpData))
            if (Editval[0]?.IsJuvenileArrest === true || Editval[0]?.IsJuvenileArrest === 'true') {
                setShowJuvinile(true);
            } else {
                setShowJuvinile(false);
            }
        } else {
            setValue({
                ...value,
                'ArrestNumber': '', 'IsJuvenileArrest': '', 'ArrestDtTm': '', 'ArrestingAgency': '', 'ArrestTypeID': '', 'SupervisorID': '',
                'PoliceForceID': '', 'RightsGivenID': '', 'JuvenileDispositionID': '', 'PhoneNo': '', 'GivenByID': '', 'PrimaryOfficerID': '', 'ModifiedByUserFK': '',
            }); setArrestDate();
        }
    }, [Editval, changesStatusCount])

    const reset_Value = () => {
        setShowJuvinile(false); setShowPoliceForce(false);
        setValue({
            ...value,
            'ArrestNumber': '', 'IsJuvenileArrest': '', 'ArrestDtTm': '', 'ArrestingAgency': '', 'ArrestTypeID': '', 'SupervisorID': '', 'PoliceForceID': '',
            'ArresteeID': '', 'RightsGivenID': '', 'JuvenileDispositionID': '', 'PhoneNo': '', 'GivenByID': '', 'PrimaryOfficerID': '', 'ModifiedByUserFK': '',
        });
        setErrors({
            ...errors,
            'ArresteeIDError': '', 'PrimaryOfficerIDError': '', 'ArrestDtTmError': '',
        });
        setArrestDate(); setMultiImage(''); setuploadImgFiles(''); setStatesChangeStatus(false)
        setStatus(false); setArrestID('');
    }

    useEffect(() => {
        if (policeForceDrpData && policeForceDrpData.length > 0) {
            const foundVal = policeForceDrpData.find(val => val.value === value?.PoliceForceID);
            if (foundVal) {
                if (foundVal.id === 'Y') {
                    setShowPoliceForce(true);
                } else {
                    setShowPoliceForce(false);
                }
            } else {
                setShowPoliceForce(false);
            }
        } else {
            setShowPoliceForce(false);
        }
    }, [policeForceDrpData, value?.PoliceForceID]);

    const get_Arresting_DropDown = () => {
        const val = { AgencyID: loginAgencyID, PINID: loginPinID }
        fetchPostData('Agency/GetData_Agency', val).then((data) => {
            if (data) {
                setAgencyNameDrpData(Comman_changeArrayFormat(data, 'AgencyID', 'Agency_Name'))
            } else {
                setAgencyNameDrpData([]);
            }
        })
    }

    const HandleChange = (e) => {
        if (e.target.name === "IsJuvenileArrest") {
            setChangesStatus(true); setStatesChangeStatus(true)
            setValue({ ...value, [e.target.name]: e.target.checked });
        } else if (e.target.name === 'PhoneNo') {
            var ele = e.target.value.replace(/[^0-9\s]/g, "")
            if (ele.length === 10) {
                var cleaned = ('' + ele).replace(/\D/g, '');
                var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
                if (match) {
                    setChangesStatus(true)
                    // setStatesChangeStatus(true)
                    setValue({ ...value, [e.target.name]: match[1] + '-' + match[2] + '-' + match[3] });
                }
            } else {
                ele = e.target.value.split('-').join('').replace(/[^0-9\s]/g, "");
                setChangesStatus(true)
                setValue({ ...value, [e.target.name]: ele });
            }
        } else {
            setChangesStatus(true); setStatesChangeStatus(true)
            setValue({ ...value, [e.target.name]: e.target.value });
        }
    };

    const handleChange = (event) => {
        setChangesStatus(true)
        const { name, value } = event.target;
        let ele = value.replace(/\D/g, '');
        setStatesChangeStatus(true)
        if (ele.length === 10) {
            const cleaned = ele.replace(/\D/g, '');
            const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
            if (match) {
                setValue((prevState) => ({
                    ...prevState,
                    [name]: match[1] + '-' + match[2] + '-' + match[3],
                }));
            }
        } else {
            ele = value.split('-').join('').replace(/\D/g, '');
            setValue((prevState) => ({
                ...prevState,
                [name]: ele,
            }));
        }
    };

    useEffect(() => {
        if (rightGivenCode !== "Y") {
            setValue({ ...value, ['GivenByID']: '' })
        }
    }, [rightGivenCode])

    useEffect(() => {
        if (!value.IsJuvenileArrest || value.IsJuvenileArrest === 'false') {
            setShowJuvinile(false);
            setValue({ ...value, ['JuvenileDispositionID']: '', ['PhoneNo']: '' });
        } else { setShowJuvinile(true); }
    }, [value.IsJuvenileArrest])


    const insert_Arrest_Data = () => {
        AddDeleteUpadate('Arrest/Insert_Arrest', value).then((res) => {
            if (res.success) {
                if (MstPage === "MST-Arrest-Dash") {
                    navigate(`/Arrest-Home?page=MST-Arrest-Dash&ArrNo=${res?.ArrestNumber}&Name=${ArresteName}&ArrestId=${stringToBase64(res?.ArrestID)}&ArrestSta=${true}&ChargeSta=${true}`);
                } else {
                    navigate(`/Arrest-Home?IncId=${IncID}&IncNo=${IncNo}&IncSta=${IncSta}&ArrestId=${stringToBase64(res?.ArrestID)}&ArrNo=${res?.ArrestNumber}&Name=${ArresteName}&ArrestSta=${true}&ChargeSta=${false}`)
                }
                toastifySuccess(res.Message); get_Arrest_Count(DecArrestId); get_Data_Arrest(MainIncidentID, MstPage === "MST-Arrest-Dash" ? true : false);
                setArrestID(res.ArrestID); setStatus(false); setChangesStatus(false); setStatesChangeStatus(false)
                if (uploadImgFiles?.length > 0) { upload_Image_File(res.ArrestID); setuploadImgFiles(''); }
                setErrors({ ...errors, ['ArresteeIDError']: '' }); get_Incident_Count(DecEIncID);
            }
        })
    }

    const update_Arrest = () => {
        AddDeleteUpadate('Arrest/Update_Arrest', value).then((res) => {
            const parsedData = JSON.parse(res.data);
            const message = parsedData.Table[0].Message;
            toastifySuccess(message); setChangesStatus(false); setStatesChangeStatus(false); get_Data_Arrest(MainIncidentID, MstPage === "MST-Arrest-Dash" ? true : false);
            setErrors({ ...errors, ['ArresteeIDError']: '' })
            if (uploadImgFiles?.length > 0) { upload_Image_File(); setuploadImgFiles(''); }
        })
    }

    const DeleteArrest = () => {
        const val = { 'ArrestID': ArrestID, 'DeletedByUserFK': loginPinID }
        AddDeleteUpadate('Arrest/Delete_Arrest', val).then((res) => {
            if (res) {
                const parsedData = JSON.parse(res.data);
                const message = parsedData.Table[0].Message;
                toastifySuccess(message);
                get_Data_Arrest(MainIncidentID, MstPage === "MST-Arrest-Dash" ? true : false);
                get_Incident_Count(DecEIncID);
                get_Arrest_Count(DecArrestId);
                reset_Value();
                navigate(`/Arrest-Home?IncId=${IncID}&IncNo=${IncNo}&IncSta=${IncSta}&ArrestId=${('')}&ArrestSta=${false}&ChargeSta=${false}`)
                //    setStatusFalse();
                //     setStatus(false);
            } else console.log("Somthing Wrong");
        })
    }

    const startRef = React.useRef();
    const startRef1 = React.useRef();

    const onKeyDown = (e) => {
        if (e.keyCode === 9 || e.which === 9) {
            startRef.current.setOpen(false);
            startRef1.current.setOpen(false);
        }
    };

    const columns = [
        {
            name: 'Arrest Number', selector: (row) => row.ArrestNumber, sortable: true
        },
        {
            name: 'Agency Name', selector: (row) => row.Agency_Name, sortable: true
        },
        {
            width: '200px', name: 'Supervisor Name',
            selector: (row) => <>{row?.Supervisor_Name ? row?.Supervisor_Name.substring(0, 60) : ''}{row?.Supervisor_Name?.length > 40 ? '  . . .' : null} </>,
            sortable: true
        },
        {
            name: 'PoliceForce Description', selector: (row) => row.PoliceForce_Description, sortable: true
        },
        {
            name: <p className='text-end' style={{ position: 'absolute', top: '7px', right: 10 }}>Delete</p>,
            cell: row =>
                <div style={{ position: 'absolute', top: 4, right: 10 }}>
                    {
                        effectiveScreenPermission ? effectiveScreenPermission[0]?.DeleteOK ?
                            <span onClick={() => setArrestID(row.ArrestID)} className="btn btn-sm bg-green text-white px-1 py-0 mr-1" data-toggle="modal" data-target="#DeleteModal">                                    <i className="fa fa-trash"></i>
                            </span>
                            : <></> :
                            <span onClick={() => setArrestID(row.ArrestID)} className="btn btn-sm bg-green text-white px-1 py-0 mr-1" data-toggle="modal" data-target="#DeleteModal">                                    <i className="fa fa-trash"></i>
                                <i className="fa fa-trash"></i>
                            </span>
                    }
                </div>
        }
    ]

    const onMasterPropClose = () => {
        navigate('/dashboard-page');
    }

    const set_Edit_Value = (row) => {
        if (row.ArrestID) {
            navigate(`/Arrest-Home?IncId=${IncID}&IncNo=${IncNo}&IncSta=${IncSta}&ArrestId=${stringToBase64(row?.ArrestID)}&ArrNo=${row?.ArrestNumber}&Name=${row?.Arrestee_Name}&ArrestSta=${true}&ChargeSta=${false}`)
            setArrestID(row?.ArrestID);
            setActiveArrest(row?.ArrestID); setErrors(''); setStatesChangeStatus(false); setChangesStatus(false); setStatus(true);
        }
    }

    const setStatusFalse = () => {
        if (MstPage === "MST-Arrest-Dash") {
            navigate(`/Arrest-Home?page=MST-Arrest-Dash&ArrestId=${('')}&ArrestSta=${false}&ChargeSta=${false}`);
            setClickedRow(null); reset_Value(); setErrors(''); setPossessionID(''); setPossenSinglData([]); setArrestID('');
        } else {
            navigate(`/Arrest-Home?IncId=${IncID}&IncNo=${IncNo}&IncSta=${IncSta}&ArrestId=${('')}&ArrestSta=${false}&ChargeSta=${false}`)
            setClickedRow(null); setErrors(''); setPossessionID(''); setPossenSinglData([]);
            setActiveArrest(false); setRightGivenCode(false); setArrestID(''); reset_Value();
        }
    }

    const conditionalRowStyles = [
        {
            // when: row => row === clickedRow || row.ArrestID === ArrestId,
            when: row => row.ArrestID === DecArrestId,
            style: { backgroundColor: '#001f3fbd', color: 'white', cursor: 'pointer', },
        },
    ];

    //-------------------------Image---------------------------
    const get_Arrest_MultiImage = (ArrestID) => {
        const val = { 'ArrestID': ArrestID }
        const val1 = { 'ArrestID': 0 }
        fetchPostData('Arrest/GetData_ArrestPhoto', MstPage === 'ArrestSearch' ? val1 : val)
            .then((res) => {
                if (res) { setMultiImage(res); }
                else { setMultiImage([]); }
            })
    }

    // to update image data
    const update_Vehicle_MultiImage = () => {
        const val = { "ModifiedByUserFK": loginPinID, "AgencyID": loginAgencyID, "PictureTypeID": imgData?.PictureTypeID, "ImageViewID": imgData?.ImageViewID, "ImgDtTm": imgData?.ImgDtTm, "OfficerID": imgData?.OfficerID, "Comments": imgData?.Comments, "DocumentID": imgData?.DocumentID }
        AddDeleteUpadate('PropertyVehicle/Update_PropertyVehiclePhotoDetail', val).then((res) => {
            if (res.success) {
                // toastifySuccess(res.Message); 
                const parsedData = JSON.parse(res.data);
                const message = parsedData.Table[0].Message;
                toastifySuccess(message);
                // get_Arrest_MultiImage(ArrestID);
            }
            else { toastifyError(res?.Message); }
        })
        // AddDelete_Img('PropertyVehicle/Update_PropertyVehiclePhotoDetail', val).then((res) => {
        //         if (res.success) {
        //             // toastifySuccess(res.Message); 
        //             const parsedData = JSON.parse(res.data);
        //             const message = parsedData.Table[0].Message;
        //             toastifySuccess(message);
        //             // get_Arrest_MultiImage(ArrestID);
        //         }
        //         else { toastifyError(res?.Message); }
        //     })
    }

    const upload_Image_File = async (arrID) => {
        const formdata = new FormData();
        const newData = [];
        const EncFormdata = new FormData();
        const EncDocs = [];
        for (let i = 0; i < uploadImgFiles.length; i++) {
            const { file, imgData } = uploadImgFiles[i];
            const val = {
                'ArrestID': ArrestID ? ArrestID : arrID, 'CreatedByUserFK': loginPinID, 'AgencyID': loginAgencyID,
                'PictureTypeID': imgData?.PictureTypeID, 'ImageViewID': imgData?.ImageViewID, 'ImgDtTm': imgData?.ImgDtTm,
                'OfficerID': imgData?.OfficerID, 'Comments': imgData?.Comments
            }
            const val1 = {
                'ArrestID': 0, 'CreatedByUserFK': loginPinID, 'AgencyID': loginAgencyID, 'PictureTypeID': imgData?.PictureTypeID,
                'ImageViewID': imgData?.ImageViewID, 'ImgDtTm': imgData?.ImgDtTm, 'OfficerID': imgData?.OfficerID, 'Comments': imgData?.Comments
            }
            const values = JSON.stringify(MstPage === 'ArrestSearch' ? val1 : val);
            const EncPostData = await Aes256Encrypt(JSON.stringify(MstPage === 'ArrestSearch' ? val1 : val));
            formdata.append("file", file);
            EncFormdata.append("file", file);
            newData.push(values);
            EncDocs.push(EncPostData);
        }
        formdata.append("Data", JSON.stringify(newData));
        EncFormdata.append("Data", EncDocs);
        AddDelete_Img('Arrest/Insert_ArrestPhoto', formdata, EncFormdata).then((res) => {
            if (res.success) {
                get_Arrest_MultiImage(ArrestID ? ArrestID : arrID); setuploadImgFiles([]);
            }
        }).catch(err => console.log(err))
    }

    const delete_Image_File = (e) => {
        const value = { 'PhotoID': imageid, 'DeletedByUserFK': loginPinID }
        AddDeleteUpadate('Arrest/Delete_ArrestPhoto', value).then((data) => {
            if (data.success) {
                get_Arrest_MultiImage(ArrestID); setModalStatus(false); setImageId('');
                const parsedData = JSON.parse(data.data);
                const message = parsedData.Table[0].Message;
                toastifySuccess(message);
            } else {
                toastifyError(data?.Message);

            }
        });
        // AddDelete_Img('Arrest/Delete_ArrestPhoto', value).then((data) => {
        //     if (data.success) {
        //         get_Arrest_MultiImage(ArrestID); setModalStatus(false); setImageId('');
        //         const parsedData = JSON.parse(data.data);
        //         const message = parsedData.Table[0].Message;
        //         toastifySuccess(message);
        //     } else {
        //         toastifyError(data?.Message);

        //     }
        // });
    }

    const colourStyles = {
        control: (styles) => ({
            ...styles, backgroundColor: "#fce9bf",
            height: 20, minHeight: 30, fontSize: 14, margintop: 2, boxShadow: 0,
        }),
    };

    const customStylesWithOutColor = {
        control: base => ({
            ...base, height: 20, minHeight: 30,
            fontSize: 14, margintop: 2, boxShadow: 0,
        }),
    };

    //--------------------possession---------------------------------//
    const GetSingleDataPassion = (nameID, masterNameID) => {
        const val = { 'NameID': nameID, 'MasterNameID': masterNameID }
        fetchPostData('MasterName/GetSingleData_MasterName', val).then((res) => {
            if (res) { setPossenSinglData(res); }
            else { setPossenSinglData([]); }
        })
    }

    const handleDOBChange = (date, e) => {
        if (date) {
            setValue(pre => ({ ...pre, ['AgeFrom']: '', ['AgeTo']: '' }));
            const res = getShowingWithOutTime(date).split("/");
            let age = calculateAge(`${res[0]} ${res[1]} ${res[2]}`);
            return age;
        }
    };

    function calculateAge(birthday) {
        const today = new Date();
        const date = new Date(birthday);
        const diffInMs = today.getTime() - date.getTime();
        const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
        const years = Math.floor(diffInDays / 365);
        let Days = "";
        Days += diffInDays % 7;
        const newday = Days.split('.')
        return `${years}`;
    };

    const ChangeDropDown = (e, name) => {
        let newValue = { ...value };
        if (e) {
            newValue[name] = e.value; setStatesChangeStatus(true)
            if (name === 'RightsGivenID') {
                setRightGivenCode(e.id);
            } else if (name === 'ArresteeID') {
                const age = handleDOBChange(e.DateOfBirth);
                if (e.DateOfBirth) {
                    if (age === null || age === undefined) {
                        newValue['IsJuvenileArrest'] = '';
                    } else {
                        newValue['IsJuvenileArrest'] = age < 18 ? true : '';
                    }
                    setArresteeChange(e); setPossessionID(e.value);
                } else
                    if (!e.Gendre_Description || !e.Race_Description || !e.DateOfBirth || !e.LastName) {
                        setShowModal(true); setArresteeChange(e); setPossessionID(e.value);
                    }
            }
            setChangesStatus(true);
            setValue(newValue);
        } else {
            if (name === 'RightsGivenID') {
                setRightGivenCode('N');
            } else if (name === 'ArresteeID') {
                newValue['IsJuvenileArrest'] = '';
                setPossessionID(''); setPossenSinglData([]); setArresteeChange('');
            }
            setStatesChangeStatus(true)
            newValue[name] = null;
            setChangesStatus(true);
            setValue(newValue);
        }
    };

    const checkSelectedName = (ArresteeID) => {
        if (ArresteeID) {
            const keysToCheck = ['DateOfBirth', 'Gendre_Description', 'LastName', 'Race_Description'];
            const nameStatus = MstPage === "MST-Arrest-Dash" ? mastersNameDrpData : arresteeNameData?.filter((val) => val?.NameID == ArresteeID);
            setArresteeChange(nameStatus[0]);
            const age = handleDOBChange(nameStatus[0]?.DateOfBirth);
            // setArrestName(nameStatus[0]?.LastName)
            setArrestName(nameStatus[0]?.label)
            if (age === null || age === undefined) {
                setValue({ ...value, ['IsJuvenileArrest']: '', ['ArresteeID']: parseInt(possessionID) })
            } else {
                setValue({ ...value, ['IsJuvenileArrest']: age < 18 ? true : '', ['ArresteeID']: parseInt(possessionID) })
            }
            return keysToCheck.every(key => nameStatus[0]?.hasOwnProperty(key) && nameStatus[0]?.[key]);
        } else {
            return true
        }
    }

    return (
        <>
            <div className="col-12 " id="display-not-form">
                <div className="row">
                    <div className="col-12 col-md-12 col-lg-11 pt-2 p-0">
                        <div className="row px-2">
                            <div className="col-12 col-md-12 col-lg-12 mb-0 ml-5 pl-4 ">
                                <div className="form-check">
                                    <input className="form-check-input " type="checkbox" onChange={HandleChange} name='IsJuvenileArrest' value={value?.IsJuvenileArrest} checked={value?.IsJuvenileArrest} id="flexCheckDefault" disabled
                                    />
                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                        Juvenile Arrest
                                    </label>
                                </div>
                            </div>
                            <div className="col-2 col-md-2 col-lg-1  mt-2 ">
                                <label htmlFor="" className='new-label '>Arrest No.</label>
                            </div>
                            <div className="col-4 col-md-4 col-lg-2  text-field  mt-1">
                                <input type="text" name='ArrestNumber' value={value?.ArrestNumber} className="readonlyColor" onChange={''} id='ArrestNumber' required readOnly />
                            </div>
                            <div className="col-2 col-md-2 col-lg-2 mt-2 ">
                                <label htmlFor="" className='new-label'>Arresting Agency</label>
                            </div>
                            {/* <div className="col-4 col-md-4 col-lg-3 ">
                                <Select
                                    name="ArrestingAgency"
                                    value={arrestingAgencyDrpData?.filter((obj) => obj.value === value?.ArrestingAgency)}
                                    styles={customStylesWithOutColor}
                                    isClearable
                                    options={arrestingAgencyDrpData}
                                    onChange={(e) => { ChangeDropDown(e, 'ArrestingAgency') }}
                                    placeholder="Select..."
                                />
                            </div> */}
                            <div className="col-4 col-md-4 col-lg-3 mt-1  text-field">
                                <input type="text" name='ArrestingAgency' id='ArrestingAgency' value={value?.ArrestingAgency} onChange={HandleChange} />
                            </div>
                            <div className="col-2 col-md-2 col-lg-1 mt-2">
                                {/* <label htmlFor="" className='new-label'>Arrest Type</label> */}
                                <span data-toggle="modal" onClick={() => {
                                    setOpenPage('Arrest Type')
                                }} data-target="#ListModel" className='new-link'>
                                    Arrest Type
                                </span>
                            </div>
                            <div className="col-4 col-md-4 col-lg-3 ">
                                <Select
                                    name="ArrestTypeID"
                                    value={arrestTypeDrpData?.filter((obj) => obj.value === value?.ArrestTypeID)}
                                    styles={customStylesWithOutColor}
                                    isClearable
                                    options={arrestTypeDrpData}
                                    onChange={(e) => { ChangeDropDown(e, 'ArrestTypeID') }}
                                    placeholder="Select..."
                                />
                            </div>

                            <div className="col-2 col-md-2 col-lg-1 mt-2 ">
                                <label htmlFor="" className='new-label'>Supervisor</label>
                            </div>
                            <div className="col-4 col-md-4 col-lg-2 mt-1">
                                <Select
                                    name='SupervisorID'
                                    styles={customStylesWithOutColor}
                                    value={agencyOfficerDrpData?.filter((obj) => obj.value === value?.SupervisorID)}
                                    isClearable
                                    options={agencyOfficerDrpData}
                                    onChange={(e) => ChangeDropDown(e, 'SupervisorID')}
                                    placeholder="Select..."
                                />
                            </div>
                            <div className="col-2 col-md-2 col-lg-2 mt-2">
                                <label htmlFor="" className='new-label'>Arrest Date/Time {errors.ArrestDtTmError !== 'true' ? (
                                    <p style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.ArrestDtTmError}</p>
                                ) : null}</label>
                            </div>
                            <div className="col-4 col-md-4 col-lg-3 ">
                                <DatePicker
                                    id='ArrestDtTm'
                                    name='ArrestDtTm'
                                    ref={startRef1}
                                    // onKeyDown={onKeyDown}
                                    onKeyDown={(e) => {
                                        if (!((e.key >= '0' && e.key <= '9') || e.key === 'Backspace' || e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'Delete' || e.key === ':' || e.key === '/' || e.key === ' ')) {
                                            e?.preventDefault();
                                        } else {
                                            onKeyDown(e);
                                        }
                                    }}
                                    onChange={(date) => {
                                        if (date) {
                                            if (date >= new Date()) {
                                                setChangesStatus(true); setStatesChangeStatus(true); setArrestDate(new Date()); setValue({ ...value, ['ArrestDtTm']: new Date() ? getShowingMonthDateYear(new Date()) : null })
                                            } else if (date <= new Date(incReportedDate)) {
                                                setChangesStatus(true); setStatesChangeStatus(true); setArrestDate(incReportedDate); setValue({ ...value, ['ArrestDtTm']: incReportedDate ? getShowingMonthDateYear(incReportedDate) : null })
                                            } else {
                                                setChangesStatus(true); setStatesChangeStatus(true); setArrestDate(date); setValue({ ...value, ['ArrestDtTm']: date ? getShowingMonthDateYear(date) : null })
                                            }
                                        } else {
                                            setChangesStatus(true); setStatesChangeStatus(true); setArrestDate(null); setValue({ ...value, ['ArrestDtTm']: null })
                                        }

                                    }}
                                    className='requiredColor'
                                    dateFormat="MM/dd/yyyy HH:mm"
                                    timeInputLabel
                                    showYearDropdown
                                    showMonthDropdown
                                    dropdownMode="select"
                                    isClearable={value?.ArrestDtTm ? true : false}
                                    selected={value?.ArrestDtTm ? new Date(value?.ArrestDtTm) : null}
                                    placeholderText={value?.ArrestDtTm ? value.ArrestDtTm : 'Select...'}
                                    showTimeSelect
                                    timeIntervals={1}
                                    timeCaption="Time"
                                    autoComplete="Off"
                                    // filterTime={filterPassedTime}
                                    filterTime={(time) => filterPassedDateTime(time, value?.ArrestDtTm, incReportedDate)}
                                    maxDate={new Date()}
                                    minDate={new Date(incReportedDate)}
                                />
                            </div>

                            <div className="col-2 col-md-2 col-lg-1 mt-2 px-0">
                                <label htmlFor="" className='new-label px-0'>Police Force</label>
                            </div>
                            <div className="col-4 col-md-4 col-lg-3 mt-1">
                                <Select
                                    name='PoliceForceID'
                                    styles={customStylesWithOutColor}
                                    value={policeForceDrpData?.filter((obj) => obj.value === value?.PoliceForceID)}
                                    isClearable
                                    options={policeForceDrpData}
                                    onChange={(e) => ChangeDropDown(e, 'PoliceForceID')}
                                    placeholder="Select..."
                                />
                            </div>
                        </div>
                    </div>
                    <div className=" col-4 col-md-4 col-lg-1 ">
                        <div className="img-box" >
                            <Carousel autoPlay={true} className="carousel-style" showArrows={true} showThumbs={false} showStatus={false} >
                                {
                                    multiImage.length > 0 ?
                                        multiImage?.map((item) => (
                                            <div key={item.index} data-toggle="modal" data-target="#ImageModel" onClick={() => { setImageModalStatus(true); }}>
                                                <img src={`data:image/png;base64,${item.Photo}`} style={{ height: '105px' }} />

                                            </div>
                                        ))
                                        :
                                        <div data-toggle="modal" data-target="#ImageModel" onClick={() => { setImageModalStatus(true); }}>
                                            <img src={defualtImage} style={{ height: '105px' }} />
                                        </div>
                                }
                            </Carousel>
                        </div>
                    </div>
                </div>
                <fieldset>
                    <legend>Name Information </legend>
                    <div className="row ">
                        <div className="col-12 col-md-12 col-lg-11 pt-2 p-0 ">
                            <div className="row ">
                                <div className="col-2 col-md-2 col-lg-1 mt-2 ">
                                    <label htmlFor="" className='new-label'>Arrestee {errors.ArresteeIDError !== 'true' ? (
                                        <p style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.ArresteeIDError}</p>
                                    ) : null}</label>
                                </div>
                                <div className="col-4 col-md-4 col-lg-5">
                                    {
                                        MstPage === "MST-Arrest-Dash" ?
                                            <Select
                                                name="ArresteeID"
                                                styles={colourStyles}
                                                options={mastersNameDrpData}
                                                value={mastersNameDrpData?.filter((obj) => obj.value === value?.ArresteeID)}
                                                isClearable
                                                onChange={(e) => ChangeDropDown(e, 'ArresteeID')}
                                                placeholder="Select..."
                                            />
                                            :
                                            <Select
                                                name="ArresteeID"
                                                styles={colourStyles}
                                                options={arresteeNameData}
                                                value={arresteeNameData?.filter((obj) => obj.value === value?.ArresteeID)}
                                                isClearable
                                                isDisabled={ArrestID ? true : false}
                                                onChange={(e) => ChangeDropDown(e, 'ArresteeID')}
                                                placeholder="Select..."
                                            />
                                    }
                                </div>
                                <div className="col-1  " data-toggle="modal" data-target="#MasterModal"  >
                                    <span
                                        className=" btn btn-sm bg-green text-white"
                                        onClick={() => {
                                            if (possessionID) { GetSingleDataPassion(possessionID); }
                                            setNameModalStatus(true); setDatePickerRequiredColor(true)
                                        }}
                                    >
                                        <i className="fa fa-plus" > </i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
                <fieldset>
                    <legend>Rights Information </legend>
                    <div className="row">
                        <div className="col-12 col-md-12 col-lg-11 pt-2 p-0 ">
                            <div className="row ">
                                <div className="col-2 col-md-2 col-lg-1 mt-2 px-0">
                                    <label htmlFor="" className='new-label px-0'>Rights Given</label>
                                </div>
                                <div className="col-4 col-md-4 col-lg-2 mt-1">
                                    <Select
                                        name='RightsGivenID'
                                        styles={customStylesWithOutColor}
                                        value={policeForceDrpData?.filter((obj) => obj.value === value?.RightsGivenID)}
                                        isClearable
                                        options={policeForceDrpData}
                                        onChange={(e) => ChangeDropDown(e, 'RightsGivenID')}
                                        placeholder="Select..."
                                    />
                                </div>
                                <div className="col-2 col-md-2 col-lg-2 mt-2 ">
                                    <label htmlFor="" className='new-label'>Given By</label>
                                </div>
                                <div className="col-4 col-md-4 col-lg-3 mt-1">
                                    <Select
                                        name='GivenByID'
                                        menuPlacement='top'
                                        styles={customStylesWithOutColor}
                                        value={agencyOfficerDrpData?.filter((obj) => obj.value === value?.GivenByID)}
                                        isClearable
                                        options={agencyOfficerDrpData}
                                        onChange={(e) => ChangeDropDown(e, 'GivenByID')}
                                        placeholder="Select..."
                                        isDisabled={rightGivenCode === 'N' || !rightGivenCode}
                                    // isDisabled={rightGivenCode === 'N' ? true : false}
                                    />
                                </div>
                                <div className="col-2 col-md-2 col-lg-2 mt-2 ">
                                    <label htmlFor="" className='new-label '>Primary Officer{errors.PrimaryOfficerIDError !== 'true' ? (
                                        <p style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.PrimaryOfficerIDError}</p>
                                    ) : null}</label>
                                </div>
                                <div className="col-4 col-md-4 col-lg-2 mt-1">
                                    <Select
                                        name='PrimaryOfficerID'
                                        menuPlacement='top'
                                        styles={colourStyles}
                                        value={agencyOfficerDrpData?.filter((obj) => obj.value === value?.PrimaryOfficerID)}
                                        isClearable
                                        options={agencyOfficerDrpData}
                                        onChange={(e) => ChangeDropDown(e, 'PrimaryOfficerID')}
                                        placeholder="Select..."
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
                {/* juvenile */}
                <fieldset>
                    <legend>Juvenile Disposition</legend>
                    <div className="row">
                        <div className="col-12 col-md-12 col-lg-11 pt-2 p-0 ">
                            <div className="row">
                                <div className="col-2 col-md-2 col-lg-1 mt-2 ">
                                    {/* <label htmlFor="" className='new-label'>Disposition</label> */}
                                    <span data-toggle="modal" onClick={() => {
                                        setOpenPage('Arrest Juvenile Disposition')
                                    }} data-target="#ListModel" className='new-link'>
                                        Disposition
                                    </span>
                                </div>
                                <div className="col-4 col-md-4 col-lg-4 mt-1">
                                    <Select
                                        name='JuvenileDispositionID'
                                        menuPlacement='top'
                                        styles={customStylesWithOutColor}
                                        value={arrestJuvenileDisDrpData?.filter((obj) => obj.value === value?.JuvenileDispositionID)}
                                        isClearable
                                        options={arrestJuvenileDisDrpData}
                                        onChange={(e) => ChangeDropDown(e, 'JuvenileDispositionID')}
                                        placeholder="Select..."
                                        isDisabled={value?.IsJuvenileArrest ? false : true}
                                    />
                                </div>
                                <div className="col-2 col-md-2 col-lg-5 mt-2 ">
                                    <label htmlFor="" className='new-label'>Phone No:{errors.CellPhoneError !== 'true' ? (
                                        <p style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.CellPhoneError}</p>
                                    ) : null}</label>
                                </div>
                                <div className="col-4 col-md-4 col-lg-2 mt-1 text-field">
                                    <input type="text" maxLength={10} name='PhoneNo' id='PhoneNo' className={`${value.IsJuvenileArrest === false ? "readonlyColor" : ''}`} value={value?.PhoneNo} onChange={handleChange} required disabled={value.IsJuvenileArrest === true ? false : true} />
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>
            <div className="col-12  text-right p-0" style={{ marginTop: '-10px' }}>
                <button type="button" className="btn btn-sm btn-success  mr-1" onClick={() => { setStatusFalse(); }}>New</button>
                {
                    ArrestID && (ArrestSta === true || ArrestSta === 'true') ?
                        effectiveScreenPermission ? effectiveScreenPermission[0]?.Changeok ?
                            <button type="button" className="btn btn-sm btn-success mr-1" data-toggle="modal" data-target="#myModal" disabled={!statesChangeStatus} onClick={() => { if (!showModal) { check_Validation_Error(); } }}>Update</button>
                            : <></> :
                            <button type="button" className="btn btn-sm btn-success mr-1" data-toggle="modal" data-target="#myModal" disabled={!statesChangeStatus} onClick={() => { if (!showModal) { check_Validation_Error(); } }}>Update</button>
                        :
                        effectiveScreenPermission ? effectiveScreenPermission[0]?.AddOK ?
                            <button type="button" className="btn btn-sm btn-success mr-1" data-toggle="modal" data-target="#myModal" onClick={() => { if (!showModal) { check_Validation_Error(); } }}>Save</button>
                            : <></> :
                            <button type="button" className="btn btn-sm btn-success mr-1" data-toggle="modal" data-target="#myModal" onClick={() => { if (!showModal) { check_Validation_Error(); } }}>Save</button>
                }
                {
                    MstPage === "MST-Arrest-Dash" &&
                    <button type="button" className="btn btn-sm btn-success mx-1" onClick={onMasterPropClose} data-dismiss="modal">Close</button>
                }
            </div>
            {
                modalStatus &&
                <div className="modal" id="myModal2" style={{ background: "rgba(0,0,0, 0.5)", transition: '0.5s' }} data-backdrop="false">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="box text-center py-5">
                                <h5 className="modal-title mt-2" id="exampleModalLabel">Do you want to Delete ?</h5>
                                <div className="btn-box mt-3">
                                    <button type="button" onClick={delete_Image_File} className="btn btn-sm text-white" style={{ background: "#ef233c" }} >Delete</button>
                                    <button type="button" onClick={() => { setImageId(''); setModalStatus(false); }} className="btn btn-sm btn-secondary ml-2"> Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <div className="col-12 pt-1">
                {
                    MstPage != "MST-Arrest-Dash" &&
                    <DataTable
                        dense
                        data={effectiveScreenPermission ? effectiveScreenPermission[0]?.DisplayOK ? arrestFilterData : [] : arrestFilterData}
                        columns={columns}
                        // data={arrestFilterData}
                        selectableRowsHighlight
                        highlightOnHover
                        responsive
                        // subHeaderComponent={<ThreeFilter Data={arrestData} setResult={setArrestFilterData} Col1='ArrestNumber' Col2='Agency_Name' Col3='Supervisor_Name' searchName1='Arrest Number' searchName2='Agency Name' searchName3='Supervisor Name' />}
                        onRowClicked={(row) => {
                            setClickedRow(row);
                            set_Edit_Value(row);
                        }}
                        fixedHeaderScrollHeight='170px'
                        conditionalRowStyles={conditionalRowStyles}
                        fixedHeader
                        persistTableHead={true}
                        customStyles={tableCustomStyles}
                        noDataComponent={effectiveScreenPermission ? effectiveScreenPermission[0]?.DisplayOK ? "There are no data to display" : "You don’t have permission to view data" : 'There are no data to display'}
                    />
                }
            </div>
            <ListModal {...{ openPage, setOpenPage }} />
            <ConfirmModal {...{ showModal, setShowModal, arresteeChange, value, possessionID, setPossessionID, setValue, setErrors }} />
            <DeletePopUpModal func={DeleteArrest} />
            <ChangesModal func={check_Validation_Error} />
            <IdentifyFieldColor />
            <MasterNameModel {...{ type, setArrestID, isDatePickerRequiredColor, value, setValue, setMstNameID, nameModalStatus, setNameModalStatus, loginPinID, loginAgencyID, possessionID, setPossessionID, possenSinglData, setPossenSinglData, GetSingleDataPassion }} />
            <ImageModel multiImage={multiImage} setStatesChangeStatus={setStatesChangeStatus} pinID={loginPinID} primaryOfficerID={agencyOfficerDrpData} setMultiImage={setMultiImage} uploadImgFiles={uploadImgFiles} setuploadImgFiles={setuploadImgFiles} ChangeDropDown={ChangeDropDown} modalStatus={modalStatus} setModalStatus={setModalStatus} imageId={imageid} setImageId={setImageId} imageModalStatus={imageModalStatus} setImageModalStatus={setImageModalStatus} delete_Image_File={delete_Image_File} setImgData={setImgData} imgData={imgData} updateImage={update_Vehicle_MultiImage} agencyID={loginAgencyID} />
        </>
    )
}

export default Home

const Get_Code = (data, dropDownData) => {
    const result = data?.map((sponsor) =>
        (sponsor.ArresteeID)
    )
    const result2 = dropDownData?.map((sponsor) => {
        if (sponsor.value === result[0]) {
            return { value: result[0], label: sponsor.label, id: sponsor.id }
        }
    }
    )
    const val = result2.filter(function (element) {
        return element !== undefined;
    });
    return val[0]?.label
}

const Get_Given_Code = (data, dropDownData) => {
    const result = data?.map((sponsor) =>
        (sponsor.RightsGivenID)
    )
    const result2 = dropDownData?.map((sponsor) => {
        if (sponsor.value === result[0]) {
            return { value: result[0], label: sponsor.label, id: sponsor.id }
        }
    }
    )
    const val = result2.filter(function (element) {
        return element !== undefined;
    });
    return val[0]?.id
}
import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import img from '../../../../../src/img/images1.jpg'
import Select from "react-select";
import { Decrypt_Id_Name, customStylesWithOutColor, getShowingDateText, getShowingMonthDateYear, getShowingWithOutTime, getYearWithOutDateTime } from '../../../Common/Utility';
import DatePicker from "react-datepicker";
import { fetchPostData } from '../../../hooks/Api';
import { useReactToPrint } from 'react-to-print';
import { Comman_changeArrayFormat, threeColArray } from '../../../Common/ChangeArrayFormat';
import { useContext } from 'react';
import { AgencyContext } from '../../../../Context/Agency/Index';
import { toastifyError } from '../../../Common/AlertMsg';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { get_LocalStoreData } from '../../../../redux/actions/Agency';
import { get_AgencyOfficer_Data, get_VehicleLossCode_Drp_Data } from '../../../../redux/actions/DropDownsData';
import Location from '../../../Location/Location';
import TreeModalReport from './TreeModalReport';

const VehicleMasterReport = () => {

    const dispatch = useDispatch();
    const uniqueId = sessionStorage.getItem('UniqueUserID') ? Decrypt_Id_Name(sessionStorage.getItem('UniqueUserID'), 'UForUniqueUserID') : '';
    const localStoreData = useSelector((state) => state.Agency.localStoreData);
    const propertyLossCodeData = useSelector((state) => state.DropDown.vehicleLossCodeDrpData);
    const agencyOfficerDrpData = useSelector((state) => state.DropDown.agencyOfficerDrpData);
    const [dispositionsDrpData, setDispositionsDrpData] = useState([]);

    const [propertyTypeData, setPropertyTypeData] = useState([]);
    const [verifyReport, setverifyReport] = useState(false);
    const [reportedData, setReportedData] = useState([]);
    const [MainIncidentID, setMainIncidentID] = useState('');
    const [LoginAgencyID, setLoginAgencyID] = useState('');
    const [LoginPinID, setLoginPinID,] = useState('');
    const [categoryIdDrp, setCategoryIdDrp] = useState([]);
    const [locationStatus, setLocationStatus] = useState(false);
    const [updateStatus, setUpdateStatus] = useState(0);
    const [onSelectLocation, setOnSelectLocation] = useState(true);
    const [multiImage, setMultiImage] = useState([]);
    const [proRoom, setProRoom] = useState('PropertyRoom');
    const [locationPath, setLocationPath] = useState();
    const [searchStoragepath, setSearchStoragePath] = useState();

    const [value, setValue] = useState({
        'ReportedDate': null, 'ReportedDateTo': null, 'CategoryID': null, 'VehicleNumber': '', 'VehicleNumberTo': '', 'LossCodeID': null, 'Value': '',
        'ValueTo': '', 'LastName': '', 'FirstName': '', 'MiddleName': '', 'AgencyID': '', 'StorageLocationID': null,
        'ActivityType': '', 'ActivityTypeID': '', 'ReceiveDate': null, 'ReceiveDateTo': null, 'InvestigatorID': null, 'location': '', 'DispositionID': null, 'RecoveredDateTime': null, 'RecoveredDateTimeTo': null, 'PropertyTypeID': null,
    });
    const [searchValue, setSearchValue] = useState({
        ReportedDate: '',
        ReportedDateTo: '',
        CategoryID: null,
        VehicleNumber: '',
        VehicleNumberTo: '',
        LossCodeID: null,
        Value: '',
        ValueTo: '',
        LastName: '',
        FirstName: '',
        MiddleName: '',
        ReceiveDate: '',
        ReceiveDateTo: '',
        // ActivityType: '',
        ActivityTypeID: null,
        InvestigatorID: null,
        StorageLocationID: null,
        DispositionID: null,
        PropertyTypeID: null,
        RecoveredDateTime: '',
        RecoveredDateTimeTo: '',
    });

    const [showFields, setShowFields] = useState({
        showReportedDate: false,
        showReportedDateTo: false,
        showCategoryID: false,
        showVehicleNumber: false,
        showVehicleNumberTo: false,
        showLossCodeID: false,
        showValue: false,
        showValueTo: false,
        showLastName: false,
        showFirstName: false,
        showMiddleName: false,
        showReceiveDate: false,
        showReceiveDateTo: false,
        // showActivityType: false,
        showActivityTypeID: false,
        showInvestigatorID: false,
        showStorageLocationID: false,
        showDispositionID: false,
        showRecoveredDateTime: false,
        showRecoveredDateTimeTo: false,
        showPropertyTypeID: false,
    });
    useEffect(() => {
        setShowFields({
            showReportedDate: searchValue.ReportedDate,
            showReportedDateTo: searchValue.ReportedDateTo,
            showCategoryID: searchValue.CategoryID !== null,
            showVehicleNumber: searchValue.VehicleNumber,
            showVehicleNumberTo: searchValue.VehicleNumberTo,
            showLossCodeID: searchValue.LossCodeID !== null,
            showValue: searchValue.Value,
            showValueTo: searchValue.ValueTo,
            showLastName: searchValue.LastName,
            showFirstName: searchValue.FirstName,
            showMiddleName: searchValue.MiddleName,
            showReceiveDate: searchValue.ReceiveDate,
            showReceiveDateTo: searchValue.ReceiveDateTo,
            // showActivityType: searchValue.ActivityType,
            showActivityTypeID: searchValue.ActivityTypeID !== null,
            showInvestigatorID: searchValue.InvestigatorID !== null,
            showStorageLocationID: searchValue.StorageLocationID !== null,
            showDispositionID: searchValue.DispositionID !== null,
            showRecoveredDateTime: searchValue.RecoveredDateTime,
            showRecoveredDateTimeTo: searchValue.RecoveredDateTimeTo,
            showPropertyTypeID: searchValue.PropertyTypeID !== null,
        });
    }, [searchValue]);
    const AddTransfer = [
        { value: 1, label: 'CheckIn' },
        { value: 2, label: 'CheckOut' },
        { value: 3, label: 'Release' },
        { value: 4, label: 'Destroy' },
    ]

    useEffect(() => {
        if (!localStoreData?.AgencyID || !localStoreData?.PINID) {
            if (uniqueId) dispatch(get_LocalStoreData(uniqueId));
        }
    }, []);

    useEffect(() => {
        if (localStoreData) {
            setLoginAgencyID(localStoreData?.AgencyID);
        }
    }, [localStoreData]);

    useEffect(() => {
        if (LoginAgencyID) {
            dispatch(get_AgencyOfficer_Data(LoginAgencyID, ''))
            if (propertyLossCodeData?.length === 0) { dispatch(get_VehicleLossCode_Drp_Data(LoginAgencyID)) };
            PropertyType(LoginAgencyID);
            get_Dispositions(LoginAgencyID);
        }
    }, [LoginAgencyID]);
    // Onload Function
    // useEffect(() => {
    //     if (localStoreData) {
    //         if (propertyLossCodeData?.length === 0) { dispatch(get_VehicleLossCode_Drp_Data(localStoreData?.AgencyID)) };
    //         setLoginAgencyID(localStoreData?.AgencyID);
    //         setLoginPinID(localStoreData?.PINID);
    //         PropertyType(localStoreData?.AgencyID);
    //         // if (agencyOfficerDrpData?.length === 0) { dispatch(get_AgencyOfficer_Data(localStoreData?.AgencyID)); }
    //         get_Dispositions(localStoreData?.AgencyID);

    //     }
    // }, [localStoreData])

    // useEffect(() => {
    // get_Property_Report_Data();
    // }, [LoginAgencyID])


    // const get_Property_Report_Data = () => {
    //     const val = {
    //         'MasterPropertyID': sessionStorage.getItem('MasterPropertyID') ? Decrypt_Id_Name(sessionStorage.getItem('MasterPropertyID'), 'MForMasterPropertyID') : '',
    //         'AgencyID': localStorage.getItem('AgencyID') ? Decrypt_Id_Name(localStorage.getItem('AgencyID'), 'AForAgencyID') : '',
    //     }
    //     fetchPostData('ReportProperty/GetData_ReportProperty', val).then((res) => {
    //         if (res) { setReportedData(res) }
    //         else setReportedData([]);
    //     })
    // }


    // useEffect(() => {
    //     if (reportedData?.length > 0) {
    //         setverifyReport(true);
    //     } else {
    //         setverifyReport(false);
    //     }
    // }, []);
    useEffect(() => {
        if (reportedData?.length > 0) {
            setverifyReport(true);
        }
    }, [reportedData]);
    // useEffect(() => {
    //     if (LoginAgencyID) {
    //         get_Dispositions(LoginAgencyID);
    //     }
    // }, [LoginAgencyID]);
    // const get_PropertyType = (loginAgencyID) => {
    //     const val = { AgencyID: loginAgencyID }
    //     fetchPostData('PropertyCategory/GetDataDropDown_PropertyCategory', val).then((data) => {
    //         if (data) {
    //             const res = data?.filter((val) => {
    //                 if (val.PropertyCategoryCode === "V") return val
    //             })
    //             // console.log(res, 'resp')
    //             if (res.length > 0) {
    //                 get_CategoryId_Drp(res[0]?.PropertyCategoryID);
    //                 setValue({ ...value, ['PropertyTypeID']: res[0]?.PropertyCategoryID })
    //             }
    //         }
    //     })
    // }
    const PropertyType = (loginAgencyID) => {
        const val = { AgencyID: loginAgencyID }
        fetchPostData('PropertyCategory/GetDataDropDown_PropertyCategory', val).then((data) => {
            if (data) {
                const res = data?.filter((val) => {
                    if (val.PropertyCategoryCode === "V") return val
                })
                if (res.length > 0) {
                    get_CategoryId_Drp(res[0]?.PropertyCategoryID)
                }
            }
        })
    }

    const get_CategoryId_Drp = (CategoryID) => {
        const val = { CategoryID: CategoryID }
        fetchPostData('Property/GetDataDropDown_PropertyType', val).then((data) => {
            if (data) {
                setCategoryIdDrp(threeColArray(data, 'PropertyDescID', 'Description', 'CategoryID'))
            } else {
                setCategoryIdDrp([]);
            }
        })
    }
    // console.log(value, 'value')
    const get_PropertyReport = () => {
        if (value?.ReportedDate?.trim()?.length > 0 || value?.Value?.trim()?.length > 0 || value?.ValueTo?.trim()?.length > 0 || value?.MiddleName?.trim()?.length > 0 || value?.FirstName?.trim()?.length > 0 || value?.LastName?.trim()?.length > 0 || value?.VehicleNumber?.trim()?.length > 0 || value?.VehicleNumberTo?.trim()?.length > 0 || value?.ReportedDateTo?.trim()?.length > 0 || (value?.LossCodeID !== null && value?.LossCodeID != '') || (value?.CategoryID !== null && value?.CategoryID !== '') || (value?.InvestigatorID !== null && value?.InvestigatorID !== '') || value.ActivityType?.trim()?.length > 0 || (value.StorageLocationID !== null && value.StorageLocationID !== '') || value.ReceiveDate?.trim()?.length > 0
            || (value?.DispositionID !== null && value?.DispositionID !== '') || value?.RecoveredDateTime?.trim()?.length > 0 || value?.RecoveredDateTimeTo?.trim()?.length > 0) {
            const {
                ReportedDate, ReportedDateTo, CategoryID, VehicleNumber, VehicleNumberTo, LossCodeID, Value, ValueTo, LastName, FirstName, MiddleName, StorageLocationID, ActivityType, ReceiveDate, ReceiveDateTo, InvestigatorID, DispositionID, RecoveredDateTime, RecoveredDateTimeTo, PropertyTypeID
            } = value
            const val = {
                'ReportedDate': ReportedDate, 'ReportedDateTo': ReportedDateTo, 'CategoryID': CategoryID, 'VehicleNumber': VehicleNumber, 'VehicleNumberTo': VehicleNumberTo, 'LossCodeID': LossCodeID, 'Value': Value, 'ValueTo': ValueTo, 'LastName': LastName, 'FirstName': FirstName, 'MiddleName': MiddleName, 'AgencyID': LoginAgencyID, 'StorageLocationID': StorageLocationID, 'ActivityType': ActivityType, 'ReceiveDate': ReceiveDate, 'ReceiveDateTo': ReceiveDateTo, 'InvestigatorID': InvestigatorID,
                'PropertyTypeID': PropertyTypeID,
                'DispositionID': DispositionID, 'RecoveredDateTime': RecoveredDateTime, 'RecoveredDateTimeTo': RecoveredDateTimeTo,

            }
            fetchPostData('ReportVehicle/GetData_MasterReportVehicle', val).then((res) => {
                if (res.length > 0) {
                    setReportedData(res[0]);
                    setverifyReport(true);
                    getAgencyImg(LoginAgencyID)
                    console.log(res, 'res');
                    setSearchValue(value);
                }
                else {
                    toastifyError("Data Not Available"); setverifyReport(false); setReportedData([]);
                }
            })
        } else {
            toastifyError("Please Enter Details");
        }
    }
    const getAgencyImg = (LoginAgencyID) => {
        const val = { 'AgencyID': LoginAgencyID }
        fetchPostData('Agency/GetDataAgencyPhoto', val).then((res) => {
            if (res) {
                // console.log(res)
                let imgUrl = `data:image/png;base64,${res[0]?.Agency_Photo}`;
                setMultiImage(imgUrl);

            }
            else { console.log("errror") }
        })
    }

    const ChangeDropDown = (e, name) => {
        if (e) {
            setValue({
                ...value,
                [name]: e.value
            })
        }

        else {
            setValue({
                ...value,
                [name]: null
            })
        }
    }

    const ChangeDropDown1 = (e, name) => {
        // console.log(e)
        if (e) {
            if (name === 'PropertyTypeID') {
                setValue({
                    ...value,
                    [name]: e.value
                });
            }
            else if (name === 'ActivityTypeID') {
                setValue({
                    ...value,
                    [name]: e.value,
                    ['ActivityType']: e.label
                });
            }
            else {
                setValue({
                    ...value,
                    [name]: e.value
                })
            }
        }
        else {
            if (name === 'PropertyTypeID') {
                setValue({
                    ...value,
                    [name]: null,
                    ['LossCodeID']: null
                })
            } else if (name === 'ActivityTypeID') {
                setValue({
                    ...value,
                    [name]: '',
                    ['ActivityType']: ''
                });
            } else {
                setValue({
                    ...value,
                    [name]: null
                })
            }
        }
    }
    const handlChange = (e,) => {
        // VF-2024-0534
        if (e.target.name === 'VehicleNumber' || e.target.name === 'VehicleNumberTo') {
            let ele = e.target.value.replace(/[^a-zA-Z0-9\s]/g, '');
            if (ele[0]?.match(/[a-zA-Z\s]/g)) {
                let subs = ele.toUpperCase().replace(/[^a-zA-Z\s]/g, '').substring(0, 2);
                let subs2 = ele.replace(/[^0-9]/g, '');
                if (ele?.length <= 2) {
                    const alphabet = ele.toUpperCase().replace(/[^a-zA-Z\s]/g, '') || '';
                    setValue({
                        ...value,
                        [e.target.name]: alphabet
                    })
                } else if (ele?.length <= 6 && ele.length > 2) {
                    setValue({
                        ...value,
                        [e.target.name]: subs + '-' + subs2
                    })
                } else if (ele.length >= 15) {
                    e?.preventDefault()
                } else if (ele?.length > 6 && ele.length <= 10) {
                    let subs = ele.substring(0, 2);
                    let subs2 = ele.substring(2, 6).replace(/[^0-9]/g, '');
                    let subs3 = ele.substring(6, 10).replace(/[^0-9]/g, '');
                    setValue({
                        ...value,
                        [e.target.name]: subs + '-' + subs2 + '-' + subs3
                    })
                }
            } else {
                let ele = e.target.value.replace(/[^a-zA-Z0-9\s]/g, '');
                if (ele[0]?.match(/[0-9]/g)) {
                    const digits = ele.replace(/[^0-9]/g, '');
                    if (ele?.length <= 4) {
                        setValue({
                            ...value,
                            [e.target.name]: digits
                        })
                    } else if (ele.length >= 10) {
                        e.preventDefault();
                    } else {
                        let subs = ele.substring(0, 4);
                        let subs2 = ele.substring(4).replace(/[^0-9]/g, '');
                        setValue({
                            ...value,
                            [e.target.name]: subs + '-' + subs2
                        })
                    }
                } else {
                    setValue({
                        ...value,
                        [e.target.name]: e.target.value
                    })
                }

            }
        } else {
            setValue({
                ...value,
                [e.target.name]: e.target.value
            })
        }
    }

    // const handlChange = (e,) => {
    //     if (e.target.name === 'VehicleNumber' || e.target.name === 'VehicleNumberTo') {
    //         let ele = e.target.value.toUpperCase().replace(/[^a-zA-Z0-9\s]/g, '');
    //         if (ele.length === 10) {
    //             const cleaned = ('' + ele).replace(/[^a-zA-Z0-9\s]/g, '');
    //             const match = cleaned.match(/^(\w{2})(\d{4})(\d{4})$/);
    //             if (match) {
    //                 setValue({
    //                     ...value,
    //                     [e.target.name]: match[1] + '-' + match[2] + '-' + match[3]
    //                 })
    //             }
    //         } else {
    //             ele = e.target.value.split("'").join('').replace(/[^a-zA-Z0-9\s]/g, '');
    //             setValue({
    //                 ...value,
    //                 [e.target.name]: ele
    //             })
    //         }
    //     }
    //     else {
    //         setValue({
    //             ...value,
    //             [e.target.name]: e.target.value
    //         })
    //     }
    // }

    const resetFields = () => {
        setValue({
            ...value,
            'ReportedDate': "", 'ReportedDateTo': "", 'CategoryID': '', 'VehicleNumber': '', 'VehicleNumberTo': '', 'LossCodeID': null, 'Value': '',
            'ValueTo': '', 'LastName': '', 'FirstName': '', 'MiddleName': '', 'StorageLocationID': '', 'ActivityType': '', 'ReceiveDate': '', 'ReceiveDateTo': '', 'InvestigatorID': '', 'location': '', 'DispositionID': '', 'RecoveredDateTime': '', 'RecoveredDateTimeTo': '', 'ActivityTypeID': ''
        });
        // AddTransfer('')
        // setverifyReport(false); 
        // setShowFields('');
        // setSearchValue('');
    }

    const componentRef = useRef();
    const printForm = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'Data',
        onAfterPrint: () => { '' }
    })
    const get_Dispositions = (LoginAgencyID) => {
        const val = { AgencyID: LoginAgencyID }
        fetchPostData('PropertyDispositions/GetDataDropDown_PropertyDispositions', val).then((data) => {
            if (data) {
                setDispositionsDrpData(Comman_changeArrayFormat(data, 'PropertyDispositionsID', 'Description'));
            }
            else { setDispositionsDrpData([]) }
        })
    };
    return (
        <>
            <div class="section-body view_page_design pt-1">
                <div className="row clearfix">
                    <div className="col-12 col-sm-12">
                        <div className="card Agency">
                            <div className="card-body">
                                <div className="col-12 col-md-12 col-lg-12 " >
                                    <fieldset style={{ marginTop: '-15px' }}>
                                        <legend>Vehicle Master Report</legend>
                                        <div className="row">
                                            <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                                                <label htmlFor="" className='new-label '>Reported Date From</label>
                                            </div>
                                            <div className="col-3 col-md-3 col-lg-2 ">
                                                <DatePicker
                                                    name='ReportedDate'
                                                    id='ReportedDate'
                                                    onChange={(date) => { setValue({ ...value, ['ReportedDate']: date ? getShowingDateText(date) : null, ['ReportedDateTo']: null }) }}
                                                    selected={value?.ReportedDate && new Date(value?.ReportedDate)}
                                                    dateFormat="MM/dd/yyyy"
                                                    timeInputLabel
                                                    isClearable={value?.ReportedDate ? true : false}
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    autoComplete='Off'
                                                    // disabled
                                                    maxDate={new Date()}
                                                    placeholderText='Select...'
                                                />
                                            </div>
                                            <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                                                <label htmlFor="" className='new-label'>Reported Date To</label>
                                            </div>
                                            <div className="col-3 col-md-3 col-lg-2  ">
                                                <DatePicker
                                                    name='ReportedDateTo'
                                                    id='ReportedDateTo'
                                                    onChange={(date) => { setValue({ ...value, ['ReportedDateTo']: date ? getShowingDateText(date) : null }) }}
                                                    selected={value?.ReportedDateTo && new Date(value?.ReportedDateTo)}
                                                    dateFormat="MM/dd/yyyy"
                                                    timeInputLabel
                                                    isClearable={value?.ReportedDateTo ? true : false}
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    autoComplete='Off'
                                                    maxDate={new Date()}
                                                    minDate={new Date(value?.ReportedDate)}
                                                    placeholderText='Select...'
                                                    disabled={value?.ReportedDate ? false : true}
                                                    className={!value?.ReportedDate && 'readonlyColor'}

                                                />
                                            </div>
                                            <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                                                <label htmlFor="" className='new-label'>Vehicle Category</label>
                                            </div>
                                            <div className="col-3 col-md-3 col-lg-2 mt-1 ">
                                                <Select
                                                    styles={customStylesWithOutColor}
                                                    name='CategoryID'
                                                    value={categoryIdDrp?.filter((obj) => obj.value === value?.CategoryID)}
                                                    options={categoryIdDrp}
                                                    onChange={(e) => ChangeDropDown(e, 'CategoryID')}
                                                    isClearable
                                                    placeholder="Select..."
                                                />
                                            </div>
                                            <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                                                <label htmlFor="" className='new-label'>Vehicle Number From</label>
                                            </div>
                                            <div className="col-3 col-md-3 col-lg-2 mt-1 text-field">
                                                <input type="text" name='VehicleNumber' id='VehicleNumber' value={value?.VehicleNumber} onChange={handlChange} className='' />
                                            </div>
                                            <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                                                <label htmlFor="" className='new-label'>Vehicle Number To</label>
                                            </div>
                                            <div className="col-3 col-md-3 col-lg-2 mt-1 text-field">
                                                <input type="text" name='VehicleNumberTo' id='VehicleNumberTo' value={value?.VehicleNumberTo} onChange={handlChange} className='' />
                                            </div>
                                            <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                                                <label htmlFor="" className='new-label'>Loss Code</label>
                                            </div>
                                            <div className="col-3 col-md-3 col-lg-2 mt-1 ">
                                                <Select
                                                    name='LossCodeID'
                                                    value={propertyLossCodeData?.filter((obj) => obj.value === value?.LossCodeID)}
                                                    styles={customStylesWithOutColor}
                                                    options={propertyLossCodeData}
                                                    onChange={(e) => ChangeDropDown(e, 'LossCodeID')}
                                                    isClearable
                                                    placeholder="Select..."
                                                />
                                            </div>
                                            <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                                                <label htmlFor="" className='new-label'>Value From</label>
                                            </div>
                                            <div className="col-3 col-md-3 col-lg-2 mt-1 text-field">
                                                <input type="text" name='Value' id='Value' value={value?.Value} onChange={handlChange} className='' />
                                            </div>
                                            <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                                                <label htmlFor="" className='new-label'>Value To</label>
                                            </div>
                                            <div className="col-3 col-md-3 col-lg-2 mt-1 text-field">
                                                <input type="text" name='ValueTo' id='ValueTo' value={value?.ValueTo} onChange={handlChange} className='' />
                                            </div>


                                        </div>
                                    </fieldset>
                                    <fieldset >
                                        <legend>Vehicle Owner</legend>
                                        <div className="row mt-1">
                                            <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                                                <label htmlFor="" className='new-label'>Last Name</label>
                                            </div>
                                            <div className="col-3 col-md-3 col-lg-2 mt-1 text-field">
                                                <input type="text" name='LastName' id='LastName' value={value?.LastName} onChange={handlChange} />
                                            </div>
                                            <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                                                <label htmlFor="" className='new-label'>First Name</label>
                                            </div>
                                            <div className="col-3 col-md-3 col-lg-2 mt-1 text-field">
                                                <input type="text" name='FirstName' id='FirstName' value={value?.FirstName} onChange={handlChange} />
                                            </div>
                                            <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                                                <label htmlFor="" className='new-label'>Middle Name</label>
                                            </div>
                                            <div className="col-3 col-md-3 col-lg-2 mt-1 text-field">
                                                <input type="text" name='MiddleName' id='MiddleName' value={value?.MiddleName} onChange={handlChange} className='' />
                                            </div>
                                        </div>
                                    </fieldset>
                                    <fieldset >
                                        <legend>Property Room</legend>
                                        <div className="row ">
                                            <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                                                <label htmlFor="" className='new-label'>Receive From Date</label>
                                            </div>
                                            <div className="col-3 col-md-3 col-lg-2 ">
                                                <DatePicker
                                                    name='ReceiveDate'
                                                    id='ReceiveDate'
                                                    onChange={(date) => { setValue({ ...value, ['ReceiveDate']: date ? getShowingDateText(date) : null, ['ReceiveDateTo']: null }) }}
                                                    selected={value?.ReceiveDate && new Date(value?.ReceiveDate)}
                                                    dateFormat="MM/dd/yyyy"
                                                    timeInputLabel
                                                    isClearable={value?.ReceiveDate ? true : false}
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    autoComplete='Off'
                                                    maxDate={new Date()}
                                                    placeholderText='Select...'
                                                />
                                            </div>
                                            <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                                                <label htmlFor="" className='new-label'>Receive To Date</label>
                                            </div>
                                            <div className="col-3 col-md-3 col-lg-2 ">
                                                <DatePicker
                                                    name='ReceiveDateTo'
                                                    id='ReceiveDateTo'
                                                    onChange={(date) => { setValue({ ...value, ['ReceiveDateTo']: date ? getShowingDateText(date) : null }) }}
                                                    selected={value?.ReceiveDateTo && new Date(value?.ReceiveDateTo)}
                                                    dateFormat="MM/dd/yyyy"
                                                    timeInputLabel
                                                    isClearable={value?.ReceiveDateTo ? true : false}
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    autoComplete='Off'
                                                    maxDate={new Date()}
                                                    minDate={new Date(value?.ReceiveDate)}
                                                    disabled={value?.ReceiveDate ? false : true}
                                                    placeholderText='Select...'
                                                    className={!value?.ReceiveDate && 'readonlyColor'}
                                                />
                                            </div>
                                            <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                                                <label htmlFor="" className='new-label'>Activity</label>
                                            </div>
                                            <div className="col-3 col-md-3 col-lg-2 mt-1 ">
                                                {/* <Select
                                                    name='ActivityType'
                                                    value={AddTransfer.find(option => option.label === value?.ActivityType)}
                                                    onChange={(selectedOption) => {
                                                        setValue({
                                                            ...value,
                                                            ['ActivityType']: selectedOption ? selectedOption.label : null
                                                        })
                                                    }}
                                                    placeholder="Select..."
                                                    options={AddTransfer}
                                                    isClearable
                                                    styles={customStylesWithOutColor}

                                                /> */}
                                                <Select

                                                    name='ActivityTypeID'
                                                    value={AddTransfer?.filter((obj) => obj.value === value?.ActivityTypeID)}
                                                    isClearable
                                                    options={AddTransfer}
                                                    styles={customStylesWithOutColor}
                                                    onChange={(e) => ChangeDropDown1(e, 'ActivityTypeID')}
                                                    placeholder="Select..."
                                                />
                                            </div>
                                            <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                                                <label htmlFor="" className='new-label'>Investigator</label>
                                            </div>
                                            <div className="col-3 col-md-3 col-lg-2 mt-1 ">
                                                <Select
                                                    name='InvestigatorID'
                                                    value={agencyOfficerDrpData?.filter((obj) => obj.value === value?.InvestigatorID)}
                                                    isClearable
                                                    options={agencyOfficerDrpData}
                                                    styles={customStylesWithOutColor}
                                                    onChange={(e) => ChangeDropDown(e, 'InvestigatorID')}
                                                    placeholder="Select..."
                                                />
                                            </div>
                                            <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                                                <label htmlFor="" className='new-label'>Location</label>
                                            </div>
                                            <div className="col-4 col-md-8 col-lg-5 text-field mt-1">
                                                <input type="text" name="location" id="StorageLocationID" value={locationStatus ? '' : value.location} disabled />
                                            </div>
                                            <div className="col-1 pt-1" >
                                                <button
                                                    className=" btn btn-sm bg-green text-white" data-toggle="modal" data-target="#MasterTreeModal" style={{ cursor: 'pointer' }} onClick={() => {
                                                        setLocationStatus(true);
                                                    }}>
                                                    <i className="fa fa-plus" > </i>
                                                </button>
                                            </div>


                                        </div>
                                    </fieldset>
                                    <fieldset >
                                        <legend>Stolen Property</legend>
                                        <div className="row mt-2">
                                            <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                                                <label htmlFor="" className='new-label'>Disposition</label>
                                            </div>
                                            <div className="col-3 col-md-3 col-lg-2 mt-1 ">
                                                <Select
                                                    name='DispositionID'
                                                    value={dispositionsDrpData?.filter((obj) => obj.value === value?.DispositionID)}
                                                    options={dispositionsDrpData}
                                                    onChange={(e) => ChangeDropDown(e, 'DispositionID')}
                                                    styles={customStylesWithOutColor}
                                                    isClearable
                                                    placeholder="Select..."
                                                />
                                            </div>
                                            <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                                                <label htmlFor="" className='new-label'>Recovered From Date</label>
                                            </div>
                                            <div className="col-3 col-md-3 col-lg-2">
                                                <DatePicker
                                                    name='RecoveredDateTime'
                                                    id='RecoveredDateTime'
                                                    onChange={(date) => { setValue({ ...value, ['RecoveredDateTime']: date ? getShowingMonthDateYear(date) : null, ['RecoveredDateTimeTo']: null }) }}
                                                    selected={value?.RecoveredDateTime && new Date(value?.RecoveredDateTime)}
                                                    dateFormat="MM/dd/yyyy"
                                                    timeInputLabel
                                                    isClearable={value?.RecoveredDateTime ? true : false}
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    autoComplete='Off'
                                                    // disabled
                                                    maxDate={new Date()}
                                                    placeholderText='Select...'
                                                />
                                            </div>
                                            <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                                                <label htmlFor="" className='new-label'>Recovered To Date</label>
                                            </div>
                                            <div className="col-3 col-md-3 col-lg-2 ">
                                                <DatePicker
                                                    name='RecoveredDateTimeTo'
                                                    id='RecoveredDateTimeTo'
                                                    onChange={(date) => { setValue({ ...value, ['RecoveredDateTimeTo']: date ? getShowingMonthDateYear(date) : null }) }}
                                                    selected={value?.RecoveredDateTimeTo && new Date(value?.RecoveredDateTimeTo)}
                                                    dateFormat="MM/dd/yyyy"
                                                    timeInputLabel
                                                    isClearable={value?.RecoveredDateTimeTo ? true : false}
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    autoComplete='Off'
                                                    // maxDate={new Date()}
                                                    placeholderText='Select...'
                                                    maxDate={new Date()}
                                                    minDate={new Date(value?.RecoveredDateTime)}
                                                    disabled={value?.RecoveredDateTime ? false : true}
                                                    className={!value?.RecoveredDateTime && 'readonlyColor'}
                                                />
                                            </div>

                                        </div>
                                    </fieldset>
                                    <div className="col-12 col-md-12 col-lg-12 text-right mt-3">
                                        <button className="btn btn-sm bg-green text-white px-2 py-1" onClick={() => { get_PropertyReport(); }}>Show Report</button>
                                        <button className="btn btn-sm bg-green text-white px-2 py-1 ml-2" onClick={() => { setverifyReport(false); setReportedData([]); resetFields(); }}>Clear</button>
                                        {/* <button className="btn btn-sm bg-green text-white px-2 py-1 ml-2" onClick={() => { resetFields(); }}>Clear</button> */}
                                        <Link to={'/Reports'}>
                                            <button className="btn btn-sm bg-green text-white px-2 py-1 ml-2" >Close</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* for 1 table */}
                {
                    verifyReport ?
                        <>
                            <div className="col-12 col-md-12 col-lg-12 pt-2  px-2">
                                <div className="bg-line  py-1 px-2 mt-1 d-flex justify-content-between align-items-center">
                                    <p className="p-0 m-0 d-flex align-items-center">Vehicle Master Report</p>
                                    <div style={{ marginLeft: 'auto' }}>
                                        <Link to={''} onClick={() => { printForm(); }} className="btn btn-sm bg-green  mr-2 text-white px-2 py-0"  >
                                            <i className="fa fa-print"></i>
                                        </Link>
                                        {/* <Link to={''} className="btn btn-sm bg-green  text-white px-2 py-0"  >
                                        <i className="fa fa-file"></i>
                                    </Link> */}
                                    </div>
                                </div>
                            </div>
                            <div className="container mt-1" ref={componentRef} >
                                <div className="row" style={{ border: '1px solid #80808085' }}>
                                    {
                                        reportedData?.Vehicle?.length > 0 ?
                                            <>
                                                <div className="col-4 col-md-3 col-lg-2 pt-1">
                                                    <div className="main">
                                                        {/* <img src={reportedData?.Agency_Photo} className="img-fluid" alt style={{ border: '1px solid aliceblue', marginTop: '4px', width: '150px' }} /> */}
                                                        <div className="img-box" >
                                                            <img src={multiImage} className='picture' style={{ marginTop: '4px', width: '150px', height: '150px' }} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-7  col-md-7 col-lg-9 mt-2">
                                                    <div className="main">
                                                        <h5 className='text-dark text-bold'>{reportedData?.Agency_Name}</h5>
                                                        <p className='text-p'>Address: <span className='new-span pl-2'>{reportedData?.Agency_Address1}</span></p>
                                                        <div className='d-flex '>
                                                            <p className='text-p'>State: <span className='new-span ml-2'>{reportedData?.StateName}</span>
                                                            </p>
                                                            <p className='text-p ml-5 pl-1'>City: <span className='new-span'>{reportedData?.CityName}</span>
                                                            </p>
                                                            <p className='text-p ml-2'>Zip: <span className='new-span'>{reportedData?.Zipcode}</span>
                                                            </p>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <p className='text-p'>Phone: <span className='new-span'>{reportedData?.Agency_Phone}</span></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <>
                                            </>
                                    }
                                    <div className="col-12">
                                        <hr style={{ border: '1px solid rgb(3, 105, 184)' }} />
                                    </div>
                                    <div className="col-12 bb">

                                        <fieldset>
                                            <legend>Search Criteria</legend>

                                            <div className="row">
                                                {showFields.showReportedDate && (
                                                    <>
                                                        <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                            <label className='new-label'>Reported Date From</label>
                                                        </div>
                                                        <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                            <input type="text" className='readonlyColor' value={searchValue.ReportedDate && getShowingWithOutTime(searchValue.ReportedDate)}
                                                                readOnly />
                                                        </div>
                                                    </>
                                                )}

                                                {showFields.showReportedDateTo && (
                                                    <>
                                                        <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                            <label className='new-label'>Reported Date To</label>
                                                        </div>
                                                        <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                            <input type="text" className='readonlyColor' value={searchValue.ReportedDateTo && getShowingWithOutTime(searchValue.ReportedDateTo)}
                                                                readOnly />
                                                        </div>
                                                    </>
                                                )}

                                                {
                                                    showFields.showCategoryID && searchValue.CategoryID && (
                                                        <>
                                                            <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                                <label className='new-label'>Category</label>
                                                            </div>
                                                            <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                                <input type="text" className='readonlyColor' value={categoryIdDrp.find((obj) => obj.value === searchValue.CategoryID)?.label || ''} readOnly />
                                                            </div>
                                                        </>
                                                    )}
                                                {showFields.showVehicleNumber && (
                                                    <>
                                                        <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                            <label className='new-label'>Vehicle Number From</label>
                                                        </div>
                                                        <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                            <input type="text" className='readonlyColor' value={searchValue.VehicleNumber || ''} readOnly />
                                                        </div>
                                                    </>
                                                )}
                                                {showFields.showVehicleNumberTo && (
                                                    <>
                                                        <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                            <label className='new-label'>Vehicle Number To</label>
                                                        </div>
                                                        <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                            <input type="text" className='readonlyColor' value={searchValue.VehicleNumberTo || ''} readOnly />
                                                        </div>
                                                    </>
                                                )}



                                                {
                                                    showFields.showLossCodeID && searchValue.LossCodeID && (
                                                        <>
                                                            <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                                <label className='new-label'>Loss Code</label>
                                                            </div>
                                                            <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                                <input type="text" className='readonlyColor' value={propertyLossCodeData.find((obj) => obj.value === searchValue.LossCodeID)?.label || ''} readOnly />
                                                            </div>
                                                        </>
                                                    )}
                                                {showFields.showValue && (
                                                    <>
                                                        <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                            <label className='new-label'>Value From </label>
                                                        </div>
                                                        <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                            <input type="text" className='readonlyColor' value={searchValue.Value || ''} readOnly />
                                                        </div>
                                                    </>
                                                )}
                                                {showFields.showValueTo && (
                                                    <>
                                                        <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                            <label className='new-label'>Value To</label>
                                                        </div>
                                                        <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                            <input type="text" className='readonlyColor' value={searchValue.ValueTo || ''} readOnly />
                                                        </div>
                                                    </>
                                                )}

                                                {showFields.showLastName && (
                                                    <>
                                                        <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                            <label className='new-label'>Last Name</label>
                                                        </div>
                                                        <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                            <input type="text" className='readonlyColor' value={searchValue.LastName || ''} readOnly />
                                                        </div>
                                                    </>
                                                )}
                                                {showFields.showFirstName && (
                                                    <>
                                                        <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                            <label className='new-label'>First Name</label>
                                                        </div>
                                                        <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                            <input type="text" className='readonlyColor' value={searchValue.FirstName || ''} readOnly />
                                                        </div>
                                                    </>
                                                )}
                                                {showFields.showMiddleName && (
                                                    <>
                                                        <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                            <label className='new-label'>Middle Name</label>
                                                        </div>
                                                        <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                            <input type="text" className='readonlyColor' value={searchValue.MiddleName || ''} readOnly />
                                                        </div>
                                                    </>
                                                )}
                                                {showFields.showReceiveDate && (
                                                    <>
                                                        <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                            <label className='new-label'>Receive Date From </label>
                                                        </div>
                                                        <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                            <input type="text" className='readonlyColor' value={searchValue.ReceiveDate || ''} readOnly />
                                                        </div>
                                                    </>
                                                )}
                                                {showFields.showReceiveDateTo && (
                                                    <>
                                                        <div claReceiveDateame="col-3 col-md-3 col-lg-2 mt-2">
                                                            <label className='new-label'>Receive Date To</label>
                                                        </div>
                                                        <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                            <input type="text" className='readonlyColor' value={searchValue.ReceiveDateTo || ''} readOnly />
                                                        </div>
                                                    </>
                                                )}
                                                {/* 
                                                {
                                                    showFields.showActivityType && searchValue.ActivityType && (
                                                        <>
                                                            <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                                <label className='new-label'>Activity Type</label>
                                                            </div>
                                                            <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                                <input type="text" className='readonlyColor' value={AddTransfer.find((obj) => obj.value === searchValue.ActivityType)?.label || ''} readOnly />
                                                            </div>
                                                        </>
                                                    )} */}
                                                {showFields.showActivityTypeID && searchValue.ActivityTypeID && (
                                                    <>
                                                        <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                            <label className='new-label'>Activity</label>
                                                        </div>
                                                        <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                            <input type="text" className='readonlyColor' value={AddTransfer.find((obj) => obj.value === searchValue.ActivityTypeID)?.label || ''} readOnly />
                                                        </div>
                                                    </>
                                                )}

                                                {
                                                    showFields.showInvestigatorID && searchValue.InvestigatorID && (
                                                        <>
                                                            <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                                <label className='new-label'>Investigator</label>
                                                            </div>
                                                            <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                                <input type="text" className='readonlyColor' value={agencyOfficerDrpData.find((obj) => obj.value === searchValue.InvestigatorID)?.label || ''} readOnly />
                                                            </div>
                                                        </>
                                                    )}
                                                {
                                                    showFields.showStorageLocationID && searchValue.StorageLocationID && (
                                                        <>
                                                            <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                                <label className='new-label'>Location</label>
                                                            </div>
                                                            <div className="col-3 col-md-3 col-lg-4 text-field mt-1">
                                                                <input type="text" className='readonlyColor' value={searchValue.location || ''} readOnly />
                                                            </div>
                                                        </>
                                                    )}
                                                {
                                                    showFields.showDispositionID && searchValue.DispositionID && (
                                                        <>
                                                            <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                                <label className='new-label'>Disposition</label>
                                                            </div>
                                                            <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                                <input type="text" className='readonlyColor' value={dispositionsDrpData.find((obj) => obj.value === searchValue.DispositionID)?.label || ''} readOnly />
                                                            </div>
                                                        </>
                                                    )}
                                                {showFields.showRecoveredDateTime && (
                                                    <>
                                                        <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                            <label className='new-label'>Recovered Date From </label>
                                                        </div>
                                                        <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                            <input type="text" className='readonlyColor' value={searchValue.RecoveredDateTime || ''} readOnly />
                                                        </div>
                                                    </>
                                                )}
                                                {showFields.showRecoveredDateTimeTo && (
                                                    <>
                                                        <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                            <label className='new-label'>Recovered Date To </label>
                                                        </div>
                                                        <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                            <input type="text" className='readonlyColor' value={searchValue.RecoveredDateTimeTo || ''} readOnly />
                                                        </div>
                                                    </>
                                                )}

                                            </div>
                                        </fieldset>
                                    </div>
                                    {
                                        reportedData?.Vehicle?.length > 0 ?
                                            <>
                                                {
                                                    reportedData?.Vehicle?.map((obj) => (
                                                        <>

                                                            <div className="container" >
                                                                {/* <h5 className=" text-white text-bold bg-green text-center  py-1 px-3">Vehicle Information:{obj?.PropertyNumber}</h5>
                                                                <div className="table-responsive" style={{ border: '1px solid #ddd' }}>
                                                                    <div className="text-white text-bold bg-green py-1 px-2  d-flex justify-content-between align-items-center">
                                                                        <p className="p-0 m-0 d-flex align-items-center">Property Information</p>
                                                                    </div>
                                                                    <table className="table table-bordered" >
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>
                                                                                    <h6 className='text-dark text-bold'>Property Number:</h6>
                                                                                    <p>{obj?.PropertyNumber}</p>
                                                                                </td>
                                                                             
                                                                                <td>
                                                                                    <h6 className='text-dark text-bold'>Expiration Date</h6>
                                                                                    <p>{obj?.InspectionExpiresDtTm ? getShowingMonthDateYear(obj?.InspectionExpiresDtTm) : null}</p>
                                                                                </td>
                                                                                <td >
                                                                                    <h6 className='text-dark text-bold'>Reported Date/Time:</h6>
                                                                                    <p>{obj?.ReportedDtTm ? getShowingDateText(obj?.ReportedDtTm) : null}</p>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td >
                                                                                    <h6 className='text-dark text-bold'>Property Type:</h6>
                                                                                    <p>{obj?.PropertyType_Description}</p>
                                                                                </td>
                                                                                <td>
                                                                                    <h6 className='text-dark text-bold'>Property Value:</h6>
                                                                                    <p>{obj?.Value}</p>
                                                                                </td>
                                                                                <td>
                                                                                    <h6 className='text-dark text-bold'>Property Reason:</h6>
                                                                                    <p>{obj?.Description}</p>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>






                                                       
                                                                    <table className="table table-bordered" style={{ marginTop: '-15px' }}>
                                                                        <tbody className=''>
                                                                            <div className="col-12">
                                                                                <div className="row">
                                                                                    {
                                                                                        JSON.parse(obj?.Photo)?.length > 0 ?
                                                                                            JSON.parse(obj?.Photo)?.map((item, index) => {
                                                                                                return (
                                                                                                    <div className="col-3">
                                                                                                        <div className="main" key={index}>
                                                                                                            <img src={item.Photo} style={{ height: '200px' }} />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )
                                                                                            })
                                                                                            :
                                                                                            <>
                                                                                            </>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </tbody>
                                                                    </table>
                                                                </div> */}

                                                                {/* <h5 className=" text-white text-bold bg-green text-center py-1 px-3">Vehicle Information : {obj?.VehicleNumber}</h5> */}
                                                                <div className="table-responsive mt-2" >
                                                                    <div className="text-white text-bold bg-green py-1 px-2  d-flex justify-content-between align-items-center">
                                                                        <p className="p-0 m-0 d-flex align-items-center">Vehicle Information: {obj?.VehicleNumber}</p>
                                                                    </div>
                                                                    <table className="table table-bordered" >
                                                                        <tbody>
                                                                            <tr>
                                                                                <td colSpan={3}>
                                                                                    <h6 className='text-dark text-bold'>Vehicle Number:</h6>
                                                                                    <p className='text-list'>{obj?.VehicleNumber}</p>
                                                                                </td>
                                                                                <td colSpan={3}>
                                                                                    <h6 className='text-dark text-bold'>Reported Date/Time:</h6>
                                                                                    <p>{obj?.ReportedDtTm}</p>
                                                                                </td>
                                                                                <td colSpan={3}>
                                                                                    <h6 className='text-dark text-bold'>Value:</h6>
                                                                                    <p>{obj?.Value}</p>
                                                                                </td>

                                                                                <td colSpan={3}>
                                                                                    <h6 className='text-dark text-bold'>Category</h6>
                                                                                    <p className='text-list'>{obj?.Category_Description}</p>
                                                                                </td>

                                                                                {/* <td>
                                                                                    <h6 className='text-dark text-bold'>Expiration Date</h6>
                                                                                    <p>{obj?.InspectionExpiresDtTm ? getShowingMonthDateYear(obj?.InspectionExpiresDtTm) : null}</p>
                                                                                </td>
                                                                                <td >
                                                                                    <h6 className='text-dark text-bold'>Reported Date/Time:</h6>
                                                                                    <p>{obj?.ReportedDtTm ? getShowingDateText(obj?.ReportedDtTm) : null}</p>
                                                                                </td> */}
                                                                            </tr>
                                                                            <tr>
                                                                                <td colSpan={3}>
                                                                                    <h6 className='text-dark text-bold'>VIN</h6>
                                                                                    <p className='text-list'>{obj?.VIN}</p>
                                                                                </td>
                                                                                <td colSpan={3}>
                                                                                    <h6 className='text-dark text-bold'>State Plate Number</h6>
                                                                                    <p className='text-list'>{obj?.StatePlateNumber}</p>
                                                                                </td>
                                                                                <td colSpan={6}>
                                                                                    <h6 className='text-dark text-bold'>Loss Code:</h6>
                                                                                    <p>{obj?.LossCode_Description}</p>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td colSpan={3}>
                                                                                    <h6 className='text-dark text-bold'>Plate Type:</h6>
                                                                                    <p className='text-list'>{obj?.PlateType_Description}</p>
                                                                                </td>
                                                                                <td colSpan={3}>
                                                                                    <h6 className='text-dark text-bold'>Make:</h6>
                                                                                    <p className='text-list'>{obj?.Make_Description}</p>
                                                                                </td>
                                                                                <td colSpan={3}>
                                                                                    <h6 className='text-dark text-bold'>Primary Color:</h6>
                                                                                    <p className='text-list'>{obj?.PrimaryColor_Description}</p>
                                                                                </td>
                                                                                <td colSpan={3}>
                                                                                    <h6 className='text-dark text-bold'>Secondary Color:</h6>
                                                                                    <p className='text-list'>{obj?.SecondaryColor_Description}</p>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td colSpan={3}>
                                                                                    <h6 className='text-dark text-bold'>Model:</h6>
                                                                                    <p className='text-list'>{obj?.Model_Description}</p>
                                                                                </td>
                                                                                <td colSpan={3}>
                                                                                    <h6 className='text-dark text-bold'>Style:</h6>
                                                                                    <p className='text-list'>{obj?.style_Desc}</p>
                                                                                </td>
                                                                                <td colSpan={3}>
                                                                                    <h6 className='text-dark text-bold'>VOD:</h6>
                                                                                    <p className='text-list'>{obj?.VOD_Description}</p>
                                                                                </td>
                                                                                <td colSpan={3}>
                                                                                    <h6 className='text-dark text-bold'>ManufactureYear:</h6>
                                                                                    <p className='text-list'>{obj?.ManufactureYear ? getYearWithOutDateTime(obj?.ManufactureYear) : null}</p>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td colSpan={3}>
                                                                                    <h6 className='text-dark text-bold'>Plate Expire Year:</h6>
                                                                                    <p className='text-list'>{obj?.PlateExpireDtTm ? getShowingWithOutTime(obj?.PlateExpireDtTm) : null}</p>
                                                                                </td>
                                                                                <td colSpan={3}>
                                                                                    <h6 className='text-dark text-bold'>Owner MNI:</h6>
                                                                                    <p className='text-list'>{obj?.NameIDNumber}</p>
                                                                                </td>
                                                                                <td colSpan={3}>
                                                                                    <h6 className='text-dark text-bold'>Owner</h6>
                                                                                    <p className='text-list'>{obj?.Owner_Description}</p>
                                                                                </td>
                                                                                <td colSpan={3}>
                                                                                    <h6 className='text-dark text-bold'>SSN</h6>
                                                                                    <p className='text-list'>{obj?.SSN}</p>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td colSpan={10}>
                                                                                    <h6 className='text-dark text-bold'>In Possession Of</h6>
                                                                                    <p className='text-list'>{obj?.Owner_Description}</p>
                                                                                </td>
                                                                                <td colSpan={2}>
                                                                                    <h6 className='text-dark text-bold'>OAN Number</h6>
                                                                                    <p className='text-list'>{obj?.OANID}</p>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td colSpan={10}>
                                                                                    <h6 className='text-dark text-bold'>Address</h6>
                                                                                    <p className='text-list'>{obj?.Address}</p>
                                                                                </td>

                                                                                <td colSpan={2}>
                                                                                    <h6 className='text-dark text-bold'>Phone Number</h6>
                                                                                    <p className='text-list'>{obj?.Contact}</p>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                    {/* <table className="table table-bordered" >
                                                                        <tbody className=''>
                                                                            <div className="col-12">
                                                                                <div className="row">
                                                                                    {
                                                                                        JSON.parse(obj?.Photo)?.length > 0 ?
                                                                                            JSON.parse(obj?.Photo)?.map((item, index) => {
                                                                                                return (
                                                                                                    <div className="col-3">
                                                                                                        <div className="main" key={index}>
                                                                                                            <img src={item.Photo} style={{ height: '200px' }} />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )
                                                                                            })
                                                                                            :
                                                                                            <>
                                                                                            </>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </tbody>
                                                                    </table> */}
                                                                </div>
                                                                {/* vehicle-document */}
                                                                {JSON.parse(obj?.Document)?.length > 0 ?
                                                                    <div className="table-responsive mt-2" >
                                                                        <div className="text-white text-bold bg-green py-1 px-2  d-flex justify-content-between align-items-center">
                                                                            <p className="p-0 m-0 d-flex align-items-center">Vehicle Document:</p>
                                                                        </div>
                                                                        <table className="table " >
                                                                            <thead className='text-dark master-table'>
                                                                                <tr>
                                                                                    <th className='' >Document Name</th>
                                                                                    <th className='' >Notes</th>
                                                                                    <th className=''>Document Type</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {
                                                                                    JSON.parse(obj?.Document)?.map((item, key) => (
                                                                                        <>
                                                                                            <tr key={key}>
                                                                                                <td className='text-list'>{item.DocumentName}</td>
                                                                                                <td className='text-list'>{item.DocumentNotes}</td>
                                                                                                <td className='text-list'>{item.DocumentType_Description}</td>
                                                                                            </tr>
                                                                                        </>
                                                                                    ))
                                                                                }
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                    : <></>}
                                                                {/* vehicle-recoverd */}
                                                                {
                                                                    JSON.parse(obj?.Recovered)?.length > 0 ?
                                                                        <>
                                                                            <div className="table-responsive" >
                                                                                <div className="text-white text-bold bg-green py-1 px-2  d-flex justify-content-between align-items-center">
                                                                                    <p className="p-0 m-0 d-flex align-items-center">Recovered Property:</p>
                                                                                </div>
                                                                                <table className="table " >
                                                                                    <thead className='text-dark master-table'>
                                                                                        <tr>
                                                                                            <th className='' style={{ width: '100px' }}>Recovered Id Number</th>
                                                                                            <th className='' style={{ width: '100px' }}>Recovered Date/Time</th>
                                                                                            <th className='' style={{ width: '100px' }}>Recovery Type</th>
                                                                                            <th className='' style={{ width: '100px' }}>Recovered Value</th>
                                                                                            <th className='' style={{ width: '100px' }}>Comment</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody >
                                                                                        {
                                                                                            JSON.parse(obj?.Recovered)?.map((item, key) => (
                                                                                                <>
                                                                                                    <tr key={key}>
                                                                                                        <td style={{ width: '100px' }} className='text-list'>{item.RecoveredIDNumber}</td>
                                                                                                        <td style={{ width: '100px' }} className='text-list'>{getShowingDateText(item.RecoveredDateTime)}</td>
                                                                                                        <td style={{ width: '100px' }} className='text-list'>{item.RecoveryType}</td>
                                                                                                        <td style={{ width: '100px' }} className='text-list'>{item.RecoveredValue}</td>
                                                                                                        <td style={{ width: '100px' }} className='text-list'>{item.Comments}</td>
                                                                                                    </tr>
                                                                                                </>
                                                                                            ))
                                                                                        }
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        <></>
                                                                }
                                                                {
                                                                    JSON.parse(obj?.Transaction)?.length > 0 ?
                                                                        <>
                                                                            <div className="table-responsive mt-2" >
                                                                                <div className="text-white text-bold bg-green py-1 px-2  d-flex justify-content-between align-items-center">
                                                                                    <p className="p-0 m-0 d-flex align-items-center">Transaction Information:</p>
                                                                                </div>
                                                                                <table className="table " >
                                                                                    <thead className='text-dark master-table'>
                                                                                        <tr>
                                                                                            <th className='' style={{ width: '100px' }}>Transaction Name</th>
                                                                                            <th className='' style={{ width: '100px' }}>Transaction Number</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {
                                                                                            JSON.parse(obj?.Transaction)?.map((item, key) => (
                                                                                                <>
                                                                                                    <tr key={key}>
                                                                                                        <td style={{ width: '100px' }} className='text-list'>{item.TransactionName}</td>
                                                                                                        <td style={{ width: '100px' }} className='text-list'>{item.TransactionNumber}</td>
                                                                                                    </tr>
                                                                                                </>
                                                                                            ))
                                                                                        }
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        <></>
                                                                }
                                                                {
                                                                    JSON.parse(obj?.Propertyroom)?.length > 0 ?
                                                                        <>
                                                                            <div className="table-responsive mt-2" >
                                                                                <div className="text-white text-bold bg-green py-1 px-2  d-flex justify-content-between align-items-center">
                                                                                    <p className="p-0 m-0 d-flex align-items-center">Property Room Information:</p>
                                                                                </div>
                                                                                <table className="table " >
                                                                                    <thead className='text-dark master-table'>
                                                                                        <tr>
                                                                                            <th className='' style={{ width: '100px' }}>Activity</th>
                                                                                            <th className='' style={{ width: '100px' }}>Reason</th>
                                                                                            <th className='' style={{ width: '100px' }}>Date/Time</th>
                                                                                            <th className='' style={{ width: '100px' }}>By PIN</th>
                                                                                            <th className='' style={{ width: '100px' }}>For PIN</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {
                                                                                            JSON.parse(obj?.Transaction)?.map((item, key) => (
                                                                                                <>
                                                                                                    <tr key={key}>
                                                                                                        <td style={{ width: '100px' }} className='text-list'>{item.TransactionName}</td>
                                                                                                        <td style={{ width: '100px' }} className='text-list'>{item.TransactionNumber}</td>
                                                                                                    </tr>
                                                                                                </>
                                                                                            ))
                                                                                        }
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        <></>
                                                                }
                                                                {/* vehicle-img */}
                                                                {
                                                                    JSON.parse(obj?.Photo)?.length > 0 ?
                                                                        <div className="table-responsive mt-3">
                                                                            <div className="text-white text-bold bg-green py-1 px-2  d-flex justify-content-between align-items-center">
                                                                                <p className="p-0 m-0 d-flex align-items-center">Vehicle Image Information</p>
                                                                            </div>
                                                                            <table className="table table-bordered" >
                                                                                <tbody className=''>
                                                                                    <div className="row">
                                                                                        {
                                                                                            JSON.parse(obj?.Photo)?.map((item, index) => {
                                                                                                return (
                                                                                                    <div className="col-3">
                                                                                                        <div className="main" key={index}>
                                                                                                            <img src={item.Photo} style={{ height: '200px' }} />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </div>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                        :
                                                                        <>
                                                                        </>
                                                                }


                                                            </div>
                                                        </>
                                                    ))
                                                }
                                            </>
                                            :
                                            <>
                                            </>
                                    }
                                </div>
                            </div>
                        </>
                        :
                        <>
                        </>
                }
            </div >
            <TreeModalReport {...{ proRoom, locationStatus, setLocationStatus, locationPath, setLocationPath, setSearchStoragePath, setValue }} />
        </>
    )
}

export default VehicleMasterReport
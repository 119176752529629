import { Incident_ReportDate, Local_Store_Data, NameType_Data, } from '../actionTypes';
import * as api from '../api'
import { threeColArray } from '../../Components/Common/ChangeArrayFormat';
import { fetchPostData } from '../../Components/hooks/Api';

//----------get the All  local Array data  with unique ID ------------Dk-> 
export const insert_LocalStoreData = (formData, Tokens) => async (dispatch) => {
    try {
        dispatch({ type: Local_Store_Data, payload: Tokens });
    } catch (error) {
        dispatch({ type: Local_Store_Data, payload: Tokens });
    }
};

//----------get the All  local Array data  with unique ID ------------Dk->   
export const get_LocalStoreData = (UniqueId) => async (dispatch) => {
    const val = { UniqueId: UniqueId };
    fetchPostData('LocalStorage/GetData_UniqueLocalStorage', val).then((data) => {
        // console.log("LocalStorage after Refresh", data)
        if (data) {
            dispatch({ type: Local_Store_Data, payload: data[0] });
        } else {
            dispatch({ type: Local_Store_Data, payload: [] });
        }
    })
    // try {
    //     const data = await api.get_LocalStoreData(val);
    //     const TextData = JSON.parse(data?.data?.data);
    //     const dataObj = TextData?.Table[0]
    //     // console.log("get Local data", dataObj)
    //     dispatch({ type: Local_Store_Data, payload: dataObj });
    // } catch (error) {
    //     dispatch({ type: Local_Store_Data, payload: [] });
    // }
};

export const get_Inc_ReportedDate = (ID) => async (dispatch) => {
    const val = { IncidentID: ID };
    fetchPostData('Incident/GetDataReportedDate', val).then((data) => {
        if (data) {
            dispatch({ type: Incident_ReportDate, payload: data[0]?.ReportedDate });
        } else {
            dispatch({ type: Incident_ReportDate, payload: [] });
        }
    })
    // try {
    //     const data = await api.get_Inc_ReportedDate(val);
    //     const TextData = JSON.parse(data?.data?.data);
    //     const dataObj = TextData?.Table[0]
    //     dispatch({ type: Incident_ReportDate, payload: dataObj?.ReportedDate });
    // } catch (error) {
    //     dispatch({ type: Incident_ReportDate, payload: [] });
    // }
};

export const get_NameTypeData = (LoginAgencyID) => async (dispatch) => {
    const val = { AgencyID: LoginAgencyID }
    fetchPostData('NameType/GetDataDropDown_NameType', val).then((data) => {
        if (data) {
            dispatch({ type: NameType_Data, payload: threeColArray(data, 'NameTypeID', 'Description', 'NameTypeCode') });
        } else {
            dispatch({ type: NameType_Data, payload: [] });
        }
    })
    // try {
    //     let res = await api.get_NameTypeData(val);
    //     let TextData = JSON.parse(res?.data?.data);
    //     let dataObj = TextData?.Table
    //     dispatch({ type: NameType_Data, payload: threeColArray(dataObj, 'NameTypeID', 'Description', 'NameTypeCode') });
    // } catch {
    //     dispatch({ type: NameType_Data, payload: [] });
    // }
}

import React, { useEffect, useState } from 'react'
import img from '../../../../../../src/img/images1.jpg'
import DatePicker from "react-datepicker";
import { Decrypt_Id_Name, getShowingWithOutTime, getShowingDateText, getShowingWithMonthOnly } from '../../../../Common/Utility';
import { Link } from 'react-router-dom';
import { fetchPostData } from '../../../../hooks/Api';
import { toastifyError } from '../../../../Common/AlertMsg';
import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { useContext } from 'react';
import { AgencyContext } from '../../../../../Context/Agency/Index';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { get_LocalStoreData } from '../../../../../redux/actions/Agency';

const IncidentMonthly = () => {

    const { localStoreArray, setLocalStoreArray, get_LocalStorage } = useContext(AgencyContext)
    const dispatch = useDispatch();
    const uniqueId = sessionStorage.getItem('UniqueUserID') ? Decrypt_Id_Name(sessionStorage.getItem('UniqueUserID'), 'UForUniqueUserID') : '';
    const localStoreData = useSelector((state) => state.Agency.localStoreData);

    const [multiImage, setMultiImage] = useState([]);
    const [verifyIncident, setverifyIncident] = useState(false);
    const [year, setYear] = useState()
    const [month, setMonth] = useState()
    const [startDate, setStartDate] = useState();
    const [incidentData, setIncidentData] = useState([]);
    const [masterReportData, setMasterReportData] = useState([]);
    const [MainIncidentID, setMainIncidentID] = useState('');
    const [LoginAgencyID, setLoginAgencyID] = useState('');
    const [LoginPinID, setLoginPinID,] = useState('');



    useEffect(() => {
        if (!localStoreData?.AgencyID || !localStoreData?.PINID) {
            if (uniqueId) dispatch(get_LocalStoreData(uniqueId));
        }
    }, []);

    // Onload Function
    useEffect(() => {
        if (localStoreData) {
            setLoginAgencyID(localStoreData?.AgencyID);
            setLoginPinID(localStoreData?.PINID);
        }
    }, [localStoreData])



    const [value, setValue] = useState({
        'ReportedDate': '', 'AgencyID': ''
        // Month: '',
        //  Year: '',
    });
    const [searchValue, setSearchValue] = useState({
        ReportedDate: '',
    });

    const [showFields, setShowFields] = useState({
        showReportedDateFrom: false,
    });
    useEffect(() => {
        setShowFields({
            showReportedDateFrom:searchValue.ReportedDate,
        });
    }, [searchValue]);
    useEffect(() => {
        if (incidentData?.length > 0) {
            setverifyIncident(true);
        }
    }, [incidentData]);

    const get_MonthlyReport = async () => {
        if (value?.ReportedDate?.trim()?.length > 0) {
            const { ReportedDate, AgencyID } = value
            const val = {
                'ReportedDate': ReportedDate, 'AgencyID': LoginAgencyID,
            }
            fetchPostData('Report/IncidentReport_Monthly', val).then((res) => {
                if (res.length > 0) {
                    console.log(res);
                    setIncidentData(res[0].Incident); setMasterReportData(res[0]); getAgencyImg(LoginAgencyID);
                    setSearchValue(value); 
                } else {
                    toastifyError("Data Not Available");
                    setIncidentData([]);
                    setverifyIncident(false);
                }
            });
        } else {
            toastifyError("Please Select Year/Month")
        }
    }

    const componentRef = useRef();
    const printForm = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'Data',
        onAfterPrint: () => { '' }
    })

    const Reset = () => {
        setValue({ ...value, 'ReportedDate': '', });
        setStartDate(''); setverifyIncident(false); setIncidentData([]); setMasterReportData([])
    }

    const getAgencyImg = (LoginAgencyID) => {
        const val = { 'AgencyID': LoginAgencyID }
        fetchPostData('Agency/GetDataAgencyPhoto', val).then((res) => {
            if (res) {
                let imgUrl = `data:image/png;base64,${res[0]?.Agency_Photo}`;
                setMultiImage(imgUrl);

            }
            else { console.log("errror") }
        })
    }
  
    return (
        <>
            <div class="section-body view_page_design pt-3">
                <div className="row clearfix">
                    <div className="col-12 col-sm-12">
                        <div className="card Agency">
                            <div className="card-body">
                                <fieldset>
                                    <legend>Incident Monthly Report</legend>
                                    <div className="row mt-2">
                                        <div className="col-3 col-md-3 col-lg-1 mt-2 ">
                                            <label htmlFor="" className='new-label'>Year</label>
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-2 ">
                                            <DatePicker
                                                selected={startDate}
                                                peekNextMonth
                                                onChange={(date) => {
                                                    setStartDate(date); console.log(date);
                                                    setValue({ ...value, ['ReportedDate']: getShowingWithOutTime(date) })
                                                }}
                                                dateFormat="MM/yyyy"
                                                showMonthYearPicker
                                                maxDate={new Date()}
                                                autoComplete="nope"
                                                placeholderText={'Select...'}
                                            />
                                        </div>
                                        <div className="col-9 col-md-9 col-lg-9 mt-1 pt-1 " >
                                            <button className="btn btn-sm bg-green text-white px-2 py-1" onClick={() => { get_MonthlyReport(); }}>Show Report</button>
                                            <button className="btn btn-sm bg-green text-white px-2 py-1 ml-2" onClick={() => { Reset(); }}>Clear</button>
                                            <Link to={'/Reports'}>
                                                <button className="btn btn-sm bg-green text-white px-2 py-1 ml-2" >Close</button>
                                            </Link>
                                        </div>
                                    </div>
                                </fieldset>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* for 1 table */}
            {
                verifyIncident ?

                    incidentData?.length > 0 ?
                        <>
                            <div className="col-12 col-md-12 col-lg-12 pt-2  px-2" >
                                <div className="bg-line  py-1 px-2 mt-1 d-flex justify-content-between align-items-center">
                                    <p className="p-0 m-0 d-flex align-items-center">Incident Monthly Report</p>
                                    <div style={{ marginLeft: 'auto' }}>
                                        <Link to={''} className="btn btn-sm bg-green  mr-2 text-white px-2 py-0"  >
                                            <i className="fa fa-print" onClick={printForm}></i>
                                        </Link>
                                        {/* <Link to={''} className="btn btn-sm bg-green  text-white px-2 py-0"  >
                                            <i className="fa fa-file"></i>
                                        </Link> */}
                                    </div>
                                </div>
                            </div>

                            <div className="container mt-1" ref={componentRef}>
                                <div className="row" style={{ border: '1px solid #80808085' }}>
                                    <>
                                        <div className="col-4 col-md-3 col-lg-2 pt-1 ">
                                            <div className="main">
                                                {/* <img src={masterReportData?.Agency_Photo} className="img-fluid" alt='Agency_Photo' style={{ border: '1px solid aliceblue', marginTop: '4px', width: '150px' }} /> */}
                                                <div className="img-box" >
                                                    <img src={multiImage} className='picture' style={{ marginTop: '4px', width: '150px', height: '150px' }} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-7  col-md-7 col-lg-9 mt-2">
                                            <div className="main">
                                                <h5 className='text-dark text-bold'>{masterReportData?.Agency_Name}</h5>
                                                <p className='text-p'>Address: <span className='text-address'>{masterReportData?.Agency_Address1}</span></p>
                                                <div className='d-flex '>
                                                    <p className='text-p'>State: <span className='new-span ml-3'>{masterReportData?.StateName}</span>
                                                    </p>
                                                    <p className='text-p ml-5 pl-1'>City: <span className='new-span  '>{masterReportData?.CityName}</span>
                                                    </p>
                                                    <p className='text-p ml-2'>Zip: <span className='new-span  '>{masterReportData?.Agency_ZipId}</span>
                                                    </p>
                                                </div>
                                                <div className='d-flex'>
                                                    <p className='text-p'>Phone: <span className='new-span  ml-1'>{masterReportData?.Agency_Phone}</span></p>
                                                    <p className='text-p ml-4 pl-1'>Fax: <span className='new-span  '> {masterReportData?.Agency_Fax}</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    <div className="col-12">
                                        <hr style={{ border: '1px solid rgb(3, 105, 184)' }} />
                                    </div>
                                    <div className="col-12">

                                        <fieldset>
                                            <legend>Search Criteria</legend>

                                            <div className="row">
                                                {showFields.showReportedDateFrom && (
                                                    <>
                                                        <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                            <label className='new-label'>Year</label>
                                                        </div>
                                                        <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                            <input type="text" className='readonlyColor' value={searchValue.ReportedDate && getShowingWithMonthOnly(searchValue.ReportedDate)} readOnly />
                                                        </div>
                                                    </>
                                                )}

                                            </div>
                                        </fieldset>
                                    </div>
                                    {
                                        incidentData?.length > 0 ?
                                            <>
                                                <div className="container">
                                                    <h5 className="text-white text-bold bg-green py-1 px-3">Incident Details</h5>
                                                    <div className="col-12">
                                                        <div className="table-responsive">
                                                            <div className='d-flex justify-content-between bb bt'>
                                                                <h6 className='text-dark'>Patrol Zone <span></span></h6>
                                                                <h6 className='text-dark'>Zip Code: <span className='text-gray'>{masterReportData?.Agency_ZipId}</span> </h6>
                                                                <h6 className='text-dark'>City: <span className='text-gray'> {masterReportData?.CityName}</span></h6>
                                                            </div>
                                                            <table className="table">
                                                                <thead className='text-dark master-table'>
                                                                    <tr className='bb'>
                                                                        <th className='' style={{ width: '100px' }}>Incident Number</th>
                                                                        <th className='' style={{ width: '150px' }}>Reported Date/Time</th>
                                                                        <th className='' style={{ width: '150px' }}>Officer</th>
                                                                        <th className='' style={{ width: '150px' }}>CAD CFS Code</th>
                                                                        <th className='' style={{ width: '150px' }}>Crime Location</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className='master-tbody'>
                                                                    {
                                                                        // console.log(JSON.parse(obj?.Arrest))
                                                                        incidentData?.map((item, key) => (
                                                                            <tr key={key} style={{ borderBottom: '0.2px solid gray' }}>
                                                                                <td style={{ width: '100px' }} className='text-list'>{item.IncidentNumber}</td>
                                                                                <td style={{ width: '150px' }} className='text-list'>{item.ReportedDate && getShowingDateText(item.ReportedDate)}</td>
                                                                                <td style={{ width: '150px' }} className='text-list'>{item.Officer_Name}</td>
                                                                                <td style={{ width: '150px' }} className='text-list'>{item.CADCFSCode_Description}</td>
                                                                                <td style={{ width: '150px' }} className='text-list'>{item.CrimeLocation}</td>
                                                                            </tr>
                                                                        ))
                                                                    }
                                                                </tbody>
                                                            </table>
                                                            <hr />
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <></>
                                    }
                                </div>
                            </div>
                        </>
                        :
                        <>
                        </>
                    :
                    <>
                    </>
            }
        </>
    )
}

export default IncidentMonthly
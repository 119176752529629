import { toastifyError } from '../../Components/Common/AlertMsg';
import { Comman_changeArrayFormat } from '../../Components/Common/ChangeArrayFormat';
import { fetchPostData } from '../../Components/hooks/Api';
import { Agency_OfficerDrp_Data, VehicleSearch_Modal_Status, VehicleSearch_Type, Vehicle_Search_Data } from '../actionTypes';
import * as api from '../api'

function hasValues(obj) {
    for (let key in obj) {
        if (obj[key]) {
            return true;
        }
    }
    return false;
}

export const get_AgencyOfficer_Data = (ID) => async (dispatch) => {
    const val = { AgencyID: ID };
    fetchPostData('DropDown/GetData_HeadOfAgency', val).then((data) => {
        if (data) {
            dispatch({ type: Agency_OfficerDrp_Data, payload: Comman_changeArrayFormat(data, 'PINID', 'HeadOfAgency') });
        } else {
            dispatch({ type: Agency_OfficerDrp_Data, payload: [] });
        }
    })
};


export const get_Vehicle_Search_Data = (VIN, LossCodeID, MakeID, ManufactureYear, PlateID, VehicleNo, PlateTypeID, CategoryID, setSearchModalState, loginAgencyID) => async (dispatch) => {
    dispatch({ type: VehicleSearch_Type, payload: 'Pro-Vehicle' });
    const val = { 'VIN': VIN, 'LossCodeID': LossCodeID, 'MakeID': MakeID, 'ManufactureYear': ManufactureYear, 'AgencyID': loginAgencyID, 'PlateID': PlateID, 'VehicleNo': VehicleNo, 'PlateTypeID': PlateTypeID, 'CategoryID': CategoryID }
    const isEmpty = hasValues(val)
    if (isEmpty) {
        fetchPostData('PropertyVehicle/Search_PropertyVehicle', { 'VIN': VIN, 'LossCodeID': LossCodeID, 'MakeID': MakeID, 'ManufactureYear': ManufactureYear, 'PlateID': PlateID, 'AgencyID': loginAgencyID, 'VehicleNo': VehicleNo, 'PlateTypeID': PlateTypeID, 'CategoryID': CategoryID }).then((res) => {
            if (res?.length > 0) {
                console.log(res);
                dispatch({ type: Vehicle_Search_Data, payload: res });
                dispatch({ type: VehicleSearch_Modal_Status, payload: true });
                setSearchModalState(true)
            } else {
                dispatch({ type: Vehicle_Search_Data, payload: [] });
                toastifyError("Data Not Available")
            }
        })
    } else {
        console.log("Search Empty")
        // setSearchModalState(false)
        toastifyError("Empty Search")
    }
};
import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import img from '../../../../../src/img/images1.jpg'
import Select from "react-select";
import { Decrypt_Id_Name, customStylesWithOutColor, getShowingDateText, getShowingMonthDateYear } from '../../../Common/Utility';
import DatePicker from "react-datepicker";
import { fetchPostData } from '../../../hooks/Api';
import { useReactToPrint } from 'react-to-print';
import { Comman_changeArrayFormat, threeColArray } from '../../../Common/ChangeArrayFormat';
import { useContext } from 'react';
import { AgencyContext } from '../../../../Context/Agency/Index';
import { toastifyError } from '../../../Common/AlertMsg';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { get_LocalStoreData } from '../../../../redux/actions/Agency';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { get_AgencyOfficer_Data, get_VehicleLossCode_Drp_Data } from '../../../../redux/actions/DropDownsData';

const MasterPropertyReport = () => {

    const dispatch = useDispatch();
    const uniqueId = sessionStorage.getItem('UniqueUserID') ? Decrypt_Id_Name(sessionStorage.getItem('UniqueUserID'), 'UForUniqueUserID') : '';
    const localStoreData = useSelector((state) => state.Agency.localStoreData);
    const agencyOfficerDrpData = useSelector((state) => state.DropDown.agencyOfficerDrpData);
    const propertyLossCodeData = useSelector((state) => state.DropDown.vehicleLossCodeDrpData);


    const { get_PropertyLossCode, localStoreArray, setLocalStoreArray, get_LocalStorage } = useContext(AgencyContext);
    const [dispositionsDrpData, setDispositionsDrpData] = useState([]);

    const [multiImage, setMultiImage] = useState([]);
    const [propertyTypeData, setPropertyTypeData] = useState([]);
    const [verifyReport, setverifyReport] = useState(false);
    const [reportedData, setReportedData] = useState([]);
    const [localStatus, setlocalStatus] = useState(false);
    const [LoginAgencyID, setLoginAgencyID] = useState('');
    const [LoginPinID, setLoginPinID,] = useState('');
    const [recoverDate, setRecoverDate] = useState();

    const [value, setValue] = useState({
        'PropertyTypeID': null, 'LossCodeID': null, 'ReportedDtTm': "", 'ReportedDtTmTo': "", 'PropertyNumber': '', 'PropertyNumberTo': '',
        'LastName': '', 'FirstName': '', 'MiddleName': '', 'AgencyID': '', 'ValueTo': '', 'ValueFrom': '',
        'ActivityType': '', 'ActivityTypeID': '', 'ReceiveDate': '', 'ReceiveDateTo': '', 'InvestigatorID': '', 'location': '',
        'DispositionID': null, 'RecoveredDateTime': '', 'RecoveredDateTimeTo': '',
    });
    const [searchValue, setSearchValue] = useState({
        PropertyTypeID: null,
        LossCodeID: null,
        ReportedDtTm: '',
        ReportedDtTmTo: '',
        PropertyNumber: '',
        PropertyNumberTo: '',
        LastName: '',
        FirstName: '',
        MiddleName: '',
        ValueTo: '',
        ValueFrom: '',
        ActivityTypeID: null,
        ReceiveDate: '',
        ReceiveDateTo: '',
        InvestigatorID: null,
        location: '',
        DispositionID: null,
        RecoveredDateTime: '',
        RecoveredDateTimeTo: '',
    });

    const [showFields, setShowFields] = useState({
        showPropertyTypeID: false,
        showLossCodeID: false,
        showReportedDtTm: false,
        showReportedDtTmTo: false,
        showPropertyNumber: false,
        showPropertyNumberTo: false,
        showLastName: false,
        showFirstName: false,
        showMiddleName: false,
        showValueTo: false,
        showValueFrom: false,
        showActivityTypeID: false,
        showReceiveDate: false,
        showReceiveDateTo: false,
        showInvestigatorID: false,
        showlocation: false,
        showDispositionID: false,
        showRecoveredDateTime: false,
        showRecoveredDateTimeTo: false,
    });
    useEffect(() => {
        if (!localStoreData?.AgencyID || !localStoreData?.PINID) {
            if (uniqueId) dispatch(get_LocalStoreData(uniqueId));
        }
    }, []);
    // useEffect(() => {
    //     if (localStoreData) {
    //         if (propertyLossCodeData?.length === 0) { dispatch(get_VehicleLossCode_Drp_Data(localStoreData?.AgencyID)) };
    //         setLoginAgencyID(localStoreData?.AgencyID);
    //         setLoginPinID(localStoreData?.PINID);
    //         get_PropertyType(localStoreData?.AgencyID);
    //         // if (agencyOfficerDrpData?.length === 0) { dispatch(get_AgencyOfficer_Data(localStoreData?.AgencyID)); }
    //         get_Dispositions(localStoreData?.AgencyID);
    //     }
    // }, [localStoreData])
    useEffect(() => {
        if (localStoreData) {
            setLoginAgencyID(localStoreData?.AgencyID);
        }
    }, [localStoreData]);

    useEffect(() => {
        if (LoginAgencyID) {
            dispatch(get_AgencyOfficer_Data(LoginAgencyID, ''))
            if (propertyLossCodeData?.length === 0) { dispatch(get_VehicleLossCode_Drp_Data(LoginAgencyID)) };
            get_PropertyType(LoginAgencyID);
            get_Dispositions(LoginAgencyID);
        }
    }, [LoginAgencyID]);
    useEffect(() => {
        setShowFields({
            showPropertyTypeID: searchValue.PropertyTypeID !== null,
            showLossCodeID: searchValue.LossCodeID !== null,
            showReportedDtTm: searchValue.ReportedDtTm,
            showReportedDtTmTo: searchValue.ReportedDtTmTo,
            showPropertyNumber: searchValue.PropertyNumber,
            showPropertyNumberTo: searchValue.PropertyNumberTo,
            showLastName: searchValue.LastName,
            showFirstName: searchValue.FirstName,
            showMiddleName: searchValue.MiddleName,
            showValueTo: searchValue.ValueTo,
            showValueFrom: searchValue.ValueFrom,
            showActivityTypeID: searchValue.ActivityTypeID !== null,
            showReceiveDate: searchValue.ReceiveDate,
            showReceiveDateTo: searchValue.ReceiveDateTo,
            showInvestigatorID: searchValue.InvestigatorID !== null,
            showlocation: searchValue.location,
            showDispositionID: searchValue.DispositionID !== null,
            showRecoveredDateTime: searchValue.RecoveredDateTime,
            showRecoveredDateTimeTo: searchValue.RecoveredDateTimeTo,

        });
    }, [searchValue]);
    // useEffect(() => {
    //     if (LoginAgencyID) {
    //         get_Dispositions(LoginAgencyID);
    //     }
    // }, [LoginAgencyID]);

    // Onload Function

    useEffect(() => {
        if (reportedData?.length > 0) {
            setverifyReport(true);
        }
    }, [reportedData]);
    const get_PropertyType = (LoginAgencyID) => {
        const val = { AgencyID: LoginAgencyID }
        fetchPostData('PropertyCategory/GetDataDropDown_PropertyCategory', val).then((data) => {
            if (data) {
                const res = data?.filter((val) => {
                    if (val.PropertyCategoryCode !== "V") return val
                })
                // const id = data?.filter((val) => { if (val.PropertyCategoryCode === "A") return val })
                // if (id.length > 0) {
                //   // setValue({ ...value,['PropertyTypeID']: id[0].PropertyCategoryID, ['PropertyCategoryCode']: id[0].PropertyCategoryCode,});
                //   setValue(prevValues => { return { ...prevValues, ['PropertyTypeID']: id[0].PropertyCategoryID, ['PropertyCategoryCode']: id[0].PropertyCategoryCode, } })
                //   PropertyCategory(id[0].PropertyCategoryID)
                // }
                setPropertyTypeData(threeColArray(res, 'PropertyCategoryID', 'Description', 'PropertyCategoryCode'))
            } else {
                setPropertyTypeData([]);
            }
        })
    }

    const componentRef = useRef();

    const printForm = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'Data',
        onAfterPrint: () => { '' }
    })
    const get_Dispositions = (LoginAgencyID) => {
        const val = { AgencyID: LoginAgencyID }
        fetchPostData('PropertyDispositions/GetDataDropDown_PropertyDispositions', val).then((data) => {
            if (data) {
                setDispositionsDrpData(Comman_changeArrayFormat(data, 'PropertyDispositionsID', 'Description'));
            }
            else { setDispositionsDrpData([]) }
        })
    };
    const getAgencyImg = (LoginAgencyID) => {
        const val = { 'AgencyID': LoginAgencyID }
        fetchPostData('Agency/GetDataAgencyPhoto', val).then((res) => {
            if (res) {
                // console.log(res)
                let imgUrl = `data:image/png;base64,${res[0]?.Agency_Photo}`;
                setMultiImage(imgUrl);

            }
            else { console.log("errror") }
        })
    }

    const isNotEmpty = (value) => {
        console.log(value)
        return value !== null && value.trim() !== '';
    }

    const get_PropertyReport = () => {
            const { ReportedDtTm, ReportedDtTmTo, PropertyNumber, PropertyNumberTo, LastName, FirstName, MiddleName, ValueTo, ValueFrom, DispositionID, RecoveredDateTime, RecoveredDateTimeTo, ActivityType, ReceiveDate, ReceiveDateTo, location, PropertyTypeID, LossCodeID,InvestigatorID, } = value
            const isValid =  isNotEmpty(ReportedDtTm) || isNotEmpty(ReportedDtTmTo) || isNotEmpty(ValueFrom) || isNotEmpty(ValueTo) || isNotEmpty(MiddleName) || isNotEmpty(FirstName) || isNotEmpty(LastName) || isNotEmpty(PropertyNumberTo) || isNotEmpty(PropertyNumber) || (LossCodeID !== null && LossCodeID !== '') || (ActivityType !== null && ActivityType.trim() !== '') || isNotEmpty(location) || isNotEmpty(ReceiveDateTo) || isNotEmpty(ReceiveDate) || isNotEmpty(DispositionID) || isNotEmpty(RecoveredDateTime) || isNotEmpty(RecoveredDateTimeTo) || (PropertyTypeID !== null && PropertyTypeID !== '') ||  (InvestigatorID !== null && InvestigatorID !== '');  
            if (isValid){
                const val = {
                    'ReportedDtTm': ReportedDtTm,'ReportedDtTmTo': ReportedDtTmTo, 
                     'LossCodeID': LossCodeID,  'PropertyNumber': PropertyNumber, 'PropertyNumberTo': PropertyNumberTo,
                    'LastName': LastName, 'FirstName': FirstName, 'MiddleName': MiddleName, 'AgencyID': LoginAgencyID,
                    'ValueTo': parseFloat(ValueTo) === 0 || parseFloat(ValueTo) < 0 ? '0.00' : parseFloat(ValueTo),
                    'ValueFrom': parseFloat(ValueFrom) === 0 || parseFloat(ValueFrom) < 0 ? '0.00' : parseFloat(ValueFrom),
                    'PropertyTypeID': PropertyTypeID,
                    'ActivityType': ActivityType, 'ReceiveDate': ReceiveDate, 'ReceiveDateTo': ReceiveDateTo, 'location': location,
                    'DispositionID': DispositionID, 'RecoveredDateTime': RecoveredDateTime, 'RecoveredDateTimeTo': RecoveredDateTimeTo,
                    'InvestigatorID': InvestigatorID
                }
                fetchPostData('ReportProperty/GetData_ReportProperty', val).then((res) => {
                    if (res.length > 0) {
                        console.log(res)
                        setReportedData(res[0]);
                        setverifyReport(true);
                        getAgencyImg(LoginAgencyID)
                        setSearchValue(value);
                    }
                    else {
                        toastifyError("Data Not Available");
                        setverifyReport(false); setReportedData([]);
                    }
                })
            }
            else {
            toastifyError("Please Enter Details");
        }
    }

    const ChangeDropDown = (e, name) => {
        // console.log(e)
        if (e) {
            if (name === 'PropertyTypeID') {
                setValue({
                    ...value,
                    [name]: e.value
                });
                switch (e.id) {
                    case 'A': get_PropertyLossCode('1', '', '', '', '', ''); break;
                    case 'B': get_PropertyLossCode('', '1', '', '', '', ''); break;
                    case 'S': get_PropertyLossCode('', '', '1', '', '', ''); break;
                    case 'O': get_PropertyLossCode('', '', '', '1', '', ''); break;
                    case 'D': get_PropertyLossCode('', '', '', '', '1', ''); break;
                    case 'G': get_PropertyLossCode('', '', '', '', '', '1'); break;
                    default: get_PropertyLossCode('1', '', '', '', '', '');
                }
            }
            else if (name === 'ActivityTypeID') {
                setValue({
                    ...value,
                    [name]: e.value,
                    ['ActivityType']: e.label
                });
            }
            else {
                setValue({
                    ...value,
                    [name]: e.value
                })
            }
        }
        else {
            if (name === 'PropertyTypeID') {
                setValue({
                    ...value,
                    [name]: null,
                    ['LossCodeID']: null
                })
            } else if (name === 'ActivityTypeID') {
                setValue({
                    ...value,
                    [name]: '',
                    ['ActivityType']: ''
                });
            } else {
                setValue({
                    ...value,
                    [name]: null
                })
            }
        }
    }

    const handlChange = (e,) => {
        if (e.target.name === 'PropertyNumber' || e.target.name === 'PropertyNumberTo') {
            var ele = e.target.value.replace(/[^a-zA-Z\s^0-9\s]/g, '');
            if (ele.length === 10) {
                var cleaned = ('' + ele).replace(/[^a-zA-Z\s^0-9\s]/g, '');
                var match = cleaned.match(/^(\w{3})(\d{7})$/);
                if (match) {
                    setValue({
                        ...value,
                        [e.target.name]: match[1].toUpperCase() + '-' + match[2]
                    })
                }
            } else {
                ele = e.target.value.split("'").join('').replace(/[^a-zA-Z\s^0-9\s]/g, '');
                setValue({
                    ...value,
                    [e.target.name]: ele
                })
            }
        } else {
            setValue({
                ...value,
                [e.target.name]: e.target.value
            })
        }
    }

    const resetFields = () => {
        setValue({
            ...value,
            'PropertyTypeID': null, 'LossCodeID': null, 'ReportedDtTm': "", 'ReportedDtTmTo': "", 'PropertyNumber': '', 'PropertyNumberTo': '',
            'ValueTo': '', 'ValueFrom': '', 'LastName': '', 'FirstName': '', 'MiddleName': '',
            'ActivityType': '', 'ActivityTypeID': '', 'ReceiveDate': '', 'ReceiveDateTo': '', 'InvestigatorID': '', 'location': '', 'DispositionID': '', 'RecoveredDateTime': '', 'RecoveredDateTimeTo': '',
        });
        setverifyReport(false); setReportedData([]);

    }


    const AddTransfer = [
        { value: 1, label: 'CheckIn' },
        { value: 2, label: 'CheckOut' },
        { value: 3, label: 'Release' },
        { value: 4, label: 'Destroy' },
    ]

    return (
        <>
            <div class="section-body view_page_design pt-1" >
                <div className="row clearfix">
                    <div className="col-12 col-sm-12">
                        <div className="card Agency">
                            <div className="card-body">
                                <div className="col-12 col-md-12 col-lg-12  " >
                                    <fieldset>
                                        <legend>Property Master Report</legend>
                                        <div className="row mt-2">
                                            <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                                                <label htmlFor="" className='new-label'>Reported From Date</label>
                                            </div>
                                            <div className="col-3 col-md-3 col-lg-3 mb-1">
                                                <DatePicker
                                                    name='ReportedDtTm'
                                                    id='ReportedDtTm'
                                                    onChange={(date) => { setValue({ ...value, ['ReportedDtTm']: date ? getShowingDateText(date) : null, ['ReportedDtTmTo']: null }) }}
                                                    selected={value?.ReportedDtTm && new Date(value?.ReportedDtTm)}
                                                    dateFormat="MM/dd/yyyy"
                                                    timeInputLabel
                                                    isClearable={value?.ReportedDtTm ? true : false}
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    autoComplete='Off'
                                                    // disabled
                                                    maxDate={new Date()}
                                                    placeholderText='Select...'
                                                />
                                            </div>
                                            <div className="col-3 col-md-3 col-lg-3 mt-2 ">
                                                <label htmlFor="" className='new-label'>Reported To Date</label>
                                            </div>
                                            <div className="col-3 col-md-3 col-lg-3 mb-1">
                                                <DatePicker
                                                    name='ReportedDtTmTo'
                                                    id='ReportedDtTmTo'
                                                    onChange={(date) => { setValue({ ...value, ['ReportedDtTmTo']: date ? getShowingDateText(date) : null }) }}
                                                    selected={value?.ReportedDtTmTo && new Date(value?.ReportedDtTmTo)}
                                                    dateFormat="MM/dd/yyyy"
                                                    timeInputLabel
                                                    isClearable={value?.ReportedDtTmTo ? true : false}
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    autoComplete='Off'
                                                    // maxDate={new Date()}
                                                    placeholderText='Select...'
                                                    maxDate={new Date()}
                                                    minDate={new Date(value?.ReportedDtTm)}
                                                    disabled={value?.ReportedDtTm ? false : true}
                                                    className={!value?.ReportedDtTm && 'readonlyColor'}
                                                />

                                            </div>
                                            <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                                                <label htmlFor="" className='new-label'>Property Type</label>
                                            </div>
                                            <div className="col-3 col-md-3 col-lg-3 mt-1" >
                                                <Select
                                                    styles={customStylesWithOutColor}
                                                    name='PropertyTypeID'
                                                    value={propertyTypeData?.filter((obj) => obj.value === value?.PropertyTypeID)}
                                                    options={propertyTypeData}
                                                    onChange={(e) => ChangeDropDown(e, 'PropertyTypeID')}
                                                    isClearable
                                                    placeholder="Select..."
                                                />
                                            </div>
                                            <div className="col-3 col-md-3 col-lg-3 mt-2 ">
                                                <label htmlFor="" className='new-label'>Loss Code</label>
                                            </div>
                                            <div className="col-3 col-md-3 col-lg-3 ">
                                                <Select
                                                    name='LossCodeID'
                                                    styles={customStylesWithOutColor}
                                                    value={propertyLossCodeData?.filter((obj) => obj.value === value?.LossCodeID)}
                                                    options={propertyLossCodeData}
                                                    onChange={(e) => ChangeDropDown(e, 'LossCodeID')}
                                                    isClearable
                                                    placeholder="Select..."
                                                />
                                            </div>
                                            <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                                                <label htmlFor="" className='new-label'>Value From</label>
                                            </div>
                                            <div className="col-3 col-md-3 col-lg-3 mt-1 text-field">
                                                <input type="text" name='ValueFrom' id='ValueFrom' value={value?.ValueFrom} onChange={handlChange} className='' />
                                            </div>
                                            <div className="col-3 col-md-3 col-lg-3 mt-2 ">
                                                <label htmlFor="" className='new-label'>Value To</label>
                                            </div>
                                            <div className="col-3 col-md-3 col-lg-3 mt-1 text-field">
                                                <input type="text" name='ValueTo' id='ValueTo' value={value?.ValueTo} onChange={handlChange} className='' />
                                            </div>
                                            <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                                                <label htmlFor="" className='new-label'>Property Number From</label>
                                            </div>
                                            <div className="col-3 col-md-3 col-lg-3 mt-1 text-field">
                                                <input type="text" name='PropertyNumber' id='PropertyNumber' value={value?.PropertyNumber} maxLength={10} onChange={handlChange} className='' />
                                            </div>
                                            <div className="col-3 col-md-3 col-lg-3 mt-2 ">
                                                <label htmlFor="" className='new-label'>Property Number To</label>
                                            </div>
                                            <div className="col-3 col-md-3 col-lg-3 mt-1 text-field">
                                                <input type="text" name='PropertyNumberTo' id='PropertyNumberTo' value={value?.PropertyNumberTo} maxLength={10} onChange={handlChange} className='' />
                                            </div>
                                        </div>
                                    </fieldset>
                                    <fieldset >
                                        <legend>Property Owner</legend>
                                        <div className="row mt-2">
                                            <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                                                <label htmlFor="" className='new-label'>Last Name</label>
                                            </div>
                                            <div className="col-3 col-md-3 col-lg-2 mt-1 text-field">
                                                <input type="text" name='LastName' id='LastName' value={value?.LastName} onChange={handlChange} />
                                            </div>
                                            <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                                                <label htmlFor="" className='new-label'>First Name</label>
                                            </div>
                                            <div className="col-3 col-md-3 col-lg-2 mt-1 text-field">
                                                <input type="text" name='FirstName' id='FirstName' value={value?.FirstName} onChange={handlChange} />
                                            </div>
                                            <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                                                <label htmlFor="" className='new-label'>Middle Name</label>
                                            </div>
                                            <div className="col-3 col-md-3 col-lg-2 mt-1 text-field">
                                                <input type="text" name='MiddleName' id='MiddleName' value={value?.MiddleName} onChange={handlChange} className='' />
                                            </div>
                                        </div>
                                    </fieldset>
                                    <fieldset >
                                        <legend>Property Room</legend>
                                        <div className="row ">
                                            <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                                                <label htmlFor="" className='new-label'>Receive From Date</label>
                                            </div>
                                            <div className="col-3 col-md-3 col-lg-2 ">
                                                <DatePicker
                                                    name='ReceiveDate'
                                                    id='ReceiveDate'
                                                    onChange={(date) => { setValue({ ...value, ['ReceiveDate']: date ? getShowingDateText(date) : null, ['ReceiveDateTo']: null }) }}
                                                    selected={value?.ReceiveDate && new Date(value?.ReceiveDate)}
                                                    dateFormat="MM/dd/yyyy"
                                                    timeInputLabel
                                                    isClearable={value?.ReceiveDate ? true : false}
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    autoComplete='Off'
                                                    maxDate={new Date()}
                                                    placeholderText='Select...'
                                                />
                                            </div>
                                            <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                                                <label htmlFor="" className='new-label'>Receive To Date</label>
                                            </div>
                                            <div className="col-3 col-md-3 col-lg-2 ">
                                                <DatePicker
                                                    name='ReceiveDateTo'
                                                    id='ReceiveDateTo'
                                                    onChange={(date) => { setValue({ ...value, ['ReceiveDateTo']: date ? getShowingDateText(date) : null }) }}
                                                    selected={value?.ReceiveDateTo && new Date(value?.ReceiveDateTo)}
                                                    dateFormat="MM/dd/yyyy"
                                                    timeInputLabel
                                                    isClearable={value?.ReceiveDateTo ? true : false}
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    autoComplete='Off'
                                                    maxDate={new Date()}
                                                    minDate={new Date(value?.ReceiveDate)}
                                                    disabled={value?.ReceiveDate ? false : true}
                                                    placeholderText='Select...'
                                                    className={!value?.ReceiveDate && 'readonlyColor'}
                                                />
                                            </div>
                                            <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                                                <label htmlFor="" className='new-label'>Activity</label>
                                            </div>
                                            <div className="col-3 col-md-3 col-lg-2 mt-1 ">
                                                <Select

                                                    name='ActivityTypeID'
                                                    value={AddTransfer?.filter((obj) => obj.value === value?.ActivityTypeID)}
                                                    isClearable
                                                    options={AddTransfer}
                                                    styles={customStylesWithOutColor}
                                                    onChange={(e) => ChangeDropDown(e, 'ActivityTypeID')}
                                                    placeholder="Select..."
                                                />
                                            </div>
                                            <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                                                <label htmlFor="" className='new-label'>Investigator</label>
                                            </div>
                                            <div className="col-3 col-md-3 col-lg-2 mt-1 ">
                                                <Select
                                                    name='InvestigatorID'
                                                    value={agencyOfficerDrpData?.filter((obj) => obj.value === value?.InvestigatorID)}
                                                    isClearable
                                                    options={agencyOfficerDrpData}
                                                    styles={customStylesWithOutColor}
                                                    onChange={(e) => ChangeDropDown(e, 'InvestigatorID')}
                                                    placeholder="Select..."
                                                />
                                            </div>
                                            <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                                                <label htmlFor="" className='new-label'>Location</label>
                                            </div>
                                            <div className="col-6  col-md-6 col-lg-6 text-field  mt-1">
                                                <input type="text" name='location' value={value?.location} onChange={handlChange} id='location' className='' />

                                            </div>
                                        </div>
                                    </fieldset>
                                    <fieldset >
                                        <legend>Stolen Property</legend>
                                        <div className="row mt-2">
                                            <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                                                <label htmlFor="" className='new-label'>Disposition</label>
                                            </div>
                                            <div className="col-3 col-md-3 col-lg-2 mt-1 ">
                                                <Select
                                                    name='DispositionID'
                                                    value={dispositionsDrpData?.filter((obj) => obj.value === value?.DispositionID)}
                                                    options={dispositionsDrpData}
                                                    onChange={(e) => ChangeDropDown(e, 'DispositionID')}
                                                    styles={customStylesWithOutColor}
                                                    isClearable
                                                    placeholder="Select..."
                                                />
                                            </div>
                                            <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                                                <label htmlFor="" className='new-label'>Recovered From Date</label>
                                            </div>
                                            <div className="col-3 col-md-3 col-lg-2">
                                                <DatePicker
                                                    name='RecoveredDateTime'
                                                    id='RecoveredDateTime'
                                                    onChange={(date) => { setValue({ ...value, ['RecoveredDateTime']: date ? getShowingMonthDateYear(date) : null, ['RecoveredDateTimeTo']: null }) }}
                                                    selected={value?.RecoveredDateTime && new Date(value?.RecoveredDateTime)}
                                                    dateFormat="MM/dd/yyyy"
                                                    timeInputLabel
                                                    isClearable={value?.RecoveredDateTime ? true : false}
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    autoComplete='Off'
                                                    // disabled
                                                    maxDate={new Date()}
                                                    placeholderText='Select...'
                                                />
                                            </div>
                                            <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                                                <label htmlFor="" className='new-label'>Recovered To Date</label>
                                            </div>
                                            <div className="col-3 col-md-3 col-lg-2 ">
                                                <DatePicker
                                                    name='RecoveredDateTimeTo'
                                                    id='RecoveredDateTimeTo'
                                                    onChange={(date) => { setValue({ ...value, ['RecoveredDateTimeTo']: date ? getShowingMonthDateYear(date) : null }) }}
                                                    selected={value?.RecoveredDateTimeTo && new Date(value?.RecoveredDateTimeTo)}
                                                    dateFormat="MM/dd/yyyy"
                                                    timeInputLabel
                                                    isClearable={value?.RecoveredDateTimeTo ? true : false}
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    autoComplete='Off'
                                                    // maxDate={new Date()}
                                                    placeholderText='Select...'
                                                    maxDate={new Date()}
                                                    minDate={new Date(value?.RecoveredDateTime)}
                                                    disabled={value?.RecoveredDateTime ? false : true}
                                                    className={!value?.RecoveredDateTime && 'readonlyColor'}
                                                />
                                            </div>

                                        </div>
                                    </fieldset>
                                    <div className="col-12 col-md-12 col-lg-12 text-right mt-1">
                                        <button className="btn btn-sm bg-green text-white px-2 py-1"
                                            onClick={() => { get_PropertyReport(); }}>Show Report</button>
                                        <button className="btn btn-sm bg-green text-white px-2 py-1 ml-2" onClick={() => { resetFields(); }}>Clear</button>
                                        <Link to={'/Reports'}>
                                            <button className="btn btn-sm bg-green text-white px-2 py-1 ml-2" >Close</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
                {/* for 1 table */}
                {
                    verifyReport ?

                        reportedData?.Property?.length > 0 ?
                            <>
                                <div className="col-12 col-md-12 col-lg-12 pt-2  px-2">
                                    <div className="bg-line  py-1 px-2 mt-1 d-flex justify-content-between align-items-center">
                                        <p className="p-0 m-0 d-flex align-items-center">Property Master Report</p>
                                        <div style={{ marginLeft: 'auto' }}>
                                            <Link to={''} onClick={() => { printForm(); }} className="btn btn-sm bg-green  mr-2 text-white px-2 py-0"  >
                                                <i className="fa fa-print"></i>
                                            </Link>
                                            {/* <Link to={''} className="btn btn-sm bg-green  text-white px-2 py-0"  >
                                        <i className="fa fa-file"></i>
                                    </Link> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="container mt-1" ref={componentRef} >
                                    <div className="row" style={{ border: '1px solid #80808085' }}>
                                        <>
                                            <div className="col-4 col-md-3 col-lg-2 pt-1">
                                                <div className="main">
                                                    {/* <img src={reportedData?.Agency_Photo} className="img-fluid" alt style={{ border: '1px solid aliceblue', marginTop: '4px', width: '150px' }} /> */}
                                                    <div className="img-box" >
                                                        <img src={multiImage} className='picture' style={{ marginTop: '4px', width: '150px', height: '150px' }} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-7  col-md-7 col-lg-9 mt-2">
                                                <div className="main">
                                                    <h5 className='text-dark text-bold'>{reportedData?.Agency_Name}</h5>
                                                    <p className='text-p'>Address: <span className='new-span pl-2'>{reportedData?.Agency_Address1}</span></p>
                                                    <div className='d-flex '>
                                                        <p className='text-p'>State: <span className='new-span ml-2'>{reportedData?.StateName}</span>
                                                        </p>
                                                        <p className='text-p ml-5 pl-1'>City: <span className='new-span'>{reportedData?.CityName}</span>
                                                        </p>
                                                        <p className='text-p ml-2'>Zip: <span className='new-span'>{reportedData?.ZipId}</span>
                                                        </p>
                                                    </div>
                                                    <div className='d-flex'>
                                                        <p className='text-p'>Phone: <span className='new-span'>{reportedData?.Agency_Phone}</span></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        <div className="col-12">

                                            <fieldset>
                                                <legend>Search Criteria</legend>

                                                <div className="row">
                                                    {showFields.showReportedDtTm && searchValue.ReportedDtTm && (
                                                        <>
                                                            <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                                <label className='new-label'>Reported From Date</label>
                                                            </div>
                                                            <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                                <input type="text" className='readonlyColor' value={searchValue.ReportedDtTm || ''} readOnly />
                                                            </div>
                                                        </>
                                                    )}

                                                    {showFields.showReportedDtTmTo && (
                                                        <>
                                                            <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                                <label className='new-label'>Reported To Date</label>
                                                            </div>
                                                            <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                                <input type="text" className='readonlyColor' value={searchValue.ReportedDtTmTo || ''} readOnly />
                                                            </div>
                                                        </>
                                                    )}
                                                    {showFields.showPropertyTypeID && searchValue.PropertyTypeID && (
                                                        <>
                                                            <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                                <label className='new-label'>Property Type</label>
                                                            </div>
                                                            <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                                <input type="text" className='readonlyColor' value={propertyTypeData.find((obj) => obj.value === searchValue.PropertyTypeID)?.label || ''} readOnly />
                                                            </div>
                                                        </>
                                                    )}
                                                    {showFields.showLossCodeID && searchValue.LossCodeID && (
                                                        <>
                                                            <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                                <label className='new-label'>Loss Code</label>
                                                            </div>
                                                            <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                                <input type="text" className='readonlyColor' value={propertyLossCodeData.find((obj) => obj.value === searchValue.LossCodeID)?.label || ''} readOnly />
                                                            </div>
                                                        </>
                                                    )}
                                                    {showFields.showValueFrom && (
                                                        <>
                                                            <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                                <label className='new-label'>Value From</label>
                                                            </div>
                                                            <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                                <input type="text" className='readonlyColor' value={searchValue.ValueFrom || ''} readOnly />
                                                            </div>
                                                        </>
                                                    )}
                                                    {showFields.showValueTo && (
                                                        <>
                                                            <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                                <label className='new-label'>Value To</label>
                                                            </div>
                                                            <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                                <input type="text" className='readonlyColor' value={searchValue.ValueTo || ''} readOnly />
                                                            </div>
                                                        </>
                                                    )}
                                                    {showFields.showPropertyNumber && (
                                                        <>
                                                            <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                                <label className='new-label'>Property From Number</label>
                                                            </div>
                                                            <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                                <input type="text" className='readonlyColor' value={searchValue.PropertyNumber || ''} readOnly />
                                                            </div>
                                                        </>
                                                    )}
                                                    {showFields.showPropertyNumberTo && (
                                                        <>
                                                            <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                                <label className='new-label'>Property To Number</label>
                                                            </div>
                                                            <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                                <input type="text" className='readonlyColor' value={searchValue.PropertyNumberTo || ''} readOnly />
                                                            </div>
                                                        </>
                                                    )}
                                                    {showFields.showLastName && (
                                                        <>
                                                            <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                                <label className='new-label'>Last Name</label>
                                                            </div>
                                                            <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                                <input type="text" className='readonlyColor' value={searchValue.LastName || ''} readOnly />
                                                            </div>
                                                        </>
                                                    )}
                                                    {showFields.showFirstName && (
                                                        <>
                                                            <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                                <label className='new-label'>First Name</label>
                                                            </div>
                                                            <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                                <input type="text" className='readonlyColor' value={searchValue.FirstName || ''} readOnly />
                                                            </div>
                                                        </>
                                                    )}
                                                    {showFields.showMiddleName && (
                                                        <>
                                                            <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                                <label className='new-label'>Middle Name</label>
                                                            </div>
                                                            <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                                <input type="text" className='readonlyColor' value={searchValue.MiddleName || ''} readOnly />
                                                            </div>
                                                        </>
                                                    )}
                                                    {showFields.showReceiveDate && (
                                                        <>
                                                            <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                                <label className='new-label'>Receive From Date</label>
                                                            </div>
                                                            <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                                <input type="text" className='readonlyColor' value={searchValue.ReceiveDate || ''} readOnly />
                                                            </div>
                                                        </>
                                                    )}
                                                    {showFields.showReceiveDateTo && (
                                                        <>
                                                            <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                                <label className='new-label'>Receive To Date</label>
                                                            </div>
                                                            <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                                <input type="text" className='readonlyColor' value={searchValue.ReceiveDateTo || ''} readOnly />
                                                            </div>
                                                        </>
                                                    )}

                                                    {showFields.showActivityTypeID && searchValue.ActivityTypeID && (
                                                        <>
                                                            <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                                <label className='new-label'>Activity</label>
                                                            </div>
                                                            <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                                <input type="text" className='readonlyColor' value={AddTransfer.find((obj) => obj.value === searchValue.ActivityTypeID)?.label || ''} readOnly />
                                                            </div>
                                                        </>
                                                    )}

                                                    {showFields.showInvestigatorID && searchValue.InvestigatorID && (

                                                        <>
                                                            <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                                <label className='new-label'>Investigator</label>
                                                            </div>
                                                            <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                                <input type="text" className='readonlyColor' value={agencyOfficerDrpData.find((obj) => obj.value === searchValue.InvestigatorID)?.label || ''} readOnly />
                                                            </div>
                                                        </>
                                                    )}
                                                    {showFields.showlocation && (
                                                        <>
                                                            <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                                <label className='new-label'>Location</label>
                                                            </div>
                                                            <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                                <input type="text" className='readonlyColor' value={searchValue.location || ''} readOnly />
                                                            </div>
                                                        </>
                                                    )}
                                                    {
                                                        showFields.showDispositionID && searchValue.DispositionID && (

                                                            <>
                                                                <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                                    <label className='new-label'>Disposition</label>
                                                                </div>
                                                                <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                                    <input type="text" className='readonlyColor' value={dispositionsDrpData.find((obj) => obj.value === searchValue.DispositionID)?.label || ''} readOnly />
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                    {showFields.showRecoveredDateTime && (
                                                        <>
                                                            <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                                <label className='new-label'>Recovered From Date</label>
                                                            </div>
                                                            <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                                <input type="text" className='readonlyColor' value={searchValue.RecoveredDateTime || ''} readOnly />
                                                            </div>
                                                        </>
                                                    )}
                                                    {showFields.showRecoveredDateTimeTo && (
                                                        <>
                                                            <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                                <label className='new-label'>Recovered To Date</label>
                                                            </div>
                                                            <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                                <input type="text" className='readonlyColor' value={searchValue.RecoveredDateTimeTo || ''} readOnly />
                                                            </div>
                                                        </>
                                                    )}

                                                </div>
                                            </fieldset>
                                        </div>
                                        {
                                            reportedData?.Property?.length > 0 ?
                                                <>
                                                    {
                                                        reportedData?.Property?.map((obj) => (
                                                            <>
                                                                <div className="col-12">
                                                                    <hr style={{ border: '1px solid rgb(3, 105, 184)' }} />
                                                                </div>

                                                                <div className="container" style={{ pageBreakAfter: 'always' }}>
                                                                    <h5 className=" text-white text-bold bg-green text-center py-1 px-3">Property Information:{obj?.PropertyNumber}</h5>
                                                                    <div className="table-responsive" >
                                                                        <table className="table table-bordered" >
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>
                                                                                        <h6 className='text-dark text-bold'>Property Number:</h6>
                                                                                        <p className='text-list'>{obj?.PropertyNumber}</p>
                                                                                    </td>
                                                                                    <td>
                                                                                        <h6 className='text-dark text-bold'>Property Loss</h6>
                                                                                        <p className='text-list'>{obj?.PropertyLossCode_Description}</p>
                                                                                    </td>
                                                                                    <td >
                                                                                        <h6 className='text-dark text-bold'>Reported Date/Time:</h6>
                                                                                        <p className='text-list'>{obj?.ReportedDtTm ? getShowingDateText(obj?.ReportedDtTm) : null}</p>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td >
                                                                                        <h6 className='text-dark text-bold'>Property Type:</h6>
                                                                                        <p className='text-list'>{obj?.PropertyType_Description}</p>
                                                                                    </td>
                                                                                    <td colSpan={2}>
                                                                                        <h6 className='text-dark text-bold'>Property Value:</h6>
                                                                                        <p className='text-list'>{obj?.Value}</p>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                    {
                                                                        JSON.parse(obj?.Article)?.length > 0 ?
                                                                            <>
                                                                                <div className="table-responsive" >
                                                                                    <div className="text-white text-bold bg-green py-1 px-2  d-flex justify-content-between align-items-center">
                                                                                        <p className="p-0 m-0 d-flex align-items-center">Property Article</p>
                                                                                    </div>

                                                                                    <table className="table table-bordered" >
                                                                                        <tbody className='master-tbody'>
                                                                                            {
                                                                                                JSON.parse(obj?.Article)?.map((item, key) => (
                                                                                                    <>
                                                                                                        <tr>
                                                                                                            <td>
                                                                                                                <h6 className='text-dark text-bold'>Article ID Number:</h6>
                                                                                                                <p className='text-list'>{item.ArticleIDNumber}</p>
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                <h6 className='text-dark text-bold'>Model ID</h6>
                                                                                                                <p className='text-list'>{item.ModelID}</p>
                                                                                                            </td>
                                                                                                            <td >
                                                                                                                <h6 className='text-dark text-bold'>OAN:</h6>
                                                                                                                <p className='text-list'>{item.OAN}</p>
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                <h6 className='text-dark text-bold'>Quantity:</h6>
                                                                                                                <p className='text-list'>{item.Quantity}</p>
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                <h6 className='text-dark text-bold'>Brand:</h6>
                                                                                                                <p className='text-list'>{item.Brand}</p>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                        <tr>
                                                                                                            <td>
                                                                                                                <h6 className='text-dark text-bold'>Serial ID</h6>
                                                                                                                <p className='text-list'>{item.SerialID}</p>
                                                                                                            </td>
                                                                                                            <td colSpan={2}>
                                                                                                                <h6 className='text-dark text-bold'>Top Color Description:</h6>
                                                                                                                <p className='text-list'>{item.TopColor_Description}</p>
                                                                                                            </td>
                                                                                                            <td colSpan={2}>
                                                                                                                <h6 className='text-dark text-bold'>Bottom Color Description:</h6>
                                                                                                                <p className='text-list'>{item.BottomColor_Description}</p>
                                                                                                            </td>
                                                                                                        </tr>

                                                                                                    </>
                                                                                                ))
                                                                                            }
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            <>
                                                                            </>
                                                                    }
                                                                    {
                                                                        JSON.parse(obj?.Drug)?.length > 0 ?
                                                                            <>
                                                                                <div className="table-responsive" >
                                                                                    <div className="text-white text-bold bg-green py-1 px-2  d-flex justify-content-between align-items-center">
                                                                                        <p className="p-0 m-0 d-flex align-items-center">Property Drug:</p>
                                                                                    </div>

                                                                                    <table className="table " >
                                                                                        <thead className='text-dark master-table'>
                                                                                            <tr>
                                                                                                <th className='' style={{ width: '100px' }}>Estimated Drug Qty</th>
                                                                                                <th className='' style={{ width: '100px' }}>Fraction Drug Qty</th>
                                                                                                <th className='' style={{ width: '100px' }}>Measurement Type</th>
                                                                                                <th className='' style={{ width: '100px' }}>Suspected Drug Type</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody >
                                                                                            {
                                                                                                JSON.parse(obj?.Drug)?.map((item, key) => (
                                                                                                    <>
                                                                                                        <tr key={key} >
                                                                                                            <td className='text-list' style={{ width: '100px' }}>{item.EstimatedDrugQty}</td>
                                                                                                            <td className='text-list' style={{ width: '100px' }}>{item.FractionDrugQty}</td>
                                                                                                            <td className='text-list' style={{ width: '100px' }}>{item.MeasurementType_Description}</td>
                                                                                                            <td className='text-list' style={{ width: '100px' }}>{item.SuspectedDrugType_Description}</td>
                                                                                                        </tr>
                                                                                                    </>
                                                                                                ))
                                                                                            }
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            <>
                                                                            </>
                                                                    }
                                                                    {
                                                                        JSON.parse(obj?.Boat)?.length > 0 ?
                                                                            <>
                                                                                <div className="table-responsive" >
                                                                                    <div className="text-white text-bold bg-green py-1 px-2  d-flex justify-content-between align-items-center">
                                                                                        <p className="p-0 m-0 d-flex align-items-center">Property Boat:</p>
                                                                                    </div>

                                                                                    <table className="table " >
                                                                                        <thead className='text-dark master-table'>
                                                                                            <tr>
                                                                                                <th className='' style={{ width: '100px' }}>Boat ID Number</th>
                                                                                                <th className='' style={{ width: '100px' }}>Registration Number</th>
                                                                                                <th className='' style={{ width: '100px' }}>Registration State</th>
                                                                                                <th className='' style={{ width: '100px' }}>Model</th>
                                                                                                <th className='' style={{ width: '100px' }}>Material </th>
                                                                                                <th className='' style={{ width: '100px' }}>Manufacture Year </th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody className=''>
                                                                                            {
                                                                                                JSON.parse(obj?.Boat)?.map((item, key) => (
                                                                                                    <>
                                                                                                        <tr key={key} >
                                                                                                            <td className='text-list' style={{ width: '100px' }}>{item.BoatIDNumber}</td>
                                                                                                            <td className='text-list' style={{ width: '100px' }}>{item.RegistrationNumber}</td>
                                                                                                            <td className='text-list' style={{ width: '100px' }}>{item.Registration_StateName}</td>
                                                                                                            <td className='text-list' style={{ width: '100px' }}>{item.Model_Description}</td>
                                                                                                            <td className='text-list' style={{ width: '100px' }}>{item.Material_Description}</td>
                                                                                                            <td className='text-list' style={{ width: '100px' }}>{item.ManufactureYear}</td>
                                                                                                        </tr>
                                                                                                    </>
                                                                                                ))
                                                                                            }
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            <>
                                                                            </>
                                                                    }
                                                                    {
                                                                        JSON.parse(obj?.Other)?.length > 0 ?
                                                                            <>
                                                                                <div className="table-responsive" >
                                                                                    <div className="text-white text-bold bg-green py-1 px-2  d-flex justify-content-between align-items-center">
                                                                                        <p className="p-0 m-0 d-flex align-items-center">Property Other:</p>
                                                                                    </div>
                                                                                    <table className="table ">
                                                                                        <thead className='text-dark master-table'>
                                                                                            <tr>
                                                                                                <th className='' style={{ width: '100px' }}>Model ID</th>
                                                                                                <th className='' style={{ width: '100px' }}>Brand</th>
                                                                                                <th className='' style={{ width: '100px' }}>Quantity</th>
                                                                                                <th className='' style={{ width: '100px' }}>Top Color</th>
                                                                                                <th className='' style={{ width: '100px' }}>Bottom Color</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody className=''>
                                                                                            {
                                                                                                JSON.parse(obj?.Other)?.map((item, key) => (
                                                                                                    <>
                                                                                                        <tr key={key} >
                                                                                                            <td className='text-list' style={{ width: '100px' }}>{item.ModelID}</td>
                                                                                                            <td className='text-list' style={{ width: '100px' }}>{item.Brand}</td>
                                                                                                            <td className='text-list' style={{ width: '100px' }}>{item.Quantity}</td>
                                                                                                            <td className='text-list' style={{ width: '100px' }}>{item.TopColor_Description}</td>
                                                                                                            <td className='text-list' style={{ width: '100px' }}>{item.BottomColor_Description}</td>
                                                                                                        </tr>
                                                                                                    </>
                                                                                                ))
                                                                                            }
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            <></>
                                                                    }
                                                                    {
                                                                        JSON.parse(obj?.Security)?.length > 0 ?
                                                                            <>
                                                                                <div className="table-responsive" >
                                                                                    <div className="text-white text-bold bg-green py-1 px-2  d-flex justify-content-between align-items-center">
                                                                                        <p className="p-0 m-0 d-flex align-items-center">Property Security:</p>
                                                                                    </div>

                                                                                    <table className="table " >
                                                                                        <thead className='text-dark master-table'>
                                                                                            <tr>
                                                                                                <th className='' style={{ width: '100px' }}>Security ID Number</th>
                                                                                                <th className='' style={{ width: '100px' }}>Denomination</th>
                                                                                                <th className='' style={{ width: '100px' }}>IssuingAgency</th>
                                                                                                <th className='' style={{ width: '100px' }}>MeasureTypeID</th>
                                                                                                <th className='' style={{ width: '100px' }}>SerialID</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody >
                                                                                            {
                                                                                                JSON.parse(obj?.Security)?.map((item, key) => (
                                                                                                    <>
                                                                                                        <tr key={key} >
                                                                                                            <td className='text-list' style={{ width: '100px' }}>{item.SecurityIDNumber}</td>
                                                                                                            <td className='text-list' style={{ width: '100px' }}>{item.Denomination}</td>
                                                                                                            <td className='text-list' style={{ width: '100px' }}>{item.IssuingAgency}</td>
                                                                                                            <td className='text-list' style={{ width: '100px' }}>{item.MeasureTypeID}</td>
                                                                                                            <td className='text-list' style={{ width: '100px' }}>{item.SerialID}</td>
                                                                                                        </tr>
                                                                                                    </>
                                                                                                ))
                                                                                            }
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            <></>
                                                                    }
                                                                    {
                                                                        JSON.parse(obj?.weapon)?.length > 0 ?
                                                                            <>
                                                                                <div className="table-responsive" >
                                                                                    <div className="text-white text-bold bg-green py-1 px-2  d-flex justify-content-between align-items-center">
                                                                                        <p className="p-0 m-0 d-flex align-items-center">Property Weapon:</p>
                                                                                    </div>

                                                                                    <table className="table table-bordered">
                                                                                        <thead className='text-dark master-table'>
                                                                                            <tr>
                                                                                                <th className='' style={{ width: '100px' }}>Weapon ID Number</th>
                                                                                                <th className='' style={{ width: '100px' }}>Style</th>
                                                                                                <th className='' style={{ width: '100px' }}>Manufacture Year</th>
                                                                                                <th className='' style={{ width: '100px' }}>Barrel Length</th>
                                                                                                <th className='' style={{ width: '100px' }}>Caliber</th>
                                                                                                <th className='' style={{ width: '100px' }}>Finish</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody className='master-tbody'>
                                                                                            {
                                                                                                JSON.parse(obj?.weapon)?.map((item, key) => (
                                                                                                    <>
                                                                                                        <tr key={key} >
                                                                                                            <td className='text-list' style={{ width: '100px' }}>{item.WeaponIDNumber}</td>
                                                                                                            <td className='text-list' style={{ width: '100px' }}>{item.Style}</td>
                                                                                                            <td className='text-list' style={{ width: '100px' }}>{item.ManufactureYear}</td>
                                                                                                            <td className='text-list' style={{ width: '100px' }}>{item.BarrelLength}</td>
                                                                                                            <td className='text-list' style={{ width: '100px' }}>{item.Caliber}</td>
                                                                                                            <td className='text-list' style={{ width: '100px' }}>{item.Finish}</td>
                                                                                                        </tr>
                                                                                                    </>
                                                                                                ))
                                                                                            }
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            <></>
                                                                    }
                                                                    {
                                                                        JSON.parse(obj?.Document)?.length > 0 ?
                                                                            <>
                                                                                <div className="table-responsive" >
                                                                                    <div className="text-white text-bold bg-green py-1 px-2  d-flex justify-content-between align-items-center">
                                                                                        <p className="p-0 m-0 d-flex align-items-center">Property Document</p>
                                                                                    </div>
                                                                                    <table className="table table-bordered" >
                                                                                        <thead className='text-dark master-table'>
                                                                                            <tr>
                                                                                                <th className='' style={{ width: '100px' }}>Document Name</th>
                                                                                                <th className='' style={{ width: '100px' }}>Notes</th>
                                                                                                <th className='' style={{ width: '100px' }}>Document Type</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody >
                                                                                            {
                                                                                                JSON.parse(obj?.Document)?.map((item, key) => (
                                                                                                    <>
                                                                                                        <tr key={key}>
                                                                                                            <td style={{ width: '100px' }} className='text-list'>{item.DocumentName}</td>
                                                                                                            <td style={{ width: '100px' }} className='text-list'>{item.DocumentNotes}</td>
                                                                                                            <td style={{ width: '100px' }} className='text-list'>{item.Description_Document}</td>
                                                                                                        </tr>
                                                                                                    </>
                                                                                                ))
                                                                                            }
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            <></>
                                                                    }
                                                                    {/* vehicle-recoverd */}
                                                                    {/* {
                                                                    JSON.parse(obj?.RecoveredProperty)?.length > 0 ?
                                                                        <>
                                                                            <div className="table-responsive mt-2" >
                                                                                <div className="text-white text-bold bg-green py-1 px-2  d-flex justify-content-between align-items-center">
                                                                                    <p className="p-0 m-0 d-flex align-items-center">Recovered Property:</p>
                                                                                </div>
                                                                                <table className="table " >
                                                                                    <thead className='text-dark master-table'>
                                                                                        <tr>
                                                                                            <th className='' style={{ width: '100px' }}>Recovered Id Number</th>
                                                                                            <th className='' style={{ width: '100px' }}>Recovered Date/Time</th>
                                                                                            <th className='' style={{ width: '100px' }}>Recovery Type</th>
                                                                                            <th className='' style={{ width: '100px' }}>Recovered Value</th>
                                                                                            <th className='' style={{ width: '100px' }}>Comment</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {
                                                                                            JSON.parse(obj?.RecoveredProperty)?.map((item, key) => (
                                                                                                <>
                                                                                                    <tr key={key}>
                                                                                                        <td style={{ width: '100px' }} className='text-list'>{item.RecoveredIDNumber}</td>
                                                                                                        <td style={{ width: '100px' }} className='text-list'>{getShowingMonthDateYear(item.RecoveredDateTime)}</td>
                                                                                                        <td style={{ width: '100px' }} className='text-list'>{item.RecoveryType_Description}</td>
                                                                                                        <td style={{ width: '100px' }} className='text-list'>{item.RecoveredValue}</td>
                                                                                                        <td style={{ width: '100px' }} className='text-list'>{item.Comments}</td>
                                                                                                    </tr>
                                                                                                </>
                                                                                            ))
                                                                                        }
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        <></>
                                                                } */}
                                                                    {
                                                                        JSON.parse(obj?.Owner)?.length > 0 ?
                                                                            <>
                                                                                <div className="table-responsive" >
                                                                                    <div className="text-white text-bold bg-green py-1 px-2  d-flex justify-content-between align-items-center">
                                                                                        <p className="p-0 m-0 d-flex align-items-center">Property Owner:</p>
                                                                                    </div>
                                                                                    <table className="table " >
                                                                                        <thead className='text-dark master-table'>
                                                                                            <tr>
                                                                                                <th className=''>Owner Name</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody >
                                                                                            {
                                                                                                JSON.parse(obj?.Owner)?.map((item, key) => (
                                                                                                    <>
                                                                                                        <tr key={key} >
                                                                                                            <td className='text-list'>{item.Owner_Name}</td>
                                                                                                        </tr>
                                                                                                    </>
                                                                                                ))
                                                                                            }
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            <></>
                                                                    }
                                                                    {/* property room */}
                                                                    {
                                                                        JSON.parse(obj?.PropertyRoom)?.length > 0 ?
                                                                            <>
                                                                                <div className="table-responsive" >
                                                                                    <div className="text-white text-bold bg-green py-1 px-2  d-flex justify-content-between align-items-center">
                                                                                        <p className="p-0 m-0 d-flex align-items-center">Property Room Information:</p>
                                                                                    </div>
                                                                                    <table className="table " >
                                                                                        <thead className='text-dark master-table'>
                                                                                            <tr>
                                                                                                <th className=''>Officer Name</th>
                                                                                                <th className=''>Activity Reason</th>
                                                                                                <th className=''>Other Person Name</th>
                                                                                                <th className=''>Activity Type</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody >
                                                                                            {
                                                                                                JSON.parse(obj?.PropertyRoom)?.map((item, key) => (
                                                                                                    <>
                                                                                                        <tr key={key} >
                                                                                                            <td className='text-list'>{item.Officer_Name}</td>
                                                                                                            <td className='text-list'>{item.ActivityReason_Des}</td>
                                                                                                            <td className='text-list'>{item.OtherPersonName_Name}</td>
                                                                                                            <td className='text-list'>{item.Status}</td>
                                                                                                        </tr>
                                                                                                    </>
                                                                                                ))
                                                                                            }
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            <></>
                                                                    }
                                                                    {/* property notes */}
                                                                    {
                                                                        JSON.parse(obj?.PropertyNotes)?.length > 0 ?
                                                                            <>
                                                                                <div className="table-responsive" >
                                                                                    <div className="text-white text-bold bg-green py-1 px-2  d-flex justify-content-between align-items-center">
                                                                                        <p className="p-0 m-0 d-flex align-items-center">Property Notes:</p>
                                                                                    </div>
                                                                                    <table className="table " >
                                                                                        <thead className='text-dark master-table'>
                                                                                            <tr>
                                                                                                <th className='' style={{ width: '200px' }}>Officer</th>
                                                                                                <th className='' style={{ width: '100px' }}>Property Notes</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody >
                                                                                            {
                                                                                                JSON.parse(obj?.PropertyNotes)?.map((item, key) => (
                                                                                                    <>
                                                                                                        <tr key={key} >
                                                                                                            <td className='text-list' style={{ width: '200px' }}>{item.OfficerName}</td>
                                                                                                            <td className='text-list' style={{ width: '100px' }}>{item.Notes}</td>
                                                                                                        </tr>
                                                                                                    </>
                                                                                                ))
                                                                                            }
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            <></>
                                                                    }
                                                                    {/* property offence */}
                                                                    {
                                                                        JSON.parse(obj?.PropertyOffense)?.length > 0 ?
                                                                            <>
                                                                                <div className="table-responsive" >
                                                                                    <div className="text-white text-bold bg-green py-1 px-2  d-flex justify-content-between align-items-center">
                                                                                        <p className="p-0 m-0 d-flex align-items-center">Property Offense:</p>
                                                                                    </div>
                                                                                    <table className="table " >
                                                                                        <thead className='text-dark master-table'>
                                                                                            <tr>
                                                                                                <th className='' style={{ width: '200px' }}>CFS Code/Description</th>
                                                                                                <th className='' style={{ width: '100px' }}>Attempt Complete</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody >
                                                                                            {
                                                                                                JSON.parse(obj?.PropertyOffense)?.map((item, key) => (
                                                                                                    <>
                                                                                                        <tr key={key} >
                                                                                                            <td className='text-list' style={{ width: '200px' }}>{item.Offense_Description}</td>
                                                                                                            <td className='text-list' style={{ width: '100px' }}>{item.AttemptComplete}</td>
                                                                                                        </tr>
                                                                                                    </>
                                                                                                ))
                                                                                            }
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            <></>
                                                                    }
                                                                    {/* property recovered */}
                                                                    {
                                                                        JSON.parse(obj?.RecoveredProperty)?.length > 0 ?
                                                                            <>
                                                                                <div className="table-responsive" >
                                                                                    <div className="text-white text-bold bg-green py-1 px-2  d-flex justify-content-between align-items-center">
                                                                                        <p className="p-0 m-0 d-flex align-items-center">Recovered Property:</p>
                                                                                    </div>
                                                                                    <table className="table " >
                                                                                        <thead className='text-dark master-table'>
                                                                                            <tr>
                                                                                                <th className=''>Recovered Number</th>
                                                                                                <th className=''>Recovered Date/Time</th>
                                                                                                <th className=''>Recovered Value</th>
                                                                                                <th className=''>Disposition</th>
                                                                                                <th className=''>Officer Name</th>
                                                                                                <th className=''>Recovery Type</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody >
                                                                                            {
                                                                                                JSON.parse(obj?.RecoveredProperty)?.map((item, key) => (
                                                                                                    <>
                                                                                                        <tr key={key} >
                                                                                                            <td className='text-list'>{item.RecoveredIDNumber}</td>
                                                                                                            <td className='text-list'>{getShowingDateText(item.RecoveredDateTime)}</td>
                                                                                                            <td className='text-list'>{item.RecoveredValue}</td>
                                                                                                            <td className='text-list'>{item.Disposition_Des}</td>
                                                                                                            <td className='text-list'>{item.Officer_Name}</td>
                                                                                                            <td className='text-list'>{item.RecoveryType_Des}</td>
                                                                                                        </tr>
                                                                                                    </>
                                                                                                ))
                                                                                            }
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            <></>
                                                                    }
                                                                    {/* property TRANSACTION */}
                                                                    {
                                                                        JSON.parse(obj?.TransactionLog)?.length > 0 ?
                                                                            <>
                                                                                <div className="table-responsive" >
                                                                                    <div className="text-white text-bold bg-green py-1 px-2  d-flex justify-content-between align-items-center">
                                                                                        <p className="p-0 m-0 d-flex align-items-center">Transaction Information:</p>
                                                                                    </div>
                                                                                    <table className="table " >
                                                                                        <thead className='text-dark master-table'>
                                                                                            <tr>
                                                                                                <th className=''>Transaction Name</th>
                                                                                                <th className=''>Transaction Number</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody >
                                                                                            {
                                                                                                JSON.parse(obj?.TransactionLog)?.map((item, key) => (
                                                                                                    <>
                                                                                                        <tr key={key} >
                                                                                                            <td className='text-list'>{item.TransactionName}</td>
                                                                                                            <td className='text-list'>{item.TransactionNumber}</td>
                                                                                                        </tr>
                                                                                                    </>
                                                                                                ))
                                                                                            }
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            <></>
                                                                    }

                                                                    {/* {
                                                                    JSON.parse(obj?.PropertyArticle)?.length > 0 ?
                                                                        <>
                                                                            <h5 className=" text-white text-bold bg-green py-1 px-3">Property Vehicle:</h5>
                                                                            <table className="table table-bordered" >
                                                                                <thead className='text-dark master-table'>
                                                                                    <tr>
                                                                                        <th className=''>ArticleIDNumber</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody className='master-tbody'>
                                                                                    {
                                                                                        JSON.parse(obj?.PropertyArticle)?.map((item, key) => (
                                                                                            <>
                                                                                                <tr key={key} >
                                                                                                    <td>{item.ArticleIDNumber}</td>
                                                                                                </tr>
                                                                                            </>
                                                                                        ))
                                                                                    }
                                                                                </tbody>
                                                                            </table>
                                                                        </>
                                                                        :
                                                                        <></>
                                                                } */}
                                                                    <div className="col-12">
                                                                        {
                                                                            JSON.parse(obj?.Photo)?.length > 0 ? (
                                                                                <Accordion activeIndex={0}>
                                                                                    <AccordionTab header="Property Image">
                                                                                        <div className="col-12">
                                                                                            <div className="row">
                                                                                                {
                                                                                                    JSON.parse(obj?.Photo)?.length > 0 ?
                                                                                                        JSON.parse(obj?.Photo)?.map((item, index) => {
                                                                                                            return (
                                                                                                                <div className="col-3">
                                                                                                                    <div className="main" key={index}>
                                                                                                                        <img src={item.Photo} style={{ height: '200px' }} />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            )
                                                                                                        })
                                                                                                        :
                                                                                                        <>
                                                                                                        </>
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </AccordionTab>
                                                                                </Accordion>
                                                                            ) : null}
                                                                    </div>
                                                                    {/* <div className="table-responsive" >
                                                                    <div className="text-white text-bold bg-green py-1 px-2  d-flex justify-content-between align-items-center">
                                                                        <p className="p-0 m-0 d-flex align-items-center">Property Image:</p>
                                                                    </div>
                                                                    <table className="table table-bordered" >
                                                                        <tbody className=''>


                                                                            <div className="col-12">
                                                                                <div className="row">
                                                                                    {
                                                                                        JSON.parse(obj?.Photo)?.length > 0 ?
                                                                                            JSON.parse(obj?.Photo)?.map((item, index) => {
                                                                                                return (
                                                                                                    <div className="col-3">
                                                                                                        <div className="main" key={index}>
                                                                                                            <img src={item.Photo} style={{ height: '200px' }} />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )
                                                                                            })
                                                                                            :
                                                                                            <>
                                                                                            </>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </tbody>
                                                                    </table>
                                                                </div> */}
                                                                </div>
                                                            </>
                                                        ))
                                                    }
                                                </>
                                                :
                                                <>
                                                </>
                                        }
                                    </div>
                                </div>
                            </>
                            :
                            <>
                            </>
                        :
                        <>
                        </>
                }
            </div >
        </>
    )
}

export default MasterPropertyReport
import React, { useContext, useEffect, useState } from 'react'
import DatePicker from "react-datepicker";
import { customStylesWithOutColor, Decrypt_Id_Name, filterPassedDateTime, filterPassedTime, getShowingDateText, getShowingMonthDateYear } from '../../../Common/Utility';
import { AgencyContext } from '../../../../Context/Agency/Index';
import { Link, useNavigate } from 'react-router-dom';
import { fetchData, fetchPostData } from '../../../hooks/Api';
import { toastifyError } from '../../../Common/AlertMsg';
import { Comman_changeArrayFormat, Comman_changeArrayFormatBasicInfo, fourColArray, threeColArrayWithCode } from '../../../Common/ChangeArrayFormat';
import SelectBox from '../../../Common/SelectBox';
import Select, { components } from "react-select";
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { get_AgencyOfficer_Data, get_Incident_Drp_Data } from '../../../../redux/actions/DropDownsData';
import { get_LocalStoreData } from '../../../../redux/actions/Agency';


const IncidentSearchPage = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate()
    const uniqueId = sessionStorage.getItem('UniqueUserID') ? Decrypt_Id_Name(sessionStorage.getItem('UniqueUserID'), 'UForUniqueUserID') : '';
    const localStoreData = useSelector((state) => state.Agency.localStoreData);
    const fbiCodesDrpData = useSelector((state) => state.DropDown.fbiCodesDrpData);
    const receiveSourceDrpData = useSelector((state) => state.DropDown.receiveSourceDrpData);
    const cadCfsCodeDrpData = useSelector((state) => state.DropDown.cadCfsCodeDrpData);
    const rmsDispositionDrpData = useSelector((state) => state.DropDown.rmsDispositionDrpData);
    const cadDispositionDrpData = useSelector((state) => state.DropDown.cadDispositionDrpData);
    const agencyOfficerDrpData = useSelector((state) => state.DropDown.agencyOfficerDrpData);

    const { exceptionalClearID, GetDataExceptionalClearanceID, setIncidentSearchData, incAdvSearchData, setIncAdvSearchData } = useContext(AgencyContext);

    const [rmsCfsID, setRmsCfsID] = useState([]);
    const [typeOfSecurityID, setTypeOfSecurityID] = useState([]);
    const [pinActivityID, setPinActivityID] = useState([]);
    const [loginAgencyID, setLoginAgencyID] = useState('');

    const [value, setValue] = useState({
        'ReportedDate': '', 'RMSDispositionId': '', 'RMSCFSCodeID': '', 'IncidentNumber': '', 'IncidentNumberTo': '', 'MasterIncidentNumber': '', 'MasterIncidentNumberTo': '',
        'RMSCFSCodeList': '', 'OccurredFrom': '', 'OccurredFromTo': '', 'ReportedDateTo': '', 'IncidentSecurityID': '', 'PINID': '', 'FBIID': '', 'AgencyID': '',
        'DispositionDate': '', 'DispositionDateTo': '', 'ReceiveSourceID': '', 'NIBRSClearanceID': '', 'IncidentPINActivityID': '', 'IncidentSecurityIDlist': '', 'CADCFSCodeID': '', 'DispositionComments': '', 'CrimeLocation': '',
    });

    useEffect(() => {
        if (!localStoreData?.AgencyID || !localStoreData?.PINID) {
            if (uniqueId) dispatch(get_LocalStoreData(uniqueId));
        }
    }, []);

    useEffect(() => {
        if (localStoreData) {
            setLoginAgencyID(localStoreData?.AgencyID);
        }
    }, [localStoreData]);

    useEffect(() => {
        if (loginAgencyID) {
            dispatch(get_AgencyOfficer_Data(loginAgencyID, ''))
            if (cadDispositionDrpData?.length === 0) { dispatch(get_Incident_Drp_Data(loginAgencyID)) }
            if (exceptionalClearID.length === 0) { GetDataExceptionalClearanceID(loginAgencyID); }
            GetDataTypeOfSecurity(loginAgencyID)
        }
        GetDataPinActivity();
    }, [loginAgencyID]);

    const getRmsCfsCodeID = (FBIID) => {
        const val = { 'FBIID': FBIID, 'AgencyID': null, }
        fetchPostData('ChargeCodes/GetDataDropDown_ChargeCodes', val).then((data) => {
            if (data) {
                setRmsCfsID(Comman_changeArrayFormat(data, 'ChargeCodeID', 'Description'))
            } else {
                setRmsCfsID([]);
            }
        })
    }

    const GetDataTypeOfSecurity = (LoginAgencyID) => {
        const val = { AgencyID: LoginAgencyID, }
        fetchPostData('IncidentSecurity/GetDataDropDown_IncidentSecurity', val).then((data) => {
            if (data) {
                setTypeOfSecurityID(Comman_changeArrayFormat(data, 'SecurityId', 'Description'))
            } else {
                setTypeOfSecurityID([]);
            }
        })
    }

    const GetDataPinActivity = () => {
        const val = { 'AgencyID': loginAgencyID }
        fetchPostData('PINActivity/GetData_PINActivityType', val).then((data) => {
            setPinActivityID(Comman_changeArrayFormat(data, 'ActivityTypeID', 'Description'));
        })
    }

    const getIncidentSearchData = async () => {
        const {
            ReportedDate, RMSDispositionId, RMSCFSCodeID, IncidentNumber, IncidentNumberTo, MasterIncidentNumber, MasterIncidentNumberTo, RMSCFSCodeList, OccurredFrom, OccurredFromTo,
            IncidentSecurityID, PINID, FBIID, AgencyID, DispositionDate, DispositionDateTo, ReceiveSourceID, NIBRSClearanceID, IncidentPINActivityID, ReportedDateTo, IncidentSecurityIDlist, CADCFSCodeID, DispositionComments, CrimeLocation
        } = value
        const val = {
            'ReportedDate': ReportedDate, 'ReportedDateTo': ReportedDateTo, 'RMSDispositionId': RMSDispositionId, 'IncidentNumber': IncidentNumber, 'IncidentNumberTo': IncidentNumberTo,
            'MasterIncidentNumber': MasterIncidentNumber, 'MasterIncidentNumberTo': MasterIncidentNumberTo, 'DispositionDate': DispositionDate, 'DispositionDateTo': DispositionDateTo,
            'ReceiveSourceID': ReceiveSourceID, 'IncidentPINActivityID': IncidentPINActivityID, 'RMSCFSCodeList': RMSCFSCodeList, 'OccurredFrom': OccurredFrom, 'OccurredFromTo': OccurredFromTo,
            'NIBRSClearanceID': NIBRSClearanceID, 'IncidentSecurityID': IncidentSecurityID, 'RMSCFSCodeID': RMSCFSCodeID, 'PINID': PINID, 'FBIID': FBIID, 'AgencyID': loginAgencyID, 'IncidentSecurityIDlist': IncidentSecurityIDlist, 'CADCFSCodeID': CADCFSCodeID, 'DispositionComments': DispositionComments, 'CrimeLocation': CrimeLocation,
        }
        fetchPostData('Incident/Search_Incident', val).then((res) => {
            if (res.length > 0) {
                setIncidentSearchData(res); setIncAdvSearchData(true)
                reset_Fields();
                navigate('/incident');
            } else {
                toastifyError("Data Not Available");
                setIncidentSearchData([]);
            }
        });
    }

    const HandleChange = (e,) => {
        if (e.target.name === 'IncidentNumber' || e.target.name === 'IncidentNumberTo') {
            let ele = e.target.value.replace(/[^a-zA-Z0-9\s]/g, '');
            if (ele.length === 8) {
                const cleaned = ('' + ele).replace(/[^a-zA-Z0-9\s]/g, '');
                const match = cleaned.match(/^(\d{2})(\d{6})$/);
                if (match) {
                    setValue({
                        ...value,
                        [e.target.name]: match[1] + '-' + match[2]
                    })
                }
            } else {
                ele = e.target.value.split("'").join('').replace(/[^0-9\s]/g, '');
                setValue({
                    ...value,
                    [e.target.name]: ele
                })
            }
        } else {
            setValue({
                ...value,
                [e.target.name]: e.target.value
            })
        }
    }

    const changeDropDown = (e, name) => {
        if (e) {
            if (name === "RMSCFSCodeID") {
                setValue({ ...value, [name]: e.value })
            }
            else if (name === 'FBIID') {
                getRmsCfsCodeID(e.value)
                setValue({ ...value, [name]: e.value, ['RMSCFSCodeID']: '', });
            }
            else if (name === 'NIBRSClearanceID') {
                if (e.id != 'N') {
                    setValue({ ...value, [name]: e.value, ['NIBRSclearancedate']: getShowingMonthDateYear(new Date()), });
                } else {
                    setValue({ ...value, [name]: e.value, ['NIBRSclearancedate']: '', });
                }
            }
            else {
                setValue({ ...value, [name]: e.value, })
            }
        } else if (e === null) {
            if (name === "RMSDispositionId") {
                setValue({ ...value, [name]: null, ['NIBRSClearanceID']: null, ['DispositionDate']: '', ['NIBRSclearancedate']: '', });
            }
            else if (name === 'FBIID') {
                setRmsCfsID([]);
                setValue({ ...value, ['FBIID']: "", ['RMSCFSCodeID']: "", })
            }
            else if (name === 'NIBRSClearanceID') {
                setValue({ ...value, [name]: null, ['NIBRSclearancedate']: "", });
            }
            else {
                setValue({ ...value, [name]: null });
            }
        }
        else {
            setValue({ ...value, [name]: null });
        }
    }

    const RMSCFSDropDown = (e, name) => {
        if (e) {
            if (name === 'RMSCFSCodeList') {
                const ids = []
                e.forEach(({ value }) => ids.push(value))
                setValue({
                    ...value,
                    [name]: JSON.stringify(ids)
                })
            } else if (name === 'IncidentSecurityIDlist') {
                const ids = []
                e.forEach(({ value }) => ids.push(value))
                setValue({
                    ...value,
                    [name]: JSON.stringify(ids)
                })
            } else {
                setValue({
                    ...value,
                    [name]: e.value,
                })
            }
        } else {
            setValue({
                ...value,
                [name]: null,
            })
        }
    }

    const reset_Fields = () => {
        setValue({
            ...value,
            'ReportedDate': '', 'RMSDispositionId': '', 'RMSCFSCodeID': '', 'IncidentNumber': '', 'IncidentNumberTo': '', 'MasterIncidentNumber': '', 'MasterIncidentNumberTo': '',
            'RMSCFSCodeList': '', 'OccurredFrom': '', 'OccurredFromTo': '', 'ReportedDateTo': '', 'IncidentSecurityID': '', 'PINID': '', 'FBIID': '', 'AgencyID': '',
            'DispositionDate': '', 'DispositionDateTo': '', 'ReceiveSourceID': '', 'NIBRSClearanceID': '', 'IncidentPINActivityID': '', 'IncidentSecurityIDlist': '', 'CADCFSCodeID': '', 'DispositionComments': '', 'CrimeLocation': ''
        });
    }

    const colourStyles = {
        control: (styles) => ({
            ...styles,
            height: 20,
            minHeight: 35,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
        }),
    }

    const startRef = React.useRef();
    const startRef1 = React.useRef();
    const startRef2 = React.useRef();
    const startRef3 = React.useRef();
    const startRef4 = React.useRef();
    const startRef5 = React.useRef();
    const startRef6 = React.useRef();
    const startRef7 = React.useRef();
    const startRef8 = React.useRef();

    const onKeyDown = (e) => {
        if (e.keyCode === 9 || e.which === 9) {
            startRef.current.setOpen(false);
            startRef1.current.setOpen(false);
            startRef2.current.setOpen(false);
            startRef3.current.setOpen(false);
            startRef4.current.setOpen(false);
            startRef5.current.setOpen(false);
            startRef6.current.setOpen(false);
            startRef7.current.setOpen(false);
            startRef8.current.setOpen(false);
        }
    };

    return (
        <div className=" section-body  p-1 bt" >
            <div className="div">
                <div className="dark-row" >
                    <div className="col-12 col-sm-12">
                        <div className="card Agency ">
                            <div className="card-body" >
                                <div className="row ">
                                    <div className="col-12 ">
                                        <fieldset style={{ marginTop: '-15px' }}>
                                            <legend>Incident Search</legend>
                                            <div className="row px-1">
                                                <div className="col-3 col-md-3 col-lg-2  mt-2 pt-2">
                                                    <label htmlFor="" className='new-label'>Incident Number From</label>
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-3 text-field ">
                                                    <input type="text" id='IncidentNumber' maxLength={9} name='IncidentNumber' value={value?.IncidentNumber} onChange={HandleChange} />
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-3  mt-2 pt-2">
                                                    <label htmlFor="" className='new-label'>Incident Number To</label>
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-3 text-field ">
                                                    <input type="text" id='IncidentNumberTo' maxLength={9} name='IncidentNumberTo' value={value?.IncidentNumberTo} onChange={HandleChange} />
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-2  mt-2 pt-2">
                                                    <label htmlFor="" className='new-label'>Master&nbsp;Incident&nbsp;No.&nbsp;From</label>
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-3 text-field ">
                                                    <input type="text" id='IncidentNumber' maxLength={9} name='IncidentNumber' />
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-3  mt-2 pt-2">
                                                    <label htmlFor="" className='new-label'>Master Incident No. To</label>
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-3 text-field ">
                                                    <input type="text" id='IncidentNumberTo' maxLength={9} name='IncidentNumberTo' />
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-2  mt-2 pt-2">
                                                    <label htmlFor="" className='new-label'>Occurred From Date</label>
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-3 mt-2 ">
                                                    <DatePicker
                                                        name='OccurredFrom'
                                                        id='OccurredFrom'
                                                        ref={startRef2}
                                                        // onKeyDown={onKeyDown}
                                                        onKeyDown={(e) => {
                                                            if (!((e.key >= '0' && e.key <= '9') || e.key === 'Backspace' || e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'Delete' || e.key === ':' || e.key === '/' || e.key === ' ')) {
                                                                e?.preventDefault();
                                                            } else {
                                                                onKeyDown(e);
                                                            }
                                                        }}
                                                        onChange={(date) => {
                                                            if (date) {
                                                                setValue({ ...value, ['OccurredFrom']: date ? getShowingDateText(date) : null })
                                                            } else {
                                                                setValue({ ...value, ['OccurredFrom']: null, ['OccurredFromTo']: null })
                                                            }
                                                        }}
                                                        selected={value?.OccurredFrom && new Date(value?.OccurredFrom)}
                                                        dateFormat="MM/dd/yyyy HH:mm"
                                                        timeInputLabel
                                                        isClearable={value?.OccurredFrom ? true : false}
                                                        peekNextMonth
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        autoComplete='Off'
                                                        maxDate={new Date()}
                                                        placeholderText='Select...'
                                                        showTimeSelect
                                                        timeIntervals={1}
                                                        timeCaption="Time"
                                                        filterTime={filterPassedTime}

                                                    />
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-3  mt-2 pt-2">
                                                    <babel htmlFor="" className='new-label'>Occurred To Date</babel>
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-3 mt-2 ">
                                                    <DatePicker
                                                        id='OccurredFromTo'
                                                        name='OccurredFromTo'
                                                        ref={startRef3}
                                                        // onKeyDown={onKeyDown}
                                                        onKeyDown={(e) => {
                                                            if (!((e.key >= '0' && e.key <= '9') || e.key === 'Backspace' || e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'Delete' || e.key === ':' || e.key === '/' || e.key === ' ')) {
                                                                e?.preventDefault();
                                                            } else {
                                                                onKeyDown(e);
                                                            }
                                                        }}
                                                        onChange={(date) => { setValue({ ...value, ['OccurredFromTo']: date ? getShowingMonthDateYear(date) : null }) }}
                                                        dateFormat="MM/dd/yyyy HH:mm"
                                                        isClearable={value?.OccurredFromTo ? true : false}
                                                        disabled={value?.OccurredFrom ? false : true}
                                                        selected={value?.OccurredFromTo && new Date(value?.OccurredFromTo)}
                                                        minDate={new Date(value?.OccurredFrom)}
                                                        maxDate={new Date()}
                                                        placeholderText={'Select...'}
                                                        showDisabledMonthNavigation
                                                        autoComplete="off"
                                                        showYearDropdown
                                                        showMonthDropdown
                                                        dropdownMode="select"
                                                        showTimeSelect
                                                        timeIntervals={1}
                                                        timeCaption="Time"
                                                        filterTime={(time) => filterPassedDateTime(time, value?.OccurredFromTo, value?.OccurredFrom)}

                                                    />
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-2  mt-2 pt-2">
                                                    <label htmlFor="" className='new-label'>Reported From Date</label>
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-3 mt-2 ">
                                                    <DatePicker
                                                        name='ReportedDate'
                                                        id='ReportedDate'
                                                        ref={startRef}
                                                        // onKeyDown={onKeyDown}
                                                        onKeyDown={(e) => {
                                                            if (!((e.key >= '0' && e.key <= '9') || e.key === 'Backspace' || e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'Delete' || e.key === ':' || e.key === '/' || e.key === ' ')) {
                                                                e?.preventDefault();
                                                            } else {
                                                                onKeyDown(e);
                                                            }
                                                        }}
                                                        onChange={(date) => {
                                                            if (date) {
                                                                setValue({ ...value, ['ReportedDate']: date ? getShowingDateText(date) : null })
                                                            } else {
                                                                setValue({ ...value, ['ReportedDate']: null, ['ReportedDateTo']: null })
                                                            }
                                                        }}
                                                        selected={value?.ReportedDate && new Date(value?.ReportedDate)}
                                                        dateFormat="MM/dd/yyyy HH:mm"
                                                        // timeInputLabel
                                                        isClearable={value?.ReportedDate ? true : false}
                                                        peekNextMonth
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        autoComplete='Off'
                                                        maxDate={new Date()}
                                                        placeholderText='Select...'
                                                        showTimeSelect
                                                        timeIntervals={1}
                                                        timeCaption="Time"
                                                        filterTime={filterPassedTime}

                                                    />
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-3  mt-2 pt-2">
                                                    <label htmlFor="" className='new-label'>Reported To Date</label>
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-3 mt-2 ">
                                                    <DatePicker
                                                        name='ReportedDateTo'
                                                        id='ReportedDateTo'
                                                        onChange={(date) => { setValue({ ...value, ['ReportedDateTo']: date ? getShowingDateText(date) : null }) }}
                                                        selected={value?.ReportedDateTo && new Date(value?.ReportedDateTo)}
                                                        dateFormat="MM/dd/yyyy HH:mm"
                                                        // timeInputLabel
                                                        ref={startRef1}
                                                        // onKeyDown={onKeyDown}
                                                        onKeyDown={(e) => {
                                                            if (!((e.key >= '0' && e.key <= '9') || e.key === 'Backspace' || e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'Delete' || e.key === ':' || e.key === '/' || e.key === ' ')) {
                                                                e?.preventDefault();
                                                            } else {
                                                                onKeyDown(e);
                                                            }
                                                        }}
                                                        isClearable={value?.ReportedDateTo ? true : false}
                                                        peekNextMonth
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        autoComplete='Off'
                                                        disabled={value?.ReportedDate ? false : true}
                                                        maxDate={new Date()}
                                                        placeholderText='Select...'
                                                        minDate={new Date(value?.ReportedDate)}
                                                        showTimeSelect
                                                        timeIntervals={1}
                                                        timeCaption="Time"
                                                        filterTime={(time) => filterPassedDateTime(time, value?.ReportedDateTo, value?.ReportedDate)}

                                                    />
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                                                    <label htmlFor="" className='new-label'>Location</label>
                                                </div>
                                                <div className="col-9 col-md-9 col-lg-9 mt-1 text-field">
                                                    <input type="text" name='CrimeLocation' value={value?.CrimeLocation} onChange={HandleChange} id='CrimeLocation' className='' />
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-2  mt-2 pt-2">
                                                    <label htmlFor="" className='new-label'> NIBRS Code</label>
                                                </div>
                                                <div className="col-9 col-md-9 col-lg-9 mt-2 ">
                                                    <Select
                                                        name='FBIID'
                                                        styles={colourStyles}
                                                        value={fbiCodesDrpData?.filter((obj) => obj.value === value?.FBIID)}
                                                        isClearable
                                                        options={fbiCodesDrpData}
                                                        onChange={(e) => changeDropDown(e, 'FBIID')}
                                                        placeholder="Select..."
                                                    />
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-2  mt-2 pt-2">
                                                    <label htmlFor="" className='new-label'> Offense Code/Name </label>
                                                </div>
                                                <div className="col-9 col-md-9 col-lg-9 mt-2 ">
                                                    <SelectBox
                                                        name='RMSCFSCodeList'
                                                        defaultValue={[]}
                                                        isMulti
                                                        options={rmsCfsID}
                                                        isClearable
                                                        onChange={(e) => RMSCFSDropDown(e, 'RMSCFSCodeList')}
                                                        placeholder="Select..."
                                                    />
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-2  mt-2 pt-2">
                                                    <label htmlFor="" className='new-label'>CAD CFS Code</label>
                                                </div>
                                                <div className="col-9 col-md-9 col-lg-9 mt-2 ">
                                                    <Select
                                                        name='CADCFSCodeID'
                                                        value={cadCfsCodeDrpData?.filter((obj) => obj.value === value?.CADCFSCodeID)}
                                                        isClearable
                                                        menuPlacement='top'
                                                        options={cadCfsCodeDrpData}
                                                        onChange={(e) => changeDropDown(e, 'CADCFSCodeID')}
                                                        placeholder="Select..."
                                                        styles={customStylesWithOutColor}
                                                    />
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-2  mt-2 pt-2">
                                                    <label htmlFor="" className='new-label'>Type Of Security</label>
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-3 mt-2 ">
                                                    <Select
                                                        name='IncidentSecurityIDlist'
                                                        defaultValue={[]}
                                                        isMulti
                                                        options={typeOfSecurityID}
                                                        isClearable
                                                        onChange={(e) => RMSCFSDropDown(e, 'IncidentSecurityIDlist')}
                                                        placeholder="Select..."
                                                    // name='IncidentSecurityID'
                                                    // styles={colourStyles}
                                                    // value={typeOfSecurityID?.filter((obj) => obj.value === value?.IncidentSecurityID)}
                                                    // options={typeOfSecurityID}
                                                    // isClearable
                                                    // onChange={(e) => changeDropDown(e, 'IncidentSecurityID')}
                                                    />
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-3  mt-2 pt-2">
                                                    <label htmlFor="" className='new-label'>How Reported</label>
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-3 mt-2 ">
                                                    <Select
                                                        name='ReceiveSourceID'
                                                        value={receiveSourceDrpData?.filter((obj) => obj.value === value?.ReceiveSourceID)}
                                                        isClearable
                                                        options={receiveSourceDrpData}
                                                        menuPlacement='top'
                                                        onChange={(e) => changeDropDown(e, 'ReceiveSourceID')}
                                                        placeholder="Select..."
                                                        styles={colourStyles}
                                                    />
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-2  mt-2 pt-2">
                                                    <label htmlFor="" className='new-label'>Pin Activity</label>
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-3 mt-2 ">
                                                    <Select
                                                        name='IncidentPINActivityID'
                                                        styles={colourStyles}
                                                        value={pinActivityID?.filter((obj) => obj.value === value?.IncidentPINActivityID)}
                                                        options={pinActivityID}
                                                        isClearable
                                                        onChange={(e) => changeDropDown(e, 'IncidentPINActivityID')}
                                                        placeholder="Select..."
                                                    />
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-3  mt-2 pt-2">
                                                    <label htmlFor="" className='new-label'>Officer</label>
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-3 mt-2 ">
                                                    <Select
                                                        name='PINID'
                                                        styles={colourStyles}
                                                        menuPlacement='top'
                                                        value={agencyOfficerDrpData?.filter((obj) => obj.value === value?.PINID)}
                                                        isClearable
                                                        options={agencyOfficerDrpData}
                                                        onChange={(e) => changeDropDown(e, 'PINID')}
                                                        placeholder="Select..."
                                                    />
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                                                    <label htmlFor="" className='new-label'>Description</label>
                                                </div>
                                                <div className="col-9 col-md-9 col-lg-9 mt-1 text-field">
                                                    <input type="text" name='DispositionComments' className='' value={value.DispositionComments} onChange={HandleChange} />
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                    <div className="col-12">
                                        <fieldset >
                                            <legend>RMS Disposition/Clearance Information</legend>
                                            <div className="row px-1">
                                                <div className="col-3 col-md-3 col-lg-2  mt-2 pt-2">
                                                    <label htmlFor="" className='new-label'>RMS Disposition</label>
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-3 mt-2 ">
                                                    <Select
                                                        name='RMSDispositionId'
                                                        value={rmsDispositionDrpData?.filter((obj) => obj.value === value?.RMSDispositionId)}
                                                        isClearable
                                                        options={rmsDispositionDrpData}
                                                        onChange={(e) => changeDropDown(e, 'RMSDispositionId')}
                                                        placeholder="Select..."
                                                        styles={colourStyles}
                                                    />
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-3  mt-2 pt-2">
                                                    <label htmlFor="" className='new-label'>Exceptional Clearance</label>
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-3 mt-2 ">
                                                    <Select
                                                        name='NIBRSClearanceID'
                                                        value={exceptionalClearID?.filter((obj) => obj.value === value?.NIBRSClearanceID)}
                                                        isClearable
                                                        options={exceptionalClearID}
                                                        onChange={(e) => changeDropDown(e, 'NIBRSClearanceID')}
                                                        placeholder="Select..."
                                                        styles={colourStyles}
                                                        menuPlacement='top'
                                                    />
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-2  mt-2 pt-2">
                                                    <label htmlFor="" className='new-label'>Disposition From Date </label>
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-3 mt-2 ">
                                                    <DatePicker
                                                        id='DispositionDate'
                                                        name='DispositionDate'
                                                        ref={startRef6}
                                                        // onKeyDown={onKeyDown}
                                                        onKeyDown={(e) => {
                                                            if (!((e.key >= '0' && e.key <= '9') || e.key === 'Backspace' || e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'Delete' || e.key === ':' || e.key === '/' || e.key === ' ')) {
                                                                e?.preventDefault();
                                                            } else {
                                                                onKeyDown(e);
                                                            }
                                                        }}
                                                        onChange={(date) => {
                                                            setValue({
                                                                ...value,
                                                                ['DispositionDate']: date ? getShowingMonthDateYear(date) : null,
                                                            })
                                                        }}
                                                        dateFormat="MM/dd/yyyy"
                                                        isClearable={value?.DispositionDate ? true : false}
                                                        selected={value?.DispositionDate && new Date(value?.DispositionDate)}
                                                        maxDate={new Date()}
                                                        placeholderText={'Select...'}
                                                        showDisabledMonthNavigation
                                                        autoComplete="off"
                                                        showYearDropdown
                                                        showMonthDropdown
                                                        dropdownMode="select"
                                                    />
                                                </div>

                                                <div className="col-3 col-md-3 col-lg-3  mt-2 pt-2">
                                                    <label htmlFor="" className='new-label'>Disposition To Date </label>
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-3 mt-1">
                                                    <DatePicker
                                                        id='DispositionDateTo'
                                                        name='DispositionDateTo'
                                                        ref={startRef7}
                                                        // onKeyDown={onKeyDown}
                                                        onKeyDown={(e) => {
                                                            if (!((e.key >= '0' && e.key <= '9') || e.key === 'Backspace' || e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'Delete' || e.key === ':' || e.key === '/' || e.key === ' ')) {
                                                                e?.preventDefault();
                                                            } else {
                                                                onKeyDown(e);
                                                            }
                                                        }}
                                                        onChange={(date) => { setValue({ ...value, ['DispositionDateTo']: date ? getShowingMonthDateYear(date) : null }) }}
                                                        dateFormat="MM/dd/yyyy"
                                                        isClearable={value?.DispositionDateTo ? true : false}
                                                        disabled={value?.DispositionDate ? false : true}
                                                        selected={value?.DispositionDateTo && new Date(value?.DispositionDateTo)}
                                                        maxDate={new Date()}
                                                        minDate={new Date(value?.DispositionDate)}
                                                        placeholderText={'Select...'}
                                                        showDisabledMonthNavigation
                                                        autoComplete="off"
                                                        showYearDropdown
                                                        showMonthDropdown
                                                        dropdownMode="select"
                                                    />
                                                </div>

                                            </div>
                                        </fieldset>
                                    </div>
                                </div>
                            </div>
                            <div className="btn-box text-right  mr-1 mb-2">
                                <button type="button" className="btn btn-sm btn-success mr-1" onClick={() => { getIncidentSearchData(); }}>Search</button>
                                <Link to={'/incident'}>
                                    <button type="button" className="btn btn-sm btn-success mr-1" >Close</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default IncidentSearchPage


import React, { useContext, useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import img from '../../../../../src/img/images1.jpg'
import Select from "react-select";
import { Decrypt_Id_Name, base64ToString, customStylesWithOutColor, getShowingDateText, getShowingWithOutTime } from '../../../Common/Utility';
import DatePicker from "react-datepicker";
import { useReactToPrint } from 'react-to-print';
import { useRef } from 'react';
import { useEffect } from 'react';
import { fetchPostData } from '../../../hooks/Api';
import { Comman_changeArrayFormat } from '../../../Common/ChangeArrayFormat';
import { toastifyError } from '../../../Common/AlertMsg';
import { AgencyContext } from '../../../../Context/Agency/Index';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { get_LocalStoreData } from '../../../../redux/actions/Agency';

const NameInformation = () => {

    const dispatch = useDispatch();
    const uniqueId = sessionStorage.getItem('UniqueUserID') ? Decrypt_Id_Name(sessionStorage.getItem('UniqueUserID'), 'UForUniqueUserID') : '';
    const localStoreData = useSelector((state) => state.Agency.localStoreData);

    const useQuery = () => {
        const params = new URLSearchParams(useLocation().search);
        return {
            get: (param) => params.get(param)
        };
    };

    const query = useQuery();
    let openPage = query?.get('page');

    const [multiImage, setMultiImage] = useState([]);
    const [masterNameID, setMasterNameID] = useState();
    const [nameID, setNameID] = useState();
    const [verifyName, setverifyName] = useState(false);
    const [sexIdDrp, setSexIdDrp] = useState([]);
    const [raceIdDrp, setRaceIdDrp] = useState([]);
    const [ethinicityDrpData, setEthinicityDrpData] = useState([])
    const [nameReportData, setNameReportData] = useState([]);
    const [reportData, setReportData] = useState([])
    const [localStatus, setlocalStatus] = useState(false);
    const [LoginAgencyID, setLoginAgencyID] = useState('');
    const [LoginPinID, setLoginPinID,] = useState('');
    const [nameMultiImg, setNameMultiImg] = useState([]);

    const [value, setValue] = useState({
        'NameIDNumber': '', 'SSN': '', 'LastName': '', 'FirstName': '', 'MiddleName': '', 'DateOfBirth': '',
        'SexID': null, 'RaceID': null, 'EthnicityID': null, 'IsUSCitizen': '', 'Address': '',
        'IsVerify': '', 'AgencyID': '', 'DLNumber': '',
    });
    const [searchValue, setSearchValue] = useState({
        NameIDNumber: '',
        SSN: '',
        LastName: '',
        FirstName: '',
        MiddleName: '',
        DateOfBirth: '',
        SexID: null,
        RaceID: null,
        EthnicityID: null,
        Address: '',
        DLNumber: '',
    });

    const [showFields, setShowFields] = useState({
        showNameIDNumber: false,
        showSSN: false,
        showLastName: false,
        showFirstName: false,
        showMiddleName: false,
        showDateOfBirth: false,
        showSexID: false,
        showRaceID: false,
        showEthnicityID: false,
        showAddress: false,
        showDLNumber: false,
    });
    useEffect(() => {
        if (!localStoreData?.AgencyID || !localStoreData?.PINID) {
            if (uniqueId) dispatch(get_LocalStoreData(uniqueId));
        }
    }, []);
    useEffect(() => {
        setShowFields({
            showNameIDNumber: searchValue.NameIDNumber,
            showSSN: searchValue.SSN,
            showLastName: searchValue.LastName,
            showFirstName: searchValue.FirstName,
            showMiddleName: searchValue.MiddleName,
            showDateOfBirth: searchValue.DateOfBirth,
            showSexID: searchValue.SexID !== null,
            showRaceID: searchValue.RaceID !== null,
            showEthnicityID: searchValue.EthnicityID !== null,
            showAddress: searchValue.Address,
            showDLNumber: searchValue.DLNumber,

        });
    }, [searchValue]);
    // Onload Function
    useEffect(() => {
        if (localStoreData) {
            setLoginAgencyID(localStoreData?.AgencyID);
            setLoginPinID(localStoreData?.PINID);
            GetSexIDDrp(localStoreData?.AgencyID);
            GetRaceIdDrp(localStoreData?.AgencyID);
            getEthinicityDrp(localStoreData?.AgencyID);
        }
    }, [localStoreData])

    const getEthinicityDrp = (LoginAgencyID) => {
        const val = { AgencyID: LoginAgencyID }
        fetchPostData('/DropDown/GetDataDropDown_Ethnicity', val).then((data) => {
            if (data) {
                setEthinicityDrpData(Comman_changeArrayFormat(data, 'EthnicityID', 'Description'));
            } else {
                setEthinicityDrpData([])
            }
        })
    };

    const GetRaceIdDrp = (LoginAgencyID) => {
        const val = { AgencyID: LoginAgencyID }
        fetchPostData('DropDown/GetData_RaceType', val).then((data) => {
            if (data) {
                setRaceIdDrp(Comman_changeArrayFormat(data, 'RaceTypeID', 'Description'))
            } else {
                setRaceIdDrp([]);
            }
        })
    }

    const GetSexIDDrp = (LoginAgencyID) => {
        const val = { AgencyID: LoginAgencyID }
        fetchPostData('DropDown/GetData_SexType', val).then((data) => {
            if (data) {
                setSexIdDrp(Comman_changeArrayFormat(data, 'SexCodeID', 'Description'))
            } else {
                setSexIdDrp([]);
            }
        })
    }

    const get_MasterName_Report = () => {
        if (value?.NameIDNumber?.trim()?.length > 0 || value?.SSN?.trim()?.length > 0 || value?.DLNumber?.trim()?.length > 0 || value?.LastName?.trim()?.length > 0 || value?.FirstName?.trim()?.length > 0 || value?.MiddleName?.trim()?.length > 0 || value?.DateOfBirth?.trim()?.length > 0 || value?.Address?.trim()?.length > 0 || value?.SexID !== null || value?.RaceID !== null || value?.EthnicityID !== null) {
            const {
                NameIDNumber, SSN, LastName, FirstName, MiddleName, DateOfBirth,
                SexID, RaceID, EthnicityID, IsUSCitizen, Address, DLNumber,
                IsVerify, AgencyID,
            } = value
            const val = {
                'NameIDNumber': NameIDNumber, 'SSN': SSN, 'DLNumber': DLNumber, 'LastName': LastName, 'FirstName': FirstName, 'MiddleName': MiddleName, 'DateOfBirth': DateOfBirth,
                'SexID': SexID, 'RaceID': RaceID, 'EthnicityID': EthnicityID, 'IsUSCitizen': IsUSCitizen, 'Address': Address,
                'IsVerify': IsVerify, 'AgencyID': LoginAgencyID,
            }
            fetchPostData('ReportName/GetData_ReportName', val).then((res) => {
                if (res.length > 0) {
                    console.log(res, 'Name');
                    // console.log(res[0]?.Name);
                    setReportData(res); setNameReportData(res[0]); setverifyName(true); getAgencyImg(LoginAgencyID);
                    get_Name_MultiImage(nameID, masterNameID);
                    setSearchValue(value);
                }
                else {
                    toastifyError("Data Not Available");
                    setverifyName(false); setNameReportData([]);
                }
            })
        }
        else {
            toastifyError("Please Enter Details");
        }
    }

    const handlChange = (e) => {
        if (e.target.name === 'SSN') {
            var ele = e.target.value.replace(/\D/g, '');
            if (ele.length === 9) {
                var cleaned = ('' + ele).replace(/\D/g, '');
                var match = cleaned.match(/^(\d{3})(\d{2})(\d{4})$/);
                if (match) {
                    // console.log(match)
                    setValue({
                        ...value,
                        [e.target.name]: match[1] + '-' + match[2] + '-' + match[3]
                    })
                }
            } else {
                ele = e.target.value.split('-').join('').replace(/\D/g, '');
                setValue({
                    ...value,
                    [e.target.name]: ele
                })
            } if (e.target.name === 'SSN') {
                return 'true';
            } if (e.target.name.length === 11) {
                return 'true'
            }
        }
        else {
            setValue({
                ...value,
                [e.target.name]: e.target.value
            })
        }
    }


    // const onChangeNameIDNum = (e) => {
    //     if (e) {
    //         if (e.target.name === 'NameIDNumber') {
    //             let ele = e.target.value.replace(/[^a-zA-Z0-9\s]/g, '');
    //             if (ele.length === 10) {
    //                 const cleaned = ('' + ele).replace(/[^a-zA-Z0-9\s]/g, '');
    //                 // const match = cleaned.match(/^(\w{1})(\d{9})$/);
    //                 const match = cleaned.match(/^([AJ]{1})(\d{9})$/);
    //                 // console.log(match)
    //                 if (match) {
    //                     setValue({
    //                         ...value,
    //                         [e.target.name]: match[1] + '-' + match[2]
    //                     })
    //                 }
    //             } else {
    //                 ele = e.target.value.split("'").join('').replace(/[^a-zA-Z0-9\s]/g, '');
    //                 setValue({
    //                     ...value,
    //                     [e.target.name]: ele
    //                 })
    //             }
    //         } else {
    //             setValue({
    //                 ...value,
    //                 [e.target.name]: e.target.value
    //             })
    //         }
    //     } else {
    //         setValue({
    //             ...value,
    //             [e.target.name]: e.target.value
    //         })
    //     }
    // }

    const onChangeNameIDNum = (e) => {
        if (e) {
            if (e.target.name === 'NameIDNumber') {
                let ele = e.target.value.replace(/[^a-zA-Z0-9]/g, '');
                if (ele.length <= 11) {
                    const alphabet = ele[0]?.toUpperCase() || '';
                    const digits = ele.slice(1).replace(/[^0-9]/g, '');
                    // console.log(match)
                    if (digits.length === 9) {
                        setValue({
                            ...value,
                            [e.target.name]: alphabet + '-' + digits
                        });
                    } else {
                        setValue({
                            ...value,
                            [e.target.name]: alphabet + digits
                        });
                    }
                } else {
                    ele = e.target.value.split("'").join('').replace(/[^a-zA-Z0-9\s]/g, '');
                    setValue({
                        ...value,
                        [e.target.name]: ele
                    })
                }
            } else {
                setValue({
                    ...value,
                    [e.target.name]: e.target.value
                })
            }
        } else {
            setValue({
                ...value,
                [e.target.name]: e.target.value
            })
        }
    }



    const ChangeDropDown = (e, name) => {
        if (e) { setValue({ ...value, [name]: e.value, }); }
        else { setValue({ ...value, [name]: null, }); }
    }

    const resetFields = () => {
        setValue({
            ...value,
            'NameIDNumber': '', 'SSN': '', 'LastName': '', 'DLNumber': '', 'FirstName': '', 'MiddleName': '', 'DateOfBirth': '', 'SexID': null, 'RaceID': null, 'EthnicityID': null, 'IsUSCitizen': '', 'Address': '', 'IsVerify': '',
        });
        setverifyName(false); setNameReportData([]);
    }


    const componentRef = useRef();
    const printForm = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'Data',
        onAfterPrint: () => { '' }
    })

    const getAgencyImg = (LoginAgencyID) => {
        const val = { 'AgencyID': LoginAgencyID }
        fetchPostData('Agency/GetDataAgencyPhoto', val).then((res) => {
            if (res) {
                console.log(res)
                let imgUrl = `data:image/png;base64,${res[0]?.Agency_Photo}`;
                setMultiImage(imgUrl);

            }
            else { console.log("errror") }
        })
    }

    const get_Name_MultiImage = (nameID, masterNameID) => {
        const val = { 'NameID': nameID, 'MasterNameID': masterNameID, }
        const val1 = { 'NameID': 0, 'MasterNameID': masterNameID, }
        fetchPostData('MasterName/GetData_MasterNamePhoto', val)
            .then((res) => {
                if (res) {
                    console.log(res)
                    setNameMultiImg(res);
                }
                else { setNameMultiImg(); }
            })
    }

    return (
        <>
            <div class="section-body view_page_design pt-3">
                <div className="row clearfix">
                    <div className="col-12 col-sm-12">
                        <div className="card Agency">
                            <div className="card-body">
                                <fieldset>
                                    <legend>Name Master Report</legend>
                                    <div className="row mt-1">
                                        <div className="col-3 col-md-3 col-lg-1 mt-2 ">
                                            <label htmlFor="" className='new-label'>MNI</label>
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-3 text-field mt-1 ">
                                            <input type="text" name='NameIDNumber' maxLength={11} value={value?.NameIDNumber} onChange={onChangeNameIDNum} id='NameIDNumber' className='' />
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-1 mt-2 ">
                                            <label htmlFor="" className='new-label'>SSN</label>
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-3 text-field mt-1 ">
                                            <input type="text" name='SSN' value={value?.SSN} onChange={handlChange} id='SSN' maxLength={9} className='' />
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-1  mt-2 px-0">
                                            <label htmlFor="" className='new-label px-0'>DL&nbsp;Number</label>
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-3 text-field mt-1 ">
                                            <input type="text" name='DLNumber' value={value?.DLNumber} onChange={handlChange} id='DLNumber' className='' />
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-1 mt-2 ">
                                            <label htmlFor="" className='new-label'>Last Name</label>
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-3 text-field mt-1 ">
                                            <input type="text" name='LastName' value={value?.LastName} onChange={handlChange} id='LastName' className='' />
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-1 mt-2 ">
                                            <label htmlFor="" className='new-label'>First Name</label>
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-3 text-field mt-1 ">
                                            <input type="text" name='FirstName' value={value?.FirstName} onChange={handlChange} id='FirstName' className='' />
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-1 mt-2 px-0">
                                            <label htmlFor="" className='new-label px-0'>Middle&nbsp;Name</label>
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-3 text-field mt-1 ">
                                            <input type="text" name='MiddleName' value={value?.MiddleName} onChange={handlChange} id='MiddleName' className='' />
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-1 mt-2 ">
                                            <label htmlFor="" className='new-label'>Gender</label>
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-3  mt-1 ">
                                            <Select
                                                name='SexID'
                                                value={sexIdDrp?.filter((obj) => obj.value === value?.SexID)}
                                                options={sexIdDrp}
                                                onChange={(e) => ChangeDropDown(e, 'SexID')}
                                                isClearable
                                                placeholder="Select..."
                                                styles={customStylesWithOutColor}
                                            />
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-1 mt-2 ">
                                            <label htmlFor="" className='new-label'>Race</label>
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-3  mt-1 ">
                                            <Select
                                                name='RaceID'
                                                value={raceIdDrp?.filter((obj) => obj.value === value?.RaceID)}
                                                options={raceIdDrp}
                                                onChange={(e) => ChangeDropDown(e, 'RaceID')}
                                                isClearable
                                                placeholder="Select..."
                                                styles={customStylesWithOutColor}
                                            />
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-1 mt-2 ">
                                            <label htmlFor="" className='new-label'>Ethnicity</label>
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-3  mt-1 ">
                                            <Select
                                                name='EthnicityID'
                                                value={ethinicityDrpData?.filter((obj) => obj.value === value?.EthnicityID)}
                                                options={ethinicityDrpData}
                                                onChange={(e) => ChangeDropDown(e, 'EthnicityID')}
                                                isClearable
                                                placeholder="Select..."
                                                styles={customStylesWithOutColor}
                                            />
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-1 mt-2 ">
                                            <label htmlFor="" className='new-label'>DOB</label>
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-3  ">
                                            <DatePicker
                                                id='DateOfBirth'
                                                name='DateOfBirth'
                                                dateFormat="MM/dd/yyyy"
                                                onChange={(date) => setValue({ ...value, ['DateOfBirth']: date ? getShowingWithOutTime(date) : "" })}
                                                isClearable={value.DateOfBirth ? true : false}
                                                selected={value?.DateOfBirth && new Date(value.DateOfBirth)}
                                                placeholderText={'Select...'}
                                                peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                // dropdownMode="select"
                                                autoComplete='Off'
                                                maxDate={new Date()}
                                            />
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-1 mt-2 ">
                                            <label htmlFor="" className='new-label'>Address</label>
                                        </div>
                                        <div className="col-9 col-md-9 col-lg-7 text-field mt-2 ">
                                            <input type="text" name='Address' value={value?.Address} onChange={handlChange} id='Address' className='' />
                                        </div>
                                    </div>
                                </fieldset>
                                <div className="row">
                                    <div className="col-12 col-md-12 col-lg-12 text-right mt-3">
                                        <button className="btn btn-sm bg-green text-white px-2 py-1" onClick={() => { get_MasterName_Report(); }}>Show Report</button>
                                        <button className="btn btn-sm bg-green text-white px-2 py-1 ml-2" onClick={() => { resetFields(); }}>Clear</button>
                                        <Link to={'/Reports'}>
                                            <button className="btn btn-sm bg-green text-white px-2 py-1 ml-2" >Close</button>
                                        </Link>
                                        {/* <Link to={`${nameReportData.length > 0 ? '/nametab' : '/name-reports'}`}>
                                            <button className="btn btn-sm bg-green text-white px-2 py-1 ml-2" >Close</button>
                                        </Link> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* for 1 table */}
            {
                verifyName ?
                    <>
                        <div className="col-12 col-md-12 col-lg-12 pt-2  px-2">
                            <div className="bg-line  py-1 px-2 mt-1 d-flex justify-content-between align-items-center">
                                <p className="p-0 m-0 d-flex align-items-center">Name Master Report</p>
                                <div style={{ marginLeft: 'auto' }}>
                                    <Link to={''} className="btn btn-sm bg-green  mr-2 text-white px-2 py-0"  >
                                        <i className="fa fa-print" onClick={() => { printForm() }}></i>
                                    </Link>
                                    {/* <Link to={''} className="btn btn-sm bg-green  text-white px-2 py-0"  >
                                        <i className="fa fa-file"></i>
                                    </Link> */}
                                </div>
                            </div>
                        </div>
                        <div className="container mt-1" ref={componentRef}>
                            <div className="row" style={{ border: '1px solid #80808085' }}>
                                {
                                    reportData?.length > 0 ?
                                        <>
                                            <div className="col-4 col-md-3 col-lg-2 pt-1">
                                                <div className="main">
                                                    <div className="img-box" >
                                                        <img src={multiImage} className='picture' style={{ marginTop: '4px', width: '150px', height: '150px' }} />
                                                    </div>
                                                    {/* <img src={nameReportData?.Agency_Photo} className="img-fluid" alt style={{ border: '1px solid aliceblue', marginTop: '4px', width: '150px' }} /> */}
                                                </div>
                                            </div>
                                            <div className="col-7  col-md-7 col-lg-9 mt-2">
                                                <div className="main">
                                                    <h5 className='text-dark text-bold'>{nameReportData?.Agency_Name}</h5>
                                                    <p className='text-p'>Address: <span className='new-span pl-3'>{nameReportData?.Agency_Address1}</span></p>
                                                    <div className='d-flex '>
                                                        <p className='text-p'>State: <span className='new-span ml-3'>{nameReportData?.StateName}</span>
                                                        </p>
                                                        <p className='text-p ml-5 pl-1'>City: <span className='new-span'>{nameReportData?.CityName}</span>
                                                        </p>
                                                        <p className='text-p ml-2'>Zip: <span className='new-span'>{nameReportData?.ZipId}</span>
                                                        </p>
                                                    </div>
                                                    <div className='d-flex'>
                                                        <p className='text-p'>Phone: <span className='new-span'>{nameReportData?.Agency_Phone}</span></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className="col-3 ">
                                                <div className="main">
                                                    {/* <img src={img} className="img-fluid" alt /> */}
                                                    <img src={multiImage} className='picture' style={{ marginTop: '4px', width: '150px' }} />
                                                </div>
                                            </div>
                                            <div className="col-8 mt-4">
                                                <div className="main">
                                                    <h5 className='text-dark text-bold'>{"Agency_Name"}</h5>
                                                    <p>{"Agency_Address"}</p>
                                                    <p>{"Agency_Phone"}</p>
                                                </div>
                                            </div>
                                        </>
                                }
                                <div className="col-12">
                                    <hr style={{ border: '1px solid rgb(3, 105, 184)' }} />
                                </div>
                                <div className="col-12">

                                    <fieldset>
                                        <legend>Search Criteria</legend>

                                        <div className="row">
                                            {showFields.showNameIDNumber && (
                                                <>
                                                    <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                        <label className='new-label'>MNI</label>
                                                    </div>
                                                    <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                        <input type="text" className='readonlyColor' value={searchValue.NameIDNumber || ''} readOnly />
                                                    </div>
                                                </>
                                            )}

                                            {showFields.showSSN && (
                                                <>
                                                    <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                        <label className='new-label'>SSN</label>
                                                    </div>
                                                    <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                        <input type="text" className='readonlyColor' value={searchValue.SSN || ''} readOnly />
                                                    </div>
                                                </>
                                            )}
                                            {showFields.showLastName && (
                                                <>
                                                    <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                        <label className='new-label'>Last Name</label>
                                                    </div>
                                                    <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                        <input type="text" className='readonlyColor' value={searchValue.LastName || ''} readOnly />
                                                    </div>
                                                </>
                                            )}
                                            {showFields.showFirstName && (
                                                <>
                                                    <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                        <label className='new-label'>First Name</label>
                                                    </div>
                                                    <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                        <input type="text" className='readonlyColor' value={searchValue.FirstName || ''} readOnly />
                                                    </div>
                                                </>
                                            )}
                                            {showFields.showMiddleName && (
                                                <>
                                                    <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                        <label className='new-label'>Middle Name</label>
                                                    </div>
                                                    <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                        <input type="text" className='readonlyColor' value={searchValue.MiddleName || ''} readOnly />
                                                    </div>
                                                </>
                                            )}
                                            {showFields.showDateOfBirth && (
                                                <>
                                                    <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                        <label className='new-label'>DOB</label>
                                                    </div>
                                                    <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                        <input type="text" className='readonlyColor' value={searchValue.DateOfBirth || ''} readOnly />
                                                    </div>
                                                </>
                                            )}

                                            {showFields.showSexID && (
                                                <>
                                                    <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                        <label className='new-label'>Gender</label>
                                                    </div>
                                                    <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                        <input type="text" className='readonlyColor' value={sexIdDrp.find((obj) => obj.value === searchValue.SexID)?.label || ''} readOnly />
                                                    </div>
                                                </>
                                            )}
                                            {showFields.showRaceID && (
                                                <>
                                                    <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                        <label className='new-label'>Race</label>
                                                    </div>
                                                    <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                        <input type="text" className='readonlyColor' value={raceIdDrp.find((obj) => obj.value === searchValue.RaceID)?.label || ''} readOnly />
                                                    </div>
                                                </>
                                            )}
                                            {showFields.showEthnicityID && (
                                                <>
                                                    <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                        <label className='new-label'>Ethnicity</label>
                                                    </div>
                                                    <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                        <input type="text" className='readonlyColor' value={ethinicityDrpData.find((obj) => obj.value === searchValue.EthnicityID)?.label || ''} readOnly />
                                                    </div>
                                                </>
                                            )}
                                            {showFields.showAddress && (
                                                <>
                                                    <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                        <label className='new-label'>Address</label>
                                                    </div>
                                                    <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                        <input type="text" className='readonlyColor' value={searchValue.Address || ''} readOnly />
                                                    </div>
                                                </>
                                            )}
                                            {showFields.showDLNumber && (
                                                <>
                                                    <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                        <label className='new-label'>DL Number</label>
                                                    </div>
                                                    <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                        <input type="text" className='readonlyColor' value={searchValue.DLNumber || ''} readOnly />
                                                    </div>
                                                </>
                                            )}

                                        </div>
                                    </fieldset>
                                </div>
                                {
                                    nameReportData?.Name?.length > 0 ?
                                        <>
                                            {
                                                nameReportData?.Name?.map((obj) => (
                                                    <>
                                                        <div className="container">
                                                            <div className="col-12">
                                                                <div className="table-responsive" >
                                                                    <h5 className=" text-white text-bold bg-green py-1 px-3">Name Information:</h5>
                                                                    <table className="table table-bordered">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>
                                                                                    <h6 className='text-dark text-bold'>Name ID:</h6>
                                                                                    <p>{obj?.NameIDNumber}</p>
                                                                                </td>
                                                                                <td colSpan={2}>
                                                                                    <h6 className='text-dark text-bold'>Name:</h6>
                                                                                    <p>{obj?.FullName}</p>
                                                                                </td>
                                                                                <td >
                                                                                    <h6 className='text-dark text-bold'>Birth Date</h6>
                                                                                    <p>{obj?.DateOfBirth ? getShowingWithOutTime(obj?.DateOfBirth) : ''}</p>
                                                                                </td>
                                                                                <td colSpan={2}>
                                                                                    <h6 className='text-dark text-bold'>Age</h6>
                                                                                    <p>{obj?.AgeFrom}</p>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td colSpan={2}>
                                                                                    <h6 className='text-dark text-bold'>Address:</h6>
                                                                                    <p>{obj?.Address}</p>
                                                                                </td>
                                                                                <td>
                                                                                    <h6 className='text-dark text-bold'>Race:</h6>
                                                                                    <p>{obj?.Race_Description}</p>
                                                                                </td>
                                                                                <td>
                                                                                    <h6 className='text-dark text-bold'>Gender:</h6>
                                                                                    <p>{obj?.Gender}</p>
                                                                                </td>
                                                                                <td >
                                                                                    <h6 className='text-dark text-bold'>Height</h6>
                                                                                    <p>{obj?.HeightFrom}</p>
                                                                                </td>
                                                                                <td colSpan={2}>
                                                                                    <h6 className='text-dark text-bold'>Weight</h6>
                                                                                    {/* <p>{obj?.WeightFrom}</p> */}
                                                                                    {/* <p>{`${obj?.WeightFrom}-${obj?.WeightTo}`}</p> */}
                                                                                    <p>
                                                                                        {obj?.WeightFrom && obj?.WeightTo
                                                                                            ? `${obj.WeightFrom}-${obj.WeightTo}`
                                                                                            : ''}
                                                                                    </p>

                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td colSpan={2}>
                                                                                    <h6 className='text-dark text-bold'>SSN</h6>
                                                                                    <p>{obj?.SSN}</p>
                                                                                </td>
                                                                                <td>
                                                                                    <h6 className='text-dark text-bold'>Eye Color</h6>
                                                                                    <p>{obj?.EyeColor_Description}</p>

                                                                                </td>
                                                                                <td >
                                                                                    <h6 className='text-dark text-bold'>Hair Color</h6>
                                                                                    <p>{obj?.HairColor_Description}</p>

                                                                                </td>

                                                                                <td colSpan={2}>
                                                                                    <h6 className='text-dark text-bold'>Ethnicity</h6>
                                                                                    <p>{obj?.Ethnicity_Description}</p>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td colSpan={2}>
                                                                                    <h6 className='text-dark text-bold'>DL Number</h6>
                                                                                    <p>{obj?.DLNumber}</p>

                                                                                </td>
                                                                                <td >
                                                                                    <h6 className='text-dark text-bold'>Marital Status</h6>
                                                                                    <p>{obj?.MaritalStatus_Description}</p>

                                                                                </td>
                                                                                <td >
                                                                                    <h6 className='text-dark text-bold'>Birth Place</h6>
                                                                                    <p>{obj?.BirthPlace}</p>

                                                                                </td>
                                                                                <td colSpan={2}>
                                                                                    <h6 className='text-dark text-bold'> Nationality</h6>
                                                                                    <p>{obj?.BINationality}</p>
                                                                                </td>

                                                                                {/* <td >
                                                            <h6 className='text-dark text-bold'>SMT</h6>
                                                            <p>{obj?.SMT}</p>

                                                        </td> */}
                                                                            </tr>
                                                                            {/* <tr>
                                                                                <td >
                                                                                    <h6 className='text-dark text-bold'>Reason Code</h6>
                                                                                    <p>{obj?.NameReasonCode_Description}</p>
                                                                                </td>
                                                                            </tr> */}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                {/* smt */}
                                                                <div className="table-responsive" >
                                                                    {
                                                                        JSON.parse(obj?.SMT).length > 0 ?
                                                                            <>
                                                                                <h5 className="text-white text-bold bg-green py-1 px-3" >SMT Information:</h5>
                                                                                <table className="table table-bordered">
                                                                                    <thead className='text-dark master-table'>
                                                                                        <tr>
                                                                                            <th className=''>SMT Location</th>
                                                                                            <th className=''>SMT Type</th>
                                                                                            <th className=''>SMT Description</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody className='master-tbody'>
                                                                                        {
                                                                                            JSON.parse(obj?.SMT)?.map((item, key) => (
                                                                                                <>
                                                                                                    <tr key={key} style={{ borderBottom: '0.2px solid gray' }}>
                                                                                                        <td>{item.SMTLocation_Description}</td>
                                                                                                        <td>{item.SMTType_Decription}</td>
                                                                                                        <td>{item.SMT_Description}</td>
                                                                                                    </tr>
                                                                                                </>
                                                                                            ))
                                                                                        }
                                                                                    </tbody>
                                                                                </table>
                                                                            </>
                                                                            :
                                                                            <></>
                                                                    }
                                                                </div>
                                                                <div className="table-responsive" >
                                                                    {
                                                                        JSON.parse(obj?.Aliases)?.length > 0 ?
                                                                            <>
                                                                                <h5 className="text-white text-bold bg-green py-1 px-3">Alias Name Information:</h5>
                                                                                <table className="table table-bordered" >
                                                                                    <thead className='text-dark master-table'>

                                                                                        <tr>
                                                                                            <th className=''>Alias Name:</th>
                                                                                            <th className=''>Alias DOB:</th>
                                                                                            <th className=''>Alias SSN</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody className='master-tbody'>
                                                                                        {
                                                                                            JSON.parse(obj?.Aliases)?.map((item, key) => (
                                                                                                <>
                                                                                                    <tr key={key} >
                                                                                                        <td>{item.FullName}</td>
                                                                                                        {/* <td>{getShowingWithOutTime(item.DOB)}</td> */}
                                                                                                        <td>{item.DOB ? getShowingWithOutTime(item.DOB) : ''}</td>
                                                                                                        <td>{item.AliasSSN}</td>
                                                                                                    </tr>
                                                                                                </>
                                                                                            ))
                                                                                        }
                                                                                    </tbody>
                                                                                </table>
                                                                            </>
                                                                            :
                                                                            <></>
                                                                    }
                                                                </div>
                                                                {/* <div className="table-responsive mt-3">
                                                                    {
                                                                        JSON.parse(obj?.Photo)?.length > 0 ?
                                                                            <>
                                                                                <table className="table table-bordered" >
                                                                                    <tbody className=''>
                                                                                        <div className="row">
                                                                                            {
                                                                                                
                                                                                                JSON.parse(obj?.Photo)?.map((item, index) => {
                                                                                                    <>
                                                                                                    <h5 className="text-white text-bold bg-green py-1 px-3">Name Image:</h5>
                                                                                                        <div className="col-1">
                                                                                                            <div className="main" key={index}>
                                                                                                                <img src={item.Photo} />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </>
                                                                                                })
                                                                                                 
                                                                                            }
                                                                                        </div>
                                                                                    </tbody>
                                                                                </table>
                                                                            </>
                                                                            :
                                                                            <></>
                                                                    }
                                                                </div> */}
                                                                {/* //----------------------------Activity Informatio------------------------------ */}
                                                                <div className="table-responsive" >
                                                                    {
                                                                        JSON.parse(obj?.Incident)?.length > 0 ?
                                                                            <>
                                                                                <h5 className="text-white text-bold bg-green py-1 px-3">Activity Information:</h5>
                                                                                <table className="table table-bordered" >
                                                                                    <thead className='text-dark master-table'>
                                                                                        <tr>
                                                                                            <th className=''>Incident Number:</th>
                                                                                            <th className=''>Reported Date:</th>
                                                                                            <th className=''>CAD/CFS:</th>
                                                                                            <th className=''>RMS/CFS:</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody className='master-tbody'>
                                                                                        {
                                                                                            JSON.parse(obj?.Incident)?.map((item, key) => (
                                                                                                <>
                                                                                                    <tr key={key} >
                                                                                                        <td>{item.IncidentNumber}</td>
                                                                                                        <td>{item.ReportedDate ? getShowingWithOutTime(item.ReportedDate) : ''}</td>
                                                                                                        <td>{item.CADCFSCode_Description}</td>
                                                                                                        <td>{item.RMSCFSCode_Description}</td>
                                                                                                    </tr>
                                                                                                </>
                                                                                            ))
                                                                                        }
                                                                                    </tbody>
                                                                                </table>
                                                                            </>
                                                                            :
                                                                            <></>
                                                                    }
                                                                </div>
                                                                {/* //---------------------------------------Property Information----------------------------------------- */}

                                                                <div className="table-responsive" >
                                                                    {
                                                                        JSON.parse(obj?.Property)?.length > 0 ?
                                                                            <>
                                                                                <h5 className="text-white text-bold bg-green py-1 px-3">Property Information:</h5>
                                                                                <table className="table table-bordered" >
                                                                                    <thead className='text-dark master-table'>
                                                                                        <tr>
                                                                                            <th className=''>Property Number:</th>
                                                                                            <th className=''>Value</th>
                                                                                            <th className=''>Type:</th>
                                                                                            <th className=''>Classification</th>
                                                                                            <th className=''>Reason</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody className='master-tbody'>
                                                                                        {
                                                                                            JSON.parse(obj?.Property)?.map((item, key) => (
                                                                                                <>
                                                                                                    <tr key={key} style={{ borderBottom: '0.2px solid gray' }}>
                                                                                                        <td>{item.PropertyNumber}</td>
                                                                                                        <td>{item.Value}</td>
                                                                                                        <td>{item.PropertyType_Description}</td>
                                                                                                        <td>{item.PropertyCategory_Description}</td>
                                                                                                        <td>{item.PropertyLossCode_Description}</td>
                                                                                                    </tr>
                                                                                                </>
                                                                                            ))
                                                                                        }
                                                                                    </tbody>
                                                                                </table>
                                                                            </>
                                                                            :
                                                                            <></>
                                                                    }
                                                                </div>

                                                                {/* ----------------------Arrest Information---------------------- */}
                                                                <div className="table-responsive" >
                                                                    {
                                                                        JSON.parse(obj?.Arrest)?.length > 0 ?
                                                                            <>
                                                                                <h5 className="text-white text-bold bg-green py-1 px-3">Arrest Information:</h5>
                                                                                <table className="table table-bordered" >
                                                                                    <thead className='text-dark master-table'>
                                                                                        <tr>
                                                                                            <th className=''>Arrest Number:</th>
                                                                                            <th className=''>Arrest Date:</th>
                                                                                            <th className=''>Charge Code/Desc</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody className='master-tbody'>
                                                                                        {
                                                                                            JSON.parse(obj?.Arrest)?.map((item, key) => (
                                                                                                <>
                                                                                                    <tr key={key} style={{ borderBottom: '0.2px solid gray' }}>
                                                                                                        <td>{item.ArrestNumber}</td>
                                                                                                        <td>{getShowingWithOutTime(item.ArrestDtTm)}</td>
                                                                                                        <td>{item.FirstName}</td>
                                                                                                    </tr>
                                                                                                </>
                                                                                            ))
                                                                                        }
                                                                                    </tbody>
                                                                                </table>
                                                                            </>
                                                                            :
                                                                            <></>
                                                                    }
                                                                </div>

                                                                {/* //----------------------------Transaction Informatio------------------------ */}
                                                                <div className="table-responsive" >
                                                                    {
                                                                        JSON.parse(obj?.Transaction)?.length > 0 ?
                                                                            <>
                                                                                <h5 className="text-white text-bold bg-green py-1 px-3">Transaction Information:</h5>
                                                                                <table className="table table-bordered" >
                                                                                    <thead className='text-dark master-table'>
                                                                                        <tr>
                                                                                            <th className=''>TransactionNumber:</th>
                                                                                            <th className=''>TransactionName:</th>
                                                                                            <th className=''>Date of Birth</th>
                                                                                            <th className=''>Age</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody className='master-tbody'>
                                                                                        {
                                                                                            JSON.parse(obj?.Transaction)?.map((item, key) => (
                                                                                                <>
                                                                                                    <tr key={key} style={{ borderBottom: '0.2px solid gray' }}>
                                                                                                        <td>{item.TransactionNumber}</td>
                                                                                                        <td>{item.TransactionName}</td>
                                                                                                        <td>{getShowingWithOutTime(item.DateofBirth)}</td>
                                                                                                        <td>{item.Age}</td>
                                                                                                    </tr>
                                                                                                </>
                                                                                            ))
                                                                                        }
                                                                                    </tbody>
                                                                                </table>
                                                                            </>
                                                                            :
                                                                            <></>
                                                                    }
                                                                </div>
                                                                {/* Name History */}
                                                                <div className="table-responsive" >
                                                                    {
                                                                        JSON.parse(obj?.NameHistory)?.length > 0 ?
                                                                            <>
                                                                                <h5 className="text-white text-bold bg-green py-1 px-3">Name History Information:</h5>
                                                                                <table className="table table-bordered" >
                                                                                    <thead className='text-dark master-table'>
                                                                                        <tr>
                                                                                            <th className=''>Name:</th>
                                                                                            <th className=''>Date of Birth</th>
                                                                                            <th className=''>Age</th>
                                                                                            <th className=''>Height</th>
                                                                                            <th className=''>Weight</th>
                                                                                            <th className=''>Hair Color</th>
                                                                                            <th className=''>Eye Color</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody className='master-tbody'>
                                                                                        {
                                                                                            JSON.parse(obj?.NameHistory)?.map((item, key) => (
                                                                                                <>
                                                                                                    <tr key={key} style={{ borderBottom: '0.2px solid gray' }}>
                                                                                                        <td>{item.FullName}</td>
                                                                                                        <td>{getShowingWithOutTime(item.DateofBirth)}</td>
                                                                                                        <td>{item['Age From']}</td>
                                                                                                        <td>{item['Height From']}</td>
                                                                                                        <td>{item['Weight From']}</td>
                                                                                                        <td>{item['Hair Color']}</td>
                                                                                                        <td>{item['Eye Color']}</td>
                                                                                                    </tr>
                                                                                                </>
                                                                                            ))
                                                                                        }
                                                                                    </tbody>
                                                                                </table>
                                                                            </>
                                                                            :
                                                                            <></>
                                                                    }
                                                                </div>
                                                                {/* <div className="table-responsive" >
                                                                    {
                                                                        JSON.parse(obj?.Warrant) ?
                                                                            <>
                                                                                <h5 className="text-white text-bold bg-green py-1 px-3">Warrant Information:</h5>
                                                                                <table className="table table-bordered" >
                                                                                    <thead className='text-dark master-table'>
                                                                                        <tr>
                                                                                            <th className=''>Warrant Number</th>
                                                                                            <th className=''>Warrant Type</th>
                                                                                            <th className=''>Warrant Classification</th>
                                                                                            <th className=''>Warrant Name</th>
                                                                                            <th className=''>Warrant Status</th>
                                                                                            <th className=''>Date Of Complain</th>
                                                                                            <th className=''>Date/Time Issued</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody className='master-tbody'>
                                                                                        {
                                                                                            JSON.parse(obj?.Warrant)?.map((item, key) => (
                                                                                                <>
                                                                                                    <tr key={key} style={{ borderBottom: '0.2px solid gray' }}>
                                                                                                        <td>{item?.WarrantNumber}</td>
                                                                                                        <td>{item?.WarrantType_Description}</td>
                                                                                                        <td>{item?.WarrantClassification_Description}</td>
                                                                                                        <td>{item?.WarrantName}</td>
                                                                                                        <td>{item?.WarrantStatus_Description}</td>
                                                                                                        <td>{item?.DateOfComplain && getShowingDateText(item?.DateOfComplain)}</td>
                                                                                                        <td>{item?.DateTimeIssued && getShowingDateText(item?.DateTimeIssued)}</td>

                                                                                                    </tr>
                                                                                                </>
                                                                                            ))
                                                                                        }
                                                                                    </tbody>
                                                                                </table>
                                                                            </>
                                                                            :
                                                                            <></>
                                                                    }
                                                                </div> */}
                                                                {/* <div className="table-responsive" >
                                                                    <h5 className="text-white text-bold bg-green py-1 px-3" >Name Image:
                                                                    </h5>
                                                                    <table className="table ">
                                                                        <tbody className=''>
                                                                            <div className="row">
                                                                                {
                                                                                    JSON.parse(obj?.Photo)?.length > 0 ?
                                                                                        JSON.parse(obj?.Photo)?.map((item, index) => {
                                                                                            return (
                                                                                                <div className="col-3">
                                                                                                    <div className="main" key={index}>
                                                                                                        <img src={`data:image/png;base64,${item.Photo}`} style={{ height: '105px' }} />
                                                                                                    </div>
                                                                                                </div>
                                                                                            )
                                                                                        })
                                                                                        :
                                                                                        <>
                                                                                        </>
                                                                                }
                                                                            </div>
                                                                        </tbody>
                                                                    </table>
                                                                </div> */}
                                                                {
                                                                    JSON.parse(obj?.Photo)?.length > 0 ?
                                                                        JSON.parse(obj?.Photo)?.map((item, index) => {
                                                                            return (
                                                                                <div className="table-responsive mt-3">
                                                                                    <div className="text-white text-bold bg-green py-1 px-2  d-flex justify-content-between align-items-center">
                                                                                        <p className="p-0 m-0 d-flex align-items-center">Name Image:</p>
                                                                                    </div>
                                                                                    <table className="table table-bordered" >
                                                                                        <tbody className=''>
                                                                                            <div className="row">
                                                                                                <div className="col-2">
                                                                                                    <div className="main" key={index}>
                                                                                                        <img src={item.Photo} style={{ height: '100px' }} />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            )
                                                                        })
                                                                        :
                                                                        <>
                                                                        </>
                                                                }

                                                            </div>
                                                        </div>
                                                    </>
                                                ))
                                            }
                                        </>
                                        :
                                        <>
                                        </>
                                }
                            </div>
                        </div>
                    </>
                    :
                    <>
                    </>
            }
        </>
    )
}

export default NameInformation
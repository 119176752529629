import React, { useContext, useEffect, useState } from 'react'
import Select from "react-select";
import DatePicker from "react-datepicker";
import { Decrypt_Id_Name, base64ToString, getShowingMonthDateYear } from '../../../../Common/Utility';
import { AddDeleteUpadate, ScreenPermision, fetchPostData } from '../../../../hooks/Api';
import { toastifySuccess } from '../../../../Common/AlertMsg';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { get_LocalStoreData } from '../../../../../redux/actions/Agency';
import { useLocation } from 'react-router-dom';
import { AgencyContext } from '../../../../../Context/Agency/Index';
import ChangesModal from '../../../../Common/ChangesModal';

const AgencySetting = () => {

    const dispatch = useDispatch();
    const localStoreData = useSelector((state) => state.Agency.localStoreData);
    const uniqueId = sessionStorage.getItem('UniqueUserID') ? Decrypt_Id_Name(sessionStorage.getItem('UniqueUserID'), 'UForUniqueUserID') : '';
    const [reportingTypeDrp, setReportingTypeDrp] = useState([{ value: 1, label: 'UCR' }, { value: 2, label: 'NIBRS' }]);
    const [editVal, setEditVal] = useState([])
    const [pinID, setPinId] = useState('');
    const [effectiveScreenPermission, setEffectiveScreenPermission] = useState([]);

    const { get_CountList, setChangesStatus, } = useContext(AgencyContext);
    const [statesChangeStatus, setStatesChangeStatus] = useState(false);

    const useQuery = () => {
        const params = new URLSearchParams(useLocation().search);
        return {
            get: (param) => params.get(param)
        };
    };

    const query = useQuery();
    var aId = query?.get("Aid");
    var aIdSta = query?.get("ASta");

    if (!aId) aId = 0;
    else aId = parseInt(base64ToString(aId));

    useEffect(() => {
        if (!localStoreData?.AgencyID || !localStoreData?.PINID) {
            if (uniqueId) dispatch(get_LocalStoreData(uniqueId));
        }
    }, []);

    useEffect(() => {
        if (localStoreData) {
            setPinId(localStoreData?.PINID); getScreenPermision(localStoreData?.AgencyID, localStoreData?.PINID);
        }
    }, [localStoreData]);

    useEffect(() => {
        if (pinID) {
            setValue({
                ...value,
                MaxAgeForJuvenile: "", ReportingTypeID: 1, BaseDate: "", ExpiryYear: "", SolvabilityRating: "", MaxAgeForStatutoryRape: "", MaxAgeForJuvenileByFederal_UCR_NIBRS: "",
                IsEnhancedNameIndex: "", IsAliasReqForConsolidating: "", IsRMSCFSCode: "", IsReqHeightWeightArrest: "", IsEnableMasterIncident: '',
                ModifiedByUserFK: pinID,
                AgencyID: aId,
            });
        }
    }, [pinID]);

    useEffect(() => {
        if (aId) {
            getAgencySettingData(aId);
        }
    }, [aId]);

    const [value, setValue] = useState({
        MaxAgeForJuvenile: "", ReportingTypeID: 1, BaseDate: "", ExpiryYear: "", SolvabilityRating: "", MaxAgeForStatutoryRape: "", MaxAgeForJuvenileByFederal_UCR_NIBRS: "",
        IsEnhancedNameIndex: "", IsAliasReqForConsolidating: "", IsRMSCFSCode: "", IsReqHeightWeightArrest: "", IsEnableMasterIncident: '',
        ModifiedByUserFK: pinID,
        AgencyID: aId,
    });


    // Get Effective Screeen Permission
    const getScreenPermision = (aId, pinID) => {
        ScreenPermision("A087", aId, pinID).then(res => {
            if (res) setEffectiveScreenPermission(res)
            else setEffectiveScreenPermission()
        });
    }


    const getAgencySettingData = (aId) => {
        fetchPostData('Agency/GetData_SingleData', { 'AgencyID': aId }).then((res) => {
            setEditVal(res)
        })
    }

    useEffect(() => {
        if (aId && editVal?.length > 0) {
            setValue({
                ...value,
                MaxAgeForJuvenile: editVal[0]?.MaxAgeForJuvenile, ReportingTypeID: editVal[0]?.ReportingTypeID, BaseDate: editVal[0]?.BaseDate, ExpiryYear: editVal[0]?.ExpiryYear, SolvabilityRating: editVal[0]?.SolvabilityRating, MaxAgeForStatutoryRape: editVal[0]?.MaxAgeforStatutoryRape, MaxAgeForJuvenileByFederal_UCR_NIBRS: editVal[0]?.MaxAgeForJuvenileByFederal_UCR_NIBRS,
                IsEnhancedNameIndex: editVal[0]?.IsEnhancedNameIndex, IsAliasReqForConsolidating: editVal[0]?.IsAliasReqForConsolidating, IsRMSCFSCode: editVal[0]?.IsRMSCFSCode, IsReqHeightWeightArrest: editVal[0]?.IsReqHeightWeightArrest,
                IsEnableMasterIncident: editVal[0]?.IsEnableMasterIncident,
            });
        } else {
            setValue({
                ...value,
                MaxAgeForJuvenile: "", ReportingTypeID: "", BaseDate: "", ExpiryYear: "", SolvabilityRating: "", MaxAgeForStatutoryRape: "", MaxAgeForJuvenileByFederal_UCR_NIBRS: "",
                IsEnhancedNameIndex: "", IsAliasReqForConsolidating: "", IsRMSCFSCode: "", IsReqHeightWeightArrest: "", IsEnableMasterIncident: '',
            })
        }
    }, [editVal]);

    const updateAgencySetting = () => {
        AddDeleteUpadate('Agency/AgencyDetails', value).then((res) => {
            if (res?.success) {
                const parsedData = JSON.parse(res.data);
                const message = parsedData.Table[0].Message;
                toastifySuccess(message); setChangesStatus(false); setStatesChangeStatus(false)
                // toastifySuccess(res?.Message);
                getAgencySettingData(aId)
            } else {
                console.log("Api Throw Error")
            }
        })
    }

    const ChangeDropDown = (e, name) => {
        setStatesChangeStatus(true)
        if (e) {
            setChangesStatus(true)
            setValue({
                ...value,
                [name]: e.value
            })
        } else {
            setChangesStatus(true)
            setValue({
                ...value,
                [name]: null
            })
        }
    }

    const HandleChanges = (e) => {
        setStatesChangeStatus(true)
        if (e) {
            if (e.target.name === "IsEnhancedNameIndex" || e.target.name === "IsAliasReqForConsolidating" || e.target.name === "IsRMSCFSCode" || e.target.name === "IsReqHeightWeightArrest" || e.target.name === "IsEnableMasterIncident") {
                setChangesStatus(true)
                setValue({
                    ...value,
                    [e.target.name]: e.target.checked
                })
            } else {
                setChangesStatus(true)
                setValue({
                    ...value,
                    [e.target.name]: e.target.value
                })
            }
        } else {
            setChangesStatus(true)
            setValue({
                ...value,
                [e.target.name]: " "
            })
        }
    }

    // custuom style withoutColor
    const customStylesWithOutColor = {
        control: base => ({
            ...base,
            height: 20,
            minHeight: 35,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
        }),
    };

    const startRef = React.useRef();
    const onKeyDown = (e) => {
        if (e.keyCode === 9 || e.which === 9) {
            startRef.current.setOpen(false);
        }
    };

    return (
        <>
            {
                effectiveScreenPermission ?
                    effectiveScreenPermission[0]?.DisplayOK ?
                        <>
                            <div className="col-12 col-md-12 " >
                                <div className="row ">
                                    <div className="col-4 col-md-4 col-lg-4 mt-2 pt-1">
                                        <label htmlFor="" className='new-label'>Maximum Age For Juvenile</label>
                                    </div>
                                    <div className="col-6 col-md-6 col-lg-2 mt-2 text-field">
                                        <input type="text" name='MaxAgeForJuvenile' value={value?.MaxAgeForJuvenile} onChange={HandleChanges} className='' maxLength={2} required />
                                    </div>
                                    <div className="col-4 col-md-4 col-lg-3 mt-2 pt-1">
                                        <label htmlFor="" className='new-label'>Reporting Type</label>
                                    </div>
                                    <div className="col-6 col-md-6 col-lg-3 mt-2 ">
                                        <Select
                                            styles={customStylesWithOutColor}
                                            value={reportingTypeDrp?.filter((obj) => obj.value === value?.ReportingTypeID)}
                                            options={reportingTypeDrp}
                                            onChange={(e) => ChangeDropDown(e, 'ReportingTypeID')}
                                            name="ReportingTypeID"
                                            isClearable
                                            placeholder="Select..."
                                        />
                                    </div>
                                    <div className="col-4 col-md-4 col-lg-4 mt-2 pt-1">
                                        <label htmlFor="" className='new-label'>Base Date</label>
                                    </div>
                                    <div className="col-6 col-md-6 col-lg-2 ">
                                        <DatePicker
                                            ref={startRef}
                                            onKeyDown={onKeyDown}
                                            id='BaseDate'
                                            name='BaseDate'
                                            className=''
                                            dateFormat="MM/dd/yyyy HH:mm"
                                            onChange={(date) => { setChangesStatus(true); setStatesChangeStatus(true); setValue({ ...value, ['BaseDate']: date ? getShowingMonthDateYear(date) : null }) }}
                                            selected={value?.BaseDate && new Date(value?.BaseDate)}
                                            timeInputLabel
                                            isClearable={value?.BaseDate ? true : false}
                                            placeholderText={'Select...'}
                                            showTimeSelect
                                            timeIntervals={1}
                                            timeCaption="Time"
                                            showYearDropdown
                                            showMonthDropdown
                                            dropdownMode="select"
                                        />
                                    </div>
                                    {/* <div className="col-4 col-md-4 col-lg-3 mt-2 pt-1">
                                        <label htmlFor="" className='new-label'>Expiry Year</label>
                                    </div>
                                    <div className="col-6 col-md-6 col-lg-3 mt-1 text-field">
                                        <input type="text" name='ExpiryYear' value={value?.ExpiryYear} onChange={HandleChanges} className='' maxLength={2} required />
                                    </div> */}
                                    {/* <div className="col-4 col-md-4 col-lg-4 mt-2 pt-1">
                                        <label htmlFor="" className='new-label'>Solvability Rating</label>
                                    </div>
                                    <div className="col-6 col-md-6 col-lg-2 mt-1 text-field">
                                        <input type="text" name='SolvabilityRating' value={value?.SolvabilityRating} onChange={HandleChanges} maxLength={3} required />
                                    </div> */}
                                    <div className="col-8 col-md-8 col-lg-3 mt-2 pt-1">
                                        <label htmlFor="" className='new-label'>Maximum Age For Statutory Rape</label>
                                    </div>
                                    <div className="col-4 col-md-4 col-lg-3 mt-1 text-field">
                                        <input type="text" name='MaxAgeForStatutoryRape' value={value?.MaxAgeForStatutoryRape} onChange={HandleChanges} maxLength={3} required />
                                    </div>
                                    <div className="col-8 col-md-8 col-lg-4 mt-2 pt-1">
                                        <label htmlFor="" className='new-label'>Maximum Age For Juvenile Set By Federal UCR/NIBRS</label>
                                    </div>
                                    <div className="col-4 col-md-4 col-lg-2 mt-1 text-field">
                                        <input type="text" name='MaxAgeForJuvenileByFederal_UCR_NIBRS' value={value?.MaxAgeForJuvenileByFederal_UCR_NIBRS} onChange={HandleChanges} maxLength={2} required />
                                    </div>
                                    <div className="col-12 col-md-12 col-lg-12">
                                        <div className="row">
                                            <div className="col-12 col-md-12 col-lg-12 ml-lg-5 pl-lg-5 ml-md-0 pl-md-0 mt-1">
                                                <input type="checkbox" name='IsEnhancedNameIndex' checked={value?.IsEnhancedNameIndex} value={value?.IsEnhancedNameIndex} onChange={HandleChanges} />
                                                <label className='ml-2' >Enhanced Name Index</label>
                                            </div>
                                            <div className="col-12 col-md-12 col-lg-12 mt-1 ml-lg-5 pl-lg-5 ml-md-0 pl-md-0">
                                                <input type="checkbox" name='IsAliasReqForConsolidating' checked={value?.IsAliasReqForConsolidating} value={value?.IsAliasReqForConsolidating} onChange={HandleChanges} />
                                                <label className='ml-2' >Alias Requried For Consolidating</label>
                                            </div>
                                            {/* <div className="col-12 col-md-12 col-lg-12 mt-1 ml-lg-5 pl-lg-5 ml-md-0 pl-md-0">
                                                <input type="checkbox" name='IsRMSCFSCode' checked={value?.IsRMSCFSCode} value={value?.IsRMSCFSCode} onChange={HandleChanges} />
                                                <label className='ml-2' >Default RMS CFS Code To Offense Code - Yes/No</label>
                                            </div>
                                            <div className="col-12 col-md-12 col-lg-12  mt-1 ml-lg-5 pl-lg-5 ml-md-0 pl-md-0">
                                                <input type="checkbox" name="IsReqHeightWeightArrest" checked={value?.IsReqHeightWeightArrest} value={value?.IsReqHeightWeightArrest} onChange={HandleChanges} />
                                                <label className='ml-2' >Require Height & Weight for an Arrest? Yes/No</label>
                                            </div>
                                            <div className="col-12 col-md-12 col-lg-12 mt-1 ml-lg-5 pl-lg-5 ml-md-0 pl-md-0">
                                                <input type="checkbox" name="IsEnableMasterIncident" checked={value?.IsEnableMasterIncident} value={value?.IsEnableMasterIncident} onChange={HandleChanges} />
                                                <label className='ml-2' >Enable Master Incident Field in Incident Screen for Input. Yes/No</label>
                                            </div> */}
                                        </div>
                                        <div className="col-12 text-right mt-2 p-0" style={{ position: 'fixed', bottom: '10px', right: '29px' }}>
                                            {
                                                effectiveScreenPermission ?
                                                    effectiveScreenPermission[0]?.Changeok ?
                                                        <button type="button" disabled={!statesChangeStatus} onClick={() => { updateAgencySetting() }} className="btn btn-sm btn-success">Update</button>
                                                        :
                                                        <>
                                                        </>
                                                    :
                                                    <button type="button" disabled={!statesChangeStatus} onClick={() => { updateAgencySetting() }} className="btn btn-sm btn-success">Update</button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ChangesModal func={updateAgencySetting} />

                        </>
                        :
                        <>
                            <p className='text-center mt-2'>You don’t have permission to view data</p>
                        </>
                    :
                    <>
                        <div className="col-12 col-md-12 " >
                            <div className="row ">
                                <div className="col-4 col-md-4 col-lg-4 mt-2 pt-1">
                                    <label htmlFor="" className='new-label'>Maximum Age For Juvenile</label>
                                </div>
                                <div className="col-6 col-md-6 col-lg-2 mt-2 text-field">
                                    <input type="text" name='MaxAgeForJuvenile' value={value?.MaxAgeForJuvenile} onChange={HandleChanges} className='' maxLength={2} required />
                                </div>
                                <div className="col-4 col-md-4 col-lg-3 mt-2 pt-1">
                                    <label htmlFor="" className='new-label'>Reporting Type</label>
                                </div>
                                <div className="col-6 col-md-6 col-lg-3 mt-2 ">
                                    <Select
                                        styles={customStylesWithOutColor}
                                        value={reportingTypeDrp?.filter((obj) => obj.value === value?.ReportingTypeID)}
                                        options={reportingTypeDrp}
                                        onChange={(e) => ChangeDropDown(e, 'ReportingTypeID')}
                                        name="ReportingTypeID"
                                        isClearable
                                        placeholder="Select..."
                                    />
                                </div>
                                <div className="col-4 col-md-4 col-lg-4 mt-2 pt-1">
                                    <label htmlFor="" className='new-label'>Base Date</label>
                                </div>
                                <div className="col-6 col-md-6 col-lg-2 ">
                                    <DatePicker
                                        ref={startRef}
                                        onKeyDown={onKeyDown}
                                        id='BaseDate'
                                        name='BaseDate'
                                        className=''
                                        dateFormat="MM/dd/yyyy HH:mm"
                                        onChange={(date) => { setChangesStatus(true); setValue({ ...value, ['BaseDate']: date ? getShowingMonthDateYear(date) : null }) }}
                                        selected={value?.BaseDate && new Date(value?.BaseDate)}
                                        timeInputLabel
                                        isClearable={value?.BaseDate ? true : false}
                                        placeholderText={'Select...'}
                                        showTimeSelect
                                        timeIntervals={1}
                                        timeCaption="Time"
                                        showYearDropdown
                                        showMonthDropdown
                                        dropdownMode="select"

                                    />
                                </div>
                                {/* <div className="col-4 col-md-4 col-lg-3 mt-2 pt-1">
                                    <label htmlFor="" className='new-label'>Expiry Year</label>
                                </div>
                                <div className="col-6 col-md-6 col-lg-3 mt-1 text-field">
                                    <input type="text" name='ExpiryYear' value={value?.ExpiryYear} onChange={HandleChanges} className='' maxLength={2} required />
                                </div>
                                <div className="col-4 col-md-4 col-lg-4 mt-2 pt-1">
                                    <label htmlFor="" className='new-label'>Solvability Rating</label>
                                </div> */}
                                <div className="col-6 col-md-6 col-lg-2 mt-1 text-field">
                                    <input type="text" name='SolvabilityRating' value={value?.SolvabilityRating} onChange={HandleChanges} maxLength={3} required />
                                </div>
                                <div className="col-8 col-md-8 col-lg-3 mt-2 pt-1">
                                    <label htmlFor="" className='new-label'>Maximum Age For Statutory Rape</label>
                                </div>
                                <div className="col-4 col-md-4 col-lg-3 mt-1 text-field">
                                    <input type="text" name='MaxAgeForStatutoryRape' value={value?.MaxAgeForStatutoryRape} onChange={HandleChanges} maxLength={3} required />
                                </div>
                                <div className="col-8 col-md-8 col-lg-4 mt-2 pt-1">
                                    <label htmlFor="" className='new-label'>Maximum Age For Juvenile Set By Federal UCR/NIBRS</label>
                                </div>
                                <div className="col-4 col-md-4 col-lg-2 mt-1 text-field">
                                    <input type="text" name='MaxAgeForJuvenileByFederal_UCR_NIBRS' value={value?.MaxAgeForJuvenileByFederal_UCR_NIBRS} onChange={HandleChanges} maxLength={2} required />
                                </div>
                                <div className="col-12 col-md-12 col-lg-12">
                                    <div className="row">
                                        <div className="col-12 col-md-12 col-lg-12 ml-lg-5 pl-lg-5 ml-md-0 pl-md-0 mt-1">
                                            <input type="checkbox" name='IsEnhancedNameIndex' checked={value?.IsEnhancedNameIndex} value={value?.IsEnhancedNameIndex} onChange={HandleChanges} />
                                            <label className='ml-2' >Enhanced Name Index</label>
                                        </div>
                                        <div className="col-12 col-md-12 col-lg-12 mt-1 ml-lg-5 pl-lg-5 ml-md-0 pl-md-0">
                                            <input type="checkbox" name='IsAliasReqForConsolidating' checked={value?.IsAliasReqForConsolidating} value={value?.IsAliasReqForConsolidating} onChange={HandleChanges} />
                                            <label className='ml-2' >Alias Requried For Consolidating</label>
                                        </div>
                                        {/* <div className="col-12 col-md-12 col-lg-12 mt-1 ml-lg-5 pl-lg-5 ml-md-0 pl-md-0">
                                            <input type="checkbox" name='IsRMSCFSCode' checked={value?.IsRMSCFSCode} value={value?.IsRMSCFSCode} />
                                            <label className='ml-2' >Default RMS CFS Code To Offense Code - Yes/No</label>
                                        </div>
                                        <div className="col-12 col-md-12 col-lg-12  mt-1 ml-lg-5 pl-lg-5 ml-md-0 pl-md-0">
                                            <input type="checkbox" name="IsReqHeightWeightArrest" checked={value?.IsReqHeightWeightArrest} value={value?.IsReqHeightWeightArrest} onChange={HandleChanges} />
                                            <label className='ml-2' >Require Height & Weight for an Arrest? Yes/No</label>
                                        </div>
                                        <div className="col-12 col-md-12 col-lg-12 mt-1 ml-lg-5 pl-lg-5 ml-md-0 pl-md-0">
                                            <input type="checkbox" name="IsEnableMasterIncident" />
                                            <label className='ml-2' >Enable Master Incident Field in Incident Screen for Input. Yes/No</label>
                                        </div> */}
                                    </div>
                                    <div className="col-12 text-right mt-2 p-0" style={{ position: 'fixed', bottom: '10px', right: '29px' }}>
                                        {
                                            effectiveScreenPermission ?
                                                effectiveScreenPermission[0]?.Changeok ?
                                                    <button type="button" disabled={!statesChangeStatus} onClick={() => { updateAgencySetting() }} className="btn btn-sm btn-success">Update</button>
                                                    :
                                                    <>
                                                    </>
                                                :
                                                <button type="button" disabled={!statesChangeStatus} onClick={() => { updateAgencySetting() }} className="btn btn-sm btn-success">Update</button>
                                        }
                                    </div>
                                </div>
                            </div>

                        </div>
                        <ChangesModal func={updateAgencySetting} />
                    </>
            }
        </>
    )
}

export default AgencySetting
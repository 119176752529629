import { toastifyError } from '../../Components/Common/AlertMsg';
import { Comman_changeArrayFormat } from '../../Components/Common/ChangeArrayFormat';
import { fetchPostData } from '../../Components/hooks/Api';
import { PropertyMainModule_Data, PropertySearch_Modal_Status, PropertySearch_Type, Property_Search_Data } from '../actionTypes';
import * as api from '../api'

function hasValues(obj) {
    for (let key in obj) {
        if (obj[key]) {
            return true;
        }
    }
    return false;
}

export const get_PropertyMainModule_Data = (IncidentID, IsMaster) => async (dispatch) => {
    const val = { 'IncidentID': IncidentID, 'IsMaster': IsMaster }
    fetchPostData('Property/GetData_Property', val).then((res) => {
        if (res) {
            dispatch({ type: PropertyMainModule_Data, payload: res });
        } else {
            dispatch({ type: PropertyMainModule_Data, payload: [] });
        }
    })
};

export const get_Property_Article_Search_Data = (SerialID, ModelID, Brand, LossCodeID, TopColorID, CategoryID, setSearchModalState, loginAgencyID) => async (dispatch) => {
    dispatch({ type: PropertySearch_Type, payload: 'Pro-Article' });
    const val = { 'SerialID': SerialID, 'ModelID': ModelID, 'Brand': Brand, 'LossCodeID': LossCodeID, 'TopColorID': TopColorID, 'CategoryID': CategoryID, 'SearchType': 'Article' }
    const isEmpty = hasValues(val)
    if (isEmpty) {
        fetchPostData('Property/Search_PropertyArticle', { 'SerialID': SerialID, 'ModelID': ModelID, 'Brand': Brand, 'LossCodeID': LossCodeID, 'TopColorID': TopColorID, 'CategoryID': CategoryID, 'AgencyID': loginAgencyID, }).then((res) => {
            if (res?.length > 0) {
                console.log(res);
                dispatch({ type: Property_Search_Data, payload: res });
                dispatch({ type: PropertySearch_Modal_Status, payload: true });
                setSearchModalState(true)
            } else {
                dispatch({ type: Property_Search_Data, payload: [] });
                toastifyError("Data Not Available")
            }
        })
    } else {
        // toastifyError("Empty Search")
        console.log("Search Empty")
    }
};
export const get_Property_Drug_Search_Data = ( LossCodeID, CategoryID, setSearchModalState, loginAgencyID) => async (dispatch) => {
    dispatch({ type: PropertySearch_Type, payload: 'Pro-Drug' });
    const val = { 'LossCodeID': LossCodeID,  'CategoryID': CategoryID, 'SearchType': 'Drug' }
    const isEmpty = hasValues(val)
    if (isEmpty) {
        fetchPostData('Property/Search_PropertyDrug', { 'LossCodeID': LossCodeID,   'CategoryID': CategoryID, 'AgencyID': loginAgencyID, }).then((res) => {
            if (res?.length > 0) {
                // console.log(res);
                dispatch({ type: Property_Search_Data, payload: res });
                dispatch({ type: PropertySearch_Modal_Status, payload: true });
                setSearchModalState(true)
            } else {
                dispatch({ type: Property_Search_Data, payload: [] });
                toastifyError("Data Not Available")
            }
        })
    } else {
        // toastifyError("Empty Search")
        console.log("Search Empty")
    }
};

export const get_Property_Security_Search_Data = (SerialID, IssuingAgency, SecurityDtTm, CategoryID, Denomination, setSearchModalState, loginAgencyID) => async (dispatch) => {
    dispatch({ type: PropertySearch_Type, payload: 'Pro-Security' });
    const val = { 'SerialID': SerialID, 'IssuingAgency': IssuingAgency, 'SecurityDtTm': SecurityDtTm, 'CategoryID': CategoryID, 'Denomination': Denomination, 'SearchType': 'Security' }
    const isEmpty = hasValues(val)
    if (isEmpty) {
        fetchPostData('Property/Search_PropertySecurity', { 'SerialID': SerialID, 'IssuingAgency': IssuingAgency, 'SecurityDtTm': SecurityDtTm, 'CategoryID': CategoryID, 'Denomination': Denomination, 'AgencyID': loginAgencyID, }).then((res) => {
            if (res?.length > 0) {
                // console.log(res)
                dispatch({ type: Property_Search_Data, payload: res });
                dispatch({ type: PropertySearch_Modal_Status, payload: true });
                setSearchModalState(true)
            } else {
                dispatch({ type: Property_Search_Data, payload: [] });
                toastifyError("Data Not Available")
            }
        })
    } else {
        // toastifyError("Empty Search")
        console.log("Search Empty")
    }
};

export const get_Property_Weapon_Search_Data = (SerialID, MakeID, LossCodeID, CategoryID, Caliber, setSearchModalState, loginAgencyID) => async (dispatch) => {
    dispatch({ type: PropertySearch_Type, payload: 'Pro-Weapon' });
    const val = { 'SerialID': SerialID, 'MakeID': MakeID, 'LossCodeID': LossCodeID, 'CategoryID': CategoryID, 'Caliber': Caliber, 'SearchType': 'Weapon' }
    const isEmpty = hasValues(val)
    if (isEmpty) {
        fetchPostData('Property/Search_PropertyWeapon', { 'SerialID': SerialID, 'MakeID': MakeID, 'LossCodeID': LossCodeID, 'CategoryID': CategoryID, 'Caliber': Caliber, 'AgencyID': loginAgencyID, }).then((res) => {
            if (res?.length > 0) {
                // console.log(res)
                dispatch({ type: Property_Search_Data, payload: res });
                dispatch({ type: PropertySearch_Modal_Status, payload: true });
                setSearchModalState(true)
            } else {
                dispatch({ type: Property_Search_Data, payload: [] });
                toastifyError("Data Not Available")
            }
        })
    } else {
        // toastifyError("Empty Search")
        console.log("Search Empty")
    }
};

export const get_Property_Other_Search_Data = (SerialID, ModelID, Brand, LossCodeID, CategoryID, setSearchModalState, loginAgencyID) => async (dispatch) => {
    dispatch({ type: PropertySearch_Type, payload: 'Pro-Other' });
    const val = { 'SerialID': SerialID, 'ModelID': ModelID, 'Brand': Brand, 'LossCodeID': LossCodeID, 'CategoryID': CategoryID, 'SearchType': 'Other' }
    const isEmpty = hasValues(val)
    if (isEmpty) {
        fetchPostData('Property/Search_PropertyOther', { 'SerialID': SerialID, 'ModelID': ModelID, 'Brand': Brand, 'LossCodeID': LossCodeID, 'CategoryID': CategoryID, 'AgencyID': loginAgencyID, }).then((res) => {
            if (res?.length > 0) {
                // console.log(res)
                dispatch({ type: Property_Search_Data, payload: res });
                dispatch({ type: PropertySearch_Modal_Status, payload: true });
                setSearchModalState(true)
            } else {
                dispatch({ type: Property_Search_Data, payload: [] });
                toastifyError("Data Not Available")
            }
        })
    } else {
        // toastifyError("Empty Search")
        console.log("Search Empty")
    }
};

export const get_Property_Boat_Search_Data = (RegistrationStateID, RegistrationNumber, HIN, LossCodeID, ManufactureYear, CategoryID, TopColorID, MakeID, PropulusionID, setSearchModalState, loginAgencyID) => async (dispatch) => {
    dispatch({ type: PropertySearch_Type, payload: 'Pro-Boat' });
    const val = {
        'RegistrationStateID': RegistrationStateID, 'RegistrationNumber': RegistrationNumber, 'HIN': HIN, 'LossCodeID': LossCodeID,
        'ManufactureYear': ManufactureYear, 'CategoryID': CategoryID, 'TopColorID': TopColorID, 'MakeID': MakeID, 'PropulusionID': PropulusionID, 'SearchType': 'Boat'
    }
    const isEmpty = hasValues(val)
    if (isEmpty) {
        fetchPostData('Property/Search_PropertyBoat', { 'RegistrationStateID': RegistrationStateID, 'RegistrationNumber': RegistrationNumber, 'HIN': HIN, 'LossCodeID': LossCodeID, 'ManufactureYear': ManufactureYear, 'CategoryID': CategoryID, 'TopColorID': TopColorID, 'MakeID': MakeID, 'PropulusionID': PropulusionID, 'AgencyID': loginAgencyID, }).then((res) => {
            if (res?.length > 0) {
                console.log(res)
                dispatch({ type: Property_Search_Data, payload: res });
                dispatch({ type: PropertySearch_Modal_Status, payload: true });
                setSearchModalState(true)
            } else {
                dispatch({ type: Property_Search_Data, payload: [] });
                toastifyError("Data Not Available")
            }
        })
    } else {
        // toastifyError("Empty Search")
        console.log("Search Empty")
    }
};
import React, { useEffect, useContext, useState } from 'react'
import { Link, useLocation } from "react-router-dom";
import { AgencyContext } from '../../../Context/Agency/Index';
import { Decrypt_Id_Name, DecryptedList } from '../../Common/Utility';
import { get_LocalStoreData } from '../../../redux/actions/Agency';
import { useDispatch, useSelector } from 'react-redux';

const Tab = () => {

    const { changesStatus, get_Name_Count, incidentCount, setVehicleStatus, setChangesStatus } = useContext(AgencyContext)
    const dispatch = useDispatch();
    const localStoreData = useSelector((state) => state.Agency.localStoreData);
    const uniqueId = sessionStorage.getItem('UniqueUserID') ? Decrypt_Id_Name(sessionStorage.getItem('UniqueUserID'), 'UForUniqueUserID') : '';
    const [currentTab, setCurrentTab] = useState('Incident');
    const [incidentStatus, setIncidentStatus] = useState(false)

    const currentLocation = window.location.pathname + window.location.search;


    const useQuery = () => {
        const params = new URLSearchParams(useLocation().search);
        return {
            get: (param) => params.get(param)
        };
    };

    const query = useQuery();
    var IncID = query?.get("IncId");
    var IncNo = query?.get("IncNo");
    var IncSta = query?.get("IncSta");
    var NameID = query?.get("NameID");
    var MasterNameID = query?.get("MasterNameID");
    var NameStatus = query?.get('NameStatus');
    var OffId = query?.get("OffId");
    var OffSta = query?.get("OffSta");
    let ProId = query?.get('ProId');
    let MProId = query?.get('MProId');
    var ProSta = query?.get('ProSta');
    let VehId = query?.get('VehId');
    let MVehId = query?.get('MVehId');
    var VehSta = query?.get('VehSta');

    if (!IncID) IncID = 0;
    else IncID = IncID;

    if (!NameID) NameID = 0;
    else NameID = NameID;

    if (!MasterNameID) MasterNameID = 0;
    else MasterNameID = MasterNameID;

    if (!OffId) OffId = 0;
    else OffId = OffId;

    if (!ProId) ProId = 0;
    else ProId = ProId;

    if (!MProId) MProId = 0;
    else MProId = MProId;

    if (!VehId) VehId = 0;
    else VehId = VehId;

    if (!MVehId) MVehId = 0;
    else MVehId = MVehId;


    useEffect(() => {
        if (!localStoreData?.AgencyID || !localStoreData?.PINID) {
            if (uniqueId) dispatch(get_LocalStoreData(uniqueId));
        }
    }, []);

    useEffect(() => {
        if (IncSta === false || IncSta === 'false') { setIncidentStatus(false); } else { setIncidentStatus(true) }
    }, [IncSta]);

    const active = window.location.pathname;
    useEffect(() => {
        const pathname = window.location.pathname;
        if (pathname.includes('Inc-Home')) setCurrentTab('Incident');
        if (pathname.includes('Off-Home')) setCurrentTab('Offense');
        if (pathname.includes('Name-Home')) setCurrentTab('Name');
        if (pathname.includes('Prop-Home')) setCurrentTab('Property');
        if (pathname.includes('Vehicle-Home')) setCurrentTab('Vehicle');
        if (pathname.includes('Document-Home')) setCurrentTab('Document');
        if (pathname.includes('Offvic-Home')) setCurrentTab('OffenderVicitm');
    }, [window.location.pathname]);


    return (
        <div className="col-12 inc__tabs">
            <ul className="nav nav-tabs">
                <li className="nav-item">
                    <Link
                        // className={`nav-link  ${active === `/Inc-Home?IncId=${IncID}&IncNo=${IncNo}&IncSta=${IncSta}` ? 'active' : ''}`}
                        // data-toggle={changesStatus ? "modal" : "pill"}
                        // data-target={changesStatus ? "#SaveModal" : ''}
                        // to={changesStatus  ? `/Inc-Home?IncId=${IncID}&IncNo=${IncNo}&IncSta=${IncSta}` : `/Inc-Home?IncId=${IncID}&IncNo=${IncNo}&IncSta=${IncSta}`}
                        // style={{ color: currentTab === 'Incident' ? 'Red' : '#130e0e', fontWeight: '600' }}
                        // onClick={() => { if (!changesStatus) { setCurrentTab('Incident'); } }}
                        className={`nav-link  ${active === `/Inc-Home?IncId=${IncID}&IncNo=${IncNo}&IncSta=${IncSta}` ? 'active' : ''}`}
                        to={changesStatus ? currentLocation : `/Inc-Home?IncId=${IncID}&IncNo=${IncNo}&IncSta=${IncSta}`}
                        style={{ color: currentTab === 'Incident' ? 'Red' : '#130e0e', fontWeight: '600' }}
                        data-toggle={changesStatus ? "modal" : "pill"}
                        data-target={changesStatus ? "#SaveModal" : ''}
                        onClick={() => { if (!changesStatus) { setCurrentTab('Incident') } }}
                    >
                        Incident
                    </Link>
                </li>
                <li className="nav-item" >
                    <Link
                        className={`nav-link${active === `/Off-Home?IncId=${IncID}&IncNo=${IncNo}&IncSta=${IncSta}&OffId=${OffId}&OffSta=${OffSta}` ? 'active' : ''} ${incidentStatus ? '' : 'disabled'}`}
                        to={changesStatus ? currentLocation : `/Off-Home?IncId=${IncID}&IncNo=${IncNo}&IncSta=${IncSta}&OffId=${OffId}&OffSta=${OffSta}`}
                        style={{ color: currentTab === 'Offense' ? 'Red' : incidentCount[0]?.OffenseCount > 0 ? 'blue' : '#130e0e', fontWeight: '600' }}
                        data-toggle={changesStatus ? "modal" : "pill"}
                        data-target={changesStatus ? "#SaveModal" : ''}
                        onClick={() => { if (!changesStatus) { setCurrentTab('Offense') } }}
                    >
                        Offense{`${incidentCount[0]?.OffenseCount > 0 ? '(' + incidentCount[0]?.OffenseCount + ')' : ''}`}
                    </Link>
                </li>
                <li className="nav-item">
                    <Link
                        className={`nav-link  ${active === `/Name-Home?IncId=${IncID}&IncNo=${IncNo}&IncSta=${IncSta}&NameID=${NameID}&MasterNameID=${MasterNameID}&NameStatus=${NameStatus}` ? 'active' : ''} ${incidentStatus ? '' : 'disabled'} `}
                        to={changesStatus ? currentLocation : `/Name-Home?IncId=${IncID}&IncNo=${IncNo}&IncSta=${IncSta}&NameID=${NameID}&MasterNameID=${MasterNameID}&NameStatus=${NameStatus}`}
                        style={{ color: currentTab === 'Name' ? 'Red' : incidentCount[0]?.NameCount > 0 ? 'blue' : '#130e0e', fontWeight: '600' }}
                        data-toggle={changesStatus ? "modal" : "pill"}
                        data-target={changesStatus ? "#SaveModal" : ''}
                        onClick={() => { if (!changesStatus) { setCurrentTab('Name'); get_Name_Count(''); } }}
                        tabIndex="-1"
                        aria-disabled="true"
                    >
                        Name{`${incidentCount[0]?.NameCount > 0 ? '(' + incidentCount[0]?.NameCount + ')' : ''}`}
                    </Link>
                </li>
                <li className="nav-item">
                    <Link
                        className={`nav-link  ${active === `/Prop-Home?IncId=${IncID}&IncNo=${IncNo}&IncSta=${IncSta}&ProId=${ProId}&MProId=${MProId}&ProSta=${ProSta}` ? 'active' : ''} ${incidentStatus ? '' : 'disabled'}`}
                        to={changesStatus ? currentLocation :
                            `/Prop-Home?IncId=${IncID}&IncNo=${IncNo}&IncSta=${IncSta}&ProId=${ProId}&MProId=${MProId}&ProSta=${ProSta}`
                        }
                        data-toggle={changesStatus ? "modal" : "pill"}
                        style={{ color: currentTab === 'Property' ? 'Red' : incidentCount[0]?.PropertyCount > 0 ? 'blue' : '#130e0e', fontWeight: '600' }}
                        data-target={changesStatus ? "#SaveModal" : ''}
                        tabIndex="-1"
                        aria-disabled="true"
                        onClick={() => { if (!changesStatus) { setCurrentTab('Property') } }}
                    >
                        Property{`${incidentCount[0]?.PropertyCount > 0 ? '(' + incidentCount[0]?.PropertyCount + ')' : ''}`}
                    </Link>
                </li>
                <li className="nav-item">
                    <Link
                        className={`nav-link  ${active === `/Vehicle-Home?IncId=${IncID}&IncNo=${IncNo}&IncSta=${IncSta}&VehId=${VehId}&MVehId=${MVehId}&VehSta=${VehSta}` ? 'active' : ''} ${incidentStatus ? '' : 'disabled'}`}
                        to={changesStatus ? currentLocation : `/Vehicle-Home?IncId=${IncID}&IncNo=${IncNo}&IncSta=${IncSta}&VehId=${VehId}&MVehId=${MVehId}&VehSta=${VehSta}`}
                        data-toggle={changesStatus ? "modal" : "pill"}
                        style={{ color: currentTab === 'Vehicle' ? 'Red' : incidentCount[0]?.VehicleCount > 0 ? 'blue' : '#130e0e', fontWeight: '600' }}
                        data-target={changesStatus ? "#SaveModal" : ''}
                        tabIndex="-1" aria-disabled="true"
                        onClick={() => { if (!changesStatus) { setCurrentTab('Vehicle'); setVehicleStatus(false) } }}
                    >
                        Vehicle{`${incidentCount[0]?.VehicleCount > 0 ? '(' + incidentCount[0]?.VehicleCount + ')' : ''}`}
                    </Link>
                </li>
                <li className="nav-item">
                    <Link
                        className={`nav-link  ${active === `/Document-Home?IncId=${IncID}&IncNo=${IncNo}&IncSta=${IncSta}` ? 'active' : ''} ${incidentStatus ? '' : 'disabled'}`}
                        to={changesStatus ? currentLocation : `/Document-Home?IncId=${IncID}&IncNo=${IncNo}&IncSta=${IncSta}`}
                        data-toggle={changesStatus ? "modal" : "pill"}
                        style={{ color: currentTab === 'Document' ? 'Red' : incidentCount[0]?.DocumentManagementCount > 0 ? 'blue' : '#130e0e', fontWeight: '600' }}
                        data-target={changesStatus ? "#SaveModal" : ''}
                        tabIndex="-1" aria-disabled="true"
                        onClick={() => { if (!changesStatus) { setCurrentTab('Document') } }}
                    >
                        Document{`${incidentCount[0]?.DocumentManagementCount > 0 ? '(' + incidentCount[0]?.DocumentManagementCount + ')' : ''}`}
                    </Link>
                </li>
                <li className="nav-item">
                    <Link
                        className={`nav-link  ${active === `/Offvic-Home?IncId=${IncID}&IncNo=${IncNo}&NameID=${NameID}&IncSta=${IncSta}` ? 'active' : ''} ${incidentStatus ? '' : 'disabled'}`}
                        to={changesStatus ? currentLocation : `/Offvic-Home?IncId=${IncID}&IncNo=${IncNo}&IncSta=${IncSta}`}
                        data-toggle={changesStatus ? "modal" : "pill"}
                        style={{ color: currentTab === 'OffenderVicitm' ? 'Red' : '#130e0e', fontWeight: '600' }}
                        data-target={changesStatus ? "#SaveModal" : ''}
                        tabIndex="-1" aria-disabled="true"
                        onClick={() => { if (!changesStatus) { setCurrentTab('OffenderVicitm') } }}
                    >
                        Offender/Victim Info
                    </Link>
                </li>
                <li className="nav-item">
                    <Link
                        className="nav-link  disabled"
                        data-toggle="pill"
                        to=''
                        tabIndex="-1"
                        style={{ color: currentTab === 'NIBRS' ? 'Red' : incidentCount[0]?.NIBRS > 0 ? 'blue' : '#130e0e', fontWeight: '600' }}
                        aria-disabled="true"
                    // to={changesStatus ? currentLocation : `/Document-Home?IncId=${IncID}&IncNo=${IncNo}&IncSta=${IncSta}`}
                    // data-toggle={changesStatus ? "modal" : "pill"}
                    // style={{ color: currentTab === 'Document' ? 'Red' : incidentCount[0]?.DocumentManagementCount > 0 ? 'blue' : '#130e0e', fontWeight: '600' }}
                    // data-target={changesStatus ? "#SaveModal" : ''}
                    // tabIndex="-1" aria-disabled="true"
                    // onClick={() => { if (!changesStatus) { setCurrentTab('Document') } }}
                    >
                        NIBRS
                    </Link>
                    {/* <a className="nav-link disabled" data-toggle="pill" href="#" tabIndex="-1" aria-disabled="true">NIBRS</a> */}
                </li>
            </ul>
        </div >
    )
}

export default Tab
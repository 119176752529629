import React, { useRef, useState } from 'react'
import { Decrypt_Id_Name, getShowingWithOutTime } from '../../../Common/Utility';
import { Link } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { get_LocalStoreData } from '../../../../redux/actions/Agency';
import { get_Incident_Drp_Data } from '../../../../redux/actions/DropDownsData';

const FamilyViolenceReport = (props) => {

    const { reportData, showFields, searchValue, multiImage,rmsCfsID } = props

    const dispatch = useDispatch();
    const uniqueId = sessionStorage.getItem('UniqueUserID') ? Decrypt_Id_Name(sessionStorage.getItem('UniqueUserID'), 'UForUniqueUserID') : '';
    const localStoreData = useSelector((state) => state.Agency.localStoreData);
    const fbiCodesDrpData = useSelector((state) => state.DropDown.fbiCodesDrpData);
    // const [multiImage, setMultiImage] = useState([]);
    const [LoginAgencyID, setLoginAgencyID] = useState('');
    const [LoginPinID, setLoginPinID,] = useState('');




    useEffect(() => {
        if (!localStoreData?.AgencyID || !localStoreData?.PINID) {
            if (uniqueId) dispatch(get_LocalStoreData(uniqueId));
        }
    }, []);


    useEffect(() => {
        if (localStoreData) {
            setLoginAgencyID(localStoreData?.AgencyID);
            setLoginPinID(localStoreData?.PINID);
            if (fbiCodesDrpData?.length === 0) { dispatch(get_Incident_Drp_Data(localStoreData?.AgencyID)) }

        }
    }, [localStoreData])
    const componentRef = useRef();
    const printForm = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'Data',
        onAfterPrint: () => { '' }
    })
    return (
        <>

            <div className="col-12 col-md-12 col-lg-12  px-2" >
                <div className="bg-line  py-1 px-2 mt-1 d-flex justify-content-between align-items-center">
                    <p className="p-0 m-0 d-flex align-items-center">Family Violence Summary Report</p>
                    <div style={{ marginLeft: 'auto' }}>
                        <Link to={''} className="btn btn-sm bg-green  mr-2 text-white px-2 py-0"  >
                            <i className="fa fa-print" onClick={printForm}></i>
                        </Link>

                    </div>
                </div>
            </div>
            <div className="container mt-1" ref={componentRef}>
                <div className="row" style={{ border: '1px solid #80808085' }}>
                    <>
                        <div className="col-4 col-md-3 col-lg-2">
                            <div className="main">
                                <div className="img-box" >
                                    <img src={multiImage} className='picture' style={{ marginTop: '4px', width: '150px', height: '150px' }} />
                                </div>
                            </div>
                        </div>
                        <div className="col-7  col-md-7 col-lg-9 mt-2">
                            <div className="main">
                                <h5 className='text-dark text-bold'>{reportData?.Agency_Name}</h5>
                                <p className='text-p'>Address: <span className=''>{reportData?.Agency_Address1}</span></p>
                                <div className='d-flex '>
                                    <p className='text-p'>State: <span className='new-span '>{reportData?.StateName}</span>
                                    </p>
                                    <p className='text-p ml-5 pl-1'>City: <span className='new-span  '>{reportData?.CityName}</span>
                                    </p>
                                    <p className='text-p ml-2'>Zip: <span className='new-span  '>{reportData?.Agency_ZipId}</span>
                                    </p>
                                </div>
                                <div className='d-flex'>
                                    <p className='text-p'>Phone: <span className='new-span  '>{reportData?.Agency_Phone}</span></p>
                                    <p className='text-p ml-3 '>Fax: <span className='new-span  '> {reportData?.Agency_Fax}</span></p>
                                </div>
                            </div>
                        </div>
                    </>
                    <div className="col-12">
                        <hr style={{ border: '1px solid rgb(3, 105, 184)' }} />
                    </div>
                    <div className="col-12 bb">
                        <fieldset>
                            <legend>Search Criteria</legend>

                            <div className="row">
                                {showFields.showIncidentNumber && (
                                    <>
                                        <div className="col-3 col-md-3 col-lg-2 mt-2">
                                            <label className='new-label'>Incident Number From</label>
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                            <input type="text" className='readonlyColor'
                                                value={searchValue.IncidentNumber}

                                                readOnly />
                                        </div>
                                    </>
                                )}
                                {showFields.showIncidentNumberTo && (
                                    <>
                                        <div className="col-3 col-md-3 col-lg-2 mt-2">
                                            <label className='new-label'>Incident Number To</label>
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                            <input type="text" className='readonlyColor'
                                                value={searchValue.IncidentNumberTo}

                                                readOnly />
                                        </div>
                                    </>
                                )}
                                {showFields.showReportedDateFrom && (
                                    <>
                                        <div className="col-3 col-md-3 col-lg-2 mt-2">
                                            <label className='new-label'>Reported Date From</label>
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                            <input type="text" className='readonlyColor'
                                                value={searchValue.ReportedDate && getShowingWithOutTime(searchValue.ReportedDate)}

                                                readOnly />
                                        </div>
                                    </>
                                )}
                                {showFields.showReportedDateTo && (
                                    <>
                                        <div className="col-3 col-md-3 col-lg-2 mt-2">
                                            <label className='new-label'>Reported Date To</label>
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                            <input type="text" className='readonlyColor'
                                                // value={searchValue.ReportedDateTo || ''} 
                                                value={searchValue.ReportedDateTo && getShowingWithOutTime(searchValue.ReportedDateTo)}
                                                readOnly />
                                        </div>
                                    </>
                                )}
                                {showFields.showFBIID && searchValue.FBIID && (
                                    <>
                                        <div className="col-3 col-md-3 col-lg-2 mt-2">
                                            <label className='new-label'>NIBRS</label>
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                            <input type="text" className='readonlyColor' value={fbiCodesDrpData.find((obj) => obj.value === searchValue.FBIID)?.label || ''} readOnly />
                                        </div>
                                    </>
                                )}
                                {showFields.showRMSCFSCodeID && searchValue.RMSCFSCodeID && (
                                    <>
                                        <div className="col-3 col-md-3 col-lg-2 mt-2">
                                            <label className='new-label'>Offense Code</label>
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                            <input type="text" className='readonlyColor' value={rmsCfsID.find((obj) => obj.value === searchValue.RMSCFSCodeID)?.label || ''} readOnly />
                                        </div>
                                    </>
                                )}
                            </div>
                        </fieldset>
                    </div>
                    {
                        reportData?.Relationship?.length > 0 ?
                            <>
                                {
                                    reportData?.Relationship?.map((obj) => (
                                        <>
                                            <div className="container" style={{ pageBreakAfter: 'always' }}>
                                                <h5 className=" text-white text-bold bg-green py-1 px-3 text-center">Family Violence Summary Report</h5>


                                                <h6 className='text-white pl-2 text-center  bg-green'>Incident Number:- <span className='text-white'>
                                                    {obj?.IncidentNumber}
                                                </span></h6>

                                                {
                                                    JSON.parse(obj?.NameRelationship)?.length > 0 ?
                                                        <>
                                                            {
                                                                // JSON.parse(obj?.NameRelationship)?.map((item, key) => (

                                                                JSON.parse(obj?.NameRelationship || obj?.Offense)?.map((item, key) => (
                                                                    <>
                                                                        <div className="table-responsive bb" >
                                                                            <table className="table table-bordered "  >
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td colSpan={4}>
                                                                                            <h6 className='text-dark text-bold'>Victim Name:</h6>
                                                                                            <p className='text-list'>{item?.VictimName}</p>
                                                                                        </td>
                                                                                        <td colSpan={4}>
                                                                                            <h6 className='text-dark text-bold'>Offender Name</h6>
                                                                                            <p className='text-list'>{item?.OffenderName}</p>
                                                                                        </td>
                                                                                        <td colSpan={4}>
                                                                                            <h6 className='text-dark text-bold'>Relation(V to O)</h6>
                                                                                            <p className='text-list'>{item?.Relation}</p>
                                                                                        </td>

                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td colSpan={8}>
                                                                                            <h6 className='text-dark text-bold'>Location:</h6>
                                                                                            <p className='text-list'>{item?.CrimeLocation}</p>
                                                                                        </td>
                                                                                        <td colSpan={4}>
                                                                                            <h6 className='text-dark text-bold'>Officer:</h6>
                                                                                            <p className='text-list'>{item?.PrimaryOfficer_Name}</p>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td colSpan={8}>
                                                                                            <h6 className='text-dark text-bold'>Weapon:</h6>
                                                                                            <p className='text-list'>{item?.WeaponType}</p>
                                                                                        </td>
                                                                                        <td colSpan={4}>
                                                                                            <h6 className='text-dark text-bold'>Physical Injury</h6>
                                                                                            <p className='text-list'>{item?.Injury}</p>
                                                                                        </td>

                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td colSpan={8}>
                                                                                            <h6 className='text-dark text-bold'>Offender Using:</h6>
                                                                                            <p className='text-list'>{item?.OffenderUse}</p>
                                                                                        </td>
                                                                                        <td colSpan={4}>
                                                                                            <h6 className='text-dark text-bold'>Bias:</h6>
                                                                                            <p className='text-list'>{item?.CrimeBias}</p>
                                                                                        </td>
                                                                                    </tr>

                                                                                </tbody>
                                                                            </table>
                                                                            <table className="table table-bordered">
                                                                                <thead className='text-dark master-table'>
                                                                                    <tr>
                                                                                        <th className=''>Offense</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody className='master-tbody'>
                                                                                    {
                                                                                        (JSON.parse(obj?.NameRelationship || "[]") || []).map((nameRelationship, nameRelationshipKey) => (
                                                                                            (JSON.parse(nameRelationship?.Offense || "[]") || []).map((offense, offenseKey) => (
                                                                                                <tr key={`${nameRelationshipKey}-${offenseKey}`} style={{ borderBottom: '0.2px solid gray' }}>
                                                                                                    <td>{offense.Offense_Desc}</td>
                                                                                                </tr>
                                                                                            ))
                                                                                        ))
                                                                                    }
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </>
                                                                ))
                                                            }
                                                        </>
                                                        : <></>
                                                }

                                            </div>
                                        </>
                                    ))
                                }
                            </>
                            :
                            <>
                            </>
                    }
                    {/* <div className="container" style={{ pageBreakAfter: 'always' }}>
                        <h5 className=" text-white text-bold bg-green py-1 px-3 text-center">Family Violence Summary Report</h5>
                        {
                            reportData?.Relationship?.map((obj) => (
                                <>

                                    <div className="table-responsive bb" >
                                        {
                                            JSON.parse(obj?.NameRelationship).length > 0 ?
                                                <>

                                                    <h6 className='text-white pl-2 text-center  bg-green'>Incident Number:- <span className='text-white'>
                                                        {obj?.IncidentNumber}
                                                    </span></h6>
                                                    <div className="table-responsive" >
                                                        <table className="table table-bordered" >
                                                            <tbody>
                                                                <tr>
                                                                    <td colSpan={4}>
                                                                        <h6 className='text-dark text-bold'>Victim Name:</h6>
                                                                        <p className='text-list'>{obj?.PropertyNumber}</p>
                                                                    </td>
                                                                    <td colSpan={4}>
                                                                        <h6 className='text-dark text-bold'>Offender Name</h6>
                                                                        <p className='text-list'>{obj?.OffenderName}</p>
                                                                    </td>
                                                                    <td colSpan={4}>
                                                                        <h6 className='text-dark text-bold'>Relation(V to O)</h6>
                                                                        <p className='text-list'>{obj?.Relation}</p>
                                                                    </td>

                                                                </tr>
                                                                <tr>
                                                                    <td colSpan={8}>
                                                                        <h6 className='text-dark text-bold'>Location:</h6>
                                                                        <p className='text-list'>{obj?.CrimeLocation}</p>
                                                                    </td>
                                                                    <td colSpan={4}>
                                                                        <h6 className='text-dark text-bold'>Officer:</h6>
                                                                        <p className='text-list'>{obj?.Value}</p>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan={4}>
                                                                        <h6 className='text-dark text-bold'>Weapon:</h6>
                                                                        <p className='text-list'>{obj?.PropertyNumber}</p>
                                                                    </td>
                                                                    <td colSpan={4}>
                                                                        <h6 className='text-dark text-bold'>Physical Injury</h6>
                                                                        <p className='text-list'>{obj?.Injury}</p>
                                                                    </td>
                                                                    <td colSpan={4}>
                                                                        <h6 className='text-dark text-bold'>Offense</h6>
                                                                        <p className='text-list'>{obj?.PropertyLossCode_Description}</p>
                                                                    </td>

                                                                </tr>
                                                                <tr>
                                                                    <td colSpan={8}>
                                                                        <h6 className='text-dark text-bold'>Offender Using:</h6>
                                                                        <p className='text-list'>{obj?.PropertyType_Description}</p>
                                                                    </td>
                                                                    <td colSpan={4}>
                                                                        <h6 className='text-dark text-bold'>Bias:</h6>
                                                                        <p className='text-list'>{obj?.Value}</p>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </>
                                                :
                                                <></>
                                        }
                                    </div>
                                </>
                            ))
                        }
                    </div> */}
                </div>
            </div >

        </>
    )
}

export default FamilyViolenceReport
import React, { useRef } from 'react'
import { Decrypt_Id_Name, changeArrayFormat, customStylesWithOutColor, getShowingDateText, getShowingWithOutTime } from '../../../../Common/Utility'
import DatePicker from "react-datepicker";
import Select from "react-select";
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import img from '../../../../../../src/img/images1.jpg'
import { fetchPostData } from '../../../../hooks/Api';
import { toastifyError } from '../../../../Common/AlertMsg';
import { useEffect } from 'react';
import { Comman_changeArrayFormat } from '../../../../Common/ChangeArrayFormat';
import { useContext } from 'react';
import { AgencyContext } from '../../../../../Context/Agency/Index';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { get_LocalStoreData } from '../../../../../redux/actions/Agency';

const IncidentOfficer = () => {

    const { localStoreArray, setLocalStoreArray, get_LocalStorage } = useContext(AgencyContext)
    const dispatch = useDispatch();
    const uniqueId = sessionStorage.getItem('UniqueUserID') ? Decrypt_Id_Name(sessionStorage.getItem('UniqueUserID'), 'UForUniqueUserID') : '';
    const localStoreData = useSelector((state) => state.Agency.localStoreData);

    const [multiImage, setMultiImage] = useState([]);
    const [verifyIncident, setverifyIncident] = useState(false);
    const [headOfAgency, setHeadOfAgency] = useState([]);
    const [incidentData, setIncidentData] = useState([]);
    const [reportData, setReportData] = useState([]);
    const [LoginAgencyID, setLoginAgencyID] = useState('');
    const [LoginPinID, setLoginPinID,] = useState('');

    const [value, setValue] = useState({
        'ReportedDate': "", 'ReportedDateTo': "", 'OfficerPINID': null, 'AgencyID': null,
    });

    const [searchValue, setSearchValue] = useState({
        ReportedDate: '',
        ReportedDateTo: '',
        OfficerPINID: null
    });

    const [showFields, setShowFields] = useState({
        showReportedDateFrom: false,
        showReportedDateTo: false,
        showOfficerName: false,
    });

    useEffect(() => {
        if (!localStoreData?.AgencyID || !localStoreData?.PINID) {
            if (uniqueId) dispatch(get_LocalStoreData(uniqueId));
        }
    }, []);

    useEffect(() => {
        setShowFields({
            showReportedDateFrom: searchValue.ReportedDate,
            showReportedDateTo: searchValue.ReportedDateTo,
            showOfficerName: searchValue.OfficerPINID !== null,
        });
    }, [searchValue]);

    // Onload Function
    useEffect(() => {
        if (localStoreData) {
            setLoginAgencyID(localStoreData?.AgencyID); get_Head_Of_Agency(localStoreData?.AgencyID);
            setLoginPinID(localStoreData?.PINID);
        }
    }, [localStoreData])

    const get_Head_Of_Agency = (LoginAgencyID) => {
        const val = { AgencyID: LoginAgencyID }
        fetchPostData('DropDown/GetData_HeadOfAgency', val).then((data) => {
            if (data) {
                setHeadOfAgency(Comman_changeArrayFormat(data, 'PINID', 'HeadOfAgency'));
            } else {
                setHeadOfAgency([])
            }
        })
    };

    const getIncidentSearchData = async () => {
        console.log(value?.ReportedDate?.trim()?.length > 0 )
        if (value?.ReportedDate?.trim()?.length > 0 || value?.ReportedDateTo?.trim()?.length > 0 || value?.OfficerPINID !== null) {
            const { ReportedDate, ReportedDateTo, OfficerPINID, AgencyID, } = value
            const val = { 'ReportedDate': ReportedDate, 'ReportedDateTo': ReportedDateTo, 'OfficerPINID': OfficerPINID, 'AgencyID': LoginAgencyID, }
            fetchPostData('Report/IncidentOfficer', val).then((res) => {
                if (res.length > 0) {
                    setIncidentData(res[0].Incident); setReportData(res[0]); setverifyIncident(true); getAgencyImg(LoginAgencyID);
                    setSearchValue(value);
                } else {
                    toastifyError("Data Not Available");
                    setReportData([]);
                    setverifyIncident(false);
                }
            });
        } else {
            toastifyError("Please Enter Details");
        }
    }

    const ChangeDropDown = (e, name) => {
        if (e) { setValue({ ...value, [name]: e.value }) }
        else { setValue({ ...value, [name]: null }) }
    }


    const resetFields = () => {
        setValue({ ...value, 'ReportedDate': "", 'ReportedDateTo': "", 'OfficerPINID': null, });
        setReportData([]); setverifyIncident(false); setIncidentData([]);
    }


    const componentRef = useRef();
    const printForm = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'Data',
        onAfterPrint: () => { '' }
    })

    const getAgencyImg = (LoginAgencyID) => {
        const val = { 'AgencyID': LoginAgencyID }
        fetchPostData('Agency/GetDataAgencyPhoto', val).then((res) => {
            if (res) {
                let imgUrl = `data:image/png;base64,${res[0]?.Agency_Photo}`;
                setMultiImage(imgUrl);

            }
            else { console.log("errror") }
        })
    }
    return (
        <>
            <div class="section-body view_page_design pt-3">
                <div className="row clearfix">
                    <div className="col-12 col-sm-12">
                        <div className="card Agency">
                            <div className="card-body">
                                <fieldset>
                                    <legend>Incident Officer Report</legend>
                                    <div className="row">
                                        <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                                            <label htmlFor="" className='new-label'>Reported From Date</label>
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-2 ">
                                            <DatePicker
                                                name='ReportedDate'
                                                id='ReportedDate'
                                                onChange={(date) => {
                                                    setValue({
                                                        ...value,
                                                        ['ReportedDate']: date ? getShowingDateText(date) : null,
                                                    })
                                                }}
                                                selected={value?.ReportedDate && new Date(value?.ReportedDate)}
                                                dateFormat="MM/dd/yyyy"
                                                timeInputLabel
                                                isClearable={value?.ReportedDate ? true : false}
                                                peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                autoComplete='Off'
                                                disabled={false}
                                                maxDate={new Date()}
                                                placeholderText='Select...'
                                            />
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                                            <label htmlFor="" className='new-label'>Reported To Date</label>
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-2  ">
                                            <DatePicker
                                                name='ReportedDateTo'
                                                id='ReportedDateTo'
                                                onChange={(date) => { setValue({ ...value, ['ReportedDateTo']: date ? getShowingDateText(date) : null }) }}
                                                selected={value?.ReportedDateTo && new Date(value?.ReportedDateTo)}
                                                dateFormat="MM/dd/yyyy"
                                                timeInputLabel
                                                isClearable={value?.ReportedDateTo ? true : false}
                                                peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                autoComplete='Off'
                                                disabled={value?.ReportedDate ? false : true}
                                                minDate={new Date(value?.ReportedDate)}
                                                maxDate={new Date()}
                                                placeholderText='Select...'
                                            />
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-1 mt-2 ">
                                            <label htmlFor="" className='new-label'>Officer&nbsp;Name</label>
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-3  mt-1 ">
                                            <Select
                                                styles={customStylesWithOutColor}
                                                name='OfficerPINID'
                                                value={headOfAgency?.filter((obj) => obj.value === value?.OfficerPINID)}
                                                isClearable
                                                options={headOfAgency}
                                                onChange={(e) => ChangeDropDown(e, 'OfficerPINID')}
                                                placeholder="Select..."
                                            />
                                        </div>

                                        <div className="col-12 col-md-12 col-lg-12 mt-2 text-right">
                                            <button className="btn btn-sm bg-green text-white px-2 py-1 ml-2" onClick={() => { getIncidentSearchData(); }}>Show Report</button>
                                            <button className="btn btn-sm bg-green text-white px-2 py-1 ml-2" onClick={() => { resetFields(); }}>Clear</button>
                                            <Link to={'/Reports'}>
                                                <button className="btn btn-sm bg-green text-white px-2 py-1 ml-2" >Close</button>
                                            </Link>
                                        </div>
                                    </div>
                                </fieldset>

                            </div>
                        </div>
                    </div>
                </div>
            </div >
            {
                verifyIncident &&
                <>
                    <div className="col-12 col-md-12 col-lg-12 pt-2  px-2" >
                        <div className="bg-line  py-1 px-2 mt-1 d-flex justify-content-between align-items-center">
                            <p className="p-0 m-0 d-flex align-items-center">Incident By Officer Report</p>
                            <div style={{ marginLeft: 'auto' }}>
                                <Link to={''} className="btn btn-sm bg-green  mr-2 text-white px-2 py-0"  >
                                    <i className="fa fa-print" onClick={printForm}></i>
                                </Link>

                            </div>
                        </div>
                    </div>
                    <div className="container mt-1" ref={componentRef}>
                        <div className="row" style={{ border: '1px solid #80808085' }}>
                            <>
                                <div className="col-4 col-md-3 col-lg-2 pt-1">
                                    <div className="main">
                                        <div className="img-box" >
                                            <img src={multiImage} className='picture' style={{ marginTop: '4px', width: '150px', height: '150px' }} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-7  col-md-7 col-lg-9 mt-2 pt-1 ml-5">
                                    <div className="main">
                                        <h5 className='text-dark text-bold'>{reportData?.Agency_Name}</h5>
                                        <p className='text-p'>Address: <span className=''>{reportData?.Agency_Address1}</span></p>
                                        <div className='d-flex '>
                                            <p className='text-p'>State: <span className='new-span '>{reportData?.StateName}</span>
                                            </p>
                                            <p className='text-p ml-5 pl-1'>City: <span className='new-span  '>{reportData?.CityName}</span>
                                            </p>
                                            <p className='text-p ml-2'>Zip: <span className='new-span  '>{reportData?.Agency_ZipId}</span>
                                            </p>
                                        </div>
                                        <div className='d-flex'>
                                            <p className='text-p'>Phone: <span className='new-span  '>{reportData?.Agency_Phone}</span></p>
                                            <p className='text-p ml-3 '>Fax: <span className='new-span  '> {reportData?.Agency_Fax}</span></p>
                                        </div>
                                    </div>
                                </div>
                            </>

                            <div className="container" style={{ pageBreakAfter: 'always' }}>
                                <h5 className=" text-white text-bold bg-green py-1 px-3 text-center">Incident By Officers</h5>

                                <fieldset>
                                    <legend>Search Criteria</legend>

                                    <div className="row">
                                        {showFields.showReportedDateFrom && (
                                            <>
                                                <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                    <label className='new-label'>Reported Date From</label>
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                    <input type="text" className='readonlyColor'
                                                        //  value={searchValue.ReportedDate || ''}
                                                        readOnly
                                                        value={searchValue.ReportedDate && getShowingWithOutTime(searchValue.ReportedDate)}
                                                    />
                                                    {/* <input type="text" className='readonlyColor' value={searchValue.ReportedDate ? getShowingWithOutTime(searchValue.ReportedDate) : ''} readOnly /> */}
                                                </div>
                                            </>
                                        )}

                                        {showFields.showReportedDateTo && (
                                            <>
                                                <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                    <label className='new-label'>Reported Date To</label>
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                    <input type="text" className='readonlyColor'
                                                        //  value={searchValue.ReportedDateTo || ''} 
                                                        value={searchValue.ReportedDateTo && getShowingWithOutTime(searchValue.ReportedDateTo)}
                                                        readOnly />
                                                    {/* <input type="text" className='readonlyColor' value={searchValue.ReportedDateTo ? getShowingWithOutTime(searchValue.ReportedDateTo) : ''} readOnly /> */}
                                                </div>
                                            </>
                                        )}

                                        {showFields.showOfficerName && (
                                            <>
                                                <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                    <label className='new-label'>Officer Name</label>
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                    <input type="text" className='readonlyColor' value={headOfAgency.find((obj) => obj.value === searchValue.OfficerPINID)?.label || ''} readOnly />
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </fieldset>
                                <hr />
                                {
                                    reportData?.Incident?.map((obj) => (
                                        <>
                                            <div className="table-responsive">
                                                <div className="table-responsive" >
                                                    {
                                                        JSON.parse(obj?.Incident).length > 0 ?
                                                            <>
                                                                {/* <h5 className="text-white text-bold bg-green py-1 px-3" >SMT Information:</h5> */}
                                                                <table className="table table-bordered">
                                                                    <thead className='text-dark master-table'>
                                                                        <tr>
                                                                            <th scope="col" colSpan='2' style={{ width: '100px' }}>Officer Name:- <span style={{ color: '#000', fontWeight: 'bold' }}>
                                                                                {obj?.Officer_Name}
                                                                            </span></th>
                                                                            <th scope="col">Total Incident: <span style={{ color: '#000', fontWeight: 'bold' }}>
                                                                                {JSON.parse(obj?.Incident).length}
                                                                            </span></th>
                                                                        </tr>
                                                                    </thead>
                                                                </table >

                                                                <table className="table table-bordered">
                                                                    <thead className='text-dark master-table'>
                                                                        <tr>
                                                                            <th className=''>Incident Number:</th>
                                                                            <th className=''>Reported Date/Time:</th>
                                                                            <th className=''>Offense:</th>
                                                                            <th className=''>RMS Disposition:</th>
                                                                            <th className=''>CAD Disposition:</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className='master-tbody'>
                                                                        {
                                                                            JSON.parse(obj?.Incident)?.map((obj, key) => (
                                                                                <>
                                                                                    <tr key={key}>
                                                                                        <td className='text-list'>{obj?.IncidentNumber}</td>
                                                                                        <td className='text-list'>{obj?.ReportedDate && getShowingDateText(obj?.ReportedDate)}</td>
                                                                                        <td className='text-list'>{obj?.RMSCFSCode_Description}</td>
                                                                                        <td className='text-list'>{obj?.RMS_Disposition}</td>
                                                                                        <td className='text-list'>{obj?.CADDispositions_Description}</td>
                                                                                    </tr>
                                                                                </>
                                                                            ))
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </>
                                                            :
                                                            <></>
                                                    }
                                                </div>
                                                <hr />
                                            </div>
                                        </>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </>
            }


        </>
    )
}

export default IncidentOfficer
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Sidebar from './Components/Inc/Sidebar';
import Header from './Components/Inc/Header';
import { AgencyContext } from './Context/Agency/Index';
import { useHistory } from 'react-router-dom';
const CryptoJS = require("crypto-js");

const Auth = (props) => {

  const { cmp, listManagementSideBar, agencySideBar, propertyRoomSideBar, personnelSideBar, path, incidentSideBar, dashboardSidebar, nameSearchSideBar, arrestSearchSideBar, progressData, progressStatus, reportSidebar, searchSidebar, consolidationSideBar } = props
  const Com = cmp;
  const TIMESTAMP_KEY = 'lastTimestamp';
  const CHECK_INTERVAL = 60000; // Example: 1 minute
  const EXPIRY_THRESHOLD = 300000; // Example: 5 minutes

  const navigate = useNavigate()
  const [dependency, setDependency] = useState('initial');
  const [prevDependency, setPrevDependency] = useState('initial');

  const { setAuthSession, setLogByOtp, setIsLogout } = useContext(AgencyContext);

  const IsLoginSession = sessionStorage.getItem('is-login') ? sessionStorage.getItem('is-login') : false;

  const currentLocation = window.location.pathname + window.location.search + window.location.hash;

  // console.log(currentLocation)
  // useEffect(() => {
  //   console.log(dependency)
  //   console.log(prevDependency)
  //   if (dependency !== prevDependency) {
  //     // This code will run only when 'dependency' changes
  //     // console.log('Effect triggered');
  //     // setPrevDependency(dependency);
  //     // if (IsLoginSession && window.location.pathname === "/dashboard-page") {
  //     //   if (window.location.pathname === "/dashboard-page") {
  //     //     preventBack();
  //     //   }
  //     // }
  //     preventBack();
  //   }
  // }, [dependency, prevDependency, window.location.href, path, currentLocation]);


  // useEffect(() => {
  //   console.log('window.location.pathname', window.location.pathname)
  //   if (IsLoginSession && window.location.pathname !== "/dashboard-page") {
  //     if (window.location.pathname !== "/dashboard-page") {
  //       setDependency('changed')
  //     }
  //   }
  // }, [window.location.pathname, window.location.href, path, currentLocation]);


  // function preventBack() {
  //   console.log("Outside preentBack conditon")
  //   if (IsLoginSession && window.location.href === window.location.origin + "/dashboard-page") {
  //     // alert('Inside conditon')
  //     window.history.pushState(null, "", window.location.href);
  //     window.onpopstate = function () {
  //       window.history.pushState(null, "", window.location.href);
  //     };
  //   }
  // }


  // don't remover code Dev Kashyap  its work on local completly

  useEffect(() => {
    // console.log(window.location.pathname)
    if (IsLoginSession && window.location.pathname === "/dashboard-page") {
      if (window.location.pathname === "/dashboard-page") {
        preventBack();
      }
    }

    // new Code
    // if (IsLoginSession && window.location.href === window.location.origin + "/dashboard-page") {
    //   preventBack();
    // }
  }, [window.location.pathname, window.location.href]);


  function preventBack() {
    window.onpopstate = function (event) {
      if (event.state && event.state.url === currentLocation || currentLocation === window.location.pathname) {
        window.history.go(1);
      }
    };
    window.history.pushState({ url: currentLocation }, '');
    window.history.pushState(null, null, currentLocation);
  }

  useEffect(() => {
    if (IsLoginSession === 'true' || IsLoginSession === true) {
      // console.log("Login Sucess")
    } else {
      setIsLogout(false);
      setLogByOtp(false)
      setAuthSession('');
      navigate('/');
    }
  }, [])

  useEffect(() => {
    if (IsLoginSession === 'true' || IsLoginSession === true) {
      // console.log("Login Sucess")
    } else {
      setIsLogout(false);
      setLogByOtp(false)
      setAuthSession('');
      navigate('/');
    }
  }, [IsLoginSession])


  // to sign out if user is not active from a long time
  const timerRef = useRef(null);
  const timeout = 1200000;

  const resetTimer = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(logout, timeout);
  };

  const logout = () => {
    window.location.href = '/'
    localStorage.clear()
  };

  useEffect(() => {
    resetTimer();
    const events = ['mousemove', 'mousedown', 'keypress', 'scroll', 'touchstart', 'load', 'click', 'resize'];
    const handleActivity = () => {
      resetTimer();
    };

    events.forEach(event => {
      window.addEventListener(event, handleActivity);
    });

    return () => {
      clearTimeout(timerRef.current);
      events.forEach(event => {
        window.removeEventListener(event, handleActivity);
      });
    };
  }, []);



  // // Handle tab close
  // useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //     localStorage.setItem('loginStatus', 'closed'); // Set status to closed
  //   };

  //   window.addEventListener('beforeunload', handleBeforeUnload);

  //   // Cleanup the event listener
  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // }, []);

  // // Check on tab focus to check for 'closed' state
  // useEffect(() => {
  //   const handleFocus = () => {
  //     const loginStatus = localStorage.getItem('loginStatus');
  //     if (loginStatus === 'closed') {
  //       localStorage.setItem('loginStatus', 'false'); // Reset the login status
  //       navigate('/'); // Redirect to login
  //       localStorage.clear();
  //       sessionStorage.clear();
  //     }
  //   };

  //   window.addEventListener('focus', handleFocus);

  //   // Cleanup the event listener
  //   return () => {
  //     window.removeEventListener('focus', handleFocus);
  //   };
  // }, [navigate]);


  return (
    <>
      {/* Sidebar */}
      {
        !IsLoginSession ? navigate('/')
          : <>
            <Sidebar {...{ listManagementSideBar, agencySideBar, propertyRoomSideBar, personnelSideBar, path, incidentSideBar, dashboardSidebar, nameSearchSideBar, arrestSearchSideBar, reportSidebar, searchSidebar, consolidationSideBar }} />
            <div className="page">
              <Header {...{ listManagementSideBar, agencySideBar, personnelSideBar }} />
              {/* Component */}
              <Com {...{ progressData, progressStatus }} />
            </div>
          </>
      }
    </>
  )
}

export default Auth


export function encrypt(plain) {
  const aesKey = '0ca175b9c0f726a831d895e269332461';
  const key = CryptoJS.enc.Utf8.parse(aesKey);
  const encryptedData = CryptoJS.AES.encrypt(plain, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  });
  return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Hex.parse(encryptedData.ciphertext.toString()));
}


export function decrypt(secret) {
  const aesKey = '0ca175b9c0f726a831d895e269332461';
  const key = CryptoJS.enc.Utf8.parse(aesKey);
  const decryptedData = CryptoJS.AES.decrypt(secret, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  });
  return decryptedData.toString(CryptoJS.enc.Utf8);
}
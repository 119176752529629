import React, { useContext, useEffect, useRef, useState } from 'react'
import DatePicker from "react-datepicker";
import Select from "react-select";
import { customStylesWithOutColor, Decrypt_Id_Name, getShowingDateText, getShowingMonthDateYear } from '../../../Common/Utility';
import { fetchPostData } from '../../../hooks/Api';
import { threeColArray } from '../../../Common/ChangeArrayFormat';
import { AgencyContext } from '../../../../Context/Agency/Index';
import { useDispatch } from 'react-redux';
import { get_PropertyLossCode_Drp_Data, get_PropertyTypeData } from '../../../../redux/actions/DropDownsData';
import { Property_LossCode_Drp_Data } from '../../../../redux/actionTypes';
import { useSelector } from 'react-redux';
import { get_LocalStoreData } from '../../../../redux/actions/Agency';
import { Link } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { toastifyError } from '../../../Common/AlertMsg';

const ChainOfCustodyReport = () => {
  const dispatch = useDispatch();
  const uniqueId = sessionStorage.getItem('UniqueUserID') ? Decrypt_Id_Name(sessionStorage.getItem('UniqueUserID'), 'UForUniqueUserID') : '';
  const localStoreData = useSelector((state) => state.Agency.localStoreData);
  const propertyTypeData = useSelector((state) => state.DropDown.propertyTypeData);
  const { setChangesStatus, } = useContext(AgencyContext);
  const [loginAgencyID, setLoginAgencyID] = useState('');
  const [propertyCategoryData, setPropertyCategoryData] = useState([]);
  const [verifyReport, setverifyReport] = useState(false);
  const [reportedData, setReportedData] = useState([]);
  const [multiImage, setMultiImage] = useState([]);

  const [value, setValue] = useState({
    'IncidentNumber': '', 'IncidentNumberTo': '', 'PropertyNumber': '', 'PropertyNumberTo': '', 'ReportedDate': null, 'ReportedDateTo': null, 'OccurredFrom': null, 'OccurredTo': null, 'AgencyID': '', 'PropertyTypeID': null, 'CategoryID': null,
  });
  const [searchValue, setSearchValue] = useState({
    PropertyTypeID: null,
    CategoryID: null,
    ReportedDtTm: '',
    ReportedDtTmTo: '',
    PropertyNumber: '',
    PropertyNumberTo: '',
    IncidentNumber: '',
    IncidentNumberTo: '',
    OccurredFrom: '',
    OccurredTo: '',
  
});

const [showFields, setShowFields] = useState({
    showPropertyTypeID: false,
    showCategoryID: false,
    showReportedDtTm: false,
    showReportedDtTmTo: false,
    showPropertyNumber: false,
    showPropertyNumberTo: false,
    showIncidentNumber: false,
    showIncidentNumberTo: false,
    showOccurredFrom: false,
    showOccurredTo: false,
    showValueFrom: false,
});
  useEffect(() => {
    if (!localStoreData?.AgencyID || !localStoreData?.PINID) {
      if (uniqueId) dispatch(get_LocalStoreData(uniqueId));
    }
  }, []);

  useEffect(() => {
    if (localStoreData) {
      setLoginAgencyID(localStoreData?.AgencyID);
      dispatch(get_PropertyTypeData(localStoreData?.AgencyID));
    }
  }, [localStoreData]);
  useEffect(() => {
    setShowFields({
        showPropertyTypeID: searchValue.PropertyTypeID !== null,
        showCategoryID: searchValue.CategoryID !== null,
        showReportedDtTm: searchValue.ReportedDtTm,
        showReportedDtTmTo: searchValue.ReportedDtTmTo,
        showPropertyNumber: searchValue.PropertyNumber,
        showPropertyNumberTo: searchValue.PropertyNumberTo,
        showIncidentNumber: searchValue.IncidentNumber,
        showIncidentNumberTo: searchValue.IncidentNumberTo,
        showOccurredFrom: searchValue.OccurredFrom,
        showOccurredTo: searchValue.OccurredTo,

    });
}, [searchValue]);
  const componentRef = useRef();

  const printForm = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Data',
    onAfterPrint: () => { '' }
  })
  const propertyhandle = (e,) => {
    if (e.target.name === 'PropertyNumber' || e.target.name === 'PropertyNumberTo') {
      var ele = e.target.value.replace(/[^a-zA-Z\s^0-9\s]/g, '');
      if (ele.length === 10) {
        var cleaned = ('' + ele).replace(/[^a-zA-Z\s^0-9\s]/g, '');
        var match = cleaned.match(/^(\w{3})(\d{7})$/);
        if (match) {
          setValue({
            ...value,
            [e.target.name]: match[1].toUpperCase() + '-' + match[2]
          })
        }
      } else {
        ele = e.target.value.split("'").join('').replace(/[^a-zA-Z\s^0-9\s]/g, '');
        setValue({
          ...value,
          [e.target.name]: ele
        })
      }
    } else {
      setValue({
        ...value,
        [e.target.name]: e.target.value
      })
    }
  }
  const handleChange = (e) => {
    if (e.target.name === 'IncidentNumber' || e.target.name === 'IncidentNumberTo') {
      var ele = e.target.value.replace(/[^a-zA-Z\s^0-9\s]/g, '');
      if (ele.length === 8) {
        var cleaned = ('' + ele).replace(/[^a-zA-Z\s^0-9\s]/g, '');
        var match = cleaned.match(/^(\d{2})(\d{6})$/);
        if (match) {
          // console.log(match)
          setValue({
            ...value,
            [e.target.name]: match[1] + '-' + match[2]
          })
        }
      } else {
        ele = e.target.value.split("'").join('').replace(/[^0-9\s]/g, '');
        setValue({
          ...value,
          [e.target.name]: ele
        })
      }
    } else {
      setValue({
        ...value,
        [e.target.name]: e.target.value
      })
    }
  }
  const getAgencyImg = (LoginAgencyID) => {
    const val = { 'AgencyID': LoginAgencyID }
    fetchPostData('Agency/GetDataAgencyPhoto', val).then((res) => {
      if (res) {
        // console.log(res)
        let imgUrl = `data:image/png;base64,${res[0]?.Agency_Photo}`;
        setMultiImage(imgUrl);

      }
      else { console.log("errror") }
    })
  }
  const startRef = React.useRef();
  const startRef1 = React.useRef();
  const startRef2 = React.useRef();
  const startRef3 = React.useRef();


  const onKeyDown = (e) => {
    if (e.keyCode === 9 || e.which === 9) {
      startRef.current.setOpen(false);
      startRef1.current.setOpen(false);
      startRef2.current.setOpen(false);
      startRef3.current.setOpen(false);

    }
  };
  const PropertyCategory = (CategoryID) => {
    const val = { CategoryID: CategoryID, }
    fetchPostData('Property/GetDataDropDown_PropertyType', val).then((data) => {
      if (data) {
        setPropertyCategoryData(threeColArray(data, 'PropertyDescID', 'Description', 'CategoryID'))
      } else {
        setPropertyCategoryData([]);
      }
    })
  }
  const ChangeDropDown = (e, name) => {
    if (e) {
      if (name === 'SuspectedDrugTypeID') {
        setChangesStatus(true)
        setValue({
          ...value,
          [name]: e.value, 'SuspectedDrugType_Description': e.label, 'TypeMarijuana': '', 'MarijuanaNumber': '', '  ClandistineLabsNumber': '', 'DrugManufactured': '',
        });
      } else if (name === 'PropertyTypeID') {
        switch (e.id) {
          case 'A': dispatch(get_PropertyLossCode_Drp_Data(loginAgencyID, '1', '', '', '', '', '')); break;
          case 'B': dispatch(get_PropertyLossCode_Drp_Data(loginAgencyID, '', '1', '', '', '', '')); break;
          case 'S': dispatch(get_PropertyLossCode_Drp_Data(loginAgencyID, '', '', '1', '', '', '')); break;
          case 'O': dispatch(get_PropertyLossCode_Drp_Data(loginAgencyID, '', '', '', '1', '', '')); break;
          case 'D': dispatch(get_PropertyLossCode_Drp_Data(loginAgencyID, '', '', '', '', '1', '')); break;
          case 'G': dispatch(get_PropertyLossCode_Drp_Data(loginAgencyID, '', '', '', '', '', '1')); break;
          default: dispatch(get_PropertyLossCode_Drp_Data(loginAgencyID, '1', '', '', '', '', ''));;
        }
        PropertyCategory(e.value);
        setChangesStatus(true);
        setValue({ ...value, ['PropertyCategoryCode']: e.id, ['PropertyTypeID']: e.value, ['ClassificationID']: null, ['LossCodeID']: null, ['Value']: '', });
      } else if (name === 'CategoryID') {
        setChangesStatus(true)
        setValue({ ...value, [name]: e.value });
      }
      else {
        setChangesStatus(true)
        setValue({ ...value, [name]: e.value });
      }
    } else {
      if (name === 'SuspectedDrugTypeID') {
        setChangesStatus(true)
        setValue({
          ...value,
          [name]: null
        });
      } else if (name === 'PropertyTypeID') {
        setChangesStatus(true);
        setValue({
          ...value,
          ['PropertyTypeID']: null, ['PropertyCategoryCode']: '', ['CategoryID']: null, ['ClassificationID']: null, ['LossCodeID']: null, ['Value']: '',
        });
        setPropertyCategoryData([]);
        dispatch({ type: Property_LossCode_Drp_Data, payload: [] });
      }
      else if (name === 'CategoryID') {
        setChangesStatus(true);
        setValue({ ...value, ['CategoryID']: null, ['ClassificationID']: null, });
      }

      else {
        setChangesStatus(true);
        setValue({ ...value, [name]: null });
      }
      void 0;
    }
  }

  const handleChange1 = (e) => {
    const { name, checked } = e.target;
    setValue(prevValue => ({
      ...prevValue,
      [name]: checked
    }));
  };
  const isNotEmpty = (value) => {
    console.log(value)
    return value !== null && value.trim() !== '';
  }
  const get_ChainReport = () => {
    const { ReportedDtTm, ReportedDtTmTo, PropertyNumber, PropertyNumberTo, PropertyTypeID, IncidentNumber, IncidentNumberTo,OccurredFrom,OccurredTo,CategoryID } = value
    const isValid = isNotEmpty(ReportedDtTm) || isNotEmpty(ReportedDtTmTo) || isNotEmpty(PropertyNumberTo) || isNotEmpty(IncidentNumber) || isNotEmpty(IncidentNumberTo) || isNotEmpty(OccurredFrom)|| isNotEmpty(OccurredTo) || isNotEmpty(PropertyNumber) || (PropertyTypeID !== null && PropertyTypeID !== '')||(CategoryID !== null && CategoryID !== '')
    if (isValid) {
      const val = {
        'ReportedDtTm': ReportedDtTm, 'ReportedDtTmTo': ReportedDtTmTo,
        'OccurredFrom': OccurredFrom, 'OccurredTo': OccurredTo,
        'PropertyNumber': PropertyNumber, 'PropertyNumberTo': PropertyNumberTo,
        'AgencyID': loginAgencyID,
        'PropertyTypeID': PropertyTypeID,
        'CategoryID': CategoryID,
        'IncidentNumber': IncidentNumber, 'IncidentNumberTo': IncidentNumberTo,

      }
      fetchPostData('ReportProperty/GetData_ReportProperty', val).then((res) => {
        if (res.length > 0) {
          console.log(res)
          setReportedData(res[0]);
          setverifyReport(true);
          getAgencyImg(loginAgencyID)
          setSearchValue(value);
        }
        else {
          toastifyError("Data Not Available");
          setverifyReport(false); setReportedData([]);
        }
      })
    }
    else {
      toastifyError("Please Enter Details");
    }
  }
  const resetFields = () => {
    setValue({
      ...value,
      'PropertyTypeID': null, 'ReportedDtTm': "", 'ReportedDtTmTo': "", 'OccurredFrom': "", 'OccurredTo': "",'PropertyNumber': '', 'PropertyNumberTo': '',
      'IncidentNumberTo': '', 'IncidentNumber': '','CategoryID':null,
    });
    setverifyReport(false); setReportedData([]);

  }

  return (
    <>
      <div class="section-body view_page_design pt-1" >
        <div className="row clearfix">
          <div className="col-12 col-sm-12">
            <div className="card Agency">
              <div className="card-body">
                <div className="col-12 col-md-12 col-lg-12  " >
                  <fieldset>
                    <legend>Chain Of Custody Report</legend>
                    <div className="row mt-2">
                      <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                        <label htmlFor="" className='new-label'>Property Number From</label>
                      </div>
                      <div className="col-3 col-md-3 col-lg-3 mt-1 text-field">
                        <input type="text" name='PropertyNumber' id='PropertyNumber' value={value?.PropertyNumber} maxLength={10} onChange={propertyhandle} className='' />
                      </div>
                      <div className="col-3 col-md-3 col-lg-3 mt-2 ">
                        <label htmlFor="" className='new-label'>Property Number To</label>
                      </div>
                      <div className="col-3 col-md-3 col-lg-3 mt-1 text-field">
                        <input type="text" name='PropertyNumberTo' id='PropertyNumberTo' value={value?.PropertyNumberTo} maxLength={10} onChange={propertyhandle} className='' />
                      </div>
                      <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                        <label htmlFor="" className='new-label'>Incident Number From</label>
                      </div>
                      <div className="col-3 col-md-3 col-lg-3 mt-1 text-field">
                        <input type="text" name='IncidentNumber' maxLength={9} id='IncidentNumber' value={value.IncidentNumber} onChange={handleChange} className='' />
                      </div>
                      <div className="col-3 col-md-3 col-lg-3 mt-2 ">
                        <label htmlFor="" className='new-label'>Incident Number To</label>
                      </div>
                      <div className="col-3 col-md-3 col-lg-3 mt-1 text-field">
                        <input type="text" name='IncidentNumberTo'
                          // disabled={!value.IncidentNumber}
                          disabled={!value?.IncidentNumber}
                          className={!value?.IncidentNumber ? 'readonlyColor' : ''}
                          maxLength={9} id='IncidentNumberTo' value={value.IncidentNumberTo} onChange={handleChange} />
                      </div>
                      <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                        <label htmlFor="" className='new-label'>Reported From Date</label>
                      </div>
                      <div className="col-3 col-md-3 col-lg-3 mb-1">
                        <DatePicker
                          name='ReportedDtTm'
                          id='ReportedDtTm'
                          ref={startRef}
                          onKeyDown={onKeyDown}
                          onChange={(date) => { setValue({ ...value, ['ReportedDtTm']: date ? getShowingDateText(date) : null, ['ReportedDtTmTo']: null }) }}
                          selected={value?.ReportedDtTm && new Date(value?.ReportedDtTm)}
                          dateFormat="MM/dd/yyyy"
                          timeInputLabel
                          isClearable={value?.ReportedDtTm ? true : false}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          autoComplete='Off'
                          // disabled
                          maxDate={new Date()}
                          placeholderText='Select...'
                        />
                      </div>
                      <div className="col-3 col-md-3 col-lg-3 mt-2 ">
                        <label htmlFor="" className='new-label'>Reported To Date</label>
                      </div>
                      <div className="col-3 col-md-3 col-lg-3 mb-1">
                        <DatePicker
                          name='ReportedDtTmTo'
                          id='ReportedDtTmTo'
                          ref={startRef1}
                          onKeyDown={onKeyDown}
                          onChange={(date) => { setValue({ ...value, ['ReportedDtTmTo']: date ? getShowingDateText(date) : null }) }}
                          selected={value?.ReportedDtTmTo && new Date(value?.ReportedDtTmTo)}
                          dateFormat="MM/dd/yyyy"
                          timeInputLabel
                          isClearable={value?.ReportedDtTmTo ? true : false}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          autoComplete='Off'
                          // maxDate={new Date()}
                          placeholderText='Select...'
                          maxDate={new Date()}
                          minDate={new Date(value?.ReportedDtTm)}
                          disabled={value?.ReportedDtTm ? false : true}
                          className={!value?.ReportedDtTm && 'readonlyColor'}
                        />
                      </div>
                      <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                        <label htmlFor="" className='new-label'>Occurred From Date</label>
                      </div>
                      <div className="col-3 col-md-3 col-lg-3">
                        <DatePicker
                          name='OccurredFrom'
                          id='OccurredFrom'
                          ref={startRef2}
                          onKeyDown={onKeyDown}
                          onChange={(date) => {
                            if (date) {
                              setValue({ ...value, ['OccurredFrom']: date ? getShowingDateText(date) : null })
                            } else {
                              setValue({ ...value, ['OccurredFrom']: null, ['OccurredTo']: null })
                            }
                          }}
                          selected={value?.OccurredFrom && new Date(value?.OccurredFrom)}
                          dateFormat="MM/dd/yyyy"
                          timeInputLabel
                          isClearable={value?.OccurredFrom ? true : false}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          autoComplete='Off'
                          // disabled
                          maxDate={new Date()}
                          placeholderText='Select...'
                        />
                      </div>
                      <div className="col-3 col-md-3 col-lg-3 mt-2 ">
                        <label htmlFor="" className='new-label'>Occurred To Date</label>
                      </div>
                      <div className="col-3 col-md-3 col-lg-3">
                        <DatePicker
                          id='OccurredTo'
                          name='OccurredTo'
                          ref={startRef3}
                          onKeyDown={onKeyDown}
                          onChange={(date) => { setValue({ ...value, ['OccurredTo']: date ? getShowingMonthDateYear(date) : null }) }}
                          dateFormat="MM/dd/yyyy"
                          isClearable={value?.OccurredTo ? true : false}
                          disabled={value?.OccurredFrom ? false : true}
                          selected={value?.OccurredTo && new Date(value?.OccurredTo)}
                          minDate={new Date(value?.OccurredFrom)}
                          maxDate={new Date()}
                          placeholderText={'Select...'}
                          showDisabledMonthNavigation
                          autoComplete="off"
                          showYearDropdown
                          showMonthDropdown
                          dropdownMode="select"
                        />
                      </div>
                      <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                        <label htmlFor="" className='new-label'>Type</label>
                      </div>
                      <div className="col-3 col-md-3 col-lg-3 mt-1" >
                        <Select
                          styles={customStylesWithOutColor}
                          name='PropertyTypeID'
                          value={propertyTypeData?.filter((obj) => obj.value === value?.PropertyTypeID)}
                          options={propertyTypeData}
                          onChange={(e) => ChangeDropDown(e, 'PropertyTypeID')}
                          isClearable
                          placeholder="Select..."
                        />
                      </div>
                      <div className="col-3 col-md-3 col-lg-3 mt-2 ">
                        <label htmlFor="" className='new-label'>Category</label>
                      </div>
                      <div className="col-3 col-md-3 col-lg-3 mt-1" >
                        <Select
                          name='CategoryID'
                          id='CategoryID'
                          styles={customStylesWithOutColor}
                          value={propertyCategoryData?.filter((obj) => obj.value === value?.CategoryID)}
                          options={propertyCategoryData}
                          onChange={(e) => ChangeDropDown(e, 'CategoryID')}
                          isClearable
                          placeholder="Select..."
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-2"></div>
                      <div className="col-6 col-md-6 col-lg-2 mt-3 mb-1">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="Stolen"
                            checked={value?.Stolen || false}
                            onChange={handleChange1}
                            id="flexCheckStolen"
                          />
                          <label className="form-check-label" htmlFor="flexCheckStolen">
                            Stolen
                          </label>
                        </div>
                      </div>
                      <div className="col-6 col-md-6 col-lg-2 mt-3 mb-1">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="Recovered"
                            checked={value?.Recovered || false}
                            onChange={handleChange1}
                            id="flexCheckRecovered"
                          />
                          <label className="form-check-label" htmlFor="flexCheckRecovered">
                            Recovered
                          </label>
                        </div>
                      </div>
                      <div className="col-6 col-md-6 col-lg-2 mt-3 mb-1">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="Towed"
                            checked={value?.Towed || false}
                            onChange={handleChange1}
                            id="flexCheckTowed"
                          />
                          <label className="form-check-label" htmlFor="flexCheckTowed">
                            Towed
                          </label>
                        </div>
                      </div>
                      <div className="col-6 col-md-6 col-lg-2 mt-3 mb-1">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="Damaged"
                            checked={value?.Damaged || false}
                            onChange={handleChange1}
                            id="flexCheckDamaged"
                          />
                          <label className="form-check-label" htmlFor="flexCheckDamaged">
                            Damaged
                          </label>
                        </div>
                      </div>
                    </div>

                  </fieldset>
                  <div className="col-12 col-md-12 col-lg-12 text-right mt-1">
                    <button className="btn btn-sm bg-green text-white px-2 py-1"
                      onClick={() => { get_ChainReport(); }}>Show Report</button>
                    <button className="btn btn-sm bg-green text-white px-2 py-1 ml-2" onClick={() => { resetFields(); }}>Clear</button>
                    <Link to={'/Reports'}>
                      <button className="btn btn-sm bg-green text-white px-2 py-1 ml-2" >Close</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {
          verifyReport ?

            reportedData?.Property?.length > 0 ?
              <>
                <div className="col-12 col-md-12 col-lg-12 pt-2  px-2">
                  <div className="bg-line  py-1 px-2 mt-1 d-flex justify-content-between align-items-center">
                    <p className="p-0 m-0 d-flex align-items-center">Chain Of Custody Report</p>
                    <div style={{ marginLeft: 'auto' }}>
                      <Link to={''} onClick={() => { printForm(); }} className="btn btn-sm bg-green  mr-2 text-white px-2 py-0"  >
                        <i className="fa fa-print"></i>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="container mt-1" ref={componentRef} >
                  <div className="row" style={{ border: '1px solid #80808085' }}>
                    <>
                      <div className="col-4 col-md-3 col-lg-2 pt-1">
                        <div className="main">
                          {/* <img src={reportedData?.Agency_Photo} className="img-fluid" alt style={{ border: '1px solid aliceblue', marginTop: '4px', width: '150px' }} /> */}
                          <div className="img-box" >
                            <img src={multiImage} className='picture' style={{ marginTop: '4px', width: '150px', height: '150px' }} />
                          </div>
                        </div>
                      </div>
                      <div className="col-7  col-md-7 col-lg-9 mt-2">
                        <div className="main">
                          <h5 className='text-dark text-bold'>{reportedData?.Agency_Name}</h5>
                          <p className='text-p'>Address: <span className='new-span pl-2'>{reportedData?.Agency_Address1}</span></p>
                          <div className='d-flex '>
                            <p className='text-p'>State: <span className='new-span ml-2'>{reportedData?.StateName}</span>
                            </p>
                            <p className='text-p ml-5 pl-1'>City: <span className='new-span'>{reportedData?.CityName}</span>
                            </p>
                            <p className='text-p ml-2'>Zip: <span className='new-span'>{reportedData?.ZipId}</span>
                            </p>
                          </div>
                          <div className='d-flex'>
                            <p className='text-p'>Phone: <span className='new-span'>{reportedData?.Agency_Phone}</span></p>
                          </div>
                        </div>
                      </div>
                    </>

                    {
                      reportedData?.Property?.length > 0 ?
                        <>
                          {
                            reportedData?.Property?.map((obj) => (
                              <>

                                <div className="container" >
                                  <div className="property-room px-3">
                                    <div className="row">
                                      <div className="col-4 col-md-3 col-lg-2 mt-2">
                                        <div className="img-box" >
                                          <img src={reportedData?.Agency_Photo} className='picture' style={{ width: '150px', height: '140px' }} />
                                        </div>
                                      </div>
                                      <div className="col-8 col-md-9 col-lg-10">
                                        <div className="row mt-3">
                                          <div className="col-7 d-flex justify-content-center">
                                            <h6>Agency:</h6>
                                            <span>{reportedData.Agency_Name}</span>
                                          </div>
                                          <div className="col-7 d-flex justify-content-center">
                                            <h6>Phone:</h6>
                                            <span>{reportedData.Agency_Phone}</span>
                                          </div>
                                          <div className="col-7 d-flex justify-content-center">
                                            <h6>Fax:</h6>
                                            <span>{reportedData.Agency_Fax}</span>
                                          </div>
                                          <div className="col-7 d-flex justify-content-center">
                                            <h6>Address:</h6>
                                            <span>{reportedData.Agency_Address1}</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <hr />
                                  {/* Article info */}
                                  {reportedData.Property[0].Type === 'Article' && (
                                    <>
                                      <div className="property-info px-0">
                                        <fieldset>
                                          <legend className='prop-legend px-0'>Evidence Property Information</legend>
                                          <table className="table table-bordered mt-3">
                                            <tbody>
                                              <tr className="table-row">
                                                <td className="table-cell">
                                                  <span className='prop-td'>Property No:</span>
                                                  <p>{reportedData.Property[0].PropertyNumber}</p>
                                                </td>
                                                <td className="table-cell">
                                                  <span className='prop-td'>Property Type:</span>
                                                  <p>{reportedData.Property[0].Type}</p>
                                                </td>
                                                <td className="table-cell">
                                                  <span className='prop-td'>Reported Date:</span>
                                                  <p>{reportedData.Property[0].ReportedDtTm}</p>
                                                </td>
                                              </tr>
                                              <tr className="table-row">
                                                <td className="table-cell">
                                                  <span className='prop-td'>Incident No:</span>
                                                  <p>{reportedData.Property[0].IncidentNumber}</p>
                                                </td>
                                                <td className="table-cell">
                                                  <span className='prop-td'>Loss Code:</span>
                                                  <p>{reportedData.Property[0].LossCode_Description}</p>
                                                </td>
                                                <td className="table-cell">
                                                  <span className='prop-td'>Owner Name:</span>
                                                  <p>{reportedData.Property[0].OwnerDescription || 'N/A'}</p>
                                                </td>
                                              </tr>
                                              <tr className="table-row">
                                                <td className="table-cell">
                                                  <span className='prop-td'>Category:</span>
                                                  <p>{reportedData.Property[0].Category_Description}</p>
                                                </td>
                                                <td className="table-cell">
                                                  <span className='prop-td'>Classification:</span>
                                                  <p>{reportedData.Property[0].Classification_Description || 'N/A'}</p>
                                                </td>
                                                <td className="table-cell">
                                                  <span className='prop-td'>Brand:</span>
                                                  <p>{reportedData.Property[0].Brand}</p>
                                                </td>
                                              </tr>
                                              <tr className="table-row">
                                                <td className="table-cell">
                                                  <span className='prop-td'>Top Color:</span>
                                                  <p>{reportedData.Property[0].TopColor_Description}</p>
                                                </td>
                                                <td className="table-cell">
                                                  <span className='prop-td'>Bottom Color:</span>
                                                  <p>{reportedData.Property[0].BottomColor_Description}</p>
                                                </td>
                                                <td className="table-cell">
                                                  <span className='prop-td'>Quantity:</span>
                                                  <p>{reportedData.Property[0].Quantity}</p>
                                                </td>
                                              </tr>
                                              <tr className="table-row">
                                                <td className="table-cell" colSpan="3">
                                                  <span className='prop-td'>Misc Description:</span>
                                                  <p>{reportedData.Property[0].Description}</p>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </fieldset>
                                      </div>
                                      <div className="release-info">
                                        <fieldset>
                                          <legend className='prop-legend'>Chain Of Custody Information</legend>
                                          <table className="table table-bordered mt-3">
                                            <thead className='text-dark master-table'>
                                              <tr>
                                                <th className=''>Incident No.</th>
                                                <th className=''>Activity Date/Time</th>
                                                <th className=''>Activity Type</th>
                                                <th className=''>Officer</th>
                                                <th className=''>Property Room Person</th>
                                                <th className=''>Location/Reason</th>
                                                <th className=''>Destroyed Date/Time</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {reportedData.PropertyRoom.map((item, index) => (
                                                <tr key={index}>
                                                  <td className='text-list'>{item.IncidentNumber1}</td>
                                                  <td className='text-list'>{item.ChainDate || 'N/A'}</td>
                                                  <td className='text-list'>{item.ActivityReason_Des}</td>
                                                  <td className='text-list'>{item.Officer_Name}</td>
                                                  <td className='text-list'>{item.PropertyRoomPerson_Name || 'N/A'}</td>
                                                  <td className='text-list'>{item.location || 'N/A'}</td>
                                                  <td className='text-list'>{item.DestroyDate || 'N/A'}</td>
                                                </tr>
                                              ))}
                                            </tbody>
                                          </table>
                                        </fieldset>
                                      </div>
                                    </>

                                  )}

                                  {/* Boat info */}
                                  {reportedData.Property[0].Type === 'Boat' && (
                                    <>
                                      <div className="property-info px-0">
                                        <fieldset>
                                          <legend className='prop-legend px-0'>Evidence Property Information</legend>
                                          <table className="table table-bordered mt-3">
                                            <tbody>
                                              <tr className="table-row">
                                                <td className="table-cell">
                                                  <span className='prop-td'>Property No:</span>
                                                  <p>{reportedData.Property[0].PropertyNumber}</p>
                                                </td>
                                                <td className="table-cell">
                                                  <span className='prop-td'>Property Type:</span>
                                                  <p>{reportedData.Property[0].Type}</p>
                                                </td>
                                                <td className="table-cell">
                                                  <span className='prop-td'>Reported Date:</span>
                                                  <p>{reportedData.Property[0].ReportedDtTm}</p>
                                                </td>
                                              </tr>
                                              <tr className="table-row">
                                                <td className="table-cell">
                                                  <span className='prop-td'>Incident No:</span>
                                                  <p>{reportedData.Property[0].IncidentNumber}</p>
                                                </td>
                                                <td className="table-cell">
                                                  <span className='prop-td'>Loss Code:</span>
                                                  <p>{reportedData.Property[0].LossCode_Description}</p>
                                                </td>
                                                <td className="table-cell">
                                                  <span className='prop-td'>Owner Name:</span>
                                                  <p>{reportedData.Property[0].OwnerDescription || 'N/A'}</p>
                                                </td>
                                              </tr>
                                              <tr className="table-row">
                                                <td className="table-cell">
                                                  <span className='prop-td'>Category:</span>
                                                  <p>{reportedData.Property[0].Category_Description}</p>
                                                </td>
                                                <td className="table-cell">
                                                  <span className='prop-td'>Classification:</span>
                                                  <p>{reportedData.Property[0].Classification_Description || 'N/A'}</p>
                                                </td>
                                                <td className="table-cell">
                                                  <span className='prop-td'>Brand:</span>
                                                  <p>{reportedData.Property[0].Brand}</p>
                                                </td>
                                              </tr>
                                              <tr className="table-row">
                                                <td className="table-cell">
                                                  <span className='prop-td'>Top Color:</span>
                                                  <p>{reportedData.Property[0].TopColor_Description}</p>
                                                </td>
                                                <td className="table-cell">
                                                  <span className='prop-td'>Bottom Color:</span>
                                                  <p>{reportedData.Property[0].BottomColor_Description}</p>
                                                </td>
                                                <td className="table-cell">
                                                  <span className='prop-td'>Quantity:</span>
                                                  <p>{reportedData.Property[0].Quantity}</p>
                                                </td>
                                              </tr>
                                              <tr className="table-row">
                                                <td className="table-cell" colSpan="3">
                                                  <span className='prop-td'>Misc Description:</span>
                                                  <p>{reportedData.Property[0].Description}</p>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </fieldset>
                                      </div>
                                      <div className="release-info">
                                        <fieldset>
                                          <legend className='prop-legend'>Chain Of Custody Information</legend>
                                          <table className="table table-bordered mt-3">
                                            <thead className='text-dark master-table'>
                                              <tr>
                                                <th className=''>Incident No.</th>
                                                <th className=''>Activity Date/Time</th>
                                                <th className=''>Activity Type</th>
                                                <th className=''>Officer</th>
                                                <th className=''>Property Room Person</th>
                                                <th className=''>Location/Reason</th>
                                                <th className=''>Destroyed Date/Time</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {reportedData.PropertyRoom.map((item, index) => (
                                                <tr key={index}>
                                                  <td className='text-list'>{item.IncidentNumber1}</td>
                                                  <td className='text-list'>{item.ChainDate || 'N/A'}</td>
                                                  <td className='text-list'>{item.ActivityReason_Des}</td>
                                                  <td className='text-list'>{item.Officer_Name}</td>
                                                  <td className='text-list'>{item.PropertyRoomPerson_Name || 'N/A'}</td>
                                                  <td className='text-list'>{item.location || 'N/A'}</td>
                                                  <td className='text-list'>{item.DestroyDate || 'N/A'}</td>
                                                </tr>
                                              ))}
                                            </tbody>
                                          </table>
                                        </fieldset>
                                      </div>
                                    </>

                                  )}

                                  {/* Drug info */}

                                  {reportedData.Property[0].Type === 'Drug' && (
                                    <>
                                      <div className="property-info px-0">
                                        <fieldset>
                                          <legend className='prop-legend px-0'>Evidence Property Information</legend>
                                          <table className="table table-bordered mt-3">
                                            <tbody>
                                              <tr className="table-row">
                                                <td className="table-cell">
                                                  <span className='prop-td'>Property No:</span>
                                                  <p>{reportedData.Property[0].PropertyNumber}</p>
                                                </td>
                                                <td className="table-cell">
                                                  <span className='prop-td'>Property Type:</span>
                                                  <p>{reportedData.Property[0].Type}</p>
                                                </td>
                                                <td className="table-cell">
                                                  <span className='prop-td'>Reported Date:</span>
                                                  <p>{reportedData.Property[0].ReportedDtTm}</p>
                                                </td>
                                              </tr>
                                              <tr className="table-row">
                                                <td className="table-cell">
                                                  <span className='prop-td'>Incident No:</span>
                                                  <p>{reportedData.Property[0].IncidentNumber}</p>
                                                </td>
                                                <td className="table-cell">
                                                  <span className='prop-td'>Loss Code:</span>
                                                  <p>{reportedData.Property[0].LossCode_Description}</p>
                                                </td>
                                                <td className="table-cell">
                                                  <span className='prop-td'>Owner Name:</span>
                                                  <p>{reportedData.Property[0].OwnerDescription || 'N/A'}</p>
                                                </td>
                                              </tr>
                                              <tr className="table-row">
                                                <td className="table-cell">
                                                  <span className='prop-td'>Category:</span>
                                                  <p>{reportedData.Property[0].Category_Description}</p>
                                                </td>
                                                <td className="table-cell">
                                                  <span className='prop-td'>Classification:</span>
                                                  <p>{reportedData.Property[0].Classification_Description || 'N/A'}</p>
                                                </td>
                                                <td className="table-cell">
                                                  <span className='prop-td'>Brand:</span>
                                                  <p>{reportedData.Property[0].Brand}</p>
                                                </td>
                                              </tr>
                                              <tr className="table-row">
                                                <td className="table-cell">
                                                  <span className='prop-td'>Top Color:</span>
                                                  <p>{reportedData.Property[0].TopColor_Description}</p>
                                                </td>
                                                <td className="table-cell">
                                                  <span className='prop-td'>Bottom Color:</span>
                                                  <p>{reportedData.Property[0].BottomColor_Description}</p>
                                                </td>
                                                <td className="table-cell">
                                                  <span className='prop-td'>Quantity:</span>
                                                  <p>{reportedData.Property[0].Quantity}</p>
                                                </td>
                                              </tr>
                                              <tr className="table-row">
                                                <td className="table-cell" colSpan="3">
                                                  <span className='prop-td'>Misc Description:</span>
                                                  <p>{reportedData.Property[0].Description}</p>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </fieldset>
                                      </div>
                                      <div className="release-info">
                                        <fieldset>
                                          <legend className='prop-legend'>Chain Of Custody Information</legend>
                                          <table className="table table-bordered mt-3">
                                            <thead className='text-dark master-table'>
                                              <tr>
                                                <th className=''>Incident No.</th>
                                                <th className=''>Activity Date/Time</th>
                                                <th className=''>Activity Type</th>
                                                <th className=''>Officer</th>
                                                <th className=''>Property Room Person</th>
                                                <th className=''>Location/Reason</th>
                                                <th className=''>Destroyed Date/Time</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {reportedData.PropertyRoom.map((item, index) => (
                                                <tr key={index}>
                                                  <td className='text-list'>{item.IncidentNumber1}</td>
                                                  <td className='text-list'>{item.ChainDate || 'N/A'}</td>
                                                  <td className='text-list'>{item.ActivityReason_Des}</td>
                                                  <td className='text-list'>{item.Officer_Name}</td>
                                                  <td className='text-list'>{item.PropertyRoomPerson_Name || 'N/A'}</td>
                                                  <td className='text-list'>{item.location || 'N/A'}</td>
                                                  <td className='text-list'>{item.DestroyDate || 'N/A'}</td>
                                                </tr>
                                              ))}
                                            </tbody>
                                          </table>
                                        </fieldset>
                                      </div>
                                    </>

                                  )}
                                  {/* Other info */}

                                  {reportedData.Property[0].Type === 'Other' && (
                                    <>
                                      <div className="property-info px-0">
                                        <fieldset>
                                          <legend className='prop-legend px-0'>Evidence Property Information</legend>
                                          <table className="table table-bordered mt-3">
                                            <tbody>
                                              <tr className="table-row">
                                                <td className="table-cell">
                                                  <span className='prop-td'>Property No:</span>
                                                  <p>{reportedData.Property[0].PropertyNumber}</p>
                                                </td>
                                                <td className="table-cell">
                                                  <span className='prop-td'>Property Type:</span>
                                                  <p>{reportedData.Property[0].Type}</p>
                                                </td>
                                                <td className="table-cell">
                                                  <span className='prop-td'>Reported Date:</span>
                                                  <p>{reportedData.Property[0].ReportedDtTm}</p>
                                                </td>
                                              </tr>
                                              <tr className="table-row">
                                                <td className="table-cell">
                                                  <span className='prop-td'>Incident No:</span>
                                                  <p>{reportedData.Property[0].IncidentNumber}</p>
                                                </td>
                                                <td className="table-cell">
                                                  <span className='prop-td'>Loss Code:</span>
                                                  <p>{reportedData.Property[0].LossCode_Description}</p>
                                                </td>
                                                <td className="table-cell">
                                                  <span className='prop-td'>Owner Name:</span>
                                                  <p>{reportedData.Property[0].OwnerDescription || 'N/A'}</p>
                                                </td>
                                              </tr>
                                              <tr className="table-row">
                                                <td className="table-cell">
                                                  <span className='prop-td'>Category:</span>
                                                  <p>{reportedData.Property[0].Category_Description}</p>
                                                </td>
                                                <td className="table-cell">
                                                  <span className='prop-td'>Classification:</span>
                                                  <p>{reportedData.Property[0].Classification_Description || 'N/A'}</p>
                                                </td>
                                                <td className="table-cell">
                                                  <span className='prop-td'>Brand:</span>
                                                  <p>{reportedData.Property[0].Brand}</p>
                                                </td>
                                              </tr>
                                              <tr className="table-row">
                                                <td className="table-cell">
                                                  <span className='prop-td'>Top Color:</span>
                                                  <p>{reportedData.Property[0].TopColor_Description}</p>
                                                </td>
                                                <td className="table-cell">
                                                  <span className='prop-td'>Bottom Color:</span>
                                                  <p>{reportedData.Property[0].BottomColor_Description}</p>
                                                </td>
                                                <td className="table-cell">
                                                  <span className='prop-td'>Quantity:</span>
                                                  <p>{reportedData.Property[0].Quantity}</p>
                                                </td>
                                              </tr>
                                              <tr className="table-row">
                                                <td className="table-cell" colSpan="3">
                                                  <span className='prop-td'>Misc Description:</span>
                                                  <p>{reportedData.Property[0].Description}</p>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </fieldset>
                                      </div>
                                      <div className="release-info">
                                        <fieldset>
                                          <legend className='prop-legend'>Chain Of Custody Information</legend>
                                          <table className="table table-bordered mt-3">
                                            <thead className='text-dark master-table'>
                                              <tr>
                                                <th className=''>Incident No.</th>
                                                <th className=''>Activity Date/Time</th>
                                                <th className=''>Activity Type</th>
                                                <th className=''>Officer</th>
                                                <th className=''>Property Room Person</th>
                                                <th className=''>Location/Reason</th>
                                                <th className=''>Destroyed Date/Time</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {reportedData.PropertyRoom.map((item, index) => (
                                                <tr key={index}>
                                                  <td className='text-list'>{item.IncidentNumber1}</td>
                                                  <td className='text-list'>{item.ChainDate || 'N/A'}</td>
                                                  <td className='text-list'>{item.ActivityReason_Des}</td>
                                                  <td className='text-list'>{item.Officer_Name}</td>
                                                  <td className='text-list'>{item.PropertyRoomPerson_Name || 'N/A'}</td>
                                                  <td className='text-list'>{item.location || 'N/A'}</td>
                                                  <td className='text-list'>{item.DestroyDate || 'N/A'}</td>
                                                </tr>
                                              ))}
                                            </tbody>
                                          </table>
                                        </fieldset>
                                      </div>
                                    </>

                                  )}


                                  {/* Security info */}

                                  {reportedData.Property[0].Type === 'Security' && (
                                    <>
                                      <div className="property-info px-0">
                                        <fieldset>
                                          <legend className='prop-legend px-0'>Evidence Property Information</legend>
                                          <table className="table table-bordered mt-3">
                                            <tbody>
                                              <tr className="table-row">
                                                <td className="table-cell">
                                                  <span className='prop-td'>Property No:</span>
                                                  <p>{reportedData.Property[0].PropertyNumber}</p>
                                                </td>
                                                <td className="table-cell">
                                                  <span className='prop-td'>Property Type:</span>
                                                  <p>{reportedData.Property[0].Type}</p>
                                                </td>
                                                <td className="table-cell">
                                                  <span className='prop-td'>Reported Date:</span>
                                                  <p>{reportedData.Property[0].ReportedDtTm}</p>
                                                </td>
                                              </tr>
                                              <tr className="table-row">
                                                <td className="table-cell">
                                                  <span className='prop-td'>Incident No:</span>
                                                  <p>{reportedData.Property[0].IncidentNumber}</p>
                                                </td>
                                                <td className="table-cell">
                                                  <span className='prop-td'>Loss Code:</span>
                                                  <p>{reportedData.Property[0].LossCode_Description}</p>
                                                </td>
                                                <td className="table-cell">
                                                  <span className='prop-td'>Owner Name:</span>
                                                  <p>{reportedData.Property[0].OwnerDescription || 'N/A'}</p>
                                                </td>
                                              </tr>
                                              <tr className="table-row">
                                                <td className="table-cell">
                                                  <span className='prop-td'>Category:</span>
                                                  <p>{reportedData.Property[0].Category_Description}</p>
                                                </td>
                                                <td className="table-cell">
                                                  <span className='prop-td'>Classification:</span>
                                                  <p>{reportedData.Property[0].Classification_Description || 'N/A'}</p>
                                                </td>
                                                <td className="table-cell">
                                                  <span className='prop-td'>Brand:</span>
                                                  <p>{reportedData.Property[0].Brand}</p>
                                                </td>
                                              </tr>
                                              <tr className="table-row">
                                                <td className="table-cell">
                                                  <span className='prop-td'>Top Color:</span>
                                                  <p>{reportedData.Property[0].TopColor_Description}</p>
                                                </td>
                                                <td className="table-cell">
                                                  <span className='prop-td'>Bottom Color:</span>
                                                  <p>{reportedData.Property[0].BottomColor_Description}</p>
                                                </td>
                                                <td className="table-cell">
                                                  <span className='prop-td'>Quantity:</span>
                                                  <p>{reportedData.Property[0].Quantity}</p>
                                                </td>
                                              </tr>
                                              <tr className="table-row">
                                                <td className="table-cell" colSpan="3">
                                                  <span className='prop-td'>Misc Description:</span>
                                                  <p>{reportedData.Property[0].Description}</p>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </fieldset>
                                      </div>
                                      <div className="release-info">
                                        <fieldset>
                                          <legend className='prop-legend'>Chain Of Custody Information</legend>
                                          <table className="table table-bordered mt-3">
                                            <thead className='text-dark master-table'>
                                              <tr>
                                                <th className=''>Incident No.</th>
                                                <th className=''>Activity Date/Time</th>
                                                <th className=''>Activity Type</th>
                                                <th className=''>Officer</th>
                                                <th className=''>Property Room Person</th>
                                                <th className=''>Location/Reason</th>
                                                <th className=''>Destroyed Date/Time</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {reportedData.PropertyRoom.map((item, index) => (
                                                <tr key={index}>
                                                  <td className='text-list'>{item.IncidentNumber1}</td>
                                                  <td className='text-list'>{item.ChainDate || 'N/A'}</td>
                                                  <td className='text-list'>{item.ActivityReason_Des}</td>
                                                  <td className='text-list'>{item.Officer_Name}</td>
                                                  <td className='text-list'>{item.PropertyRoomPerson_Name || 'N/A'}</td>
                                                  <td className='text-list'>{item.location || 'N/A'}</td>
                                                  <td className='text-list'>{item.DestroyDate || 'N/A'}</td>
                                                </tr>
                                              ))}
                                            </tbody>
                                          </table>
                                        </fieldset>
                                      </div>
                                    </>

                                  )}



                                  {/* Weapon info */}


                                  {reportedData.Property[0].Type === 'Weapon' && (
                                    <>
                                      <div className="property-info px-0">
                                        <fieldset>
                                          <legend className='prop-legend px-0'>Evidence Property Information</legend>
                                          <table className="table table-bordered mt-3">
                                            <tbody>
                                              <tr className="table-row">
                                                <td className="table-cell">
                                                  <span className='prop-td'>Property No:</span>
                                                  <p>{reportedData.Property[0].PropertyNumber}</p>
                                                </td>
                                                <td className="table-cell">
                                                  <span className='prop-td'>Property Type:</span>
                                                  <p>{reportedData.Property[0].Type}</p>
                                                </td>
                                                <td className="table-cell">
                                                  <span className='prop-td'>Reported Date:</span>
                                                  <p>{reportedData.Property[0].ReportedDtTm}</p>
                                                </td>
                                              </tr>
                                              <tr className="table-row">
                                                <td className="table-cell">
                                                  <span className='prop-td'>Incident No:</span>
                                                  <p>{reportedData.Property[0].IncidentNumber}</p>
                                                </td>
                                                <td className="table-cell">
                                                  <span className='prop-td'>Loss Code:</span>
                                                  <p>{reportedData.Property[0].LossCode_Description}</p>
                                                </td>
                                                <td className="table-cell">
                                                  <span className='prop-td'>Owner Name:</span>
                                                  <p>{reportedData.Property[0].OwnerDescription || 'N/A'}</p>
                                                </td>
                                              </tr>
                                              <tr className="table-row">
                                                <td className="table-cell">
                                                  <span className='prop-td'>Category:</span>
                                                  <p>{reportedData.Property[0].Category_Description}</p>
                                                </td>
                                                <td className="table-cell">
                                                  <span className='prop-td'>Classification:</span>
                                                  <p>{reportedData.Property[0].Classification_Description || 'N/A'}</p>
                                                </td>
                                                <td className="table-cell">
                                                  <span className='prop-td'>Brand:</span>
                                                  <p>{reportedData.Property[0].Brand}</p>
                                                </td>
                                              </tr>
                                              <tr className="table-row">
                                                <td className="table-cell">
                                                  <span className='prop-td'>Top Color:</span>
                                                  <p>{reportedData.Property[0].TopColor_Description}</p>
                                                </td>
                                                <td className="table-cell">
                                                  <span className='prop-td'>Bottom Color:</span>
                                                  <p>{reportedData.Property[0].BottomColor_Description}</p>
                                                </td>
                                                <td className="table-cell">
                                                  <span className='prop-td'>Quantity:</span>
                                                  <p>{reportedData.Property[0].Quantity}</p>
                                                </td>
                                              </tr>
                                              <tr className="table-row">
                                                <td className="table-cell" colSpan="3">
                                                  <span className='prop-td'>Misc Description:</span>
                                                  <p>{reportedData.Property[0].Description}</p>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </fieldset>
                                      </div>
                                      <div className="release-info">
                                        <fieldset>
                                          <legend className='prop-legend'>Chain Of Custody Information</legend>
                                          <table className="table table-bordered mt-3">
                                            <thead className='text-dark master-table'>
                                              <tr>
                                                <th className=''>Incident No.</th>
                                                <th className=''>Activity Date/Time</th>
                                                <th className=''>Activity Type</th>
                                                <th className=''>Officer</th>
                                                <th className=''>Property Room Person</th>
                                                <th className=''>Location/Reason</th>
                                                <th className=''>Destroyed Date/Time</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {reportedData.PropertyRoom.map((item, index) => (
                                                <tr key={index}>
                                                  <td className='text-list'>{item.IncidentNumber1}</td>
                                                  <td className='text-list'>{item.ChainDate || 'N/A'}</td>
                                                  <td className='text-list'>{item.ActivityReason_Des}</td>
                                                  <td className='text-list'>{item.Officer_Name}</td>
                                                  <td className='text-list'>{item.PropertyRoomPerson_Name || 'N/A'}</td>
                                                  <td className='text-list'>{item.location || 'N/A'}</td>
                                                  <td className='text-list'>{item.DestroyDate || 'N/A'}</td>
                                                </tr>
                                              ))}
                                            </tbody>
                                          </table>
                                        </fieldset>
                                      </div>
                                    </>

                                  )}






                                </div>

                              </>
                            ))
                          }
                        </>
                        :
                        <>
                        </>
                    }
                  </div>
                </div>
              </>
              :
              <>
              </>
            :
            <>
            </>
        }
      </div>

    </>
  )
}

export default ChainOfCustodyReport
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { AddDeleteUpadate, fetchPostData } from '../../../../hooks/Api'
import DataTable from 'react-data-table-component'
import { toastifyError, toastifySuccess } from '../../../../Common/AlertMsg'
import DeletePopUpModal from '../../../../Common/DeleteModal'
import { AgencyContext } from '../../../../../Context/Agency/Index'
import { RequiredFieldIncident, RequiredFieldOnConditon } from '../../../Utility/Personnel/Validation'
import { Decrypt_Id_Name, DecryptedList, base64ToString, filterPassedTime, getShowingMonthDateYear, tableCustomStyles } from '../../../../Common/Utility'
import { RequiredFieldSpaceNotAllow } from '../../../Agency/AgencyValidation/validators'
import { Comman_changeArrayFormat, threeColArray } from '../../../../Common/ChangeArrayFormat'
import Select from "react-select";
import DatePicker from "react-datepicker";
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { get_LocalStoreData } from '../../../../../redux/actions/Agency'
import PropListng from '../../../ShowAllList/PropListng'
import ChangesModal from '../../../../Common/ChangesModal'
import { get_ScreenPermissions_Data } from '../../../../../redux/actions/IncidentAction'

const RecoveredProperty = (props) => {

    const { ListData, DecPropID, DecMPropID, DecIncID } = props

    const dispatch = useDispatch();
    const localStoreData = useSelector((state) => state.Agency.localStoreData);
    const effectiveScreenPermission = useSelector((state) => state.Incident.effectiveScreenPermission);
    const uniqueId = sessionStorage.getItem('UniqueUserID') ? Decrypt_Id_Name(sessionStorage.getItem('UniqueUserID'), 'UForUniqueUserID') : '';
    const agencyOfficerDrpData = useSelector((state) => state.DropDown.agencyOfficerDrpData);

    const useQuery = () => {
        const params = new URLSearchParams(useLocation().search);
        return {
            get: (param) => params.get(param)
        };
    };

    const query = useQuery();
    var ProId = query?.get("ProId");
    var MProId = query?.get("MProId");
    var IncID = query?.get("IncId");


    if (!ProId) ProId = 0;
    else ProId = parseInt(base64ToString(ProId));
    if (!MProId) ProId = 0;
    else MProId = parseInt(base64ToString(MProId));
    if (!IncID) IncID = 0;
    else IncID = parseInt(base64ToString(IncID));
    let MstPage = query?.get('page');

    const { get_Property_Count, setChangesStatus } = useContext(AgencyContext);
    //screen permission 
    const [propertyData, setPropertyData] = useState([]);
    const [recoveredPropertyID, setRecoveredPropertyID] = useState();
    const [status, setStatus] = useState(false);

    const [propertyID, setPropertyID] = useState();
    const [masterPropertyID, setMasterPropertyID] = useState();
    const [mainIncidentID, setMainIncidentID] = useState('');
    const [headOfAgency, setHeadOfAgency] = useState([]);
    const [loginAgencyID, setLoginAgencyID] = useState('');
    const [loginPinID, setLoginPinID,] = useState('');
    const [recoverDate, setRecoverDate] = useState();
    const [recoveryTypeDrpData, setRecoveryTypeDrpData] = useState([]);
    const [dispositionsDrpData, setDispositionsDrpData] = useState([]);
    const [recoverTypeCode, setRecoverTypeCode] = useState('');
    const [remainBalance, setRemainBalance] = useState(0);
    const [updateStatus, setUpdateStatus] = useState();

    const [value, setValue] = useState({
        'RecoveredPropertyID': '', 'MasterPropertyID': '', 'PropertyID': '', 'RecoveredIDNumber': '', 'RecoveredDateTime': '', 'OfficerPFID': null,
        'RecoveryTypeID': null, 'AgencyID': '', 'RecoveredValue': '', 'Comments': '', 'Balance': '', 'DispositionID': null, 'UCRRecoveredID': null,
        'CreatedByUserFK': '', 'ModifiedByUserFK': '', 'IsMaster': MstPage === "MST-Property-Dash" ? true : false,
    });

    const [errors, setErrors] = useState({
        'DispositionIDError': '', 'OfficerPFIDError': '', 'RecoveredDateTimeError': '', 'RecoveryTypeIDError': '', 'Comments': '', 'RecoverTypeCode': '',
    })

    useEffect(() => {
        if (!localStoreData?.AgencyID || !localStoreData?.PINID) {
            if (uniqueId) dispatch(get_LocalStoreData(uniqueId));
        }
    }, []);

    useEffect(() => {
        if (localStoreData) {
            setLoginPinID(localStoreData?.PINID); setLoginAgencyID(localStoreData?.AgencyID);
            dispatch(get_ScreenPermissions_Data("P066", localStoreData?.AgencyID, localStoreData?.PINID));
        }
    }, [localStoreData]);

    useEffect(() => {
        if (loginAgencyID || loginPinID) {

            setValue({ ...value, 'PropertyID': ProId, 'AgencyID': loginAgencyID, 'CreatedByUserFK': loginPinID, 'MasterPropertyID': MProId, })
        }
    }, [loginAgencyID, loginPinID]);

    useEffect(() => {
        if (DecPropID || DecMPropID) {
            get_property_Data(DecPropID, DecMPropID); setPropertyID(DecPropID); setMasterPropertyID(DecMPropID);
        }
    }, [DecPropID, DecMPropID]);

    useEffect(() => {
        if (IncID) { setMainIncidentID(IncID); }
    }, [IncID])


    const check_Validation_Error = (e) => {
        const DispositionIDErr = RequiredFieldIncident(value.DispositionID);
        const OfficerPFIDErr = RequiredFieldIncident(value.OfficerPFID);
        const RecoveredDateTimeErr = RequiredFieldIncident(value.RecoveredDateTime);
        const RecoveryTypeIDErr = RequiredFieldIncident(value.RecoveryTypeID);
        const CommentsErr = RequiredFieldSpaceNotAllow(value.Comments);
        const ContactErr = recoverTypeCode === 'P' ? RequiredFieldOnConditon(value.RecoveredValue) : 'true';
        setErrors(prevValues => {
            return {
                ...prevValues,
                ['DispositionIDError']: DispositionIDErr || prevValues['DispositionIDError'],
                ['OfficerPFIDError']: OfficerPFIDErr || prevValues['OfficerPFIDError'],
                ['RecoveredDateTimeError']: RecoveredDateTimeErr || prevValues['RecoveredDateTimeError'],
                ['RecoveryTypeIDError']: RecoveryTypeIDErr || prevValues['RecoveryTypeIDError'],
                ['Comments']: CommentsErr || prevValues['Comments'],
                ['ContactError']: ContactErr || prevValues['ContactError'],
            }
        })
    }

    // Check All Field Format is True Then Submit 
    const { DispositionIDError, OfficerPFIDError, RecoveredDateTimeError, RecoveryTypeIDError, Comments, ContactError } = errors

    useEffect(() => {
        if (DispositionIDError === 'true' && OfficerPFIDError === 'true' && RecoveredDateTimeError === 'true' && RecoveryTypeIDError === 'true' && Comments === 'true' && ContactError === 'true') {
            if (recoveredPropertyID) { Update_RecoveredProperty() }
            else { Add_RecoveredProperty() }
        }
    }, [DispositionIDError, OfficerPFIDError, RecoveredDateTimeError, RecoveryTypeIDError, Comments, ContactError])

    useEffect(() => {
        if (loginAgencyID) { get_Head_Of_Agency(loginAgencyID); get_RecoveryType(loginAgencyID); get_Dispositions(); }
    }, [loginAgencyID]);

    useEffect(() => {
        if (sessionStorage.getItem('propertyStolenValue')) {

            if (propertyData) {
                let remainBal = Decrypt_Id_Name(sessionStorage.getItem('propertyStolenValue'), 'SForStolenValue');
                const newArr = propertyData.map((val) => { return val.RecoveredValue });

                for (let i in newArr) { remainBal = parseFloat(remainBal) - parseFloat(newArr[i]); }
                remainBal = parseFloat(remainBal)?.toFixed(2)
                setRemainBalance(remainBal);

                setValue({ ...value, ['Balance']: remainBal })
            } else {
                setValue({ ...value, ['Balance']: parseInt(Decrypt_Id_Name(sessionStorage.getItem('propertyStolenValue'), 'SForStolenValue')) });
                setRemainBalance(parseFloat(Decrypt_Id_Name(sessionStorage.getItem('propertyStolenValue'), 'SForStolenValue')));
            }
        } else { setRemainBalance(0) }
    }, [updateStatus, propertyData, recoverTypeCode])

    const get_RecoveryType = (loginAgencyID) => {
        const val = { AgencyID: loginAgencyID }
        fetchPostData('RecoveryType/GetDataDropDown_RecoveryType', val).then((data) => {
            if (data) {
                setRecoveryTypeDrpData(threeColArray(data, 'RecoveryTypeID', 'Description', 'RecoveryTypeCode'));
            }
            else { setRecoveryTypeDrpData([]) }
        })
    };

    const get_Head_Of_Agency = (LoginAgencyID) => {
        const val = { AgencyID: LoginAgencyID }
        fetchPostData('DropDown/GetData_HeadOfAgency', val).then((data) => {
            if (data) {
                setHeadOfAgency(Comman_changeArrayFormat(data, 'PINID', 'HeadOfAgency'));
            }
            else {
                setHeadOfAgency([])
            }
        })
    };

    const get_Dispositions = (loginAgencyID) => {
        const val = { AgencyID: loginAgencyID }
        fetchPostData('PropertyDispositions/GetDataDropDown_PropertyDispositions', val).then((data) => {
            if (data) {
                setDispositionsDrpData(Comman_changeArrayFormat(data, 'PropertyDispositionsID', 'Description'));
            }
            else { setDispositionsDrpData([]) }
        })
    };

    const HandleChanges = (e) => {
        setChangesStatus(true)
        if (e.target.name === 'RecoveredValue') {
            var ele = e.target.value.replace(/[^0-9\.]/g, "")
            if (ele.includes('.')) {
                if (ele.length === 16) {
                    var total = 0
                    total = parseFloat(remainBalance) - parseFloat(e.target.value)
                    total = total?.toFixed(2)
                    setValue(pre => {
                        return {
                            ...pre,
                            ['Balance']: total,
                            // ['Balance']: parseFloat(remainBalance) - parseFloat(e.target.value),
                            [e.target.name]: ele
                        }
                    });
                } else {
                    if (ele.substr(ele.indexOf('.') + 1).slice(0, 2)) {
                        const checkDot = ele.substr(ele.indexOf('.') + 1).slice(0, 2).match(/\./g)
                        if (!checkDot) {
                            var total = 0
                            total = parseFloat(remainBalance) - parseFloat(e.target.value)
                            total = total?.toFixed(2)
                            // console.log(total?.substring(0, total.indexOf('.') + 3))
                            setValue(pre => {
                                return {
                                    ...pre,
                                    ['Balance']: total,
                                    // ['Balance']: total?.substring(0, total?.indexOf('.') + 3),
                                    [e.target.name]: ele.substring(0, ele.indexOf(".")) + '.' + ele.substr(ele.indexOf('.') + 1).slice(0, 2)
                                }
                            });
                        } else {

                        }
                    } else {
                        var total = 0
                        total = parseFloat(remainBalance) - parseFloat(e.target.value)
                        total = total.toFixed(2)
                        setValue(pre => {
                            return {
                                ...pre,
                                ['Balance']: total,
                                // ['Balance']: total?.substring(0, total?.indexOf('.') + 3),
                                [e.target.name]: ele
                            }
                        });
                    }
                }
            } else {
                if (ele.length === 16) {
                    var total = 0;
                    total = parseFloat(remainBalance) - parseFloat(ele)
                    total = total?.toFixed(2)
                    // console.log(total)
                    setValue({ ...value, ['Balance']: total ? total : remainBalance, [e.target.name]: ele });
                } else {
                    // console.log(parseFloat(remainBalance) - parseFloat(ele))
                    var total = 0;
                    total = parseFloat(remainBalance) - parseFloat(ele ? ele : 0)
                    total = total?.toFixed(2)
                    // console.log(total)
                    setValue({ ...value, ['Balance']: total ? total : remainBalance, [e.target.name]: ele });

                }
            }
        } else {
            setValue({
                ...value,
                [e.target.name]: e.target.value
            })
        }
    }


    // const HandleChanges = (e) => {
    //     if (e.target.name === 'RecoveredValue') {
    //         const ele = e.target.value.replace(/[^0-9\.]/g, "")
    //         console.log(ele)
    //         if (ele.includes('.')) {
    //             console.log(ele)
    //             if (ele.length === 16) {
    //                 let total = 0
    //                 total = parseFloat(remainBalance) - parseFloat(e.target.value)
    //                 total = total?.toFixed(2)
    //                 setValue(pre => { return { ...pre, ['Balance']: total, [e.target.name]: ele } });
    //             } else {
    //                 if (ele.substr(ele.indexOf('.') + 1).slice(0, 2)) {
    //                     const checkDot = ele.substr(ele.indexOf('.') + 1).slice(0, 2).match(/\./g)
    //                     console.log(checkDot)
    //                     if (!checkDot) {
    //                         let total = 0
    //                         total = parseFloat(remainBalance) - parseFloat(e.target.value)
    //                         total = total?.toFixed(2)
    //                         setValue(pre => { return { ...pre, ['Balance']: total, [e.target.name]: ele.substring(0, ele.indexOf(".")) + '.' + ele.substr(ele.indexOf('.') + 1).slice(0, 2) } });
    //                     }
    //                 }
    //                 let total = 0
    //                 total = parseFloat(remainBalance) - parseFloat(e.target.value)
    //                 total = total?.toFixed(2)
    //                 setValue(pre => { return { ...pre, ['Balance']: total, [e.target.name]: ele } });
    //             }
    //         } else {
    //             if (ele.length === 16) {
    //                 let total = 0;
    //                 total = parseFloat(remainBalance) - parseFloat(ele)
    //                 total = total?.toFixed(2)
    //                 setValue({ ...value, ['Balance']: total ? total : remainBalance, [e.target.name]: ele });
    //             }
    //             let total = 0;
    //             total = parseFloat(remainBalance) - parseFloat(ele ? ele : 0)
    //             total = total?.toFixed(2)
    //             setValue({ ...value, ['Balance']: total ? total : remainBalance, [e.target.name]: ele });
    //         }
    //     } else { setValue({ ...value, [e.target.name]: e.target.value }) }
    // }

    const ChangeDropDown = (e, name) => {
        if (e) {
            setChangesStatus(true)
            if (name === 'RecoveryTypeID') {
                setValue({ ...value, ['RecoveryTypeID']: e.value })
                setRecoverTypeCode(e.id)
                if (e.id === 'FU' || e.id === 'F') {
                    if (propertyData) {
                        setValue(pre => { return { ...pre, ['RecoveredValue']: remainBalance } })
                    } else {
                        setValue(pre => { return { ...pre, ['RecoveredValue']: sessionStorage.getItem('propertyStolenValue') ? Decrypt_Id_Name(sessionStorage.getItem('propertyStolenValue'), 'SForStolenValue') : '', } })
                    }
                } else if (e.id === 'P' || e.id !== 'FU' || e.id !== 'F') {
                    setValue(pre => { return { ...pre, ['RecoveredValue']: '' } })
                }
            } else {
                setValue({ ...value, [name]: e.value })
            }
        } else {
            if (name === 'RecoveryTypeID') {
                setValue({ ...value, [name]: null, ['RecoveredValue']: '' }); setUpdateStatus(updateStatus + 1);
            } else {
                setValue({ ...value, [name]: null });
            }
        }
    }

    const Add_RecoveredProperty = (e) => {
        if (value.RecoveredValue != 0) {
            if (parseFloat(value.RecoveredValue) <= parseFloat(remainBalance)) {
                if (remainBalance !== 0) {
                    AddDeleteUpadate('RecoveredProperty/Insert_RecoveredProperty', value)
                        .then((res) => {
                            const parsedData = JSON.parse(res.data);
                            const message = parsedData.Table[0].Message;
                            toastifySuccess(message);
                            setChangesStatus(false);
                            get_property_Data(propertyID, masterPropertyID); get_Property_Count(propertyID, masterPropertyID, MstPage === "MST-Property-Dash" ? true : false);
                            setStatus(false); reset();
                        })
                } else {
                    toastifyError("Remaining Balance is Zero")
                }
            } else {
                toastifyError("Recovered value should not be greater than Remaining Value")
            }
        } else {
            toastifyError("The recovered value must be greater than zero")
        }
    }

    const Update_RecoveredProperty = () => {
        AddDeleteUpadate('RecoveredProperty/Update_RecoveredProperty', value).then((res) => {
            const parsedData = JSON.parse(res.data);
            const message = parsedData.Table[0].Message;
            toastifySuccess(message);
            setChangesStatus(false);
            setStatus(false); reset(); get_property_Data(propertyID, masterPropertyID);
        })
    }

    const OnClose = () => {
        reset(); setRecoveredPropertyID(); setRecoverTypeCode()
    }

    const colourStyles = {
        control: (styles) => ({
            ...styles, backgroundColor: "#fce9bf",
            height: 20,
            minHeight: 30,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
        }),
    }

    const startRef = React.useRef();
    const startRef1 = React.useRef();
    const startRef2 = React.useRef();
    const startRef3 = React.useRef();

    const onKeyDown = (e) => {
        if (e.keyCode === 9 || e.which === 9) {
            startRef.current.setOpen(false);
            startRef1.current.setOpen(false);
            startRef2.current.setOpen(false);
            startRef3.current.setOpen(false);
        }
    };

    const reset = () => {
        setValue(pre => {
            return {
                ...pre, 'RecoveredIDNumber': '', 'RecoveredDateTime': '', 'OfficerPFID': '', 'RecoveryTypeID': '', 'RecoveredValue': '', 'Comments': '', 'DispositionID': '', 'UCRRecoveredID': '', 'AgencyID': loginAgencyID,
            }
        });
        setErrors({
            ...errors,
            'DispositionIDError': '', 'OfficerPFIDError': '', 'RecoveredDateTimeError': '', 'RecoveryTypeIDError': '', 'Comments': '', 'ContactError': '',
        });
        setRecoverDate("");
    }

    const get_property_Data = (propertyID, masterPropertyID) => {
        const val = { 'PropertyID': propertyID, 'MasterPropertyID': masterPropertyID, 'IsMaster': MstPage === "MST-Property-Dash" ? true : false, }
        fetchPostData('RecoveredProperty/GetData_RecoveredProperty', val).then((res) => {
            if (res) {
                setPropertyData(res)
            } else {
                setPropertyData();
            }
        })
    }

    const columns = [
        {
            name: 'Recovered ID Number',
            selector: (row) => row.RecoveredIDNumber,
            sortable: true
        },
        {
            name: 'Comments',
            selector: (row) => row.Comments,
            sortable: true
        },
        {
            name: 'Recovered Value',
            selector: (row) => row.RecoveredValue,
            sortable: true

        },
        {
            name: <p className='text-end' style={{ position: 'absolute', top: 8, right: 30 }}>Action</p>,
            cell: row =>
                <div className="div" style={{ position: 'absolute', top: 4, right: 30 }}>
                    {
                        effectiveScreenPermission ?
                            effectiveScreenPermission[0]?.DeleteOK ?
                                <span onClick={() => { setRecoveredPropertyID(row.RecoveredPropertyID); }} className="btn btn-sm bg-green text-white px-1 py-0 mr-1" data-toggle="modal" data-target="#DeleteModal">
                                    <i className="fa fa-trash"></i>
                                </span>
                                : <></>
                            : <span onClick={() => { setRecoveredPropertyID(row.RecoveredPropertyID); }} className="btn btn-sm bg-green text-white px-1 py-0 mr-1" data-toggle="modal" data-target="#DeleteModal">
                                <i className="fa fa-trash"></i>
                            </span>
                    }
                    {/* <span onClick={() => { setRecoveredPropertyID(row.RecoveredPropertyID); }} className="btn btn-sm bg-green text-white px-1 py-0 mr-1" data-toggle="modal" data-target="#DeleteModal">
                        <i className="fa fa-trash"></i>
                    </span> */}
                </div>
        }
    ]

    const setStatusFalse = (e, row) => {
        setStatus(false)
        setRecoveredPropertyID(row.RecoveredPropertyID);
    }

    const Delete_RecoveredProperty = () => {
        const val = { 'RecoveredPropertyID': recoveredPropertyID, 'DeletedByUserFK': loginPinID, }
        AddDeleteUpadate('RecoveredProperty/Delete_RecoveredProperty', val).then((res) => {
            if (res) {
                const parsedData = JSON.parse(res.data);
                const message = parsedData.Table[0].Message;
                toastifySuccess(message);
                get_property_Data(propertyID, masterPropertyID);
                get_Property_Count(propertyID, masterPropertyID, MstPage === "MST-Property-Dash" ? true : false);
                setRecoveredPropertyID('')
                reset('');
            } else console.log("Somthing Wrong");
        })
    }

    const escFunction = useCallback((event) => {
        if (event.key === "Escape") {
            reset(); setRecoveredPropertyID(); setRecoverTypeCode()
        }
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
        return () => { document.removeEventListener("keydown", escFunction, false); };
    }, [escFunction]);

    return (
        <>
            <PropListng {...{ ListData }} />
            <div className="col-12 col-md-12 pt-1 p-0" >
                <div className="row">
                    <div className="col-2 col-md-2 col-lg-2 mt-3">
                        <label htmlFor="" className='label-name '>Officer Pf{errors.OfficerPFIDError !== 'true' ? (
                            <p style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.OfficerPFIDError}</p>
                        ) : null}</label>
                    </div>
                    <div className="col-4 col-md-4 col-lg-2  mt-2" >
                        {/* <Select
                            name='OfficerPFID'
                            id='OfficerPFID'
                            value={headOfAgency?.filter((obj) => obj.value === value?.OfficerPFID)}
                            options={headOfAgency}
                            onChange={(e) => ChangeDropDown(e, 'OfficerPFID')}
                            styles={colourStyles}
                            isClearable
                            placeholder="Select..."
                        /> */}
                        <Select
                            name='OfficerPFID'
                            styles={colourStyles}
                            value={agencyOfficerDrpData?.filter((obj) => obj.value === value?.OfficerPFID)}
                            isClearable
                            options={agencyOfficerDrpData}
                            onChange={(e) => ChangeDropDown(e, 'OfficerPFID')}
                            placeholder="Select..."
                        />
                    </div>
                    <div className="col-2 col-md-2 col-lg-2 mt-3">
                        <label htmlFor="" className='label-name '>Recovered Date/Time  {errors.RecoveredDateTimeError !== 'true' ? (
                            <p style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.RecoveredDateTimeError}</p>
                        ) : null}</label>
                    </div>
                    <div className="col-4 col-md-4 col-lg-2" >
                        <DatePicker
                            id='RecoveredDateTime'
                            name='RecoveredDateTime'
                            ref={startRef}
                            onKeyDown={onKeyDown}
                            onChange={(date) => { setRecoverDate(date); setValue({ ...value, ['RecoveredDateTime']: date ? getShowingMonthDateYear(date) : null }) }}
                            dateFormat="MM/dd/yyyy HH:mm"
                            timeInputLabel
                            isClearable={value?.RecoveredDateTime ? true : false}
                            selected={recoverDate}
                            placeholderText={value?.RecoveredDateTime ? value.RecoveredDateTime : 'Select...'}
                            showTimeSelect
                            timeIntervals={1}
                            timeCaption="Time"
                            className='requiredColor'
                            autoComplete='Off'
                            showMonthDropdown
                            showYearDropdown
                            peekNextMonth
                            filterTime={filterPassedTime}
                            dropdownMode="select"
                            maxDate={new Date()}
                            minDate
                        />
                    </div>
                    <div className="col-2 col-md-2 col-lg-2 mt-3">
                        <Link to={'/ListManagement?page=Recovery%20Type&call=/Prop-Home'} className='new-link'>
                            Recovery Type{errors.RecoveryTypeIDError !== 'true' ? (
                                <p style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.RecoveryTypeIDError}</p>
                            ) : null}
                        </Link>
                    </div>
                    <div className="col-4 col-md-4 col-lg-2 mt-2" >
                        <Select
                            name='RecoveryTypeID'
                            value={recoveryTypeDrpData?.filter((obj) => obj.value === value?.RecoveryTypeID)}
                            options={recoveryTypeDrpData}
                            onChange={(e) => ChangeDropDown(e, 'RecoveryTypeID')}
                            styles={colourStyles}
                            isClearable
                            placeholder="Select..."
                        />
                    </div>
                    <div className="col-2 col-md-2 col-lg-2 mt-3">
                        <label htmlFor="" className='label-name '>Recovered Value {errors.ContactError !== 'true' ? (
                            <p style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.ContactError}</p>
                        ) : null}</label>
                    </div>
                    <div className="col-4 col-md-4 col-lg-2 text-field mt-2" >
                        <input type="text" name="RecoveredValue" value={value?.RecoveredValue} onChange={HandleChanges} className={`${recoverTypeCode === 'P' ? " " : "readonlyColor"} requiredColor`} required readOnly={recoverTypeCode === 'P' ? false : true}
                        />
                    </div>

                    <div className="col-2 col-md-2 col-lg-2 mt-3">
                        <label htmlFor="" className='label-name '>Balance</label>
                    </div>
                    <div className="col-4 col-md-4 col-lg-2 text-field mt-2" >
                        <input type="text" name="Balance" value={value?.Balance < 0 ? 0 : value?.Balance} onChange={HandleChanges} className="readonlyColor" required readOnly />
                    </div>
                    <div className="col-2 col-md-2 col-lg-2 mt-3">
                        <Link to={'/ListManagement?page=Property%20Dispositions&call=/Prop-Home'} className='new-link'>
                            Disposition{errors.DispositionIDError !== 'true' ? (
                                <p style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.DispositionIDError}</p>
                            ) : null}
                        </Link>
                    </div>
                    <div className="col-10 col-md-10 col-lg-2 mt-2" >
                        <Select
                            name='DispositionID'
                            value={dispositionsDrpData?.filter((obj) => obj.value === value?.DispositionID)}
                            options={dispositionsDrpData}
                            onChange={(e) => ChangeDropDown(e, 'DispositionID')}
                            styles={colourStyles}
                            isClearable
                            placeholder="Select..."
                        />
                    </div>
                    <div className="col-2 col-md-2 col-lg-2 mt-3">
                        <label htmlFor="" className='label-name '>Comments{errors.Comments !== 'true' ? (
                            <p style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.Comments}</p>
                        ) : null}</label>
                    </div>
                    <div className="col-10 col-md-10 col-lg-6 text-field mt-2" >
                        <textarea name='Comments' value={value?.Comments} onChange={HandleChanges} id="Comments" cols="30" rows='2' className="form-control requiredColor" ></textarea>
                    </div>
                </div>
                <div className="btn-box text-right  mr-1 mb-2">
                    {
                        effectiveScreenPermission ?
                            effectiveScreenPermission[0]?.AddOK ?
                                <button type="button" className="btn btn-sm btn-success mr-1" onClick={(e) => { check_Validation_Error(); }}>Save</button>
                                :
                                <>
                                </>
                            :
                            <button type="button" className="btn btn-sm btn-success mr-1" onClick={(e) => { check_Validation_Error(); }}>Save</button>
                    }
                    <button type="button" data-dismiss="modal" className="btn btn-sm btn-success mr-1" onClick={OnClose} >Reset</button>
                </div>
                <div className="col-12">
                    <DataTable
                        dense
                        columns={columns}
                        // data={propertyData}
                        data={effectiveScreenPermission ? effectiveScreenPermission[0]?.DisplayOK ? propertyData : [] : propertyData}
                        noDataComponent={effectiveScreenPermission ? effectiveScreenPermission[0]?.DisplayOK ? "There are no data to display" : "You don’t have permission to view data" : 'There are no data to display'}
                        highlightOnHover
                        fixedHeader
                        persistTableHead={true}
                        customStyles={tableCustomStyles}
                        pagination
                        paginationPerPage={'100'}
                        paginationRowsPerPageOptions={[100, 150, 200, 500]}
                        showPaginationBottom={100}
                    />
                </div>
            </div>
            <ChangesModal func={check_Validation_Error} setToReset={OnClose} />
            <DeletePopUpModal func={Delete_RecoveredProperty} />
        </>
    )
}

export default RecoveredProperty

const Get_Property_Code = (data, dropDownData) => {
    const result = data?.map((sponsor) => (sponsor.RecoveryTypeID))

    const result2 = dropDownData?.map((sponsor) => {
        if (sponsor.value === result[0]) {
            return { value: result[0], label: sponsor.label, id: sponsor.id }
        }
    }
    )
    const val = result2.filter(function (element) {
        return element !== undefined;
    });
    return val[0]?.id
}
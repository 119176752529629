import React, { useContext, useEffect, useState } from 'react'
import Select from "react-select";
import DatePicker from "react-datepicker";
import { AgencyContext } from '../../../../../Context/Agency/Index';
import { Link, useLocation } from 'react-router-dom';
import { Decrypt_Id_Name, getShowingMonthDateYear, getShowingWithOutTime } from '../../../../Common/Utility';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { get_LocalStoreData } from '../../../../../redux/actions/Agency';
import { AddDeleteUpadate, fetchPostData } from '../../../../hooks/Api';
import { toastifySuccess } from '../../../../Common/AlertMsg';
import VehicleListing from '../../../ShowAllList/VehicleListing';
import ChangesModal from '../../../../Common/ChangesModal';
import { get_ScreenPermissions_Data } from '../../../../../redux/actions/IncidentAction';

const AddInformation = (props) => {

    const { ListData, DecVehId, DecMVehId, DecIncID ,propertystatus, setPropertyStatus } = props
    const dispatch = useDispatch();
    const localStoreData = useSelector((state) => state.Agency.localStoreData);
    const uniqueId = sessionStorage.getItem('UniqueUserID') ? Decrypt_Id_Name(sessionStorage.getItem('UniqueUserID'), 'UForUniqueUserID') : '';
    const effectiveScreenPermission = useSelector((state) => state.Incident.effectiveScreenPermission);


    const useQuery = () => {
        const params = new URLSearchParams(useLocation().search);
        return {
            get: (param) => params.get(param)
        };
    };

    const query = useQuery();
    let MstVehicle = query?.get('page');

    const [loginAgencyID, setLoginAgencyID] = useState('');
    const [loginPinID, setLoginPinID,] = useState('');
    const [mainIncidentID, setMainIncidentID] = useState('');
    const [editval, setEditval] = useState();
    const { setChangesStatus } = useContext(AgencyContext)
    const [vehicleId, setVehicleId] = useState('');
    const [statesChangeStatus, setStatesChangeStatus] = useState(false);

    const [value, setValue] = useState({
        'PropertyID': '', 'ReportedDtTm': '', 'TagID': '', 'NICBID': '', 'DestroyDtTm': '', 'Description': '', 'IncidentID': '',
        'IsImmobalizationDevice': '', 'IsEligibleForImmobalization': '', 'ModifiedByUserFK': '', 'AgencyID': '',
        'IsMaster': MstVehicle === "MST-Vehicle-Dash" ? true : false, 'IsSendToPropertyRoom': ''
    })

    useEffect(() => {
        if (!localStoreData?.AgencyID || !localStoreData?.PINID) {
            if (uniqueId) dispatch(get_LocalStoreData(uniqueId));
        }
    }, []);

    useEffect(() => {
        if (localStoreData) {
            setLoginPinID(localStoreData?.PINID); setLoginAgencyID(localStoreData?.AgencyID);
            // GetSingleData(localStoreData?.vehicleID);
            dispatch(get_ScreenPermissions_Data("V093", localStoreData?.AgencyID, localStoreData?.PINID));

        }
    }, [localStoreData]);

    useEffect(() => {
        if (DecVehId) {
            setValue({
                ...value,
                'PropertyID': DecVehId, 'ModifiedByUserFK': '',
                'AgencyID': loginAgencyID, 'CreatedByUserFK': loginPinID, 'IncidentID': DecIncID,
            });
        }
    }, [DecVehId]);

    useEffect(() => {
        if (DecVehId || DecMVehId) {
            setVehicleId(DecVehId); GetSingleData(DecVehId, DecMVehId);
        }
    }, [DecVehId, DecMVehId]);

    const GetSingleData = (PropertyID, masterPropertyID) => {
        const val = { 'PropertyID': PropertyID, 'MasterPropertyID': masterPropertyID, 'IsMaster': MstVehicle === "MST-Vehicle-Dash" ? true : false, }
        const val2 = { MasterPropertyID: masterPropertyID, }
        fetchPostData('PropertyVehicle/GetSingleData_PropertyVehicle', val).then((res) => {
            if (res) {
                // console.log(res,'res')
                setEditval(res);
            }
            else { setEditval([]); }
        })
    }

    const HandleChanges = (e) => {
        setChangesStatus(true); setStatesChangeStatus(true);
        if (e.target.name === 'IsImmobalizationDevice' || e.target.name === 'IsEligibleForImmobalization' || e.target.name === 'IsSendToPropertyRoom') {
            setValue({
                ...value,
                [e.target.name]: e.target.checked
            })
        } else {
            setValue({
                ...value,
                [e.target.name]: e.target.value
            })
        }
    }

    useEffect(() => {
        if (editval) {
            setValue({
                ...value,
                'PropertyID': vehicleId,
                'DestroyDtTm': editval[0]?.DestroyDtTm ? getShowingWithOutTime(editval[0]?.DestroyDtTm) : null,
                'Description': editval[0]?.Description, 'NICBID': editval[0]?.NICBID, 'TagID': editval[0]?.TagID,
                'IsImmobalizationDevice': editval[0]?.IsImmobalizationDevice,
                'IsEligibleForImmobalization': editval[0]?.IsEligibleForImmobalization,
                'IsSendToPropertyRoom': editval[0]?.IsSendToPropertyRoom,

            })
            if (editval[0]?.IsSendToPropertyRoom) {
                setPropertyStatus(true);
            }
            else{
                setPropertyStatus(false);
            }
        }
    }, [editval])

    const resetEditVal = () => {
        if (editval) {
            setValue({
                ...value,
                'PropertyID': vehicleId,
                'DestroyDtTm': editval[0]?.DestroyDtTm ? getShowingWithOutTime(editval[0]?.DestroyDtTm) : null,
                'Description': editval[0]?.Description, 'NICBID': editval[0]?.NICBID, 'TagID': editval[0]?.TagID,
                'IsImmobalizationDevice': editval[0]?.IsImmobalizationDevice,
                'IsEligibleForImmobalization': editval[0]?.IsEligibleForImmobalization,
                'IsSendToPropertyRoom': editval[0]?.IsSendToPropertyRoom,

            })
        }
    }

    const Update_Name = () => {
        const { AgencyID, PropertyID, IncidentID, DestroyDtTm, TagID, NICBID, Description, IsImmobalizationDevice, IsEligibleForImmobalization, ModifiedByUserFK, IsMaster, IsSendToPropertyRoom } = value;
        const val = {
            'PropertyID': PropertyID,
            'AgencyID': loginAgencyID,
            'IncidentID': IncidentID,
            'ModifiedByUserFK': loginPinID,
            'DestroyDtTm': DestroyDtTm, 'TagID': TagID, 'NICBID': NICBID, 'Description': Description, 'IsEligibleForImmobalization': IsEligibleForImmobalization,
            'IsImmobalizationDevice': IsImmobalizationDevice, 'IsMaster': IsMaster, 'IsSendToPropertyRoom': IsSendToPropertyRoom, MasterPropertyID: DecMVehId
        }
        AddDeleteUpadate('PropertyVehicle/AdditionalInfo_Vehicle', val).then((res) => {
            const parsedData = JSON.parse(res.data);
            const message = parsedData.Table[0].Message;
            toastifySuccess(message); setChangesStatus(false); setStatesChangeStatus(false);
            GetSingleData(DecVehId, DecMVehId);
        })
    }

    return (
        <>
            <VehicleListing {...{ ListData }} />
            <div className="col-12 col-md-12 col-lg-12 pt-2 p-0">
                <fieldset>
                    <legend>Additional Information</legend>
                    <div className="row">
                        <div className="col-2 col-md-2 col-lg-2 mt-2 ">
                            <label htmlFor="" className='new-label'>Tag Id</label>
                        </div>
                        <div className="col-2 col-md-2 col-lg-2 text-field mt-1">
                            <input type="text" name='TagID' id='TagID' value={value?.TagID} onChange={HandleChanges} className='readonlyColor' required readOnly />
                        </div>
                        <div className="col-2 col-md-2 col-lg-2 mt-2 ">
                            <label htmlFor="" className='new-label'>NIC Id</label>
                        </div>
                        <div className="col-2 col-md-2 col-lg-2 text-field mt-1">
                            <input type="text" name='NICBIDID' id='NICBIDID' value={value?.NICBIDID} onChange={HandleChanges} className='readonlyColor' required readOnly />
                        </div>
                        <div className="col-2 col-md-2 col-lg-2 mt-2 ">
                            <label htmlFor="" className='new-label'>Destory Date</label>
                        </div>
                        <div className="col-2 col-md-2 col-lg-2 ">
                            <DatePicker
                                id='DestroyDtTm'
                                name='DestroyDtTm'
                                className='readonlyColor'
                                onChange={(date) => { setStatesChangeStatus(true); setValue({ ...value, ['DestroyDtTm']: date ? getShowingMonthDateYear(date) : null }); setChangesStatus(true) }}
                                dateFormat="MM/dd/yyyy HH:mm"
                                timeInputLabel
                                isClearable={value?.DestroyDtTm ? true : false}
                                selected={value?.DestroyDtTm && new Date(value?.DestroyDtTm)}
                                placeholderText={value?.DestroyDtTm ? value.DestroyDtTm : 'Select...'}
                                showTimeSelect
                                timeIntervals={1}
                                timeCaption="Time"
                                readOnly
                                required
                                autoComplete="nope"
                                showYearDropdown
                                showMonthDropdown
                                dropdownMode="select"
                            />
                        </div>
                    </div>
                    <div className="row mt-2">

                        <div className="col-2 col-md-2 col-lg-2 mt-2 ">
                            <label htmlFor="" className='new-label'>Description</label>
                        </div>
                        <div className="col-10 col-md-10 col-lg-10 ">
                            <textarea name='Description' id="Description" maxLength={250} value={value?.Description} onChange={HandleChanges} cols="30" rows='1' className="form-control  " >
                            </textarea>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-5 col-md-4 col-lg-4 mt-2">
                            <div className="form-check ">
                                <input className="form-check-input" name='IsImmobalizationDevice' value={value?.IsImmobalizationDevice} checked={value?.IsImmobalizationDevice} onChange={HandleChanges} type="checkbox" id="flexCheckDefault2" />
                                <label className="form-check-label" htmlFor="flexCheckDefault2">
                                    Immobilization Device
                                </label>
                            </div>
                        </div>
                        <div className="col-5 col-md-4 col-lg-4 mt-2">
                            <div className="form-check ">
                                <input className="form-check-input" name='IsEligibleForImmobalization' value={value?.IsEligibleForImmobalization} checked={value?.IsEligibleForImmobalization} onChange={HandleChanges} type="checkbox" id="flexCheckDefault3" />
                                <label className="form-check-label" htmlFor="flexCheckDefault3">
                                    Eligible For Immobilization
                                </label>
                            </div>
                        </div>
                        <div className="col-5 col-md-4 col-lg-4 mt-2">
                            <div className="form-check ">
                                <input className="form-check-input" name='IsSendToPropertyRoom' value={value?.IsSendToPropertyRoom} onChange={HandleChanges} checked={value?.IsSendToPropertyRoom} type="checkbox" id="flexCheckDefault" />
                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                    Send To Property Room
                                </label>
                            </div>
                        </div>
                    </div>
                </fieldset>
                <div className="col-12 col-md-12 col-lg-12 mt-2 mb-1 text-right">
                    {
                        effectiveScreenPermission ?
                            effectiveScreenPermission[0]?.Changeok ?
                                <button type="button" disabled={!statesChangeStatus} className="btn btn-md py-1 btn-success " onClick={Update_Name} >Update</button>
                                :
                                <>
                                </>
                            :
                            <button type="button" disabled={!statesChangeStatus} className="btn btn-md py-1 btn-success " onClick={Update_Name} >Update</button>
                    }

                </div>
            </div >
            <ChangesModal func={Update_Name} setToReset={resetEditVal} />
        </>
    )
}

export default AddInformation